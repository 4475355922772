import { UserRes } from '~shared/dtos/user.dto'
import { ApiService } from './ApiService'

export const getUser = async () => {
  const { data } = await ApiService.get<UserRes | undefined>('user')
  return data
}

export const acceptTou = async () => {
  const { data } = await ApiService.post<Partial<UserRes> | undefined>(
    'user/accept-tou'
  )
  return data
}
