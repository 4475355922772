import { toDateString, toTimeString } from '@/utils/date'
import {
  Hide,
  HStack,
  Icon,
  Stack,
  Text,
  TextProps,
  TypographyProps,
} from '@chakra-ui/react'
import { IoMdTime } from 'react-icons/io'

type DocumentDateTimeProps = {
  dateTime: string | Date | undefined
  style?: TypographyProps | TextProps
  uploadedDateTime?: string | Date | undefined
}

const commonStyles = {
  fontSize: {
    base: '0.75rem',
    lg: '0.875rem',
  },
  base: '0.75rem',
  cursor: 'text',
}

export const DocumentDateTime = ({
  dateTime,
  uploadedDateTime,
  style,
}: DocumentDateTimeProps) => {
  if (!dateTime) {
    return <></>
  }
  const time = toTimeString(dateTime)
  const isDisplayTime = time !== '0000'
  return (
    <Stack
      w="fit-content"
      spacing="0.25rem"
      direction={{ base: 'column', lg: 'row' }}
    >
      <Text width="fit-content" {...commonStyles} {...style}>
        {isDisplayTime
          ? `${toDateString(dateTime).toUpperCase()} | ${time.replace(
              /:/g,
              ''
            )} HRS SGT`
          : toDateString(dateTime)}
      </Text>
      {uploadedDateTime && (
        <HStack spacing="0.25rem" {...commonStyles}>
          <Hide below="lg">
            <Text>{'|'}</Text>
          </Hide>
          <Icon as={IoMdTime} />
          <Text>{`Uploaded ${toDateString(
            uploadedDateTime
          ).toUpperCase()}`}</Text>
        </HStack>
      )}
    </Stack>
  )
}
