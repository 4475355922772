import { useGetUserEmailFromContext } from '@/hooks'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { DeleteUserCollectionActions } from './DeleteUserCollectionActions'
import {
  getDeleteUserCollectionContent,
  getDeleteUserCollectionHeader,
} from '@/utils/user-collection'

type DeleteUserCollectionModalProps = {
  isOpen: boolean
  onClose: () => void
  userId: string
  email: string
}

export const DeleteUserCollectionModal = ({
  isOpen,
  onClose,
  userId,
  email,
}: DeleteUserCollectionModalProps) => {
  const { email: userEmail } = useGetUserEmailFromContext()
  const isUser = email === userEmail

  const header = getDeleteUserCollectionHeader({ isUser, email })
  const bodyContent = getDeleteUserCollectionContent({ isUser, email })

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent padding="24px">
        <ModalHeader px="0px" pt="0px" pb="16px">
          {header}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="0px" w="100%">
          {bodyContent}
        </ModalBody>
        <DeleteUserCollectionActions
          onClose={onClose}
          userId={userId}
          email={email}
        />
      </ModalContent>
    </Modal>
  )
}
