import { DEFAULT_TAG_COLOR, TAG_TO_COLOR } from '@/utils/tag-to-color'
import { Wrap, WrapItem, Button } from '@chakra-ui/react'
import { RxCross2 } from 'react-icons/rx'
import { DocumentTagWithCategory } from '~shared/dtos'

type EditDocumentTagsProp = {
  removeTagFromDocument: ({ displayName, category }) => void
  selectedTags?: DocumentTagWithCategory[]
  isUpsertDocumentTagLoading?: boolean
}

export const DocumentTagsWithDeleteButton = ({
  removeTagFromDocument,
  selectedTags,
  isUpsertDocumentTagLoading,
}: EditDocumentTagsProp) => {
  return (
    <>
      {selectedTags && (
        <Wrap>
          {selectedTags.map(({ displayName, category }) => {
            return (
              <WrapItem
                key={displayName}
                onClick={() => {
                  removeTagFromDocument({ displayName, category })
                }}
              >
                <Button
                  fontSize="12px"
                  height="20px"
                  borderRadius="6px"
                  padding="6px"
                  bg={TAG_TO_COLOR[category] ?? DEFAULT_TAG_COLOR}
                  textColor="white"
                  rightIcon={<RxCross2 />}
                  isDisabled={isUpsertDocumentTagLoading}
                >
                  {displayName}
                </Button>
              </WrapItem>
            )
          })}
        </Wrap>
      )}
    </>
  )
}
