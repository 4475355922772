import { Avatar } from '@chakra-ui/avatar'
import { HStack } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'
import { IoChevronDown, IoChevronUp, IoLogOutOutline } from 'react-icons/io5'
import { useAuthContext } from '@/features/auth/useAuthContext'
import { Button, Divider } from '@chakra-ui/react'
import { USER_GUIDE } from '@/constants/footer-links'
import { RiBook2Line } from 'react-icons/ri'
import { FiMail } from 'react-icons/fi'
import { CONTACT_PRESSIFY_FORM } from '@/constants/feedback'

const menuStyles = {
  textColor: 'gray.800',
  bg: 'white',
}

export const UserProfileMenu = () => {
  const { user, logout } = useAuthContext()

  return (
    <HStack spacing="0rem" width={'fit-content'}>
      <Menu placement="bottom-end">
        {({ isOpen }) => (
          <>
            <MenuButton
              alignItems="center"
              justifyItems="center"
              minWidth="1.5rem"
              _hover={{
                background: 'parent',
                color: '#DD6B20',
              }}
              _expanded={{ background: 'gray.300' }}
              padding={{
                base: '0rem',
                lg: '1rem',
              }}
              isActive={isOpen}
              as={Button}
              aria-label="profile menu options"
              variant="ghost"
              rightIcon={isOpen ? <IoChevronUp /> : <IoChevronDown />}
              role="group"
            >
              <Avatar
                width={{ base: '2rem', lg: '2.5rem' }}
                height={{ base: '2rem', lg: '2.5rem' }}
                name={user?.email}
                _groupHover={{
                  background: '#FEEBCB',
                  textColor: '#DD6B20',
                }}
              />
            </MenuButton>
            <MenuList
              boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)"
              {...menuStyles}
              padding="0.5rem"
              borderRadius="0.5rem"
              border="0rem"
              width="fit-content"
            >
              <MenuItem
                fontSize="0.875rem"
                fontWeight="400"
                _hover={{
                  cursor: 'default',
                }}
                textColor="gray.600"
                bg="white"
              >
                {user?.email}
              </MenuItem>
              <Divider width="100%" borderColor="gray.800" />
              <MenuItem
                {...menuStyles}
                icon={<RiBook2Line />}
                onClick={() => {
                  window.open(USER_GUIDE, '_blank', 'noopener')
                }}
              >
                View Help Guide
              </MenuItem>

              <MenuItem
                {...menuStyles}
                icon={<FiMail />}
                onClick={() => {
                  window.open(CONTACT_PRESSIFY_FORM, '_blank', 'noopener')
                }}
              >
                Contact Us
              </MenuItem>

              <MenuItem
                borderBottomRadius="0.5rem"
                {...menuStyles}
                icon={<IoLogOutOutline />}
                onClick={() => {
                  logout()
                }}
              >
                Log out
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </HStack>
  )
}
