import {
  HStack,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useState } from 'react'

export const AppVersionMismatchModal = () => {
  const [showVersionMismatch, setShowVersionMismatch] = useState(true)

  const handleReload = () => {
    window.location.reload()
  }

  const handleCloseModal = () => {
    // timer set to 30 mins for modal to reappear
    setShowVersionMismatch(false)
    setTimeout(() => {
      setShowVersionMismatch(true)
    }, 1800000)
  }

  return (
    <Modal
      isOpen={showVersionMismatch}
      onClose={() => {
        handleCloseModal()
      }}
      isCentered
      size="3xl"
      closeOnOverlayClick={true}
      closeOnEsc={false}
    >
      <ModalOverlay background="rgba(0, 0, 0, 0.5)" />
      <ModalContent padding="1.5rem" borderRadius="0.75rem" margin="1rem">
        <ModalHeader px="0rem" pt="0rem" pb="1rem">
          Update available!
        </ModalHeader>
        <ModalCloseButton
          marginTop="1rem"
          marginRight="0.75rem"
          autoFocus={false}
          _focus={{
            boxShadow: 'none',
          }}
        />
        <ModalBody padding="0rem" w="100%">
          We’ve made some improvements to Pressify to enhance your experience.
          Please refresh the page to apply the latest updates. <br /> <br />
          If you are in the middle of something, you can choose to ignore this
          message, but you might encounter some errors until you refresh. This
          prompt will keep reappearing until you apply the update.
          <HStack
            justifyContent="flex-end"
            spacing={4}
            pt={{ base: '2rem', lg: '1rem' }}
          >
            <Button
              _hover={{
                bg: 'gray.200',
                color: 'gray.800',
              }}
              onClick={() => {
                handleCloseModal()
              }}
              width={{ base: '100%', lg: 'auto' }}
              borderRadius="1.25rem"
              fontWeight="600"
              bg="gray.100"
              color="gray.800"
              autoFocus={false}
              _focus={{
                boxShadow: 'none',
              }}
            >
              Ignore
            </Button>
            <Button
              _hover={{
                bg: 'brand.primary.400',
                color: 'brand.primary.100',
              }}
              onClick={() => {
                handleReload()
              }}
              width={{ base: '100%', lg: 'auto' }}
              borderRadius="1.25rem"
              fontWeight="600"
              bg="brand.primary.500"
              color="brand.primary.50"
              autoFocus={false}
              _focus={{
                boxShadow: 'none',
              }}
            >
              Refresh now
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
