import { useMemo, useState } from 'react'
import { BookmarkContext } from './useBookmarkContext'
import { CollectionInSearchResults, SelectedCollection } from '~shared/dtos'
import { useDisclosure } from '@chakra-ui/react'
import { DEFAULT_COLLECTION } from '~shared/constants'

type BookmarkProviderProps = {
  children: React.ReactNode
  documentId: number
  documentTitle: string
  documentCollections: CollectionInSearchResults[]
}

export const BookmarkProvider = ({
  children,
  documentId,
  documentTitle,
  documentCollections,
}: BookmarkProviderProps) => {
  const disclosureProps = useDisclosure()

  const [selectedCollections, setSelectedCollections] = useState<
    SelectedCollection[]
  >([])

  const [newCollectionIds, setNewCollectionIds] = useState<Set<string>>(
    new Set()
  )

  const selectedCollectionIds = useMemo(() => {
    return new Set(selectedCollections.map(({ collectionId }) => collectionId))
  }, [selectedCollections])

  const collectionsWithoutStarred = useMemo(() => {
    return documentCollections?.filter(
      (collection) => collection.name !== DEFAULT_COLLECTION
    )
  }, [documentCollections])

  const collectionText = useMemo(() => {
    if (collectionsWithoutStarred.length === 1) {
      return `Current Collection: ${collectionsWithoutStarred[0].name}`
    } else if (collectionsWithoutStarred.length > 1) {
      return `Currently in ${collectionsWithoutStarred.length} collections`
    }
  }, [collectionsWithoutStarred])

  const value = useMemo(
    () => ({
      documentId,
      documentTitle,
      disclosureProps,
      selectedCollections,
      setSelectedCollections,
      selectedCollectionIds,
      newCollectionIds,
      setNewCollectionIds,
      documentCollections,
      collectionText,
    }),
    [
      documentId,
      documentTitle,
      disclosureProps,
      selectedCollections,
      setSelectedCollections,
      selectedCollectionIds,
      newCollectionIds,
      setNewCollectionIds,
      documentCollections,
      collectionText,
    ]
  )

  return (
    <BookmarkContext.Provider value={value}>
      {children}
    </BookmarkContext.Provider>
  )
}
