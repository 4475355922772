import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { DeleteCollectionActions } from './DeleteCollectionActions'

type DeleteCollectionModalProps = {
  isOpen: boolean
  onClose: () => void
  collectionName: string
  collectionId: number
}

export const DeleteCollectionModal = ({
  isOpen,
  onClose,
  collectionName,
  collectionId,
}: DeleteCollectionModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent padding="24px">
        <ModalHeader px="0px" pt="0px" pb="16px">
          {`Delete Collection: '${collectionName}'`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="0px" w="100%">
          Are you sure you want to delete? Deletion is permanent.
        </ModalBody>
        <DeleteCollectionActions
          collectionId={collectionId}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  )
}
