import { Flex, Tag, Text } from '@chakra-ui/react'
import { DocumentTagWithCategory } from '~shared/dtos'
import {
  DEFAULT_TAG_COLOR,
  TAG_TO_COLOR,
  TAG_TO_HOVER_COLOR,
  TAG_TO_HOVER_TEXT_COLOR,
} from '@/utils/tag-to-color'
import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { useAddTagToSearchParams } from '@/hooks/params/useSearchParams'
import { CategoryType } from '~shared/constants'

type DocumentTagsProps = {
  tags?: DocumentTagWithCategory[]
  isTruncated?: boolean
  children?: React.ReactNode
}

//todo(ben): rename this component
export const DisplayDocumentTags = ({
  tags,
  isTruncated = false,
  children,
}: DocumentTagsProps) => {
  const sortedTags = useMemo(() => {
    const sortedTagsMap: { [key: string]: DocumentTagWithCategory[] } = {}
    if (tags) {
      tags.forEach((tag) => {
        if (!sortedTagsMap[tag.category]) {
          sortedTagsMap[tag.category] = []
        }

        sortedTagsMap[tag.category].push(tag)
      })
    }
    return Object.keys(CategoryType).reduce((acc, category) => {
      return [...acc, ...(sortedTagsMap[category] || [])]
    }, [] as DocumentTagWithCategory[])
  }, [tags])

  return (
    <Flex
      maxH={isTruncated ? '2.75rem' : ''}
      align="start"
      w="100%"
      flexWrap="wrap"
      gap="0.25rem 0.5rem"
    >
      <Flex
        maxH={isTruncated ? '2.75rem' : ''}
        // -1.75rem to give space for chevron right icon
        maxWidth={isTruncated ? 'calc(100% - 1.75rem)' : ''}
        gap="0.25rem 0.5rem"
        flexWrap="wrap"
        overflow={isTruncated ? 'hidden' : ''}
      >
        {children}
        {sortedTags &&
          sortedTags.map((value) => {
            const key = `${value.displayName}-${value.category}`
            return (
              <DocumentTag
                key={key}
                name={value.displayName}
                category={value.category}
              />
            )
          })}
      </Flex>
      {isTruncated && (
        <ChevronRightIcon
          color={{ base: 'black', lg: 'white' }}
          bg={{ base: 'orange.50', lg: 'black' }}
          h="1.25rem"
          w="1.25rem"
          borderRadius="1.25rem"
        />
      )}
    </Flex>
  )
}

type DisplayDocumentTagProps = {
  category: string
  name: string
}

export const DocumentTag = ({ category, name }: DisplayDocumentTagProps) => {
  const [searchParams] = useSearchParams()
  const addTagToSearchParams = useAddTagToSearchParams()

  // todo (ben): search params doens't seem to be memoise but it seems like a react-router-dom issue
  const isSearchable = useMemo(() => {
    return !searchParams.getAll('tags').includes([name, category].join(','))
  }, [category, name, searchParams])

  return (
    <Tag
      maxH="1.25rem"
      minH="1.25rem"
      fontSize="0.75rem"
      lineHeight="1rem"
      borderRadius="0.375rem"
      px="0.5rem"
      bg={TAG_TO_COLOR[category] ?? DEFAULT_TAG_COLOR}
      textColor="white"
      _hover={
        isSearchable
          ? {
              cursor: 'pointer',
              bg: TAG_TO_HOVER_COLOR[category],
              textColor: TAG_TO_HOVER_TEXT_COLOR[category],
              transition: 'background-color 0.25s ease',
            }
          : { cursor: 'default' }
      }
      onClick={(e) => {
        e.stopPropagation()
        addTagToSearchParams({
          displayName: name,
          category,
        })
      }}
    >
      <Text isTruncated>{name}</Text>
    </Tag>
  )
}
