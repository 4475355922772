import { SORT_OPTIONS } from '~shared/constants'
import { DocumentRes } from '~shared/dtos'

export const sortSearchResults = (documents: DocumentRes[], sort: string) => {
  switch (sort) {
    case SORT_OPTIONS.Oldest:
      return documents.sort((a, b) => {
        const dateA = new Date(a.dateTime).getTime()
        const dateB = new Date(b.dateTime).getTime()

        return dateA - dateB
      })
    case SORT_OPTIONS.Relevance:
      return documents.sort((a, b) => {
        if (a.score && b.score) return b.score - a.score
        return 0
      })
    case SORT_OPTIONS.Newest:
    default:
      return documents.sort((a, b) => {
        const dateA = new Date(a.dateTime).getTime()
        const dateB = new Date(b.dateTime).getTime()

        return dateB - dateA
      })
  }
}
