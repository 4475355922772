import { createContext, useContext } from 'react'
import { DateRange } from 'react-day-picker'
import { DocumentTagWithCategory } from '~shared/dtos'

type FilterTagContextProps = {
  selectedTags: DocumentTagWithCategory[]
  setSelectedTags: React.Dispatch<
    React.SetStateAction<DocumentTagWithCategory[]>
  >

  selectedCategory: string
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>

  selectedDate?: DateRange
  setSelectedDate: React.Dispatch<React.SetStateAction<DateRange | undefined>>

  removeTagInCategory: (category: string) => void

  onCheckboxChange: (tag: DocumentTagWithCategory) => void
}

export const FilterTagContext = createContext<FilterTagContextProps | null>(
  null
)

export const useFilterTagContext = () => {
  const context = useContext(FilterTagContext)
  if (!context) {
    throw new Error(
      `useFilterTagContext must be used within a FilterTagContext`
    )
  }
  return context
}
