import { useGetUser } from '@/hooks'
import { AppNavPaths } from '@/utils/paths'
import { Center, Spinner } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const RedirectRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate()
  const { user, isUserLoading } = useGetUser(60000)
  useEffect(() => {
    if (user) {
      return navigate(AppNavPaths.Home)
    }
  }, [navigate, user])
  if (isUserLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }
  return children
}
