import { DocumentDateTime } from '@/features/document/DocumentDateTime'
import {
  Divider,
  Flex,
  HStack,
  StyleProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { DocumentTagWithCategory } from '~shared/dtos'
import { Descendant } from 'slate'
import { getSlateFirstImage } from '@/components/slate-text-editor/utils'
import { useNavigateToDocumentPage } from '@/hooks'
import { DisplayDocumentTags } from '@/features/document/document-tag-components/DisplayDocumentTags'

type LatestArticleProp = {
  id: number
  title: string
  tags: DocumentTagWithCategory[]
  dateTime: string
  slateContent?: Descendant[]
  isLarge?: boolean
  image?: string
  isAlignTop?: boolean
  isArticleLeft?: boolean
  isNoDivider?: boolean
} & StyleProps

//todo(ben): rework styles again
const styles = {
  bg: 'background.default',
  align: 'flex-start',
  borderRadius: '0.625rem',
  transition: 'all 0.3s ease',
  _hover: {
    cursor: 'pointer',
    opacity: 0.7,
  },
  h: '100%',
}

export const LatestArticle = ({
  id,
  title,
  tags,
  dateTime,
  isLarge,
  slateContent,
  image,
  isAlignTop,
  isArticleLeft,
  isNoDivider,
  ...style
}: LatestArticleProp) => {
  const articleImage = getSlateFirstImage(slateContent ?? []) ?? image
  const onNavigate = useNavigateToDocumentPage()

  return isLarge ? (
    <VStack
      {...styles}
      onClick={() => onNavigate(`${id}`)}
      w="67%"
      minH="19.5rem"
    >
      <ImageComponent image={articleImage} />
      <TextContentComponent title={title} dateTime={dateTime} noOfLines={2}>
        <DisplayDocumentTags tags={tags} isTruncated />
      </TextContentComponent>
    </VStack>
  ) : (
    <VStack
      {...styles}
      spacing="0"
      align="start"
      onClick={() => onNavigate(`${id}`)}
      border={{ base: '0.063rem', lg: '0rem' }}
      borderColor={{ base: 'gray.200', lg: 'transparent' }}
      minH="9.25rem"
      {...style}
    >
      {!isNoDivider && <Divider alignSelf="start" borderColor="gray.400" />}
      {!articleImage ? (
        <VStack
          spacing="0.5rem"
          py="1rem"
          justifyContent="center"
          h="100%"
          w="100%"
          background={{
            base: 'gray.800',
            lg: 'none',
          }}
          padding={{
            base: '1rem',
            lg: isAlignTop ? '0rem 0rem 1rem 0rem' : '1rem 0rem', // for the top right tile
          }}
          borderRadius={{ base: '0.5rem', lg: '0rem' }}
        >
          <TextContentComponent title={title} dateTime={dateTime} noOfLines={4}>
            <DisplayDocumentTags tags={tags} isTruncated />
          </TextContentComponent>
        </VStack>
      ) : (
        <VStack
          spacing="0.5rem"
          py="1rem"
          pt={isAlignTop ? '0rem' : '1rem'}
          justifyContent="space-between"
          h="100%"
          w="100%"
          background={{
            base: `linear-gradient(180deg, rgba(0, 0, 0, 0.40) 53%, rgba(0, 0, 0, 0.00) 70.5%), url("${articleImage}") lightgray 50% / cover no-repeat`,
            lg: 'none',
          }}
          padding={{
            base: '1rem',
            lg: isAlignTop ? '0rem 0rem 1rem 0rem' : '1rem 0rem', // for the top right tile
          }}
          borderRadius={{ base: '0.5rem', lg: '0rem' }}
        >
          <HStack h="100%" w="100%" align="top">
            {isArticleLeft && <ImageComponent image={articleImage} />}
            <TextContentComponent
              title={title}
              dateTime={dateTime}
              noOfLines={4}
            />
          </HStack>
          <DisplayDocumentTags tags={tags} isTruncated />
        </VStack>
      )}
    </VStack>
  )
}

const ImageComponent = ({ image }: { image: string }) => {
  return (
    <Flex
      h="100%"
      w="100%"
      borderRadius="0.625rem"
      style={{
        backgroundImage: `url("${image}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
  )
}

const TextContentComponent = ({
  title,
  dateTime,
  noOfLines,
  children,
}: {
  title: string
  dateTime: string
  noOfLines: number
  children?: React.ReactNode
}) => {
  return (
    <VStack h="fit-content" w="100%" padding={0} align="flex-start">
      <Text
        w="100%"
        fontWeight="bold"
        noOfLines={noOfLines}
        color={{ base: 'gray.100', lg: 'black' }}
      >
        {title}
      </Text>
      <DocumentDateTime
        dateTime={dateTime}
        style={{
          fontSize: '0.625rem',
          lineHeight: '1rem',
          color: { base: 'gray.100', lg: 'black' },
        }}
      />
      {children}
    </VStack>
  )
}
