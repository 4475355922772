import axios from 'axios'
import { useCreateAndInsertTag } from '.'
import { CreateAndInsertTagReq, DocumentTagWithCategory } from '~shared/dtos'
import { UNABLE_TO_ADD_TAG_ERROR } from '@/constants/error'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from '@/constants/query-key'
import { useToast } from './useToast'

export const useAddTagToDocument = (
  documentId: number,
  setDocumentTagsState: React.Dispatch<
    React.SetStateAction<DocumentTagWithCategory[]>
  >
) => {
  const toast = useToast()
  const { createAndInsertTag, isCreateAndInsertTagLoading } =
    useCreateAndInsertTag(documentId)
  const queryClient = useQueryClient()

  const addTagToDocument = async ({
    displayName,
    category,
  }: CreateAndInsertTagReq) => {
    try {
      await createAndInsertTag({ displayName, category })
      toast({
        title: 'Tag added to document',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setDocumentTagsState((prev) => [...prev, { displayName, category }])
      await queryClient.invalidateQueries(queryKeys.document(+documentId))
    } catch (err) {
      toast({
        description:
          (axios.isAxiosError(err) && err.response?.data?.message) ??
          UNABLE_TO_ADD_TAG_ERROR,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return { addTagToDocument, isCreateAndInsertTagLoading }
}
