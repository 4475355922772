import {
  Avatar,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Text,
  Button,
  VStack,
  useRadio,
  Flex,
  useRadioGroup,
  Icon,
  Spinner,
  useDisclosure,
  UseRadioProps,
} from '@chakra-ui/react'
import { BiTrash } from 'react-icons/bi'
import { TiTick } from 'react-icons/ti'
import { PERMISSIONS, PERMISSIONS_TO_ROLE } from '~shared/constants'
import { DeleteUserCollectionDrawer } from './DeleteUserCollectionDrawer'

const PermissionCard = ({
  children,
  ...props
}: UseRadioProps & { children: React.ReactNode }) => {
  const { getInputProps, getRadioProps, state } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Flex as="label" w="100%">
      <input {...input} />
      <HStack {...checkbox}>
        <Icon
          // set as not visible when checked. doing this to occupy the space even if its not selected
          color={state.isChecked ? 'black' : 'background.default'}
          alignSelf="center"
          as={TiTick}
        />
        <Text>{children}</Text>
      </HStack>
    </Flex>
  )
}

type CollabUpdatePermissionDrawer = {
  isOpen: boolean
  onClose: () => void
  email: string
  permission: PERMISSIONS
  onChange: ({
    permission,
    initialPermission,
  }: {
    permission: string
    initialPermission?: string
  }) => void
  isLoading: boolean
  userId: string
}

export const CollabUpdatePermissionDrawer = ({
  isOpen,
  onClose,
  email,
  userId,
  permission,
  onChange,
  isLoading,
}: CollabUpdatePermissionDrawer) => {
  const {
    isOpen: isOpenDeleteDrawer,
    onClose: onCloseDeleteDrawer,
    onOpen: onOpenDeleteDrawer,
  } = useDisclosure()
  const initialPermission = permission as unknown as string
  const { getRootProps, getRadioProps, isDisabled } = useRadioGroup({
    isDisabled: isLoading,
    defaultValue: permission,
    onChange: (value) => onChange({ initialPermission, permission: value }),
  })
  const group = getRootProps()

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      autoFocus
      placement="bottom"
      allowPinchZoom
    >
      <DrawerOverlay />
      <DrawerContent
        px="1.5rem"
        pt="1.25rem"
        pb="0.5rem"
        background="background.default"
      >
        <DrawerBody padding="0" w="100%">
          <VStack w="100%" align="left" spacing="0.75rem">
            <HStack spacing="0.75rem" fontSize="0.875rem" h="100%">
              <Avatar width="2.25rem" height="2.25rem" bg="brand.primary.500" />
              <Text fontSize="0.875rem" isTruncated>
                {email}
              </Text>
              {isLoading && <Spinner size="sm" justifySelf="end" />}
            </HStack>
            <Divider
              //100% causes overflow to appear
              w="99%"
              border="1px"
              color="black"
              alignSelf="center"
            />
            <VStack
              align="start"
              w="100%"
              fontSize="1rem"
              spacing="1rem"
              color={isLoading ? 'gray.300' : 'black'}
              {...group}
            >
              {Object.keys(PERMISSIONS_TO_ROLE).map((permission) => {
                const radio = getRadioProps({ value: permission })
                return (
                  <PermissionCard
                    key={permission}
                    isDisabled={isDisabled}
                    {...radio}
                  >
                    {PERMISSIONS_TO_ROLE[permission]}
                  </PermissionCard>
                )
              })}
            </VStack>
            <Divider
              //100% causes overflow to appear
              w="99%"
              border="1px"
              color="black"
              alignSelf="center"
            />
            <Button
              isDisabled={isLoading}
              w="100%"
              justifyContent="left"
              color="red"
              onClick={onOpenDeleteDrawer}
              leftIcon={<BiTrash color="red" />}
              fontSize="1rem"
              bg="parent"
              fontWeight="normal"
            >
              Remove user
            </Button>
          </VStack>
        </DrawerBody>
      </DrawerContent>
      <DeleteUserCollectionDrawer
        isOpen={isOpenDeleteDrawer}
        onClose={onCloseDeleteDrawer}
        userId={userId}
        email={email}
      />
    </Drawer>
  )
}
