import { generateTags, getTagsInCategory } from '@/api/tag'
import { queryKeys } from '@/constants/query-key'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

export const useGenerateTags = (content?: string, source?: string) => {
  const trimmedContent = content?.trim()
  const { isError, isLoading, data, fetchStatus } = useQuery({
    queryKey: queryKeys.generatedTags(trimmedContent ?? ''),
    queryFn: async () => {
      const data = await generateTags({ source, content })
      return data
    },
    enabled: !!trimmedContent,
    staleTime: 60000,
    retry: false,
    refetchOnWindowFocus: false,
  })
  return {
    // isloading is true when query is disabled
    //https://github.com/TanStack/query/issues/3584
    isGenerateTagsLoading: isLoading && fetchStatus !== 'idle',
    generatedTags: data?.tags,
    isGenerateTagsError: isError,
  }
}

export const useGetTagsInCategory = ({ category }: { category: string }) => {
  const {
    data,
    isLoading: isGetTagsInCategoryLoading,
    isError: isGetTagsInCategoryError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: queryKeys.tagInCategory(category),
    queryFn: async ({ pageParam }) => {
      const data = await getTagsInCategory({ pageParam, category })
      return data
    },
    enabled: !!category,
    retry: false,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => lastPage.metadata.nextPageParams,
  })

  return {
    data,
    isGetTagsInCategoryLoading,
    isGetTagsInCategoryError,
    fetchNextPage,
    hasNextPage,
  }
}
