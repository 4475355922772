import { VStack, Text } from '@chakra-ui/react'
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select'
import { FastField } from 'formik'
import { useIsMobile } from '@/hooks'

export const TimeZoneField = () => {
  const isMobile = useIsMobile()
  return (
    <VStack align="left" w="auto">
      <Text
        fontSize={{ lg: '1rem', base: '0.875rem' }}
        fontWeight="bold"
        color="gray.600"
        paddingLeft={{ base: '0rem', lg: '0.5rem' }}
      >
        Publication timezone
      </Text>
      <FastField name={'timeZone'}>
        {({ field, form }) => (
          <TimezoneSelect
            styles={{
              indicatorSeparator: () => ({ display: 'none' }),
              control: (defaultStyle, { isFocused }) => ({
                ...defaultStyle,
                borderRadius: '12px',
                height: '48px',
                '&:hover': {
                  border: '2px dashed #ED8936',
                  borderRadius: '10px',
                },
                fontSize: isMobile ? '0.875rem' : '1rem',
                borderColor: isFocused ? 'orange' : 'transparent',
                boxShadow: isFocused ? 'orange' : 'transparent',
              }),
              option: (defaultStyles, { isFocused }) => ({
                ...defaultStyles,
                fontSize: isMobile ? '0.875rem' : '1rem',
                backgroundColor: isFocused ? '#FEEBCB' : 'parent',
                fontWeight: isFocused ? 'bold' : 'normal',
                color: isFocused ? '#DD6B20' : 'parent',
                ':active': {
                  ...defaultStyles[':active'],
                  backgroundColor: '#FEEBCB',
                  color: '#DD6B20',
                },
              }),
            }}
            value={field.value}
            onChange={(timeZone) => {
              form.setFieldValue(
                'timeZone',
                (timeZone as ITimezoneOption).value
              )
            }}
          />
        )}
      </FastField>
      <Text
        color="gray.600"
        fontSize={{ lg: '0.875rem', base: '0.75rem' }}
        fontStyle="italic"
      >
        Date and time will be converted to SGT after publishing.
      </Text>
    </VStack>
  )
}
