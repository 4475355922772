import { Input, Button, Text, Box, Link, Stack, VStack } from '@chakra-ui/react'
import { Field, Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import validator from 'validator'
import { useSendLoginOtp } from '@/hooks/useAuth'
import { APPROVED_DOMAINS } from '~shared/constants'
import { HttpStatusCode, isAxiosError } from 'axios'
import {
  ErrorMessage,
  ErrorMessageWithIcon,
} from '@/components/error/ErrorMessageWithIcon'
import { useAuthContext } from '../useAuthContext'
import { useIsMobile } from '@/hooks'
import { useCallback } from 'react'
import { PRIVACY_POLICY, TERMS_OF_USE } from '@/constants/footer-links'
import {
  FAIL_TO_SEND_OTP_ERROR,
  RATE_LIMIT_ERROR,
  SHORT_HAND_EMAIL_ERROR,
} from '@/constants/error'

const schema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email is Required. e.g. user@agency.gov.sg')
    .test('is-email', 'Please enter a valid email address', (value) =>
      validator.isEmail(value)
    )
    .test(
      'is-approved_domains-email',
      'Please sign in with a defence/mindef.gov.sg email address.',
      (value) =>
        !!APPROVED_DOMAINS.find((domain) => {
          return value.toLowerCase().endsWith(domain)
        })
    ),
})

interface EmailFormValues {
  email: string
}

type EmailFormikComponentProps = {
  isSendLoginOtpLoading: boolean
}

const EmailFormikComponent = ({
  isSendLoginOtpLoading,
}: EmailFormikComponentProps) => {
  const isMobile = useIsMobile()
  const { errors } = useFormikContext<EmailFormValues>()
  const { otpError, setOtpError } = useAuthContext()

  const renderErrorMessage = () => {
    if (errors.email) {
      return <ErrorMessageWithIcon fieldName="email" />
    }
    if (otpError) {
      return <ErrorMessage msg={otpError} />
    }
    return <></>
  }

  return (
    <>
      <Form
        style={{
          width: '100%',
        }}
      >
        <Field name="email">
          {({ field }) => (
            <>
              <Stack
                direction={{ base: 'column', lg: 'row' }}
                bgColor="background.default"
                borderRadius="1.25rem"
                w="100%"
                spacing={{ lg: '0.5rem', base: '2.75rem' }}
              >
                <VStack align="left" w="100%" flex={1}>
                  <Input
                    name="email"
                    placeholder="e.g. full_name_surname@defence.gov.sg"
                    borderRadius="1.25rem"
                    value={field.value}
                    onChange={(e) => {
                      // clear otp error when user starts typing
                      if (otpError) {
                        setOtpError('')
                      }
                      field.onChange(e)
                    }}
                    isDisabled={isSendLoginOtpLoading}
                  />
                  {isMobile && renderErrorMessage()}
                </VStack>
                <Button
                  _hover={{
                    bg: 'brand.primary.400',
                    color: 'brand.primary.100',
                  }}
                  borderRadius="1.5rem"
                  fontWeight="600"
                  bg="brand.primary.500"
                  color="brand.primary.50"
                  type="submit"
                  isLoading={isSendLoginOtpLoading}
                  isDisabled={!field.value}
                >
                  {isMobile ? `Sign in` : `Get OTP`}
                </Button>
              </Stack>
              {!isMobile && renderErrorMessage()}
              {!isMobile && (
                <Text fontSize="0.75rem" fontWeight="medium" mt="1rem">
                  By signing into Pressify, you acknowledge that you have read,
                  understood, and agreed to abide by our{' '}
                  <Box as="span" textDecoration="underline" color="orange.500">
                    <Link
                      aria-label="Click to open Pressify's Terms Of Use in a new tab"
                      href={TERMS_OF_USE}
                      isExternal
                    >
                      Terms of Use
                    </Link>
                  </Box>{' '}
                  and{' '}
                  <Box as="span" textDecoration="underline" color="orange.500">
                    <Link
                      aria-label="Click to open Pressify's Privacy Policy in a new tab"
                      href={PRIVACY_POLICY}
                      isExternal
                      textDecoration="underline"
                      color="orange.500"
                    >
                      Privacy Agreement
                    </Link>
                  </Box>
                  .
                </Text>
              )}
            </>
          )}
        </Field>
      </Form>
    </>
  )
}

export const EmailForm = () => {
  const { sendLoginOtp, isSendLoginOtpLoading } = useSendLoginOtp()
  const { setTimer, setEmail, setOtpError } = useAuthContext()

  const handleSendOtp = useCallback(
    async (email: string) => {
      const lowerCasedEmail = email.toLowerCase()
      await sendLoginOtp(
        {
          email: lowerCasedEmail,
        },
        {
          onSuccess: () => {
            setTimer(60)
            setEmail(lowerCasedEmail)
          },
          onError: (error) => {
            if (isAxiosError(error)) {
              const code = error.response?.status
              if (code === HttpStatusCode.TooManyRequests) {
                setOtpError(RATE_LIMIT_ERROR)
                return
              }

              if (code === HttpStatusCode.ServiceUnavailable) {
                setOtpError(SHORT_HAND_EMAIL_ERROR)
                return
              }
            }
            // other generic errors
            setOtpError(FAIL_TO_SEND_OTP_ERROR)
          },
        }
      )
    },
    [sendLoginOtp, setEmail, setOtpError, setTimer]
  )

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values: EmailFormValues) => handleSendOtp(values.email)}
      validationSchema={schema}
    >
      <EmailFormikComponent isSendLoginOtpLoading={isSendLoginOtpLoading} />
    </Formik>
  )
}
