import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

import { useCollabContext } from './useCollabContext'
import { CollabContent } from './CollabContent'

type CollabModalProps = {
  isOpen: boolean
  onClose: () => void
  name?: string
}

export const CollabModal = ({ isOpen, onClose, name }: CollabModalProps) => {
  const { isManage } = useCollabContext()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent padding="24px" background="background.default">
        <ModalHeader px="0px" pt="0px" pb="16px">
          {isManage ? (
            <HStack>
              <Text>{`Grant access to ${name}`}</Text>
            </HStack>
          ) : (
            <Text>{`Collaborators`}</Text>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0" pt="1rem" w="100%">
          <CollabContent onClose={onClose} name={name} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
