import { ModifyTagSearchBar } from '@/features/document/document-tag-components'
import { CreateTagModal } from '@/features/document/document-tag-components/CreateTagModal'
import { IconButton, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { DocumentTagWithCategory } from '~shared/dtos'

export type AddTagButtonProps = {
  addTagToDocument: ({ displayName, category }) => void
  documentTags?: DocumentTagWithCategory[]
  isLoading?: boolean
}

export const AddTagButton = ({
  addTagToDocument,
  documentTags,
  isLoading,
}: AddTagButtonProps) => {
  const [isClicked, setIsClicked] = useState(false)

  // for createTagModal
  const [newTagName, setNewTagName] = useState<string>('')

  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      {!isClicked ? (
        <IconButton
          alignSelf="start"
          isRound
          h="1.25rem"
          aria-label="add tags"
          size="sm"
          icon={<FaPlus size="0.75rem" />}
          isDisabled={isLoading}
          onClick={() => {
            setIsClicked(!isClicked)
          }}
        />
      ) : (
        <ModifyTagSearchBar
          onOpen={onOpen}
          setIsClicked={setIsClicked}
          addTagToDocument={addTagToDocument}
          setNewTagName={setNewTagName}
          documentTags={documentTags}
        />
      )}
      <CreateTagModal
        isOpen={isOpen}
        onClose={onClose}
        tagName={newTagName}
        setTagName={setNewTagName}
        addTagToDocument={addTagToDocument}
        isLoading={isLoading}
      />
    </>
  )
}
