import { DisplayDocumentTags } from '@/features/document/document-tag-components/DisplayDocumentTags'
import { DocumentDateTime } from '@/features/document/DocumentDateTime'
import { useNavigateToDocumentPage } from '@/hooks'
import { Text, VStack } from '@chakra-ui/react'
import { DocumentTagWithCategory } from '~shared/dtos'
import { CategoryType } from '~shared/constants'

type LatestPressumProps = {
  id: number
  title: string
  tags: DocumentTagWithCategory[]
  dateTime: string
}

export const LatestPressum = ({
  id,
  title,
  tags,
  dateTime,
}: LatestPressumProps) => {
  const onNavigate = useNavigateToDocumentPage()

  const filteredSourceTag = tags
    .filter(
      (tag) =>
        tag.category === CategoryType.Source &&
        !tag.displayName.includes('Pressum')
    )
    .pop()
  // if pressum has no source, assign pressum as display for source tag
  const displayedTags = [
    filteredSourceTag ?? {
      displayName: 'Pressum',
      category: CategoryType.Source,
    },
    ...tags.filter((tag) => tag.category !== CategoryType.Source),
  ]

  return (
    <VStack
      align="left"
      justify="end"
      bg="visualisation.sidebar.background"
      p="1rem"
      w={{ base: '100%', lg: '32%' }}
      h="100%"
      borderRadius="0.625rem"
      transition="all 0.3s ease"
      _hover={{
        cursor: 'pointer',
        opacity: 0.7,
      }}
      onClick={() => {
        onNavigate(`${id}`)
      }}
    >
      <Text
        noOfLines={2}
        fontWeight="bold"
        color="black"
        fontSize={{ base: '0.75rem', lg: '0.875rem' }}
      >
        {title}
      </Text>
      <DocumentDateTime
        style={{
          color: 'gray.800',
        }}
        dateTime={dateTime}
      />
      <DisplayDocumentTags tags={[...displayedTags]} isTruncated />
    </VStack>
  )
}
