export const DEFAULT_COLLECTION = 'Starred';
export const MAX_COLLECTION_NAME_LENGTH = 24;
export const COLLECTION_CANNOT_BE_EMPTY_MESSAGE = 'Collection cannot be empty';

export enum ROLES {
  ADMIN = 'Admin',
  CONTRIBUTOR = 'Contributor',
  VIEWER = 'Viewer',
}

// todo(ben): refactor to map to backend permissions
export const PERMISSIONS_TO_ROLE = {
  MANAGE: ROLES.ADMIN,
  UPDATE: ROLES.CONTRIBUTOR,
  READ: ROLES.VIEWER,
};

// todo(ben): refactor to map to backend permissions
export enum PERMISSIONS {
  MANAGE = 'MANAGE',
  UPDATE = 'UPDATE',
  READ = 'READ',
}

export const PERMISSIONS_TO_SCORE = {
  READ: 1,
  UPDATE: 2,
  MANAGE: 3,
};

export const DEFAULT_PERMISSION: PERMISSIONS = PERMISSIONS.UPDATE;
export const COLLECTION_NAME_REGEX_VALIDATION = /^[a-zA-Z0-9 ]+$/;
