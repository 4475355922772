import { HStack, IconButton, Text, VStack } from '@chakra-ui/react'
import { FiPlusCircle } from 'react-icons/fi'
import { MdCollectionsBookmark } from 'react-icons/md'
import { CollectionButton } from '~shared/dtos'
import { useBookmarkContext } from '../useBookmarkContext'
import { generateUniqueId } from '~shared/utils'

type AddBookmarkButtonProp = {
  onClick: (value: CollectionButton) => void
  searchValue: string
  isError: boolean
}

export const AddBookmarkButton = ({
  onClick,
  searchValue,
  isError,
}: AddBookmarkButtonProp) => {
  const { selectedCollections, newCollectionIds, setNewCollectionIds } =
    useBookmarkContext()
  const newCollectionName = searchValue.trim()

  return (
    <HStack
      width="100%"
      borderRadius="8px"
      padding="8px"
      justify="space-beween"
    >
      <HStack justify="left" w="100%">
        {/** using icon button for the stylings */}
        <IconButton
          isRound
          _hover={{ bg: 'brand.primary.100' }}
          aria-label="add collection left icon"
          icon={<MdCollectionsBookmark />}
        />
        <VStack align="left" spacing="0px">
          <Text fontSize="14px" fontWeight="bold">
            Create new collection
          </Text>
          <Text isTruncated fontSize="14px">{`"${newCollectionName}"`}</Text>
        </VStack>
      </HStack>
      {newCollectionName && (
        <IconButton
          bg="parent"
          cursor="pointer"
          isDisabled={isError}
          onClick={() => {
            const collectionIdSet = new Set(
              selectedCollections.map((selected) => selected.collectionId)
            )
            const newCollectionId = generateUniqueId(
              newCollectionName,
              collectionIdSet
            )
            newCollectionIds.add(newCollectionId)
            setNewCollectionIds(newCollectionIds)
            onClick({
              collectionId: newCollectionId,
              name: newCollectionName,
              documentCount: 0,
              isNew: true,
              isEditable: true,
            })
          }}
          isRound
          _hover={{ bg: 'brand.primary.100' }}
          aria-label="add collection right icon"
          icon={<FiPlusCircle />}
        />
      )}
    </HStack>
  )
}
