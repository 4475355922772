import { FILTER_DATE } from '@/utils/date'
import { useState, useCallback, useMemo } from 'react'
import { DateRange } from 'react-day-picker'
import { DocumentTagWithCategory } from '~shared/dtos'
import { FilterTagContext } from './useFilterTagContext'

export const FilterTagsProvider = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('')

  const [selectedDate, setSelectedDate] = useState<DateRange>()

  const [selectedTags, setSelectedTags] = useState<DocumentTagWithCategory[]>(
    []
  )

  // for toggling of checkboxes
  const onCheckboxChange = useCallback(
    (newTag: DocumentTagWithCategory) => {
      setSelectedTags((prev) => {
        const { displayName, category } = newTag

        const filteredTags = prev.filter(
          (tag) =>
            !(tag.displayName === displayName && tag.category === category)
        )
        const isNew = filteredTags.length === prev.length
        return isNew ? [...prev, { ...newTag }] : filteredTags
      })
    },
    [setSelectedTags]
  )

  // for removing all tags in a category
  const removeTagInCategory = useCallback(
    (category: string) => {
      if (category === FILTER_DATE) {
        setSelectedDate(undefined)
      } else {
        setSelectedTags((prev) => {
          return prev.filter((tag) => tag.category !== category)
        })
      }
    },
    [setSelectedTags, setSelectedDate]
  )

  const contextValue = useMemo(
    () => ({
      selectedTags,
      setSelectedTags,

      selectedCategory,
      setSelectedCategory,

      selectedDate,
      setSelectedDate,

      onCheckboxChange,

      removeTagInCategory,
    }),
    [
      selectedTags,
      setSelectedTags,
      selectedCategory,
      setSelectedCategory,
      selectedDate,
      setSelectedDate,
      onCheckboxChange,
      removeTagInCategory,
    ]
  )

  return (
    <FilterTagContext.Provider value={contextValue}>
      {children}
    </FilterTagContext.Provider>
  )
}
