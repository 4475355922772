import { createContext, useContext } from 'react'
import { PERMISSIONS } from '~shared/constants'
import { CollabUser } from '~shared/dtos'

// todo(ben): look at collabUsers undefined again
type CollabContextProps = {
  userPermission: PERMISSIONS | undefined
  isEdit: boolean
  isManage: boolean
  collabUsers: CollabUser[] | undefined
  isUserCollectionsLoading: boolean
}

export const CollabContext = createContext<CollabContextProps | undefined>(
  undefined
)

export const useCollabContext = () => {
  const context = useContext(CollabContext)
  if (!context) {
    throw new Error(
      `CollabContext must be used within a CollabContext component`
    )
  }
  return context
}
