import { Icon } from '@chakra-ui/react'
import { FiTrash2 } from 'react-icons/fi'

export const DisabledTrash = () => {
  return (
    <Icon
      cursor={'not-allowed'}
      as={FiTrash2}
      _groupHover={{ display: 'flex' }}
      color={'gray.400'}
      background="#11111100"
      aria-label="Delete"
      height="40px"
      width="40px"
      padding="10px"
    />
  )
}
