import { toDateString } from '@/utils/date'
import { Text } from '@chakra-ui/react'

export const DocumentOriginator = ({
  userEmail,
  createdAt,
}: {
  userEmail: string
  createdAt: Date
}) => {
  return (
    <Text
      color="gray.500"
      fontWeight={500}
      fontSize="0.875rem"
      fontStyle="italic"
    >{`First uploaded by ${userEmail} on ${toDateString(createdAt)}`}</Text>
  )
}
