import { Text, Image, VStack, Divider } from '@chakra-ui/react'
import { ProcessedText } from '@/components/ProcessedText'
import { DocumentRes } from '~shared/dtos'
import { SlateReadOnly } from '@/components/slate-text-editor/SlateReadOnly'
import { DocumentDateTime } from '@/features/document/DocumentDateTime'
import { DocumentCardSource } from '@/features/document/DocumentCardSource'
import { MobileDocumentSummary } from './MobileDocumentSummary'
import { DocumentOriginator } from '@/features/document/DocumentOriginator'

type MobileDocumentContentProps = {
  document: DocumentRes
  isTranslateToVernacular: boolean
  isMaxRetriesExceeded: boolean
}

export const MobileDocumentContent = ({
  isMaxRetriesExceeded,
  document,
  isTranslateToVernacular,
}: MobileDocumentContentProps) => {
  return (
    <VStack spacing="1rem" align="left" w="100%">
      <Text fontSize="1rem" lineHeight="1.5rem" fontWeight="bold">
        {isTranslateToVernacular &&
        document.vernacular &&
        document.vernacular.title
          ? // document.vernacular defined as string in RightSideBar, handleVernacularChange
            document.vernacular.title
          : document.title}
      </Text>
      <DocumentDateTime
        dateTime={document.dateTime}
        style={{
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 'medium',
        }}
      />
      {document.createdAt && document.originatorUserEmail && (
        <DocumentOriginator
          userEmail={document.originatorUserEmail}
          createdAt={document.createdAt}
        />
      )}
      {document.url && (
        <DocumentCardSource
          sourceName={document.source}
          sourceLink={document.url}
        />
      )}
      {(!isMaxRetriesExceeded || document.summary) && (
        <MobileDocumentSummary
          summary={document.summary}
          isMaxRetriesExceeded={isMaxRetriesExceeded}
        />
      )}
      {document.image && <Image src={document.image} />}
      {
        // pressums will not have slate content
        document.slateContent ? (
          <>
            {isTranslateToVernacular &&
            document.vernacular &&
            document.vernacular.slateContent ? (
              <SlateReadOnly value={document.vernacular.slateContent} />
            ) : (
              <SlateReadOnly value={document.slateContent} />
            )}
          </>
        ) : (
          <ProcessedText
            style={{
              fontSize: '1rem',
            }}
            content={
              isTranslateToVernacular &&
              document.vernacular &&
              document.vernacular.content
                ? // document.vernacular defined as string in RightSideBar, handleVernacularChange
                  document.vernacular.content
                : document.content
            }
          />
        )
      }
      <Divider width="100%" borderColor="black" />
    </VStack>
  )
}
