import { Text, Image, Button } from '@chakra-ui/react'
import NoArticleSVG from '@/assets/placeholders/no-article.svg'
import { useNavigate } from 'react-router-dom'
import { AppNavPaths } from '@/utils/paths'
import { PlaceholderLayout } from '@/components/PlaceholderLayout'

export const NoArticlePlaceholder = () => {
  const navigate = useNavigate()
  return (
    <PlaceholderLayout
      image={<Image src={NoArticleSVG} />}
      text={
        <Text marginTop="1.5rem">
          No articles available to you yet. Be the first to fill up your
          Pressify world!
        </Text>
      }
    >
      <Button
        marginTop="1rem"
        onClick={() => {
          navigate(AppNavPaths.Manual)
        }}
        width={{ base: '100%', lg: 'auto' }}
        borderRadius="1.25rem"
        fontWeight="600"
        bg="brand.primary.500"
        color="brand.primary.50"
      >
        Start Uploading
      </Button>
    </PlaceholderLayout>
  )
}
