export const TAG_TO_COLOR = {
  Country: 'teal.500',
  Asset: 'purple.500',
  Event: 'blue.500',
  Source: 'pink.500',
  Miscellaneous: 'gray.500',
}

export const RECOMMENDED_TAG_TO_COLOR = {
  Country: 'teal.100',
  Asset: 'purple.100',
  Event: 'blue.100',
  Source: 'pink.100',
  Miscellaneous: 'gray.100',
}

export const RECOMMENDED_TAG_TEXT_TO_COLOR = {
  Country: 'teal.800',
  Asset: 'purple.800',
  Event: 'blue.800',
  Source: 'pink.800',
  Miscellaneous: 'gray.800',
}

export const DEFAULT_TAG_COLOR = 'gray.400'

export const TAG_TO_HOVER_COLOR = RECOMMENDED_TAG_TO_COLOR
export const TAG_TO_HOVER_TEXT_COLOR = RECOMMENDED_TAG_TEXT_TO_COLOR
