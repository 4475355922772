import { Outlet, RouteObject, ScrollRestoration } from 'react-router-dom'
import { DocumentPage } from '@/pages/DocumentPage'
import { AppNavPaths, ROOT_DOCUMENT_PATH } from '@/utils/paths'
import { LoginPage } from '@/pages/LoginPage'
import { ProtectedRoute } from './ProtectedRoute'
import { AuthProvider } from '@/features/auth/AuthContext'
import { DocumentListProvider } from '@/features/home'
import { HomePage } from '@/pages/HomePage'
import { DocumentListPage } from '@/pages/DocumentListPage'
import { AppLayout } from '@/app/AppLayout'
import { ManualUploadPage } from '@/pages/ManualUploadPage'
import { ErrorPage } from '@/components/error/ErrorPage'
import { CollabProvider } from '@/features/collections/collab/CollabContext'
import { LandingPage } from '@/pages/LandingPage'
import { RedirectRoute } from './RedirectRoute'
import { AppVersionCheck } from '@/app/AppVersionCheck/AppVersionCheck'

export const protectedRoutes: RouteObject[] = [
  {
    element: (
      <DocumentListProvider>
        <Outlet />
      </DocumentListProvider>
    ),
    children: [
      {
        path: AppNavPaths.Home,
        element: <HomePage />,
      },
      {
        path: AppNavPaths.Documents,
        element: (
          <CollabProvider>
            <DocumentListPage />
          </CollabProvider>
        ),
      },
    ],
  },
  {
    children: [
      {
        path: `${ROOT_DOCUMENT_PATH}/:documentId`,
        element: <DocumentPage />,
      },
    ],
  },
  {
    children: [
      {
        path: AppNavPaths.Manual,
        element: <ManualUploadPage />,
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
]

export const appRoutes: RouteObject[] = [
  {
    element: (
      <AuthProvider>
        <AppLayout />
        <ScrollRestoration />
        <AppVersionCheck />
      </AuthProvider>
    ),
    children: [
      {
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        children: protectedRoutes,
      },

      {
        element: (
          <RedirectRoute>
            <Outlet />
          </RedirectRoute>
        ),
        children: [
          { path: AppNavPaths.Login, element: <LoginPage /> },
          {
            path: '/',
            element: <LandingPage />,
          },
        ],
      },
    ],
  },
]
