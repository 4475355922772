import { zIndices } from '@/theme/z-index'
import { Center, Spinner } from '@chakra-ui/react'

export const SpinnerOverlay = () => {
  return (
    <Center
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      backgroundColor="rgba(255, 255, 255, 0.8)" // Optional: to dim the background
      zIndex={zIndices.overlay}
    >
      <Spinner />
    </Center>
  )
}
