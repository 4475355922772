//https://github.com/ianstormtaylor/slate/blob/main/site/components.tsx
import React, { Ref, PropsWithChildren, LegacyRef } from 'react'
import { css } from '@emotion/css'
import {
  useSlateStatic,
  ReactEditor,
  useSelected,
  useFocused,
  useSlate,
} from 'slate-react'
import { Transforms } from 'slate'

import { RiDeleteBin5Line } from 'react-icons/ri'
import { GoItalic } from 'react-icons/go'
import { RxUnderline } from 'react-icons/rx'

import { MdFormatBold, MdFormatListBulleted } from 'react-icons/md'
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from '../utils'

import { PiListNumbers } from 'react-icons/pi'
import { Button as ChakraButton } from '@chakra-ui/react'
import { LinkComponent } from './Link'
import { SlateTextTypes } from '~shared/types'

interface BaseProps {
  className: string
  [key: string]: unknown
}
type OrNull<T> = T | null

export const Button = React.forwardRef(
  (
    {
      active,
      ...props
    }: PropsWithChildren<
      {
        active: boolean
        reversed: boolean
      } & BaseProps
    >,
    ref: Ref<OrNull<HTMLSpanElement>>
  ) => {
    return (
      <ChakraButton
        p="0"
        _hover={{
          bg: 'gray.300',
        }}
        {...props}
        as="span"
        ref={ref as LegacyRef<HTMLSpanElement> | undefined}
        bg={active ? 'gray.200' : 'parent'}
        color={'black'}
      />
    )
  }
)

export const Image = ({ attributes, children, element }) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className={css`
          position: relative;
        `}
      >
        <img
          src={element.url}
          className={css`
            display: block;
            max-width: 100%;
            max-height: 20em;
            box-shadow: ${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
          `}
        />
        <Button
          active
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          className={css`
            display: ${selected && focused ? 'inline' : 'none'};
            position: absolute;
            top: 0.5em;
            left: 0.5em;
            background-color: white;
          `}
        >
          <RiDeleteBin5Line size={24} />
        </Button>
      </div>
    </div>
  )
}

export const Element = (props) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case SlateTextTypes.IMAGE:
      return <Image {...props} />
    case SlateTextTypes.BULLETED_LIST:
      return (
        <ul
          style={{
            paddingLeft: '2rem',
          }}
          {...attributes}
        >
          {children}
        </ul>
      )
    case SlateTextTypes.LIST_ITEM:
      return (
        <li
          style={{
            paddingLeft: '1rem',
          }}
          {...attributes}
        >
          {children}
        </li>
      )
    case SlateTextTypes.NUMBERED_LIST:
      return (
        <ol
          style={{
            paddingLeft: '1rem',
          }}
          {...attributes}
        >
          {children}
        </ol>
      )
    case SlateTextTypes.LINK:
      return <LinkComponent {...props} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

export const MarkButton = ({ format }) => {
  const editor = useSlate()
  let iconComponent: React.ReactNode | null = null
  switch (format) {
    case SlateTextTypes.BOLD:
      iconComponent = <MdFormatBold size={20} />
      break
    case SlateTextTypes.ITALIC:
      iconComponent = <GoItalic size={20} />
      break
    case SlateTextTypes.UNDERLINE:
      iconComponent = <RxUnderline size={20} />
      break
  }

  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {iconComponent}
    </Button>
  )
}

export const BlockButton = ({ format }) => {
  const editor = useSlate()
  let iconComponent: React.ReactNode | null = null
  switch (format) {
    case SlateTextTypes.NUMBERED_LIST:
      iconComponent = <PiListNumbers size={20} />
      break
    case SlateTextTypes.BULLETED_LIST:
      iconComponent = <MdFormatListBulleted size={20} />
      break
  }
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {iconComponent}
    </Button>
  )
}
