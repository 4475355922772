export const PRIVACY_POLICY = 'https://go.gov.sg/pressify-privacy-policy'
export const TERMS_OF_USE = 'https://go.gov.sg/pressify-terms-of-use'
export const USER_GUIDE = 'https://go.gov.sg/pressify-user-guide'
export const REPORT_VULNERABILITY = 'https://go.gov.sg/report-vulnerability'

export const DIS_INSTAGRAM = 'https://www.instagram.com/thesingaporedis/'
export const DIS_FACEBOOK = 'https://www.facebook.com/thesingaporeDIS/'
export const DIS_LINKEDIN =
  'https://sg.linkedin.com/company/digital-and-intelligence-service'

export const DOTC_WEBSITE = 'https://dotc.gov.sg/'
