import { overflowScrollBarStyles } from '@/custom-css-prop/overflow-scrollbar-styles'
import { useGetUserIdFromContext } from '@/hooks'
import { useGetCollections } from '@/hooks/useCollection'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonProps,
  Center,
  HStack,
  Spinner,
  useDisclosure,
  VStack,
  WrapItem,
  Text,
  Stack,
  AccordionButtonProps,
  AccordionPanelProps,
} from '@chakra-ui/react'
import { BiStar } from 'react-icons/bi'
import { HiOutlineGlobe, HiOutlineNewspaper } from 'react-icons/hi'
import { IoMdAdd } from 'react-icons/io'
import { CollectionButton } from './CollectionButton'
import { CollectionRes } from '~shared/dtos'
import { CreateCollectionModal } from '@/features/collections/create/CreateCollectionModal'
import { searchFilterParam } from '@/constants/search-params'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { DEFAULT_COLLECTION, OPENSEARCH_INDEX } from '~shared/constants'
import { isEmpty } from 'lodash'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useCallback, useMemo } from 'react'
import { AppNavPaths } from '@/utils/paths'
import { COLLECTIONS_TITLE, PUBLICATIONS_TITLE } from '@/constants/title'
import { PRESSUM_NAME } from '@/constants/document'

export const CollectionSidebar = () => {
  const { userId } = useGetUserIdFromContext()
  const { isCollectionsLoading, collections } = useGetCollections(userId)
  const collectionsWithoutStarred = useMemo(() => {
    return collections?.filter(
      (collection) => collection.name !== DEFAULT_COLLECTION
    )
  }, [collections])
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const {
    isOpen: isOpenCreateModal,
    onClose: onCloseCreateModal,
    onOpen: onOpenCreateModal,
  } = useDisclosure()

  const {
    collectionIds: collectionIdsSearchParam,
    indices: indicesParam,
    tags: tagsParam,
    date: { startDate: startDateParam, endDate: endDateParam },
    query: queryParam,
    sort: sortParam,
  } = searchFilterParam
  const [searchParams, setSearchParams] = useSearchParams()
  const deleteAllUrlParams = useCallback(
    (params: URLSearchParams) => {
      params.delete(startDateParam)
      params.delete(endDateParam)
      params.delete(sortParam)
      params.delete(queryParam)
      params.delete(tagsParam)
      params.delete(indicesParam)
      params.delete(collectionIdsSearchParam)
    },
    [
      collectionIdsSearchParam,
      endDateParam,
      indicesParam,
      queryParam,
      sortParam,
      startDateParam,
      tagsParam,
    ]
  )

  const starredCollectionId = collections?.find(
    (collection) => collection.name === DEFAULT_COLLECTION
  )?.collectionId

  const isAllArticles =
    isEmpty(searchParams.getAll(collectionIdsSearchParam)) &&
    (searchParams.getAll(indicesParam).includes(OPENSEARCH_INDEX.All) ||
      searchParams.getAll(indicesParam).length === 0)

  const isOnlyPressumPublication =
    searchParams.get(indicesParam) === OPENSEARCH_INDEX.PressumArticle &&
    searchParams.getAll(collectionIdsSearchParam).length === 0 &&
    searchParams.getAll(indicesParam).length === 1

  const isStarredHighlighted =
    searchParams.getAll(collectionIdsSearchParam).length === 1 &&
    searchParams.get(collectionIdsSearchParam) === `${starredCollectionId}` &&
    searchParams.getAll(indicesParam).length === 0

  const onClickAllArticles = useCallback(() => {
    if (pathname !== AppNavPaths.Documents) {
      navigate(AppNavPaths.Documents)
      return
    }
    deleteAllUrlParams(searchParams)

    setSearchParams(searchParams)
  }, [pathname, deleteAllUrlParams, searchParams, setSearchParams, navigate])

  const onClickStarrred = useCallback(() => {
    deleteAllUrlParams(searchParams)
    // set "Starred" collectionIds
    searchParams.set(collectionIdsSearchParam, `${starredCollectionId}`)
    setSearchParams(searchParams)
  }, [
    deleteAllUrlParams,
    searchParams,
    collectionIdsSearchParam,
    starredCollectionId,
    setSearchParams,
  ])

  const onClickPressums = useCallback(() => {
    deleteAllUrlParams(searchParams)
    searchParams.set(indicesParam, OPENSEARCH_INDEX.PressumArticle)
    setSearchParams(searchParams)
  }, [deleteAllUrlParams, indicesParam, searchParams, setSearchParams])

  const onClickCollection = useCallback(
    (collectionId: number) => {
      deleteAllUrlParams(searchParams)
      searchParams.set(collectionIdsSearchParam, `${collectionId}`)
      setSearchParams(searchParams)
    },
    [
      collectionIdsSearchParam,
      deleteAllUrlParams,
      searchParams,
      setSearchParams,
    ]
  )

  const isCollectionHighlighted = (collectionId: number) => {
    return (
      searchParams.getAll(collectionIdsSearchParam).length === 1 &&
      searchParams.get(collectionIdsSearchParam) === `${collectionId}` &&
      searchParams.getAll(indicesParam).length === 0
    )
  }

  return (
    <VStack width="100%" align="left" height="100%" spacing="1.5rem" pt="1rem">
      <Stack paddingX="1rem" spacing={0}>
        <StyledHeaderButton
          paddingX="0.5rem"
          leftIcon={<HiOutlineGlobe />}
          onClick={onClickAllArticles}
          isHighlighted={isAllArticles && pathname !== AppNavPaths.Home}
        >
          All articles
        </StyledHeaderButton>
        <StyledHeaderButton
          paddingX="0.5rem"
          leftIcon={<BiStar />}
          onClick={onClickStarrred}
          isHighlighted={isStarredHighlighted}
        >
          Starred
        </StyledHeaderButton>
      </Stack>
      <Accordion
        defaultIndex={[0, 1]}
        allowMultiple
        textColor="#E2E8F0"
        width="100%"
      >
        <Stack spacing="1.5rem">
          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <StyledAccordionButton
                  isExpanded={isExpanded}
                  displayText={PUBLICATIONS_TITLE}
                />

                <StyledAccordionPanel>
                  <StyledHeaderButton
                    onClick={onClickPressums}
                    isHighlighted={isOnlyPressumPublication}
                    leftIcon={<HiOutlineNewspaper />}
                  >
                    {PRESSUM_NAME}
                  </StyledHeaderButton>
                </StyledAccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <StyledAccordionButton
                  isExpanded={isExpanded}
                  displayText={COLLECTIONS_TITLE}
                  rightElement={
                    <WrapItem>
                      <HStack
                        fontSize="0.875rem"
                        fontWeight="semibold"
                        color="orange.400"
                        aria-label="Add Collection"
                        onClick={(e) => {
                          e.stopPropagation()
                          onOpenCreateModal()
                        }}
                      >
                        <IoMdAdd size="0.875rem" />
                        <Text>Add</Text>
                      </HStack>
                    </WrapItem>
                  }
                />

                <StyledAccordionPanel
                  maxHeight="calc(100vh - 22rem - 3rem)" // 22rem is the height of the bottom actions button, 3 rem is the bottom padding
                  overflowY="auto"
                  css={overflowScrollBarStyles}
                >
                  {isCollectionsLoading ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : (
                    collectionsWithoutStarred &&
                    collectionsWithoutStarred.map(
                      ({
                        collectionId,
                        name,
                        documentCount,
                        userCount,
                      }: CollectionRes) => {
                        return (
                          name !== DEFAULT_COLLECTION && (
                            <CollectionButton
                              key={collectionId}
                              name={name}
                              userCount={userCount}
                              documentCount={documentCount}
                              onClick={() => {
                                onClickCollection(collectionId)
                              }}
                              isHighlighted={isCollectionHighlighted(
                                collectionId
                              )}
                            />
                          )
                        )
                      }
                    )
                  )}
                </StyledAccordionPanel>
              </>
            )}
          </AccordionItem>
        </Stack>
      </Accordion>

      <CreateCollectionModal
        isOpen={isOpenCreateModal}
        onClose={onCloseCreateModal}
      />
    </VStack>
  )
}

const StyledHeaderButton = (
  props: ButtonProps & { isHighlighted?: boolean }
) => {
  const { children, isHighlighted, ...restProps } = props
  return (
    <Button
      {...restProps}
      variant="ghost"
      color="gray.50"
      width="100%"
      justifyContent="flex-start"
      isTruncated
      bgColor={isHighlighted ? 'gray.700' : ''}
    >
      {children}
    </Button>
  )
}

const StyledAccordionButton = ({
  isExpanded,
  displayText,
  rightElement,
  ...restProps
}: AccordionButtonProps & {
  isExpanded: boolean
  displayText: string
  rightElement?: React.ReactElement
}) => {
  return (
    <h2>
      <AccordionButton
        paddingY={0}
        paddingLeft="1.5rem"
        paddingRight="1.5rem"
        color="gray.50"
        {...restProps}
      >
        {isExpanded ? <FiChevronDown /> : <FiChevronUp />}
        <Box
          flex="1"
          textAlign="left"
          marginLeft="0.5rem"
          fontSize="1rem"
          fontWeight={700}
          lineHeight="1.5rem"
        >
          {displayText}
        </Box>
        {rightElement}
      </AccordionButton>
    </h2>
  )
}

const StyledAccordionPanel = ({
  children,
  ...restProps
}: AccordionPanelProps) => {
  return (
    <AccordionPanel
      paddingY={0}
      paddingLeft="1.5rem"
      paddingRight="1rem"
      {...restProps}
    >
      {children}
    </AccordionPanel>
  )
}
