import {
  DEFAULT_SORT_PARAM,
  searchFilterParam,
} from '@/constants/search-params'
import { AppNavPaths, ROOT_DOCUMENT_PATH } from '@/utils/paths'
import {
  getTagNameAndCategoryFromSearchParams,
  getTagNameFromSearchParams,
} from '@/utils/tag'
import { useCallback, useMemo } from 'react'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { SORT_OPTIONS, OPENSEARCH_INDEX } from '~shared/constants'
import { CollectionRes } from '~shared/dtos'

export const useSetSortSearchParams = ({ searchParams }) => {
  const setSortSearchparams = useCallback(() => {
    const { query, sort } = searchFilterParam
    searchParams.get(query)
      ? searchParams.set(sort, SORT_OPTIONS.Relevance)
      : searchParams.set(sort, SORT_OPTIONS.Newest)
  }, [searchParams])
  return { setSortSearchparams }
}

export const useGetCommonSearchParams = () => {
  const {
    sort: sortParam,
    tags: tagsParam,
    query: queryParam,
    date: { startDate: startDateParam, endDate: endDateParam },
    indices: indicesParams,
    collectionIds: collectionIdsParams,
  } = searchFilterParam
  const [searchParams] = useSearchParams()
  const sort = searchParams.get(sortParam) ?? DEFAULT_SORT_PARAM
  const tags = getTagNameAndCategoryFromSearchParams(
    searchParams.getAll(tagsParam)
  )
  const indices = searchParams.getAll(indicesParams) as OPENSEARCH_INDEX[]
  const collectionIds = searchParams.getAll(collectionIdsParams)
  const query = searchParams.get(queryParam) ?? ''
  const startDate = searchParams.get(startDateParam)
  const endDate = searchParams.get(endDateParam)

  return {
    sort,
    tags,
    query,
    startDate,
    endDate,
    collectionIds,
    indices,
  }
}

export const useAddTagToSearchParams = () => {
  const { pathname } = useLocation()
  const rootRoute = pathname.split('/')[1]
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const tags = getTagNameFromSearchParams(
    searchParams.getAll(searchFilterParam.tags)
  )
  const sort = searchParams.get(searchFilterParam.sort)
  const query = searchParams.get(searchFilterParam.query)
  const addTagToSearchParams = useCallback(
    ({ category, displayName }: { category: string; displayName: string }) => {
      const tagSet = new Set(tags)
      if (!tagSet.has(displayName)) {
        if (
          rootRoute === ROOT_DOCUMENT_PATH ||
          `/${rootRoute}` === AppNavPaths.Home
        ) {
          navigate({
            pathname: AppNavPaths.Documents,
            search: createSearchParams({
              sort: SORT_OPTIONS.Newest,
              tags: [displayName, category].join(','),
            }).toString(),
          })
        } else {
          searchParams.append(
            searchFilterParam.tags,
            [displayName, category].join(',')
          )
          if (!sort) {
            query
              ? searchParams.set(searchFilterParam.sort, SORT_OPTIONS.Relevance)
              : searchParams.set(searchFilterParam.sort, SORT_OPTIONS.Newest)
          }
          setSearchParams(searchParams)
        }
      }
    },
    [navigate, query, rootRoute, searchParams, setSearchParams, sort, tags]
  )
  return addTagToSearchParams
}

export const useAddQueryToSearchParam = () => {
  const { pathname } = useLocation()
  const rootRoute = pathname.split('/')[1]
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { sort, query } = searchFilterParam
  const addQueryToSearchParams = useCallback(
    ({ queryValue }: { queryValue: string }) => {
      if (`/${rootRoute}` === AppNavPaths.Home) {
        navigate({
          pathname: AppNavPaths.Documents,
          search: createSearchParams({
            sort: SORT_OPTIONS.Relevance,
            query: queryValue,
          }).toString(),
        })
      } else {
        searchParams.set(sort, SORT_OPTIONS.Relevance)
        searchParams.set(query, queryValue)
        setSearchParams(searchParams, { replace: true })
      }
    },
    [navigate, query, rootRoute, searchParams, setSearchParams, sort]
  )

  return { addQueryToSearchParams }
}

export const useGetHasFilters = () => {
  // check if there is tag to show collapse or expanded tag filter button
  const [searchParams] = useSearchParams()
  const {
    tags,
    date: { startDate, endDate },
  } = searchFilterParam
  const hasFilters =
    searchParams.get(tags) !== null ||
    searchParams.get(startDate) !== null ||
    searchParams.get(endDate) !== null
  return hasFilters
}

export const useGetIndexFromSearchParams = () => {
  const [searchParams] = useSearchParams()
  const indices = searchParams.getAll(searchFilterParam.indices)
  const isOneIndex = indices.length === 1
  const index = isOneIndex
    ? searchParams.get(searchFilterParam.indices) ?? ''
    : ''
  return { index, indices }
}

export const useGetCollectionIdFromSearchParams = () => {
  const [searchParams] = useSearchParams()
  const collectionIds = searchParams.getAll(searchFilterParam.collectionIds)
  const isOneCollection = collectionIds.length === 1
  const indices = searchParams.getAll(searchFilterParam.indices)
  const collectionId = isOneCollection
    ? searchParams.get(searchFilterParam.collectionIds) ?? ''
    : ''
  return {
    collectionId:
      collectionIds.length > 1 || indices.length !== 0
        ? undefined
        : +collectionId,
    collectionIds,
  }
}

export const useCheckIsSearchAllArticles = (
  publications?: string[],
  collections?: CollectionRes[]
) => {
  const { indices: indicesParams, collectionIds: collectionIdsParams } =
    searchFilterParam
  const [searchParams] = useSearchParams()
  const indices = searchParams.getAll(indicesParams)
  const collectionIds = searchParams.getAll(collectionIdsParams)
  const isSearchAllArticles = useMemo(() => {
    return (
      // if no indices and no collectionIds are selected
      (indices.length === 0 && collectionIds.length === 0) ||
      // if all indices and all collections are selected
      (collections &&
        collections.length === collectionIds.length &&
        publications &&
        publications.length === indices.length)
    )
  }, [collectionIds.length, collections, indices, publications])

  return {
    isSearchAllArticles,
  }
}
