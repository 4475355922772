import { TAG_TO_COLOR } from '@/utils/tag-to-color'
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { IoEllipse } from 'react-icons/io5'
import { CategoryType } from '~shared/constants'
import { COUNTRY_REGION_TEXT_LABEL } from '@/constants/title'

type SidebarAccordionItemProps = {
  children: ReactNode
  onClick: () => void
  itemName: string
  isColorTagged?: boolean
  isSelected?: boolean
}

export const SidebarAccordionItem = ({
  isColorTagged,
  itemName,
  children,
  onClick,
  isSelected,
}: SidebarAccordionItemProps) => {
  return (
    <AccordionItem border="none">
      <AccordionButton
        textColor="white"
        onClick={onClick}
        _hover={{
          backgroundColor: 'orange.100',
          color: 'orange.500',
          fontWeight: 'bold',
        }}
        bgColor={isSelected ? 'gray.700' : ''}
        borderRadius="4px"
        paddingX="8px"
      >
        <HStack
          as="span"
          flex="1"
          textAlign="left"
          fontWeight={isSelected ? '700' : ''}
          spacing="8px"
        >
          <Text>
            {itemName === CategoryType.Country
              ? COUNTRY_REGION_TEXT_LABEL
              : itemName}
          </Text>
          {/* manually adjust to display to "Country / Region" instead of "Country" as defined in category type */}
          {isColorTagged && (
            <Icon as={IoEllipse} fontSize="xs" color={TAG_TO_COLOR[itemName]} />
          )}
        </HStack>
        <AccordionIcon fontSize="2xl" />
      </AccordionButton>
      <AccordionPanel paddingX="0px" width="100%">
        {children}
      </AccordionPanel>
    </AccordionItem>
  )
}
