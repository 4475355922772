import {
  CreateUserCollectionReq,
  CreateUserCollectionRes,
  DeleteUserCollectionRes,
  UpdateUserCollectionReq,
  UpdateUserCollectionRes,
  UserCollectionRes,
} from '~shared/dtos/user-collection.dto'
import { ApiService } from './ApiService'

export const createUserCollection = async ({
  payload,
  collectionId,
}: {
  payload: CreateUserCollectionReq
  collectionId: number
}) => {
  const { data } = await ApiService.post<CreateUserCollectionRes>(
    `collection/${collectionId}/user`,
    payload
  )
  return data
}

export const getUserCollections = async (collectionId?: number) => {
  if (!collectionId) return []
  const { data } = await ApiService.get<UserCollectionRes[]>(
    `collection/${collectionId}/user`
  )
  return data
}

export const updateUserCollection = async ({
  payload,
  collectionId,
  userId,
}: {
  payload: UpdateUserCollectionReq
  collectionId: number
  userId: string
}) => {
  const { data } = await ApiService.patch<UpdateUserCollectionRes>(
    `collection/${collectionId}/user/${userId}`,
    payload
  )
  return data
}

export const deleteUserCollection = async ({
  collectionId,
  userId,
}: {
  collectionId: number
  userId: string
}) => {
  const { data } = await ApiService.delete<DeleteUserCollectionRes>(
    `collection/${collectionId}/user/${userId}`
  )
  return data
}
