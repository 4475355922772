import { DateFilterTag } from '@/components/tag/DateFilterTag'
import { searchFilterParam } from '@/constants/search-params'
import { useIsMobile } from '@/hooks'
import { getTagNameAndCategoryFromSearchParams } from '@/utils/tag'
import { DEFAULT_TAG_COLOR, TAG_TO_COLOR } from '@/utils/tag-to-color'
import { Button, HStack, Wrap, WrapItem } from '@chakra-ui/react'
import { useMemo } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { useSearchParams } from 'react-router-dom'

const FilterTagsComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    tags,
    date: { startDate, endDate },
  } = searchFilterParam
  const isMobile = useIsMobile()
  const selectedTags = useMemo(() => {
    return getTagNameAndCategoryFromSearchParams(searchParams.getAll(tags))
  }, [searchParams, tags])

  return (
    <>
      <DateFilterTag />
      {selectedTags.map(
        ({ displayName: selectedName, category: selectedCategory }) => {
          return (
            <WrapItem
              key={selectedName}
              onClick={() => {
                /// can't seem to set all the tags at once
                // deleting them before setting seems like the best way
                searchParams.delete(tags)
                selectedTags.filter(({ displayName, category }) => {
                  if (displayName !== selectedName) {
                    searchParams.append(tags, [displayName, category].join(','))
                    return true
                  }
                })
                setSearchParams(searchParams)
              }}
            >
              <Button
                fontSize="0.75rem"
                height="1.25rem"
                borderRadius="0.375rem"
                padding="0.375rem"
                bg={TAG_TO_COLOR[selectedCategory] ?? DEFAULT_TAG_COLOR}
                textColor="white"
                rightIcon={<RxCross2 />}
              >
                {selectedName}
              </Button>
            </WrapItem>
          )
        }
      )}
      {!isMobile && (
        <WrapItem key={'Clear Tags'}>
          <Button
            fontSize="0.75rem"
            height="1.25rem"
            width="4.5rem"
            borderRadius="0.375rem"
            padding="0.375rem"
            bg="gray.100"
            textColor="black"
            onClick={() => {
              searchParams.delete(startDate)
              searchParams.delete(endDate)
              searchParams.delete(tags)
              setSearchParams(searchParams)
            }}
          >
            Clear Tags
          </Button>
        </WrapItem>
      )}
    </>
  )
}

export const FilterTags = () => {
  const isMobile = useIsMobile()

  const [searchParams] = useSearchParams()
  const {
    tags,
    date: { startDate, endDate },
  } = searchFilterParam

  const hasFilterTags = useMemo(
    () =>
      searchParams.get(tags) ||
      searchParams.get(startDate) ||
      searchParams.get(endDate),
    [searchParams, tags, startDate, endDate]
  )

  if (!hasFilterTags) {
    return null
  }

  return (
    <>
      {isMobile ? (
        <HStack
          overflowX="auto"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
          }}
        >
          <FilterTagsComponent />
        </HStack>
      ) : (
        <Wrap>
          <FilterTagsComponent />
        </Wrap>
      )}
    </>
  )
}
