import {
  searchAllDocuments,
  searchCollections,
  searchLatestPressums,
  searchLatestPublishedArticles,
  searchTags,
} from '@/api/search'
import { queryKeys } from '@/constants/query-key'
import { CollectionOption } from '@/types/document'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { DEFAULT_COLLECTION } from '~shared/constants'
import {
  AllDocumentSearchReq,
  DocumentRes,
  PaginatedRes,
  SearchTagReq,
} from '~shared/dtos'
import { isEmpty } from 'lodash'

export const useSearchAllDocuments = ({
  sort,
  tags,
  query,
  startDate,
  endDate,
  indices,
  collectionIds,
  enabled = true,
}: AllDocumentSearchReq & { enabled?: boolean }) => {
  const params = {
    sort,
    tags,
    query,
    startDate,
    endDate,
    indices,
    collectionIds,
  }
  const isMultipleCollectionsQuery =
    (collectionIds && collectionIds.length > 1) || isEmpty(collectionIds)

  const {
    data,
    isLoading,
    isError: isSearchDocumentsError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: isMultipleCollectionsQuery
      ? queryKeys.searchDocumentsInMultipleCollectionsQuery(params)
      : queryKeys.searchDocumentsQuery(params),
    queryFn: async ({ pageParam }) => {
      const data = await searchAllDocuments({ reqParam: params, pageParam })
      return data
    },
    enabled,
    staleTime: 1000 * 60 * 1,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage: PaginatedRes<DocumentRes>) =>
      lastPage.metadata.nextPageParams,
  })

  const uniqueDocumentIds = new Set<string>()
  data?.pages.forEach((page) => {
    const uniqueDocuments = page.data.filter((document) => {
      if (!uniqueDocumentIds.has(document.id)) {
        uniqueDocumentIds.add(document.id)
        return true
      }
      return false
    })
    page.data = uniqueDocuments
  })

  return {
    data,
    isSearchDocumentsLoading: isLoading,
    isSearchDocumentsError,
    fetchNextPage,
    hasNextPage,
  }
}

export const useSearchTags = ({ query }: SearchTagReq) => {
  const {
    data,
    isLoading: isSearchTagsLoading,
    isError: isSearchTagsError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: queryKeys.searchedTags(query),
    queryFn: async ({ pageParam }) => {
      const data = await searchTags({ pageParam, query })
      return data
    },
    retry: false,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => lastPage.metadata.nextPageParams,
    enabled: query !== '',
  })

  return {
    data,
    isSearchTagsLoading,
    isSearchTagsError,
    fetchNextPage,
    hasNextPage,
  }
}

export const useSearchLatestPressums = (enabled: boolean) => {
  const { fetchStatus, isLoading, data } = useQuery({
    queryKey: queryKeys.latestPressums(),
    queryFn: searchLatestPressums,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  })
  return {
    isLatestPressumLoading: isLoading && fetchStatus !== 'idle',
    data,
  }
}

export const useSearchLatestPublishedArticles = ({
  enabled,
}: {
  enabled: boolean
}) => {
  const { data, isLoading, fetchStatus } = useQuery({
    queryKey: queryKeys.latestPublishedArticles(),
    queryFn: searchLatestPublishedArticles,
    enabled,
  })
  return { data, isLoading: isLoading && fetchStatus !== 'idle' }
}

export const useSearchCollections = ({ query }: { query: string }) => {
  const params = {
    query,
    sortOptions: [
      {
        sortBy: 'userCount',
        orderBy: 'desc',
      },
      {
        sortBy: 'name',
        orderBy: 'asc',
      },
    ],
  }
  const {
    isLoading: isSearchCollectionsLoading,
    data: collectionPages,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: queryKeys.searchCollectionsQuery(params),
    queryFn: async ({ pageParam }) => {
      const data = await searchCollections({
        pageParam,
        params,
      })
      return data
    },
    getNextPageParam: (lastPage) => lastPage.metadata.nextPageParams,
    retry: true,
    refetchOnWindowFocus: false,
  })

  const collections = useMemo(() => {
    const flattenData: CollectionOption[] | undefined =
      collectionPages?.pages.flatMap((page) => {
        return page.data
          .filter((collection) => collection.name !== DEFAULT_COLLECTION)
          .map((collection) => ({
            value: collection.collectionId,
            label: collection.name,
            isNew: false,
            documentCount: collection.documentCount,
            userCount: collection.userCount,
          }))
      })
    return flattenData
  }, [collectionPages])

  return {
    isSearchCollectionsLoading,
    collections,
    fetchNextPage,
    hasNextPage,
  }
}
