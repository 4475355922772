import { HeaderTextWithLink } from '@/components/HeaderTextWithLink'
import EmblaCarousel from '@/components/carousel/EmblaCarousel'
import { COUNTRY_SVG_MAP } from '@/constants/country'
import { LatestArticle } from '@/features/home/ArticleCards/LatestArticle'
import { LatestPressum } from '@/features/home/ArticleCards/LatestPressum'
import Country from '@/features/home/CountryCards/Country'
import { useAddTagToSearchParams } from '@/hooks/params/useSearchParams'
import { AppNavPaths } from '@/utils/paths'
import { Flex, VStack, useDisclosure, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { DocumentRes } from '~shared/dtos'
import { DocumentTagWithCategory } from '~shared/dtos/tag.dto'
import { MobileFilterTagsDrawer } from '../../components/tag/MobileFilterTagsDrawer'
import { MobileFilterButton } from '@/components/tag/MobileFilterButton'
import { COUNTRIES_REGIONS_TEXT_LABEL } from '@/constants/title'
import { isEmpty } from 'lodash'
import { CategoryType } from '~shared/constants'

export const MobileHomePage = ({
  articles,
  pressumId,
  pressums,
}: {
  articles?: DocumentRes[]
  pressumId: string
  pressums?: DocumentRes[]
}) => {
  const navigate = useNavigate()
  const addTagToSearchParams = useAddTagToSearchParams()
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <VStack
      width="100%"
      align="left"
      spacing="1rem"
      paddingX="1.5rem"
      minHeight="calc(100vh - 9rem)"
    >
      <MobileFilterButton onClick={onOpen} />
      <MobileFilterTagsDrawer isOpen={isOpen} onClose={onClose} />
      {/* Latest Articles */}
      {/* TODO (ALEX): To look at creating a component for latest article carousel */}
      {articles && !isEmpty(articles) && (
        <>
          <HeaderTextWithLink
            headerText="Latest Published Articles"
            linkText="View all"
            onClick={() => {
              navigate(AppNavPaths.Documents)
            }}
          />
          {/* negative margins to offset the page paddings */}
          <Stack marginX="-1.5rem">
            <EmblaCarousel options={{ containScroll: false }}>
              {articles.map(({ id, title, dateTime, tags, slateContent }) => {
                return (
                  <Flex
                    key={id}
                    flex="0 0 calc(100% - 3rem)" // 3rem is the left and right padding
                    minWidth={0}
                    minHeight="10rem"
                  >
                    <LatestArticle
                      key={id}
                      id={+id}
                      title={title}
                      dateTime={dateTime}
                      tags={tags as DocumentTagWithCategory[]}
                      slateContent={slateContent}
                      isAlignTop
                      isNoDivider
                    />
                  </Flex>
                )
              })}
            </EmblaCarousel>
          </Stack>
        </>
      )}

      {/* Latest Pressum */}
      {pressums && !isEmpty(pressums) && (
        <VStack align="left" spacing="1rem">
          <HeaderTextWithLink
            headerText={`Latest Pressum (${pressumId
              .split(' ')
              .slice(1)
              .join(' ')})`}
            linkText="View all"
            onClick={() =>
              addTagToSearchParams({
                displayName: pressumId ?? '',
                category: CategoryType.Source,
              })
            }
          />
          <Flex direction="column" rowGap="1rem">
            {pressums.map(({ id, title, tags, dateTime }) => {
              return (
                <LatestPressum
                  key={id}
                  id={+id}
                  title={title}
                  dateTime={dateTime}
                  tags={tags as DocumentTagWithCategory[]}
                />
              )
            })}
          </Flex>
        </VStack>
      )}

      {/* Country */}
      {/* TODO (ALEX): To look at creating a component for country carousel */}
      <VStack align="left">
        <HeaderTextWithLink headerText={COUNTRIES_REGIONS_TEXT_LABEL} />
        <EmblaCarousel
          options={{ slidesToScroll: 'auto' }}
          slideStyle={{ gap: '1rem' }}
        >
          {Object.keys(COUNTRY_SVG_MAP).map((country) => (
            <Flex key={country} flex="0 0 fit-content" minWidth={0}>
              <Country
                key={country}
                country={country}
                svg={COUNTRY_SVG_MAP[country].svg}
                background={COUNTRY_SVG_MAP[country].background}
                height="7.5rem"
                width="9.938rem"
              />
            </Flex>
          ))}
        </EmblaCarousel>
      </VStack>
    </VStack>
  )
}
