import { CreateCollectionForm } from '@/features/collections/create/CreateCollectionForm'
import { PiBookBookmark } from 'react-icons/pi'
import { DrawerLayout } from '@/components/drawer/DrawerLayout'

type MobileCreateCollectionDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

export const MobileCreateCollectionDrawer = ({
  isOpen,
  onClose,
}: MobileCreateCollectionDrawerProps) => {
  return (
    <DrawerLayout
      isOpen={isOpen}
      onClose={onClose}
      HeaderIcon={PiBookBookmark}
      title="MANAGE COLLECTIONS"
    >
      <CreateCollectionForm onClose={onClose} />
    </DrawerLayout>
  )
}
