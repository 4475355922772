export enum SORT_OPTIONS {
  Newest = 'newest',
  Oldest = 'oldest',
  Relevance = 'relevance',
}

// used when user only upload images within the content field but never write any string content.
// this is required as strings within the content field will be converted into content field and content field cannot be empty
export const NO_STRING_CONTENT_TEXT = 'No text content available';
export const SUMMARY_SHOULD_NOT_BE_GENERATED_TEXT = 'No summary generated';

// textract uses .tiff files too but we don't support it
export const TEXTRACT_ACCEPTED_FILE_TYPES = {
  'image/*': ['.png', '.jpeg'],
  'application/pdf': ['.pdf'],
};

export const LEGAL_WARNING_PRIOR_TO_UPLOAD =
  'Before you upload: Please upload only what is essential for your purposes. Uploading excerpts and summaries decreases the risk of copyright infringement. Thank you!';

export const CUSTOM_LINK_PREFIX = 'l!nk@#: '; // retain user manual input in brackets as text instead of link

export enum OPENSEARCH_INDEX {
  All = '*',
  Others = 'others',
  PressumArticle = 'pressum-article',
  OpenSourceArticle = 'opensource-article',
}

// todo(ben): sync prisma enum with shared dto enum
export enum DocumentType {
  PRESSUM_ARTICLE = 'PRESSUM_ARTICLE',
  OPENSOURCE_ARTICLE = 'OPENSOURCE_ARTICLE',
  OTHERS = 'OTHERS',
}

export const DOCUMENT_INDEX_TO_TYPE = {
  [OPENSEARCH_INDEX.OpenSourceArticle]: DocumentType.OPENSOURCE_ARTICLE,
  [OPENSEARCH_INDEX.PressumArticle]: DocumentType.PRESSUM_ARTICLE,
};

export const DOCUMENT_TYPE_TO_INDEX = {
  [DocumentType.OPENSOURCE_ARTICLE]: OPENSEARCH_INDEX.OpenSourceArticle,
  [DocumentType.PRESSUM_ARTICLE]: OPENSEARCH_INDEX.PressumArticle,
  [DocumentType.OTHERS]: OPENSEARCH_INDEX.Others,
};

export const PUBLICATIONS = [OPENSEARCH_INDEX.PressumArticle];
