import ArticlesNotFoundSvg from '@/assets/articles-not-found.svg'
import { useIsMobile } from '@/hooks'
import { Image, VStack, Text } from '@chakra-ui/react'

export const ArticlesNotFoundCard = () => {
  const isMobile = useIsMobile()
  return (
    <VStack minHeight="calc(100vh - 9rem)">
      {!isMobile && (
        <Text fontWeight="bold" fontSize={{ lg: '3rem', base: '2rem' }}>
          Articles not found
        </Text>
      )}
      <Image src={ArticlesNotFoundSvg} alt="Articles Not Found" />
      <Text
        fontSize={{ lg: '1.25rem', base: '1rem' }}
        fontWeight="medium"
        align="center"
      >
        There are currently no articles that match your search queries
      </Text>
    </VStack>
  )
}
