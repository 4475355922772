import { Icon } from '@chakra-ui/icon'
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/input'
import { IconButton, InputProps, StyleProps } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { IoSearchOutline } from 'react-icons/io5'
import { RxCross2 } from 'react-icons/rx'

type TagSearchBarProps = StyleProps &
  Pick<InputProps, 'size'> & {
    placeholder: string
    onChange: (searchQuery: string) => void
    searchQuery: string
    onRemoveSearchQuery: () => void
    hasSearchIcon?: boolean
    focusBorderColor?: string
    mobileInputBorder?: string
    mobileInputColor?: string
    mobilePlaceholderColor?: string
    mobileCrossIconColor?: string
    searchIconColor?: string
  }

export const TagSearchbar = ({
  placeholder,
  onChange,
  searchQuery,
  onRemoveSearchQuery,
  hasSearchIcon = true,
  size = 'sm',
  focusBorderColor = '#F6AD55',
  mobileInputBorder,
  mobileInputColor,
  mobilePlaceholderColor,
  mobileCrossIconColor = 'gray.800',
  searchIconColor = 'gray.400',
  ...rest
}: TagSearchBarProps) => {
  const [placeholderState, setPlaceholderState] = useState<string>(placeholder)
  const [searchQueryDisplay, setSearchQueryDisplay] =
    useState<string>(searchQuery)

  // a separate `searchQueryDisplay` variable here is used instead of the `searchQuery` as `setSearchQuery` is debounced at 250ms. `searchQuery` is used for a backend hook, hence a separate display variable is required to render an immediate visual change
  useEffect(() => {
    setSearchQueryDisplay(searchQuery)
  }, [searchQuery])

  const handleRemoveSearchQuery = () => {
    setSearchQueryDisplay('')
    onRemoveSearchQuery()
  }

  return (
    <InputGroup
      bg="gray.700"
      borderRadius="1.25rem"
      w="100%"
      alignSelf="center"
      px="1px" // to prevent the border from being cut off
      size={size}
      {...rest}
    >
      {hasSearchIcon && (
        <InputLeftElement>
          <Icon as={IoSearchOutline} color={searchIconColor} />
        </InputLeftElement>
      )}

      {searchQuery && (
        <InputRightElement display="flex" alignItems="center" h="100%">
          <IconButton
            icon={<RxCross2 />}
            aria-label="clear-text-from-searchbar"
            color={{ base: mobileCrossIconColor, lg: 'gray.50' }}
            _hover={{
              bg: 'transparent',
              color: 'black',
            }}
            size={size}
            bg="transparent"
            onClick={handleRemoveSearchQuery}
            display="flex"
            alignItems="center"
            justifyContent="center"
            h="auto"
            minW="auto"
            p="0"
          />
        </InputRightElement>
      )}
      <Input
        onFocus={() => {
          setPlaceholderState('')
        }}
        onBlur={() => {
          setPlaceholderState(placeholder)
        }}
        borderRadius="1.25rem"
        color={{ base: mobileInputColor, lg: 'gray.50' }}
        border={{
          base: mobileInputBorder,
          lg: 'none',
        }}
        focusBorderColor={focusBorderColor}
        value={searchQueryDisplay}
        placeholder={placeholderState}
        _placeholder={{
          color: { base: mobilePlaceholderColor, lg: 'gray.400' },
        }}
        onChange={({ target: { value } }) => {
          setSearchQueryDisplay(value)
          onChange(value)
        }}
      />
    </InputGroup>
  )
}
