import { APP_PATHS_TO_NAME } from '@/utils/paths'
import { Stack, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

type MobileNavButtonProps = {
  icon: ReactElement
  path: string
  isHighlight: boolean
}

export const MobileNavButton = ({
  icon,
  path,
  isHighlight,
}: MobileNavButtonProps) => {
  const navigate = useNavigate()

  return (
    <Stack
      direction="column"
      spacing="0.25rem"
      alignItems="center"
      pt="0.5rem"
      color={isHighlight ? 'orange.500' : 'inherit'}
      onClick={() => navigate(path)}
    >
      {icon}
      <Text fontSize="0.75rem">{APP_PATHS_TO_NAME[path]}</Text>
    </Stack>
  )
}
