import OtpPageBackgroundSvg from '@/assets/otp-page-background.svg'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { useAuthContext } from '../useAuthContext'
import { useIsMobile } from '@/hooks'
import { LOGIN_CAPTION } from '@/constants/auth'
import { AuthBody } from './AuthBody'
import { OtpForm } from './OtpForm'

export const OtpBody = () => {
  const { email } = useAuthContext()
  const isMobile = useIsMobile()

  return (
    <AuthBody
      caption={isMobile ? LOGIN_CAPTION : `Find what you need, fast`}
      image={OtpPageBackgroundSvg}
    >
      <VStack alignItems="inherit" w="100%">
        <Text
          fontSize="1rem"
          fontWeight={{ lg: 'medium', base: 'normal' }}
          pt="0.25rem"
        >
          {`Enter OTP sent to ${email}`}
        </Text>
        <VStack
          spacing="1.5rem"
          align="flex-start"
          justify="flex-start"
          w="100%"
        >
          <HStack maxWidth="30rem" spacing="0.75rem" w="100%">
            <OtpForm />
          </HStack>
        </VStack>
      </VStack>
    </AuthBody>
  )
}
