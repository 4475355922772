import { SimpleGrid, Text, VStack } from '@chakra-ui/react'
import FeatureFullRepo from '@/assets/feature-full-repo.svg'
import FeatureSearch from '@/assets/feature-search.svg'
import FeatureSmart from '@/assets/feature-smart.svg'
import FeatureCollab from '@/assets/feature-collab.svg'

import { FeatureGridItem } from './FeatureGridItem'
export const FeaturesShowcase = () => {
  return (
    <VStack
      pt="1.5rem"
      w="100%"
      align="left"
      spacing="1.5rem"
      pr={{ lg: '3.5rem', base: 'auto' }}
    >
      <Text
        color="#1A202C"
        fontSize={{ base: '1.5rem', lg: '2.25rem' }}
        fontStyle="normal"
        fontWeight="bold"
        lineHeight="150%"
      >
        How does Pressify help?
      </Text>

      <SimpleGrid
        columns={{ base: 1, lg: 4 }}
        spacingX="2.5rem"
        spacingY="4rem"
      >
        <FeatureGridItem
          Icon={FeatureFullRepo}
          title="Full repository"
          description="One accessible platform to house various content types, instead of keeping them in multiple emails and folders."
        />
        <FeatureGridItem
          Icon={FeatureSearch}
          title="Precise search"
          description="Allows for quick and easy searching of contents within collections."
        />
        <FeatureGridItem
          Icon={FeatureSmart}
          title="Smart features"
          description="Related content recommendations and automatic AI tagging helps quicken the research process."
        />
        <FeatureGridItem
          Icon={FeatureCollab}
          title="Collaboration"
          description="Collate contents into shared collections for the team’s access, allowing all to be literally, on one page."
        />
      </SimpleGrid>
    </VStack>
  )
}
