import { useCallback, useMemo } from 'react'
import { Editable, Slate, withReact } from 'slate-react'
import { withImagesAndInlines } from './utils'
import { Descendant, createEditor } from 'slate'
import { Leaf, Element } from './components'
import { useIsMobile } from '@/hooks'

type SlateReadonlyProps = {
  value: Descendant[]
}

export const SlateReadOnly = ({ value }: SlateReadonlyProps) => {
  const editor = useMemo(
    () => withImagesAndInlines(withReact(createEditor())),
    []
  )
  const renderElement = useCallback(
    (props) => <Element {...props} isReadonly={true} />,
    []
  )
  const renderLeaf = useCallback(
    (props) => <Leaf {...props} isReadonly={true} />,
    []
  )

  const isMobile = useIsMobile()

  return (
    //https://github.com/ianstormtaylor/slate/issues/4612#issuecomment-1348310378
    <Slate editor={editor} initialValue={value} key={JSON.stringify(value)}>
      <Editable
        style={{
          fontSize: isMobile ? '0.875rem' : '1rem',
        }}
        readOnly
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
    </Slate>
  )
}
