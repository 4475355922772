import {
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
} from '@chakra-ui/react'
import { CollabContent } from './CollabContent'
import { useCollabContext } from './useCollabContext'
import { FiUsers } from 'react-icons/fi'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'
import { Header } from '@/components/drawer/Header'

type CollabDrawerProps = {
  isOpen: boolean
  onClose: () => void
  name?: string
}

export const CollabDrawer = ({ isOpen, onClose, name }: CollabDrawerProps) => {
  const { isManage } = useCollabContext()
  const { collection } = useDocumentListContext()
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="bottom" allowPinchZoom>
      <DrawerOverlay />
      <DrawerContent
        height="90vh"
        padding="1.25rem"
        pt="1rem"
        background="background.default"
      >
        <DrawerHeader px="0" pt="0" pb="1rem">
          <HStack w="100%" justify="space-between">
            {isManage ? (
              <Header icon={FiUsers}>MANAGE ACCESS</Header>
            ) : (
              <Header icon={FiUsers}>USERS WITH ACCESS</Header>
            )}
            <CloseButton onClick={onClose} />
          </HStack>
        </DrawerHeader>
        <DrawerBody padding="0" w="100%">
          {isManage ? (
            <Text
              fontWeight="bold"
              fontSize="1.125rem"
              mb="1rem"
            >{`Grant access to ${name}`}</Text>
          ) : (
            <>
              <Text fontSize="1.125rem" mb="1rem">
                <b>{`${collection?.name} `}</b>Collection
              </Text>
            </>
          )}
          <CollabContent onClose={onClose} name={name} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
