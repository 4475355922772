import { getUnixTime } from 'date-fns'
import { RowProps, WeekNumber, useDayPicker } from 'react-day-picker'
import { Cell } from './Cell'

export const Row = ({ displayMonth, weekNumber, dates }: RowProps) => {
  const { styles, classNames, showWeekNumber, components } = useDayPicker()

  const WeeknumberComponent = components?.WeekNumber ?? WeekNumber

  let weekNumberCell
  if (showWeekNumber) {
    weekNumberCell = (
      <td className={classNames.cell} style={styles.cell}>
        <WeeknumberComponent number={weekNumber} dates={dates} />
      </td>
    )
  }

  return (
    <tr className={classNames.row} style={styles.row}>
      {weekNumberCell}
      {dates.map((date) => (
        <Cell
          className={classNames.cell}
          style={styles.cell}
          key={getUnixTime(date)}
          displayMonth={displayMonth}
          date={date}
        />
      ))}
    </tr>
  )
}
