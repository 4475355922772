import { ListItem, StyleProps, UnorderedList } from '@chakra-ui/react'

const convertSummaryToPoints = (text: string): string[] => {
  const points = text
    .split(' . ') // split text into sentences based on period, question mark, or exclamation point
    .filter((point) => point.length > 0)
    .map((sentence) => sentence.trim())

  // last sentence may end with space and period e.g. (' .')
  const lastPoint = points[points.length - 1]
  if (lastPoint.endsWith('.')) {
    points[points.length - 1] = lastPoint.slice(0, lastPoint.length - 1).trim()
  }
  return points
}

type SummaryPointsListProps = {
  summary: string
  hoverStyles?: StyleProps
  textStyles?: StyleProps
}

export const SummaryPointsList = ({
  summary,
  hoverStyles,
  textStyles,
}: SummaryPointsListProps) => {
  return (
    <UnorderedList
      _hover={hoverStyles}
      onClick={(e) => {
        // doesn't do anything for mobile
        e.stopPropagation()
      }}
    >
      {convertSummaryToPoints(summary).map((point) => {
        return (
          <ListItem key={point} {...textStyles}>
            {point}
          </ListItem>
        )
      })}
    </UnorderedList>
  )
}
