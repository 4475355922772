import { VStack, Button, ButtonProps } from '@chakra-ui/react'
import { BiArrowBack } from 'react-icons/bi'
import { useFilterTagContext } from './useFilterTagContext'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useMemo } from 'react'
import { AppNavPaths } from '@/utils/paths'
import { searchFilterParam } from '@/constants/search-params'
import { formatDateForURLParams } from '@/utils/date'

type FilterTagActionsProps = {
  isShowCategorySelection: boolean

  totalResults: number
  isResultLoading: boolean

  onReturnToCategories: () => void
  onCloseDrawer: () => void
}

const StyledButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      variant="ghost"
      color="gray.50"
      _hover={{ bg: 'transparent' }}
      width="100%"
      {...props}
    >
      {children}
    </Button>
  )
}

export const FilterTagActions = ({
  isShowCategorySelection,

  totalResults,
  isResultLoading,

  onCloseDrawer,
  onReturnToCategories,
}: FilterTagActionsProps) => {
  const {
    selectedDate,
    selectedCategory,
    selectedTags,

    removeTagInCategory,
  } = useFilterTagContext()
  const {
    tags: tagParamKey,
    date: { startDate, endDate },
  } = searchFilterParam
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const hasFilters = useMemo(
    () => selectedTags.length > 0 || !!selectedDate,
    [selectedDate, selectedTags]
  )

  return (
    <VStack width="100%">
      {isShowCategorySelection ? (
        <>
          <Button
            onClick={() => {
              searchParams.delete(tagParamKey)
              selectedTags.forEach(({ displayName, category }) => {
                searchParams.append(tagParamKey, `${displayName},${category}`)
              })

              if (selectedDate && selectedDate.from) {
                searchParams.set(
                  startDate,
                  formatDateForURLParams(selectedDate.from)
                )
                searchParams.set(
                  endDate,
                  formatDateForURLParams(selectedDate.from)
                )
              }
              if (selectedDate && selectedDate.to) {
                searchParams.set(
                  endDate,
                  formatDateForURLParams(selectedDate.to)
                )
              }
              setSearchParams(searchParams)

              if (AppNavPaths.Home === location.pathname) {
                navigate({
                  pathname: `${AppNavPaths.Documents}`,
                  search: createSearchParams(searchParams).toString(),
                })
              }
              onCloseDrawer()
            }}
            isDisabled={!hasFilters}
            borderRadius="1.25rem"
            background={hasFilters ? 'orange.500' : 'transparent'}
            _hover={{ bg: 'orange.500' }}
            variant="solid"
            color="gray.50"
            width="100%"
          >
            Apply filters{' '}
            {!isResultLoading && hasFilters ? `(${totalResults})` : ''}
          </Button>
          <StyledButton
            onClick={() => {
              searchParams.delete(tagParamKey)
              searchParams.delete(startDate)
              searchParams.delete(endDate)
              setSearchParams(searchParams)
              onCloseDrawer()
            }}
            isDisabled={!hasFilters}
          >
            Clear all
          </StyledButton>
        </>
      ) : (
        <>
          <StyledButton
            onClick={onReturnToCategories}
            color="orange.500"
            background="transparent"
            leftIcon={<BiArrowBack size="1.5rem" />}
          >
            Return to categories
          </StyledButton>
          {selectedCategory !== '' && (
            <StyledButton
              onClick={() => {
                removeTagInCategory(selectedCategory)
              }}
            >
              Clear selection
            </StyledButton>
          )}
        </>
      )}
    </VStack>
  )
}
