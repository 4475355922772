import { PRESSUM_TAG_DEFAULT_NAME, SORT_OPTIONS } from '~shared/constants'
import { lowercaseAndRemoveWhitespace } from '~shared/utils'

export enum DocumentTagChangeAction {
  Add = 'add',
  Remove = 'remove',
}

export const getTagNameFromSearchParams = (tagSearchParams: string[]) => {
  return tagSearchParams
    .map((tag) => {
      const [name] = tag.split(',')
      return name
    })
    .filter((name) => name !== undefined)
}

export const getTagNameAndCategoryFromSearchParams = (
  tagSearchParams: string[]
) => {
  return tagSearchParams
    .map((tag) => {
      const [name, category] = tag.split(',')
      return { displayName: name, category }
    })
    .filter((object) => object !== undefined)
}

export const addTagToSearchParams = ({
  searchParams,
  category,
  setSearchParams,
  displayName,
}: {
  searchParams: URLSearchParams
  setSearchParams: (searchParams: URLSearchParams) => void
  category: string
  displayName: string
}) => {
  const tags = getTagNameFromSearchParams(searchParams.getAll('tags'))
  const tagSet = new Set(tags)
  if (!tagSet.has(displayName)) {
    searchParams.append('tags', [displayName, category].join(','))
    searchParams.set('sort', SORT_OPTIONS.Relevance)
    setSearchParams(searchParams)
  }
}

export const isValidTagName = (name: string) => {
  return !name.includes(':')
}

export const isTagNamePressum = (name: string) => {
  return lowercaseAndRemoveWhitespace(name).startsWith(
    PRESSUM_TAG_DEFAULT_NAME.toLowerCase()
  )
}
