import { searchFilterParam } from '@/constants/search-params'
import { useSetSortSearchParams } from '@/hooks/params/useSearchParams'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SORT_OPTIONS } from '~shared/constants'
import { SelectDropdown, Option } from './SelectDropdown'

export const SortByOptions = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { setSortSearchparams } = useSetSortSearchParams({
    searchParams,
  })

  const { query, sort } = searchFilterParam

  const options = useMemo(() => {
    return Object.keys(SORT_OPTIONS)
      .map((key) => {
        return {
          value: SORT_OPTIONS[key],
          label: key,
        }
      })
      .filter((option) => {
        return !searchParams.get(query)
          ? option.value !== SORT_OPTIONS.Relevance
          : true
      })
  }, [query, searchParams])

  const [selected, setSelected] = useState<Option>()

  // happens on searchparams change and initialisation
  useEffect(() => {
    // Set default sort option
    if (!searchParams.get(sort)) {
      setSortSearchparams()
      setSearchParams(searchParams)
    } else {
      const isExistingOption = options.find(
        (option) => option.value === searchParams.get(sort)
      )
      if (isExistingOption) {
        setSelected(isExistingOption)
      } else {
        setSortSearchparams()
        setSearchParams(searchParams)
      }
    }
  }, [
    searchParams,
    setSearchParams,
    setSortSearchparams,
    sort,
    options,
    setSelected,
  ])

  const handleChange = (selectedOption: Option) => {
    searchParams.set(sort, selectedOption.value ?? '')
    setSearchParams(searchParams)
    setSelected(selectedOption)
  }

  return (
    <SelectDropdown
      options={options}
      handleChange={handleChange}
      selected={selected}
    />
  )
}
