import { VStack, Text, Button } from '@chakra-ui/react'
import {
  BiCalendarEvent,
  BiMapPin,
  BiNews,
  BiPaperPlane,
  BiQuestionMark,
} from 'react-icons/bi'
import { COUNTRY_REGION_TEXT_LABEL } from '@/constants/title'
import { CategoryType } from '~shared/constants'

const CATEGORY_TO_ICON = {
  [CategoryType.Source]: <BiNews />,
  [CategoryType.Country]: <BiMapPin />,
  [CategoryType.Asset]: <BiPaperPlane />,
  [CategoryType.Event]: <BiCalendarEvent />,
  [CategoryType.Miscellaneous]: <BiQuestionMark />,
}

type TagCategorySelectionProps = {
  addTagToDocument: ({ displayName, category }) => Promise<void>
  query: string
  onCloseCleanup: () => void
}

export const TagCategorySelection = ({
  query,
  addTagToDocument,
  onCloseCleanup,
}: TagCategorySelectionProps) => {
  return (
    <VStack align="left" width="100%">
      <Text fontSize="1.125rem" fontWeight={700} color="gray.800">
        Select category for "
        <Text as="span" color="orange.500" fontWeight={700} fontSize="1.125rem">
          {query}
        </Text>
        "
      </Text>
      <VStack width="100%" spacing="1rem">
        {Object.keys(CATEGORY_TO_ICON).map((category) => {
          return (
            <Button
              paddingLeft="0.75rem"
              height="fit-content"
              width="100%"
              justifyContent="flex-start"
              textAlign="left"
              variant="ghost"
              _hover={{ bg: 'transparent' }}
              leftIcon={CATEGORY_TO_ICON[category]}
              key={category}
              onClick={() => {
                addTagToDocument({
                  displayName: query,
                  category: category,
                })

                onCloseCleanup()
              }}
            >
              {category === CategoryType.Country
                ? COUNTRY_REGION_TEXT_LABEL
                : category}
            </Button>
          )
        })}
      </VStack>
    </VStack>
  )
}
