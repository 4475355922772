import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { CreateCollectionForm } from './CreateCollectionForm'
import { CREATE_NEW_COLLECTION_TEXT_HEADER } from '@/constants/title'

type CreateCollectionModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const CreateCollectionModal = ({
  isOpen,
  onClose,
}: CreateCollectionModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent padding="24px" background="background.default">
        <ModalHeader px="0px" pt="0px" pb="16px">
          {CREATE_NEW_COLLECTION_TEXT_HEADER}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="0px" w="100%">
          <CreateCollectionForm onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
