import { isEmail } from 'class-validator';

export const isEmailOfApprovedDomain = ({
  value,
  domains,
}: {
  value: string;
  domains: string[];
}) => {
  if (typeof value !== 'string' || !isEmail(value)) {
    return false;
  }

  for (const domain of domains) {
    if (value.endsWith(`@${domain}`)) {
      return true;
    }
  }

  return false;
};
