import { queryKeys } from '@/constants/query-key'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { sendLoginOtp, verifyLoginOtp, logout } from '@/api/auth'

export const useSendLoginOtp = () => {
  const { mutateAsync, isLoading: isSendLoginOtpLoading } = useMutation({
    mutationFn: sendLoginOtp,
  })
  return { sendLoginOtp: mutateAsync, isSendLoginOtpLoading }
}

export const useVerifyOtp = () => {
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading: isVerifyOtpLoading } = useMutation({
    mutationFn: verifyLoginOtp,
    onSuccess: async () => {
      await queryClient.refetchQueries(queryKeys.whoami())
    },
    onError: () => undefined,
  })
  return { verifyLoginOtp: mutateAsync, isVerifyOtpLoading }
}

export const useUserLogout = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: userLogout, isLoading: isUserLogoutLoading } =
    useMutation({
      mutationFn: logout,
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.whoami())
      },
    })
  return { userLogout, isUserLogoutLoading }
}
