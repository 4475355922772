import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'

type DocumentCardButtonProps = {
  icon: React.ReactElement
  onClick: (e) => void
  label: string
} & Partial<IconButtonProps>

export const DocumentCardButton = ({
  icon,
  label,
  onClick,
  ...props
}: DocumentCardButtonProps) => {
  return (
    <Tooltip label={label}>
      <IconButton
        borderRadius="50%"
        bg="parent"
        aria-label={label}
        icon={icon}
        onClick={(e) => {
          e.stopPropagation()
          onClick(e)
        }}
        {...props}
      />
    </Tooltip>
  )
}
