import { PERMISSIONS } from '../constants';

export const checkIsAuthorised = ({
  userPermission,
}: {
  userPermission: PERMISSIONS;
}) => {
  // only manage permissions can adjust permissions
  if (userPermission !== PERMISSIONS.MANAGE) return false;

  return true;
};
