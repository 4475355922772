import {
  Input,
  Button,
  Stack,
  VStack,
  HStack,
  Text,
  Icon,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useVerifyOtp } from '@/hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { HttpStatusCode, isAxiosError } from 'axios'
import { useAuthContext } from '../useAuthContext'
import { AppNavPaths } from '@/utils/paths'
import { ResendOtpButton } from './ResendOtpButton'
import { ErrorMessageWithIcon } from '@/components/error/ErrorMessageWithIcon'
import { MdInfo } from 'react-icons/md'

const schema = Yup.object().shape({
  otp: Yup.string()
    .trim()
    .required('OTP is required.')
    .length(6, 'Please enter a 6 character OTP'),
})

type WarningAndErrorTextProps = {
  isError: boolean
  email: string
}

const WarningAndErrorText = ({ isError, email }: WarningAndErrorTextProps) => {
  const isShowDefenceMailMessage = email.endsWith('@defence.gov.sg')
  return isError ? (
    <ErrorMessageWithIcon fieldName="otp" />
  ) : (
    isShowDefenceMailMessage && (
      <HStack>
        <Icon
          as={MdInfo}
          color="orange.500"
          w="1rem"
          h="1rem"
          mt="0.15rem"
          alignSelf="start"
        />
        <Text w="90%" color="orange.500" fontSize="0.875rem">
          Defence mail users may experience delays receiving the OTP email.
          Please wait.
        </Text>
      </HStack>
    )
  )
}

export const OtpForm = () => {
  const { verifyLoginOtp, isVerifyOtpLoading } = useVerifyOtp()
  const { email } = useAuthContext()
  const navigate = useNavigate()

  return (
    <Formik
      initialValues={{ otp: '' }}
      onSubmit={async ({ otp }, { setSubmitting, setFieldError }) => {
        await verifyLoginOtp(
          { token: otp, email },
          {
            onSuccess: () => {
              navigate(AppNavPaths.Home)
              window.location.reload()
            },
            onError: (error) => {
              if (isAxiosError(error)) {
                const code = error.response?.status
                const message = error.response?.data.message
                if (code === HttpStatusCode.Unauthorized) {
                  setFieldError('otp', message ?? 'Invalid OTP.')
                  return
                }
              }
              setFieldError(
                'otp',
                'There is an error, please contact the team.'
              )
            },
          }
        )
        setSubmitting(false)
      }}
      validationSchema={schema}
    >
      <Form
        style={{
          width: '100%',
        }}
      >
        <Field name="otp">
          {({ field, form }) => (
            <>
              <Stack
                direction={{ base: 'column', lg: 'row' }}
                spacing={{ lg: '0.5rem', base: '2.75rem' }}
              >
                <VStack w="100%" align="left" mb="1rem" flex={1}>
                  <Input
                    name="otp"
                    borderRadius="20px"
                    value={field.value}
                    onChange={field.onChange}
                    isDisabled={isVerifyOtpLoading}
                  />
                  <WarningAndErrorText
                    isError={!!form.errors.otp}
                    email={email}
                  />
                </VStack>
                <Button
                  _hover={{
                    bg: 'brand.primary.400',
                    color: 'brand.primary.100',
                  }}
                  borderRadius="20px"
                  fontWeight="600"
                  bg="brand.primary.500"
                  color="gray.50"
                  type="submit"
                  isLoading={isVerifyOtpLoading}
                >
                  Sign in
                </Button>
              </Stack>
              <ResendOtpButton />
            </>
          )}
        </Field>
      </Form>
    </Formik>
  )
}
