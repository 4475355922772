import { Text, Image, Button, useDisclosure } from '@chakra-ui/react'
import NoCollectionSVG from '@/assets/placeholders/no-collection.svg'
import { CreateCollectionModal } from './create/CreateCollectionModal'
import { useIsMobile } from '@/hooks'
import { MobileCreateCollectionDrawer } from './create/CreateCollectionDrawer'
import { PlaceholderLayout } from '@/components/PlaceholderLayout'

export const NoCollectionPlaceholder = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useIsMobile()
  return (
    <PlaceholderLayout
      image={<Image src={NoCollectionSVG} />}
      text={
        <Text marginTop="1.5rem">
          Nothing to display here. Why don't you start by creating a collection?
        </Text>
      }
    >
      <Button
        marginTop="1rem"
        onClick={onOpen}
        width={{ base: '100%', lg: 'auto' }}
        borderRadius="1.25rem"
        fontWeight="600"
        bg="brand.primary.500"
        color="brand.primary.50"
      >
        Make a collection
      </Button>
      {isMobile ? (
        <MobileCreateCollectionDrawer isOpen={isOpen} onClose={onClose} />
      ) : (
        <CreateCollectionModal isOpen={isOpen} onClose={onClose} />
      )}
    </PlaceholderLayout>
  )
}
