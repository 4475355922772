import { HStack, VStack } from '@chakra-ui/react'
import { FormFieldInput } from '.'
import { TimeZoneField } from './TimeZoneField'
import { ContentFields } from './ContentFields'
import { DateField } from './DateField'
import { TimeField } from './TimeField'
import { useIsMobile } from '@/hooks'
import { CollectionField } from './CollectionField'
import { TitleFields } from './TitleFields'

type UploadFieldsProps = {
  url?: string
}

export const UploadFields = ({ url }: UploadFieldsProps) => {
  const isMobile = useIsMobile()
  return (
    <VStack
      paddingTop={{ base: '1rem', lg: '1.5rem' }}
      spacing={{ base: '1rem', lg: '1.5rem' }}
      align="left"
      justify="left"
      width="100%"
    >
      <CollectionField />
      <FormFieldInput
        isOptional={!url}
        title={'Source URL'}
        name="url"
        styles={{
          whiteSpace: 'pre-wrap',
          color: 'black',
          isTruncated: true,
        }}
      />
      <TitleFields />
      {isMobile ? (
        <>
          <TimeZoneField />
          <HStack justifyContent="space-between" align="top">
            <DateField />
            <TimeField />
          </HStack>
        </>
      ) : (
        <HStack align="top">
          <TimeZoneField />
          <DateField />
          <TimeField />
        </HStack>
      )}
      <ContentFields />
    </VStack>
  )
}
