export const getDeleteUserCollectionHeader = ({
  isUser,
  email,
}: {
  isUser: boolean
  email: string
}) => {
  return isUser ? 'Remove your access' : `Remove access for ${email}`
}

export const getDeleteUserCollectionContent = ({
  isUser,
  email,
}: {
  isUser: boolean
  email: string
}) => {
  return isUser
    ? 'Are you sure you want to remove yourself? Your access to this collection will be permanently revoked.'
    : `Are you sure you want to remove ${email}. His access to this collection will be permanently revoked.`
}
