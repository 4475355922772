import { useUploadDocumentContext } from '../useUploadDocumentContext'
import { FormFieldInput } from './FormFieldInput'

export const TitleFields = () => {
  const { getDisplayProp } = useUploadDocumentContext()

  return (
    <>
      <FormFieldInput
        title={'Title/Headline'}
        name={'title'}
        styles={{
          lineHeight: { base: '1.25rem', lg: '2.5rem' },
          fontSize: { base: '1rem', lg: '2.25rem' },
          fontWeight: 'bold',
          whiteSpace: 'pre-wrap',
          color: 'black',
          display: getDisplayProp('title'),
          // using display to handle rendering so that the component is not unmounted
        }}
      />
      <FormFieldInput
        title={'Title/Headline'}
        name={'vernacular.title'}
        styles={{
          lineHeight: { base: '1.25rem', lg: '2.5rem' },
          fontSize: { base: '1rem', lg: '2.25rem' },
          fontWeight: 'bold',
          whiteSpace: 'pre-wrap',
          color: 'black',
          display: getDisplayProp('vernacular.title'),
          // using display to handle rendering so that the component is not unmounted
        }}
      />
    </>
  )
}
