import {
  detectAndGetLanguages,
  translateTitleAndContent,
} from '@/api/translate'
import { queryKeys } from '@/constants/query-key'
import { useMutation, useQuery } from '@tanstack/react-query'
import { TranslateReq } from '~shared/dtos'

export const useDetectAndGetLanguages = (content: string) => {
  const {
    isLoading: isDetectAndGetLanguagesLoading,
    data,
    isError: isDetectAndGetLanguagesError,
  } = useQuery({
    queryKey: queryKeys.translateLanguages(content),
    queryFn: () => detectAndGetLanguages(content),
    staleTime: 1000 * 60 * 1, // added stale time to prevent refetch upon each trigger of the translate button.
    refetchOnWindowFocus: false,
  })
  return {
    isDetectAndGetLanguagesLoading,
    data,
    isDetectAndGetLanguagesError,
  }
}

// using mutate here even though there's no CUD operations as:
// 1. translate operation is performed on click
// 2. optimising with enabled hook complicates the query and mutate fits espescially well in this use-case
export const useTranslate = () => {
  const {
    isLoading: isTranslateLoading,
    mutateAsync: translate,
    isError: isTranslateError,
  } = useMutation({
    mutationFn: async ({ title, content, sourceLanguage }: TranslateReq) => {
      //if no souce language specified, return
      if (!sourceLanguage) {
        return
      }
      // If both title and content are empty, return
      if (!title && !content) {
        return
      }
      const data = await translateTitleAndContent({
        title,
        content,
        sourceLanguage,
      })
      return data
    },
  })
  return {
    isTranslateError,
    translate,
    isTranslateLoading,
  }
}
