import { FastField, useFormikContext } from 'formik'
import { Text, VStack } from '@chakra-ui/react'
import { CollectionSelectDropdown } from './CollectionSelectDropdown'
import { ErrorMessageWithIcon } from '@/components/error/ErrorMessageWithIcon'
import { OpensourceUploadForm } from '@/types/document'
import { useMemo } from 'react'

export const CollectionField = () => {
  const name = 'collection'
  const { errors } = useFormikContext<OpensourceUploadForm>()
  const collectionError = errors.collection
  const collectionValueError = errors.collection?.value
  const collectionLabelError = errors.collection?.label
  const collectionIsNewError = errors.collection?.isNew
  const errorFieldName = useMemo(() => {
    if (collectionError) {
      return 'collection'
    }
    if (collectionValueError) {
      return 'collection.value'
    }
    if (collectionLabelError) {
      return 'collection.label'
    }
    if (collectionIsNewError) {
      return 'collection.isNew'
    }
  }, [
    collectionError,
    collectionValueError,
    collectionLabelError,
    collectionIsNewError,
  ])
  return (
    <VStack
      align="left"
      spacing="0.5rem"
      width={{
        lg: '55%',
        base: '100%',
      }}
      fontSize={{ lg: '1rem', base: '0.875rem' }}
    >
      <Text
        color="gray.600"
        fontWeight="semibold"
        w="auto"
        paddingLeft={{ base: '0rem', lg: '0.5rem' }}
      >
        Select upload collection
      </Text>
      <FastField name={name} component={CollectionSelectDropdown} />
      <Text
        color="gray.600"
        fontSize={{ lg: '0.875rem', base: '0.75rem' }}
        fontStyle="italic"
      >
        Only collections that you have editing rights to will be shown.
      </Text>
      {errorFieldName && <ErrorMessageWithIcon fieldName={errorFieldName} />}
    </VStack>
  )
}
