import { HStack, Icon, Text } from '@chakra-ui/react'
import { MdInfo } from 'react-icons/md'

export const ExtractedFromGoogleCache = () => {
  return (
    <HStack align="start" padding={0}>
      <Icon
        w="1rem"
        h="1rem"
        color="orange.500"
        marginTop="0.25rem" // to align with the text
        as={MdInfo}
      />

      <Text color="orange.500" fontSize="0.875rem" padding={0} margin={0}>
        Due to issues extracting from the news site, the contents below were
        extracted from Google Cache and may not be up-to-date.
      </Text>
    </HStack>
  )
}
