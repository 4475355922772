import { Flex } from '@chakra-ui/react'
import { Button as DayPickerButton, DayRender } from 'react-day-picker'

type ReactDayProps = {
  buttonRef: React.RefObject<HTMLButtonElement>
  dayRender: DayRender
  style: React.CSSProperties
}

export const Day = ({ buttonRef, dayRender, style }: ReactDayProps) => {
  if (dayRender.isHidden) {
    return <></>
  }

  return (
    <Flex
      style={{
        ...style,
        width: '28px',
        height: '28px',
      }}
    >
      <DayPickerButton {...dayRender.buttonProps} ref={buttonRef} />
    </Flex>
  )
}
