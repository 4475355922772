import { useIsMobile } from '@/hooks'
import { HStack, Text, Button, CloseButton } from '@chakra-ui/react'
import { FaCheckCircle, FaArrowRight } from 'react-icons/fa'

type BookmarkToastProps = {
  description: string
  navigationText: string
  onClick: () => void
  onClose: () => void
}

export const BookmarkToast = ({
  description,
  navigationText,
  onClick,
  onClose,
}: BookmarkToastProps) => {
  const isMobile = useIsMobile()
  return (
    <HStack
      w="100%"
      align="center"
      bg="green.100"
      px="1rem"
      py="0.75rem"
      justify="space-between"
    >
      <HStack spacing="0.75rem" fontWeight="400" w="fit-content">
        <FaCheckCircle size="1.5rem" color="green" />
        <Text fontSize="1rem">{description}</Text>
      </HStack>
      {!!navigationText && (
        <Button
          flexShrink={1}
          width="fit-content"
          bg="transparent"
          fontWeight="700"
          textColor="blue.500"
          onClick={onClick}
          rightIcon={isMobile ? <></> : <FaArrowRight color="blue.500" />}
          _hover={{
            bg: 'transparent',
            textDecorationLine: 'underline',
          }}
        >
          {navigationText}
        </Button>
      )}
      <CloseButton onClick={onClose} />
    </HStack>
  )
}
