import { Box, Flex } from '@chakra-ui/react'
import { FiHome } from 'react-icons/fi'
import { FaList } from 'react-icons/fa'
import { MobileNavButton } from './MobileNavButton'
import { AppNavPaths } from '@/utils/paths'
import { useState } from 'react'
import { UploadMenu } from '@/features/upload/components/menu/UploadMenu'
import { zIndices } from '@/theme/z-index'
import { useLocation } from 'react-router-dom'

const Overlay = () => (
  <Box
    position="fixed"
    width="100%"
    height="100%"
    backgroundColor="rgba(0, 0, 0, 0.5)"
    zIndex={zIndices.navbar} // overlay covers all navbar except mobile nav bar
  />
)

export const MobileNavBar = () => {
  const [isUploadSelected, setIsUploadSelected] = useState(false)
  const { pathname } = useLocation()
  const selectedPath = isUploadSelected ? AppNavPaths.Upload : pathname
  return (
    <>
      {isUploadSelected && <Overlay />}
      <Flex
        justifyContent="space-between"
        columnGap="1.5rem"
        borderTopRightRadius="1.5rem"
        width="100%"
        position="sticky"
        bottom={0}
        backgroundColor="gray.300"
        padding="0.875rem 1.5rem 0.875rem 1.5rem"
        zIndex={zIndices.overlay} // We want this to show when the overlay is active
      >
        <MobileNavButton
          isHighlight={selectedPath === AppNavPaths.Home}
          path={AppNavPaths.Home}
          icon={<FiHome width="1.5rem" height="1.5rem" />}
        />
        <MobileNavButton
          isHighlight={selectedPath === AppNavPaths.Documents}
          path={AppNavPaths.Documents}
          icon={<FaList width="1.5rem" height="1.5rem" />}
        />

        <UploadMenu
          autoSelect={false}
          selected={isUploadSelected}
          gutter={22}
          onOpen={() => {
            setIsUploadSelected(true)
          }}
          onClose={() => {
            setIsUploadSelected(false)
          }}
        />
      </Flex>
    </>
  )
}
