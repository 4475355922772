import { AGREEMENT_AGREE_PHRASE, AGREEMENT_HEADER } from '@/constants/auth'
import { PRIVACY_POLICY, TERMS_OF_USE } from '@/constants/footer-links'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  VStack,
  Text,
  Link,
  Divider,
} from '@chakra-ui/react'
import { AgreementActions } from './AgreementActions'
import { PressifyLogo } from '@/components/PressifyLogo'

type AgreementDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

export const AgreementDrawer = ({ isOpen, onClose }: AgreementDrawerProps) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      allowPinchZoom
    >
      <DrawerOverlay background="black" />
      <DrawerContent padding="1rem" h="90vh">
        <DrawerHeader
          fontSize="1.25rem"
          fontWeight="semibold"
          p="0"
          mb="2.25rem"
        >
          <VStack w="100%" spacing="4.5625rem" align="left">
            <PressifyLogo variant="sm-light" isClickable={false} />
            <Text>{AGREEMENT_HEADER}</Text>
          </VStack>
        </DrawerHeader>
        <VStack w="100%" spacing="1.5rem" mb="2.75rem">
          <VStack w="100%" spacing="2.25rem">
            <Text>
              Before you dive in,please agree to our Terms of Use and Privacy
              Policy.
              <br />
              <br />
              {AGREEMENT_AGREE_PHRASE}
            </Text>
            <VStack w="100%" spacing="1.5rem">
              <Link
                color="orange.500"
                aria-label="Terms Of Use Link"
                isExternal
                href={TERMS_OF_USE}
                fontWeight="bold"
              >
                View Terms of Use
              </Link>
              <Link
                color="orange.500"
                aria-label="Privacy Policy Link"
                isExternal
                href={PRIVACY_POLICY}
                fontWeight="bold"
              >
                View Privacy Agreement
              </Link>
            </VStack>
            <Divider borderColor="black" />
          </VStack>
          <AgreementActions onClose={onClose} />
        </VStack>
      </DrawerContent>
    </Drawer>
  )
}
