export const monthNavChevronStyles = {
  nav_button_previous: {
    fontSize: '36px',
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  nav_button_next: {
    fontSize: '36px',
    width: '24px',
    height: '24px',
  },
}

export const headerStyles = {
  caption_label: {
    fontStyle: 'medium-small',
    fontSize: '14px',
    fontWeight: '500',
    background: '#2D3748', // chrome on windows will change colour and border colour when year or month is selected. added this to prevent that
    border: '0', // chrome on windows will change colour and border colour when year or month is selected. added this to prevent that
    color: 'white',
  },
  caption: {
    width: 'inherit',
    paddingBottom: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  caption_dropdowns: {
    justifyContent: 'space-between',
  },
  ...monthNavChevronStyles,
}

export const tableHeaderStyles = {
  head_cell: {
    width: '24px',
    height: '24px',
    fontSize: '12px',
    fontWeight: '500',
    color: '#E2E8F0',
    textTransform: 'none',
  },
}

export const tableBodyStyles = {
  table: {
    width: 'inherit',
    maxWidth: 'inherit',
  },
  months: {
    width: 'inherit',
    fontSize: '14px',
    color: 'white',
  },
  month: {
    width: 'inherit',
    fontSize: '14px',
    color: 'white',
  },
}

export const tableCellStyles = {
  button_reset: {
    border: '0px',
    width: '28px',
    height: '28px',
    alignContent: 'center',
  },
}
