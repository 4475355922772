import { Divider, Stack, VStack } from '@chakra-ui/react'
import { PressifyLogo } from '../PressifyLogo'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'
import { TagLeftSideBarContent } from './TagLeftSideBarContent'
import { SidebarActions } from './SidebarActions'
import { CollectionSidebar } from './CollectionSidebar'

export const LeftSideBar = () => {
  const { isTagsExpanded, isFilterExpanded, setIsFilterExpanded } =
    useDocumentListContext()
  return (
    <VStack
      width="284px"
      height="100%"
      minH="100vh"
      bg="gray.800"
      align="left"
      borderTopRightRadius="50px"
      position="sticky"
      top={0}
      flexShrink={0}
      justifyContent="space-between"
    >
      <VStack spacing={0}>
        <PressifyLogo
          variant="sm-dark"
          styles={{
            alignSelf: 'flex-start',
            pl: '1.5rem',
            pt: '1.5rem',
            pb: '1rem',
          }}
          isClickable
        />
        <Divider borderColor="gray.600" />

        {isFilterExpanded ? (
          <VStack
            h="100%"
            w="100%"
            spacing="1rem"
            justify={isTagsExpanded ? 'space-between' : 'left'}
          >
            <TagLeftSideBarContent
              style={{
                maxH: '100%',
              }}
            />
          </VStack>
        ) : (
          <CollectionSidebar />
        )}
      </VStack>
      <Stack paddingX="1.5rem" paddingBottom="3rem">
        <SidebarActions
          onToggle={() => {
            setIsFilterExpanded((prev: boolean) => !prev)
          }}
          isShowFilter={isFilterExpanded}
        />
      </Stack>
    </VStack>
  )
}
