import { useGetUserIdFromContext } from '@/hooks'
import { useRenameCollection } from '@/hooks/useCollection'
import { useToast } from '@/hooks/useToast'
import { handleSubmit, RenameCollectionSchema } from '@/utils/rename-collection'
import { Button, HStack, Icon, Input, Text, VStack } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { MdError } from 'react-icons/md'

export type RenameCollectionFormProps = {
  collectionName: string
  collectionId: number
  onClose: () => void
}

const commonButtonStyles = {
  fontSize: '1rem',
  fontWeight: 'semibold',
  borderRadius: '1.25rem',
}

export const RenameCollectionForm = ({
  collectionName,
  collectionId,
  onClose,
}: RenameCollectionFormProps) => {
  const initialValues = { collectionName }
  const { userId } = useGetUserIdFromContext()
  const toast = useToast()

  const { renameCollection, isRenameCollectionLoading } = useRenameCollection({
    collectionId,
    userId,
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ({ collectionName }) => {
        // the handler header validation within handleSubmit is not used here as we have validation schema.
        await handleSubmit({
          oldCollectionName: initialValues.collectionName,
          collectionName,
          toast,
          renameCollection,
        })
        onClose()
      }}
      validationSchema={RenameCollectionSchema}
    >
      {(props) => (
        <Form>
          <VStack align="left" w="100%" spacing="1rem">
            <Text fontSize="0.875rem" fontWeight="400">
              {`Enter new collection name below.`}
            </Text>
            <Field name="collectionName">
              {({ field }) => (
                <>
                  <Input
                    name="collectionName"
                    _focus={{
                      border: '1px dashed #ED8936',
                      borderRadius: '1rem',
                    }}
                    border="1px"
                    borderColor="transparent"
                    focusBorderColor="orange.400"
                    w="100%"
                    value={field.value}
                    onChange={({ target: { value } }) => {
                      props.setFieldValue('collectionName', value)
                    }}
                    borderRadius="1.25rem"
                    placeholder={'Type to rename collection'}
                    bg="white"
                  />
                  {props.errors.collectionName && (
                    <HStack textColor="red">
                      <Icon as={MdError} />
                      <Text>{props.errors.collectionName}</Text>
                    </HStack>
                  )}
                </>
              )}
            </Field>
            <HStack justify="end">
              <Button onClick={onClose} {...commonButtonStyles}>
                Cancel
              </Button>
              <Button
                bg={props.values.collectionName ? 'green.100' : 'gray.100'}
                color={props.values.collectionName ? 'green.800' : 'gray.800'}
                _hover={{
                  bg: 'parent',
                  color: 'parent',
                }}
                type="submit"
                isDisabled={
                  !props.values.collectionName || !!props.errors.collectionName
                }
                isLoading={isRenameCollectionLoading}
                {...commonButtonStyles}
              >
                Save changes
              </Button>
            </HStack>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}
