import { queryKeys } from '@/constants/query-key'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { CreateUserCollectionReq, UpdateUserCollectionReq } from '~shared/dtos'
import { useGetUserEmailFromContext } from '.'
import {
  createUserCollection,
  getUserCollections,
  updateUserCollection,
  deleteUserCollection,
} from '@/api/userCollection'

export const useDeleteUserCollection = ({
  collectionId,
  userId,
}: {
  collectionId?: number
  userId: string
}) => {
  const { mutateAsync, isLoading: isDeleteUserCollectionLoading } = useMutation(
    {
      mutationFn: async () => {
        const data = await deleteUserCollection({
          collectionId: collectionId ?? NaN,
          userId,
        })
        return data
      },
    }
  )

  return {
    deleteUserCollection: mutateAsync,
    isDeleteUserCollectionLoading,
  }
}

export const useCreateUserCollection = ({
  collectionId,
  userId,
}: {
  collectionId?: number
  userId: string
}) => {
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading: isCreateUserCollectionLoading } = useMutation(
    {
      mutationFn: async (payload: CreateUserCollectionReq) => {
        const data = await createUserCollection({
          collectionId: collectionId ?? NaN,
          payload,
        })
        return data
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.collections(userId))
        await queryClient.invalidateQueries(
          queryKeys.userCollections(collectionId)
        )
      },
    }
  )

  return {
    createUserCollection: mutateAsync,
    isCreateUserCollectionLoading,
  }
}

export const useUpdateUserCollection = ({
  collectionId,
  userId,
}: {
  userId: string
  collectionId?: number
}) => {
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading: isUpdateUserCollectionLoading } = useMutation(
    {
      mutationFn: async (payload: UpdateUserCollectionReq) => {
        const data = await updateUserCollection({
          payload,
          collectionId: collectionId ?? NaN,
          userId,
        })
        return data
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeys.userCollections(collectionId)
        )
        await queryClient.invalidateQueries(queryKeys.collections(userId))
      },
    }
  )

  return {
    updateUserCollection: mutateAsync,
    isUpdateUserCollectionLoading,
  }
}

export const useGetUserCollections = (collectionId?: number) => {
  const {
    isLoading,
    data: userCollections,
    fetchStatus,
  } = useQuery({
    queryKey: queryKeys.userCollections(collectionId),
    queryFn: async () => {
      const data = await getUserCollections(collectionId)
      return data
    },
    // collectionId is from searchparams and it can be undefined
    enabled: !!collectionId,
  })

  const { email } = useGetUserEmailFromContext()
  if (userCollections) {
    userCollections.sort((a, b) => {
      if (a.email === email) {
        return -1
      }
      if (b.email === email) {
        return 1
      }
      return a.email.localeCompare(b.email)
    })
  }
  //https://github.com/TanStack/query/issues/3975#issuecomment-1244075132
  const isUserCollectionsLoading = isLoading && fetchStatus !== 'idle'
  return { isUserCollectionsLoading, userCollections }
}
