import {
  CreateToastFnReturn,
  useToast as useChakraToast,
  UseToastOptions,
} from '@chakra-ui/react'
import { Toast } from '@/components/toast/Toast'

export const useCustomToast = (): CreateToastFnReturn => {
  const toast = useChakraToast()
  const fn = (options?: UseToastOptions) => {
    return toast({
      render: (props) => {
        return <Toast {...props} />
      },
      ...options,
    })
  }
  fn.update = toast.update
  fn.promise = toast.promise
  fn.closeAll = toast.closeAll
  fn.close = toast.close
  fn.isActive = toast.isActive
  return fn
}
export { useCustomToast as useToast }
