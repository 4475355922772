import { Flex, Text, Link, FlexboxProps, StyleProps } from '@chakra-ui/react'
type HeaderTextWithLinkProps = FlexboxProps &
  StyleProps & {
    linkText?: string
    onClick?: () => void
    headerText: string
  }

export const HeaderTextWithLink = ({
  linkText,
  onClick,
  headerText,
  ...restProps
}: HeaderTextWithLinkProps) => {
  return (
    <Flex justifyContent={'space-between'} {...restProps}>
      <Text fontSize="1rem" fontWeight="bold">
        {headerText}
      </Text>
      {linkText && (
        <Link
          onClick={onClick}
          fontSize="1rem"
          color="orange.500"
          textDecoration="underline"
        >
          {linkText}
        </Link>
      )}
    </Flex>
  )
}
