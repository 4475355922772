import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { DeleteBookmarkActions } from './DeleteBookmarkActions'
import { DeleteBookmarkContent } from './DeleteBookmarkContent'

type DeleteBookmarkModalProp = {
  isOpen: boolean
  onClose: () => void
  documentId: number
}

export const DeleteBookmarkModal = ({
  isOpen,
  onClose,
  documentId,
}: DeleteBookmarkModalProp) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent padding="24px">
        <DeleteBookmarkContent />
        <DeleteBookmarkActions documentId={documentId} onClose={onClose} />
      </ModalContent>
    </Modal>
  )
}
