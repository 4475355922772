import { useMemo, useState } from 'react'
import { DocumentListContext } from './useDocumentListContext'
import {
  useGetTagsInCategory,
  useGetUserIdFromContext,
  useSearchTags,
} from '@/hooks'
import { useGetCollections } from '@/hooks/useCollection'
import { DEFAULT_COLLECTION } from '~shared/constants'
import { useGetCollectionIdFromSearchParams } from '@/hooks/params/useSearchParams'

type DocumentListProviderProps = {
  children: React.ReactNode
}

export const DocumentListProvider = ({
  children,
}: DocumentListProviderProps) => {
  const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false)
  const [expandSidebarIndex, setExpandSidebarIndex] = useState<number>(-1)
  const [currentCategory, setCurrentCategory] = useState<string>('')

  const { userId } = useGetUserIdFromContext()
  const { collections, isCollectionsLoading } = useGetCollections(userId)
  const starredCollectionId =
    useMemo(() => {
      if (collections)
        return collections.find(
          (collection) => collection.name === DEFAULT_COLLECTION
        )?.collectionId
    }, [collections]) ?? NaN

  const { collectionId } = useGetCollectionIdFromSearchParams()

  const collection = useMemo(() => {
    return collectionId && collections
      ? collections.find(
          (collection) => collection.collectionId === +collectionId
        )
      : undefined
  }, [collectionId, collections])

  const isStarredCollection = useMemo(() => {
    return collection?.name === DEFAULT_COLLECTION
  }, [collection])

  const [searchQuery, setSearchQuery] = useState<string>('')

  const getTagsInCategory = useGetTagsInCategory({
    category: currentCategory,
  })

  const searchTags = useSearchTags({
    query: searchQuery,
  })

  const isTagsExpanded = useMemo(() => {
    if (isFilterExpanded) {
      return expandSidebarIndex >= 0
    }
    return false
  }, [expandSidebarIndex, isFilterExpanded])

  const value = useMemo(
    () => ({
      isTagsExpanded,
      expandSidebarIndex,
      setExpandSidebarIndex,
      isFilterExpanded,
      setIsFilterExpanded,
      setCurrentCategory,
      currentCategory,
      setSearchQuery,
      searchQuery,
      getTagsInCategory,
      searchTags,
      collection,
      collections,
      starredCollectionId,
      isCollectionsLoading,
      isStarredCollection,
    }),
    [
      isTagsExpanded,
      expandSidebarIndex,
      isFilterExpanded,
      currentCategory,
      searchQuery,
      getTagsInCategory,
      searchTags,
      collection,
      collections,
      starredCollectionId,
      isCollectionsLoading,
      isStarredCollection,
    ]
  )

  return (
    <DocumentListContext.Provider value={value}>
      {children}
    </DocumentListContext.Provider>
  )
}
