import { DocumentTagWithCategory, GenerateTagsRes } from '~shared/dtos/tag.dto'
import { ApiService } from './ApiService'
import { PageParams, PaginatedRes } from '~shared/dtos/paginated'

export const generateTags = async ({
  source,
  content,
}: {
  source?: string
  content?: string
}) => {
  const { data } = await ApiService.post<GenerateTagsRes>('tag/generate', {
    content,
    source,
  })
  return data
}

export const getTagsInCategory = async ({
  pageParam,
  category,
}: {
  pageParam: PageParams
  category: string
}) => {
  const { data } = await ApiService.get<PaginatedRes<DocumentTagWithCategory>>(
    '/tag/category',
    { params: { ...pageParam, category } }
  )
  return data
}
