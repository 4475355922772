import { SORT_OPTIONS } from '~shared/constants'

export const searchFilterParam = {
  date: {
    startDate: 'startDate',
    endDate: 'endDate',
  },
  sort: 'sort',
  query: 'query',
  tags: 'tags',
  collectionIds: 'collectionIds',
  indices: 'indices',
}

export const DEFAULT_SORT_PARAM = SORT_OPTIONS.Newest

export const isNewlyCreatedDocIndicator = {
  create: 'true',
}

export const uploadFileSearchParams = {
  jobId: 'jobId',
  fileName: 'fileName',
}
