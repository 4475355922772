import { useCallback, useEffect, useMemo, useState } from 'react'
import { UploadDocumentContext } from './useUploadDocumentContext'
import { AwsPaginatedRes, TextractContent } from '~shared/dtos'
import { useTranslate } from '@/hooks/useTranslate'
import { CreateOpensourceArticleReq } from '~shared/dtos'
import { useFormikContext } from 'formik'
import { useDebounce } from 'use-debounce'
import { convertSlateContentToText } from '~shared/utils'
import { useGenerateTags } from '@/hooks'
import { S3_UPLOAD_MAX_FILE_SIZE } from '~shared/constants'
import { InfiniteQueryObserverResult } from '@tanstack/react-query'

type UploadDocumentProviderProps = {
  children: React.ReactNode
  documentContent?: TextractContent[]
  isContentLoading?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => Promise<
    InfiniteQueryObserverResult<AwsPaginatedRes<TextractContent[]>, unknown>
  >
}

export const UploadDocumentProvider = ({
  children,
  documentContent,
  isContentLoading,
  hasNextPage,
  fetchNextPage,
}: UploadDocumentProviderProps) => {
  const {
    values: { slateContent, vernacular },
  } = useFormikContext<CreateOpensourceArticleReq>()

  const [textContent] = useDebounce(
    convertSlateContentToText(slateContent),
    2000
  )

  // to populate with text content once we integrate with triton jpe
  const { generatedTags, isGenerateTagsLoading, isGenerateTagsError } =
    useGenerateTags('')

  const { isTranslateError, isTranslateLoading, translate } = useTranslate()

  const [isVernacular, setIsVernacular] = useState<boolean>(!!vernacular)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [stopFetching, setStopFetching] = useState<boolean>(false)

  const currentSlateContentSize = useMemo(() => {
    const encoder = new TextEncoder()
    if (isVernacular) {
      return encoder.encode(JSON.stringify(vernacular?.slateContent)).buffer
        .byteLength
    } else {
      return encoder.encode(JSON.stringify(slateContent)).buffer.byteLength
    }
  }, [isVernacular, slateContent, vernacular])

  const isFieldTooLarge = useMemo(() => {
    return currentSlateContentSize >= S3_UPLOAD_MAX_FILE_SIZE
  }, [currentSlateContentSize])

  const getDisplayProp = useCallback(
    (fieldName: string) => {
      if (
        fieldName === 'vernacular.title' ||
        fieldName === 'vernacular.slateContent'
      ) {
        return isVernacular ? 'flex' : 'none'
      }
      if (fieldName === 'title' || fieldName === 'slateContent') {
        return isVernacular ? 'none' : 'flex'
      }
    },
    [isVernacular]
  )

  // set state for stopping fetching if field is too large to prevent fetches after adjusting field size
  // i.e when a user deletes content to make the field smaller, fetchNextPage won't run
  useEffect(() => {
    if (isFieldTooLarge) {
      setStopFetching(true)
    }
  }, [isFieldTooLarge])

  // fetch all content for the document
  useEffect(() => {
    if (
      !isFieldTooLarge &&
      !stopFetching && // stop fetching if the field is too large, fetching doesn't wait for content size to compute so our block for fetching next page will be 1 api call slow.
      hasNextPage &&
      fetchNextPage &&
      !isContentLoading
    ) {
      fetchNextPage()
    }
  }, [
    fetchNextPage,
    isContentLoading,
    hasNextPage,
    isFieldTooLarge,
    stopFetching,
  ])

  const value = useMemo(
    () => ({
      generatedTags,
      isGenerateTagsLoading,
      isGenerateTagsError,
      textContent,
      isVernacular,
      setIsVernacular,
      isDirty,
      setIsDirty,
      translate,
      isTranslateError,
      isTranslateLoading,
      currentSlateContentSize,
      documentContent,
      isContentLoading,
      isFieldTooLarge,
      getDisplayProp,
    }),
    [
      documentContent,
      isContentLoading,
      generatedTags,
      isGenerateTagsLoading,
      isGenerateTagsError,
      textContent,
      isVernacular,
      setIsVernacular,
      isDirty,
      setIsDirty,
      translate,
      isTranslateError,
      isTranslateLoading,
      currentSlateContentSize,
      isFieldTooLarge,
      getDisplayProp,
    ]
  )

  return (
    <UploadDocumentContext.Provider value={value}>
      {children}
    </UploadDocumentContext.Provider>
  )
}
