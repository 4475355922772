import { StackProps, Image } from '@chakra-ui/react'
import DarkLogoSvg from '@/assets/pressify-logo-dark.svg'
import LightLogoSvg from '@/assets/pressify-logo-light.svg'
import SmDarkLogoSvg from '@/assets/pressify-logo-dark-sm.svg'
import SmLightLogoSvg from '@/assets/pressify-logo-light-sm.svg'
import { useNavigate } from 'react-router-dom'
import { AppNavPaths } from '@/utils/paths'

type PressifyLogoProp = {
  styles?: StackProps
  variant?: 'regular-light' | 'regular-dark' | 'sm-dark' | 'sm-light'
  isClickable: boolean
}

const variantToSvgMap: Record<
  NonNullable<PressifyLogoProp['variant']>,
  string
> = {
  'regular-light': LightLogoSvg,
  'regular-dark': DarkLogoSvg,
  'sm-dark': SmDarkLogoSvg,
  'sm-light': SmLightLogoSvg,
}

export const PressifyLogo = ({
  styles,
  isClickable,
  variant = 'regular-light',
}: PressifyLogoProp) => {
  const navigate = useNavigate()
  const handleClick = () => {
    if (isClickable) {
      return navigate(AppNavPaths.Home)
    }
  }

  return (
    <Image
      w="fit-content"
      src={variantToSvgMap[variant]}
      maxW="10rem"
      onClick={handleClick}
      _hover={{ cursor: isClickable && 'pointer' }}
      {...styles}
    />
  )
}
