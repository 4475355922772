import { useRef, useState, useEffect, useCallback } from 'react'
import { useDayRender, useDayPicker, DateRange } from 'react-day-picker'
import { Day } from './Day'
import { getUnixTime } from 'date-fns'
import { removeTimeFromDate } from '../utils'

type CellProps = {
  className: string | undefined
  style?: React.CSSProperties
  displayMonth: Date
  date: Date
}
// todo(ben): using set state to remove white borders between cells for now, to find a better way in future
export const Cell = ({ className, style, displayMonth, date }: CellProps) => {
  const [cellStyle, setCellStyle] = useState<React.CSSProperties | undefined>(
    style
  )

  const { selected } = useDayPicker()
  const dateRange = selected as DateRange // DateRange is a type of matcher

  const buttonRef = useRef<HTMLButtonElement>(null)
  const dayRender = useDayRender(date, displayMonth, buttonRef)

  const selectedRightBackground = `linear-gradient(to right, transparent 50%,#4A5568 50%)`
  const selectedLeftBackground = `linear-gradient(to left, transparent 50%,#4A5568 50%)`

  const updateCellBackground = useCallback(
    (background: string | undefined) => {
      if (cellStyle?.background !== background) {
        setCellStyle((prevStyle) => ({
          ...prevStyle,
          background: background,
        }))
      }
    },
    [cellStyle?.background]
  )

  useEffect(() => {
    if (
      dateRange &&
      removeTimeFromDate(dateRange.to) === removeTimeFromDate(dateRange.from) &&
      removeTimeFromDate(dateRange.to) === removeTimeFromDate(date)
    ) {
      updateCellBackground('')
    } else if (dayRender.activeModifiers.range_start) {
      updateCellBackground(selectedRightBackground)
    } else if (dayRender.activeModifiers.range_end) {
      updateCellBackground(selectedLeftBackground)
    } else if (dayRender.activeModifiers.range_middle) {
      updateCellBackground('#4A5568')
    } else {
      updateCellBackground(undefined)
    }
  }, [
    date,
    dateRange,
    dayRender.activeModifiers,
    selectedRightBackground,
    selectedLeftBackground,
    updateCellBackground,
  ])

  return (
    <td
      className={className}
      style={cellStyle}
      key={getUnixTime(date)}
      role="presentation"
    >
      <Day
        buttonRef={buttonRef}
        dayRender={dayRender}
        style={{ background: cellStyle?.background }}
      />
    </td>
  )
}
