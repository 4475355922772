import {
  VStack,
  Text,
  HStack,
  Tooltip,
  Flex,
  Icon,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Center,
} from '@chakra-ui/react'
import { CollabUser } from './CollabUser'
import { overflowScrollBarStyles } from '@/custom-css-prop/overflow-scrollbar-styles'
import { useCollabContext } from './useCollabContext'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { PERMISSIONS_TO_ROLE } from '~shared/constants'
import { IoMdCheckmark } from 'react-icons/io'
import { useIsMobile } from '@/hooks'

const ToolTipText = () => {
  return (
    <TableContainer w="100%">
      <Table variant="simple" w="100%" align="center" justifyItems="center">
        <Thead>
          <Tr alignItems="center">
            <Th
              borderColor="gray.600"
              textAlign="center"
              color="white"
              w="6.25rem"
            >
              ROLE
            </Th>
            <Th
              borderColor="gray.600"
              textAlign="center"
              color="white"
              whiteSpace="normal"
              w="9rem"
            >
              MANAGE USER ACCESS
            </Th>
            <Th
              borderColor="gray.600"
              textAlign="center"
              color="white"
              whiteSpace="normal"
              w="9rem"
            >
              DELETING & RENAMING COLLECTIONS
            </Th>
            <Th
              borderColor="gray.600"
              textAlign="center"
              color="white"
              whiteSpace="normal"
              w="10rem"
            >
              EDIT DOCUMENTS IN COLLECTION
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td textAlign="center" fontWeight="bold" borderColor="gray.600">
              {PERMISSIONS_TO_ROLE['MANAGE']}
            </Td>
            <Td borderColor="gray.600">
              <Center>
                <IoMdCheckmark
                  color="#2D3748"
                  size="1.5rem"
                  style={{
                    padding: '0.2rem',
                    borderRadius: '1rem',
                    backgroundColor: '#DD6B20',
                  }}
                />
              </Center>
            </Td>
            <Td textAlign="center" borderColor="gray.600">
              <Center>
                <IoMdCheckmark
                  color="#2D3748"
                  size="1.5rem"
                  style={{
                    padding: '0.2rem',
                    borderRadius: '1rem',
                    backgroundColor: '#DD6B20',
                  }}
                />
              </Center>
            </Td>
            <Td textAlign="center" borderColor="gray.600">
              <Center>
                <IoMdCheckmark
                  color="#2D3748"
                  size="1.5rem"
                  style={{
                    padding: '0.2rem',
                    borderRadius: '1rem',
                    backgroundColor: '#DD6B20',
                  }}
                />
              </Center>
            </Td>
          </Tr>
          <Tr fontWeight="bold">
            <Td textAlign="center" borderColor="gray.600">
              {PERMISSIONS_TO_ROLE['UPDATE']}
            </Td>
            <Td borderColor="gray.600" textAlign="center">
              —
            </Td>
            <Td borderColor="gray.600" textAlign="center">
              —
            </Td>
            <Td borderColor="gray.600" textAlign="center">
              <Center>
                <IoMdCheckmark
                  color="#2D3748"
                  size="1.5rem"
                  style={{
                    padding: '0.2rem',
                    borderRadius: '1rem',
                    backgroundColor: '#DD6B20',
                  }}
                />
              </Center>
            </Td>
          </Tr>
          <Tr borderColor="gray.600">
            <Td borderColor="gray.600" textAlign="center" fontWeight="bold">
              {PERMISSIONS_TO_ROLE['READ']}
            </Td>
            <Td borderColor="gray.600" textAlign="center">
              —
            </Td>
            <Td borderColor="gray.600" textAlign="center">
              —
            </Td>
            <Td borderColor="gray.600" textAlign="center">
              —
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export const CollabUserList = () => {
  const { collabUsers } = useCollabContext()
  const isMobile = useIsMobile()

  return (
    <VStack spacing="1rem" align="left">
      <HStack>
        <Text fontSize="0.75rem">People with access</Text>
        {!isMobile && (
          <Tooltip
            size={'lg'}
            label={<ToolTipText />}
            placement="bottom-start"
            padding="0.75rem"
            borderRadius="1.5rem"
          >
            {/** need to wrap icon with another component so that tooltip will appear properly */}
            <Flex>
              <Icon
                aria-label="tool-tip for editting"
                as={AiFillQuestionCircle}
              />
            </Flex>
          </Tooltip>
        )}
      </HStack>
      <VStack
        spacing="1rem"
        align="left"
        overflow="auto"
        maxHeight={{ base: '', lg: '18.175rem' }}
        css={overflowScrollBarStyles}
      >
        {collabUsers &&
          collabUsers.map((user) => {
            return (
              <CollabUser
                key={user.userId}
                userId={`${user.userId}`}
                email={user.email}
                permission={user.permission}
                isEnableDelete={user.isEnableDelete}
                isEnableDropdown={user.isEnableDropdown}
              />
            )
          })}
      </VStack>
    </VStack>
  )
}
