import { HStack, VStack, Text } from '@chakra-ui/react'
import { DocumentRes, DocumentTagWithCategory } from '~shared/dtos'
import { LatestArticle } from './ArticleCards/LatestArticle'

type LatestArticleProps = {
  articles: DocumentRes[]
}

export const LatestArticlesList = ({ articles }: LatestArticleProps) => {
  return (
    <VStack align="left" w="100%" h="100%" spacing="1rem">
      <Text fontSize="4xl" fontWeight="semibold">
        Latest Published Articles
      </Text>
      <HStack spacing="1rem" align="left" h="100%" w="100%">
        <LatestArticle
          id={+articles[0].id}
          isLarge
          title={articles[0].title}
          dateTime={articles[0].dateTime}
          tags={articles[0].tags as DocumentTagWithCategory[]}
          slateContent={articles[0].slateContent}
          image={articles[0].image}
        />
        <VStack align="left" w="33%" h="100%">
          {articles &&
            articles
              .slice(1, 3)
              .map(
                ({ id, title, tags, dateTime, slateContent, image }, index) => {
                  return (
                    <LatestArticle
                      key={id}
                      id={+id}
                      title={title}
                      dateTime={dateTime}
                      tags={tags as DocumentTagWithCategory[]}
                      slateContent={slateContent}
                      image={image}
                      isAlignTop={index === 0}
                      // first index no divider
                      isNoDivider={index === 0}
                      isArticleLeft
                      w="100%"
                    />
                  )
                }
              )}
        </VStack>
      </HStack>
      <HStack spacing="1rem" w="100%" h="100%">
        {articles &&
          articles
            .slice(3, 6)
            .map(({ id, title, tags, dateTime, slateContent, image }) => {
              return (
                <LatestArticle
                  key={id}
                  id={+id}
                  title={title}
                  dateTime={dateTime}
                  tags={tags as DocumentTagWithCategory[]}
                  slateContent={slateContent}
                  image={image}
                  isArticleLeft
                  w="33%"
                />
              )
            })}
      </HStack>
    </VStack>
  )
}
