import { VStack } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

type PlaceholderLayoutProps = PropsWithChildren & {
  image: JSX.Element
  text: JSX.Element
}

export const PlaceholderLayout = ({
  children,
  image,
  text,
}: PlaceholderLayoutProps) => {
  return (
    <VStack w="100%" minHeight="calc(100vh - 9rem)">
      {image}
      {text}
      {children}
    </VStack>
  )
}
