import { Flex, Grid, GridItem, Image, Text, Button } from '@chakra-ui/react'
import GetStartedHome from '@/assets/get-started-home.svg'
import { useNavigate } from 'react-router-dom'
import { AppNavPaths } from '@/utils/paths'
import { useIsMobile } from '@/hooks'

const GetStartedImage = () => {
  return (
    <GridItem area="image">
      <Flex w="100%" h="100%">
        <Image src={GetStartedHome} />
      </Flex>
    </GridItem>
  )
}

export const GetStarted = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  return (
    <Grid
      w="100%"
      templateAreas={{
        base: `"text" "image"`,
        lg: `"text image"`,
      }}
      gridTemplateColumns={{
        base: '1fr',
        md: '0.5fr 0.5fr',
      }}
      gap="2rem"
      pt={{ base: '2rem', lg: '5%' }}
      pb={{ base: '2rem', lg: '5%' }}
      px={{ base: '1.5rem', lg: '0' }}
      pl={{ base: '1.5rem', lg: '10%' }}
      bg={'#E9E6E6'}
    >
      <GridItem area="text">
        <Flex h="100%" flexDir="column" gap="2rem" justify="center" w="100%">
          {isMobile && <GetStartedImage />}
          <Text
            fontSize={{ lg: 'xl', base: '1rem' }}
            fontWeight={{ lg: 'normal', base: 'medium' }}
            fontStyle="normal"
            lineHeight="1.75rem"
            color="gray.800"
          >
            Are you and your team spending precious time hunting down scattered
            research sources, slowing down your report generation process? Don't
            worry – you're not alone!
            <br />
            <br />
            Introducing Pressify, an AI-powered platform designed specifically
            to efficiently gather, organise, and reference research sources.
            Pressify helps you to save valuable time hunting down the right
            sources, enabling you to focus more on crafting insightful reports.
          </Text>
          <Button
            w="fit-content"
            _hover={{
              bg: 'brand.primary.400',
              color: 'brand.primary.100',
            }}
            borderRadius="1.25rem"
            fontWeight="bold"
            bg="brand.primary.500"
            color="brand.primary.50"
            type="submit"
            onClick={() => navigate(AppNavPaths.Login)}
          >
            Get Started
          </Button>
        </Flex>
      </GridItem>

      {!isMobile && <GetStartedImage />}
    </Grid>
  )
}
