import { searchFilterParam } from '@/constants/search-params'
import { AppNavPaths, ROOT_DOCUMENT_PATH } from '@/utils/paths'
import { Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import { BiBook } from 'react-icons/bi'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { SORT_OPTIONS } from '~shared/constants'
import { CollectionInSearchResults } from '~shared/dtos'

type CollectionListProps = {
  collections: CollectionInSearchResults[]
}

export const CollectionList = ({ collections }: CollectionListProps) => {
  const { collectionIds: collectionIdsSearchParam, indices: indicesParam } =
    searchFilterParam
  const [searchParams, setSearchParams] = useSearchParams()

  const { pathname } = useLocation()
  const rootRoute = pathname.split('/')[1]
  const navigate = useNavigate()

  const handleOnClickCollection = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    collectionId: number
  ) => {
    if (rootRoute === ROOT_DOCUMENT_PATH) {
      navigate({
        pathname: AppNavPaths.Documents,
        search: createSearchParams({
          sort: SORT_OPTIONS.Newest,
          collectionIds: `${collectionId}`,
        }).toString(),
      })
      return
    }
    e.stopPropagation()
    searchParams.delete(indicesParam)
    searchParams.delete(collectionIdsSearchParam)
    searchParams.set(collectionIdsSearchParam, `${collectionId}`)
    setSearchParams(searchParams)
  }

  return (
    <VStack w="100%" spacing="0.5rem">
      <HStack w="100%" spacing="0.1rem">
        <Icon as={BiBook} />
        <Text fontSize="0.75rem" fontWeight="600">
          COLLECTIONS
        </Text>
      </HStack>
      <Flex w="100%" flexWrap="wrap" gap="0.25rem 0.25rem">
        {collections.map((collection, index) => (
          <HStack
            spacing={0}
            fontSize="0.875rem"
            fontStyle="normal"
            lineHeight="1.25rem"
            fontWeight={500}
            key={index}
            onClick={(e) => {
              handleOnClickCollection(e, collection.collectionId)
            }}
          >
            <Text
              textDecoration="underline"
              _hover={{
                textDecoration: 'none',
                cursor: 'pointer',
              }}
              isTruncated
              color="orange.500"
            >
              {collection.name}
            </Text>
            <Text color="gray.800">
              {index < collections.length - 1 ? ',' : ''}
            </Text>
          </HStack>
        ))}
      </Flex>
    </VStack>
  )
}
