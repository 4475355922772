import {
  Box,
  Center,
  Image,
  ImageProps,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { CategoryType } from '~shared/constants'
import { useState, useEffect } from 'react'
import { useAddTagToSearchParams } from '@/hooks/params/useSearchParams'

type CountryProps = ImageProps & {
  country: string
  svg?: string
  background?: string
}

const Country = ({ country, svg, background, height, width }: CountryProps) => {
  const { src, isLoading, isError } = usePreloadImage(svg)
  const addTagToSearchParams = useAddTagToSearchParams()

  if (isLoading) {
    return (
      <Skeleton>
        <Box width={'13.25rem'} height={'10rem'} borderRadius={'0.625rem'}>
          {' '}
        </Box>
      </Skeleton>
    )
  }
  if (isError) {
    return (
      <Box
        width={'13.25rem'}
        height={'10rem'}
        backgroundColor={background}
        borderRadius={'0.625rem'}
        alignContent={'center'}
      >
        <Center>
          <Text
            textColor="white"
            fontFamily="Inter"
            style={{
              fontSize: '1.25rem',
              fontWeight: '700',
              fontStyle: 'normal',
              lineHeight: '1.75rem',
            }}
          >
            {country}
          </Text>
        </Center>
      </Box>
    )
  }
  return (
    <Image
      height={height}
      width={width}
      src={src}
      alt={country}
      loading="lazy"
      decoding="async"
      transition="all 0.3s ease"
      _hover={{ cursor: 'pointer', opacity: 0.7 }}
      onClick={() =>
        addTagToSearchParams({
          displayName: country,
          category: CategoryType.Country,
        })
      }
    />
  )
}

function usePreloadImage(src?: string) {
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    let isCancelled = false
    if (src) {
      setLoading(true)
      const img = document.createElement('img')
      img.onload = () => {
        if (isCancelled) return
        setLoading(false)
      }
      img.onerror = (err) => {
        if (isCancelled) return
        setLoading(false)

        setError(`failed to load image with error: ${err}`)
      }
      img.src = src
    } else {
      setLoading(false)
    }

    return () => {
      isCancelled = true
    }
  }, [src])

  return { src, isLoading, isError: !!error }
}

export default Country
