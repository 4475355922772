import { useIsMobile } from '@/hooks/useIsMobile'
import { Flex, VStack } from '@chakra-ui/layout'
import { ReactNode } from 'react'
import { MobileNavBar } from './mobile/MobileNavBar'
import { MobileSearchBar } from './mobile/MobileSearchBar'
import { StyleProps } from '@chakra-ui/react'
import { LeftSideBar } from './sidebar/LeftSidebar'

type DocumentListLayoutProps = StyleProps & {
  children: ReactNode
  header?: JSX.Element
  isShowPlaceholder?: boolean
  placeholder?: JSX.Element
}

export const DocumentListLayout = ({
  header,
  children,
  isShowPlaceholder,
  placeholder,
  ...rest
}: DocumentListLayoutProps) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <VStack
      width="100%"
      height="100%"
      align="left"
      justify="space-between"
      spacing="0"
    >
      <MobileSearchBar />
      {isShowPlaceholder ? (
        <VStack
          height="100%"
          width="100%"
          pt="1rem"
          paddingRight="1.5rem"
          paddingLeft="1.5rem"
          pb="1rem"
          backgroundColor="background.default"
        >
          {placeholder}
        </VStack>
      ) : (
        <VStack
          backgroundColor="background.default"
          alignItems="left"
          spacing="1rem"
          w="100%"
          pt="1rem"
          paddingRight="1.5rem"
          paddingLeft="1.5rem"
          pb="1rem"
          {...rest}
        >
          {children}
        </VStack>
      )}

      <MobileNavBar />
    </VStack>
  ) : (
    <Flex w="100%" bg="gray.300" h="100%">
      <LeftSideBar />
      <VStack
        alignItems="left"
        w={`calc(100% - 17.75rem)`} // 17.75rem is the width of the left sidebar
        bg="background.default"
        spacing="36px"
        pb="8rem"
      >
        {header}
        {isShowPlaceholder ? (
          placeholder
        ) : (
          <VStack
            backgroundColor="background.default"
            alignItems="left"
            spacing="1rem"
            w="100%"
            pt="0"
            paddingRight="4.25rem"
            paddingLeft="2.25rem"
            {...rest}
          >
            {children}
          </VStack>
        )}
      </VStack>
    </Flex>
  )
}
