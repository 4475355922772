import { useGetUser } from '@/hooks'
import { AwsRumContext } from './useAwsRumContext'
import { useEffect, useMemo } from 'react'
import { initMonitoring } from '@/services/MonitoringService'

type AwsRumProviderProps = {
  children: React.ReactNode
}

export const AwsRumProvider = ({ children }: AwsRumProviderProps) => {
  const { user } = useGetUser()
  // will only initialise awsRum in non-dev environment
  const awsRum = useMemo(() => {
    const awsRum = initMonitoring()
    return awsRum
  }, [])

  useEffect(() => {
    if (user && awsRum) {
      awsRum.addSessionAttributes({
        user_id: `${user.id}`,
      })
    }
  }, [awsRum, user])

  const value = useMemo(() => ({ awsRum }), [awsRum])

  return (
    <AwsRumContext.Provider value={value}>{children}</AwsRumContext.Provider>
  )
}
