import { HStack, Input, Text, VStack } from '@chakra-ui/react'
import { useState } from 'react'

import { FastField } from 'formik'
import { ErrorMessageWithIcon } from '@/components/error/ErrorMessageWithIcon'

export const DateField = () => {
  const [isFocused, setIsFocused] = useState(false)

  const handleInputChange = (value) => {
    const formattedValue =
      value.length > 5
        ? value.replace(/\/{2,3}/g, '/') // prevent double slashes
        : value.replace(/(\d{2})/g, '$1/').replace(/\/{2,3}/g, '/') // add slashes for every 2 character and prevent double slashes
    return formattedValue.slice(0, 10)
  }

  return (
    <FastField name="date">
      {({ field }) => (
        <VStack
          align="left"
          spacing="0.5rem"
          width={{ base: '100%', lg: '12rem' }}
        >
          <HStack>
            <Text
              color="gray.600"
              fontSize={{ lg: '1rem', base: '0.875rem' }}
              fontWeight="semibold"
              w="auto"
              paddingLeft={{ base: '0rem', lg: '0.5rem' }}
            >
              Publication Date
            </Text>
          </HStack>
          <Input
            onFocus={() => {
              setIsFocused(true)
            }}
            onBlur={() => {
              setIsFocused(false)
            }}
            _hover={{ border: '2px dashed #ED8936', borderRadius: '10px' }}
            focusBorderColor="orange.400"
            placeholder={isFocused ? 'DD/MM/YYYY' : ''}
            border="transparent"
            height="48px"
            width={{ base: '100%', lg: '12rem' }}
            background="white"
            py="8px"
            px="16px"
            name="date"
            value={field.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const { nativeEvent } = event
              // don't format input if user is deleting
              if (
                (nativeEvent as InputEvent).inputType !==
                'deleteContentBackward'
              ) {
                const formattedValue = handleInputChange(
                  event.currentTarget.value
                )
                event.currentTarget.value = formattedValue
              }
              field.onChange(event)
            }}
            borderRadius="12px"
            fontWeight={{ lg: 'medium', base: 'normal' }}
            fontSize={{ lg: '1rem', base: '0.875rem' }}
            whiteSpace="pre-wrap"
          />
          <ErrorMessageWithIcon fieldName="date" />
        </VStack>
      )}
    </FastField>
  )
}
