import { Text, Image, Button } from '@chakra-ui/react'
import NoArticleInCollection from '@/assets/placeholders/no-article-in-collection.svg'
import { useCollabContext } from '../collections/collab/useCollabContext'
import { useNavigate } from 'react-router-dom'
import { AppNavPaths } from '@/utils/paths'
import { PlaceholderLayout } from '@/components/PlaceholderLayout'

export const NoBookmarkPlaceholder = () => {
  const { isEdit } = useCollabContext()
  const navigate = useNavigate()
  return (
    <PlaceholderLayout
      image={
        <Image
          src={NoArticleInCollection}
          width="31.25rem"
          height="21.5825rem"
        />
      }
      text={
        <Text>
          No articles exist in this collection.
          {isEdit && <> Be the first to start the ball rolling.</>}
        </Text>
      }
    >
      {isEdit && (
        <Button
          marginTop="1rem"
          onClick={() => {
            navigate(AppNavPaths.Manual)
          }}
          width={{ base: '100%', lg: 'auto' }}
          borderRadius="1.25rem"
          fontWeight="600"
          bg="brand.primary.500"
          color="brand.primary.50"
        >
          Start Uploading
        </Button>
      )}
    </PlaceholderLayout>
  )
}
