import { Field, Form, Formik } from 'formik'
import { APPROVED_DOMAINS, DEFAULT_PERMISSION } from '~shared/constants'
import { isEmailOfApprovedDomain } from '~shared/validators/isEmailOfApprovedDomain'
import * as yup from 'yup'
import { useCollabContext } from './useCollabContext'
import {
  VStack,
  Text,
  HStack,
  FormControl,
  InputGroup,
  Input,
  Icon,
  Button,
  Divider,
  Center,
  Spinner,
  InputLeftElement,
} from '@chakra-ui/react'
import { MdError } from 'react-icons/md'
import { CollabRoleDropdownField } from './CollabRoleDropdownField'
import { useCreateUserCollection } from '@/hooks/useUserCollection'
import { useGetUserIdFromContext, useIsMobile } from '@/hooks'
import { isAxiosError } from 'axios'
import { CollabUserList } from './CollabUserList'
import { LuUserPlus } from 'react-icons/lu'
import { useGetCollectionIdFromSearchParams } from '@/hooks/params/useSearchParams'
import { useToast } from '@/hooks/useToast'

type CollabModalProps = {
  onClose: () => void
  name?: string
}

export const CollabContent = ({ onClose, name }: CollabModalProps) => {
  const { isUserCollectionsLoading, collabUsers, isManage } = useCollabContext()
  const toast = useToast()
  const isMobile = useIsMobile()

  const collectionId = useGetCollectionIdFromSearchParams().collectionId
  const { userId } = useGetUserIdFromContext()

  const { createUserCollection, isCreateUserCollectionLoading } =
    useCreateUserCollection({ userId, collectionId })

  const emailInputSchema = yup.object({
    email: yup
      .string()
      .trim()
      .test(
        'is-mindef-govsg-email',
        'Please enter a valid MINDEF/SAF email address.',
        (value) => {
          return !!(
            value &&
            isEmailOfApprovedDomain({ value, domains: APPROVED_DOMAINS })
          )
        }
      )
      .test(
        'is-existing-email',
        'This user is an existing collaborator. Edit their role below.',
        (value) => {
          return !collabUsers?.find(
            (data) => data.email.toLowerCase() === value?.trim().toLowerCase()
          )
        }
      ),
    permission: yup.string().trim().required('Please select a role'),
  })

  const handleSubmit = async ({ email, permission }) => {
    if (!collectionId) {
      toast({
        description: `Unable to update access rights. Please try again later.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
    await createUserCollection(
      {
        email: email.trim().toLowerCase(),
        permission,
      },
      {
        onSuccess: () => {
          toast({
            description: `Access Rights to ${name} updated`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        },
        onError: (error) => {
          if (isAxiosError(error)) {
            toast({
              description: `Unable to update access rights. Please try again later.`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
            if (error.response?.status === 409) {
              onClose()
            }
          }
        },
      }
    )
  }

  return (
    <VStack align="left" spacing="1rem">
      {isManage && (
        <Formik
          initialValues={{
            email: '',
            permission: DEFAULT_PERMISSION,
          }}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
          validationSchema={emailInputSchema}
        >
          {(props) => (
            <Form>
              <VStack align="left" w="100%" spacing="1rem">
                {!isMobile && (
                  <Text lineHeight="1.25rem">Add collaborators</Text>
                )}
                <HStack align="start" justify="start">
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.collectionName &&
                          form.touched.collectionName
                        }
                      >
                        <VStack w="100%" align="left">
                          <InputGroup>
                            {/**
                             * i think should just add for desktop input field also?
                             */}
                            <InputLeftElement>
                              <Icon as={LuUserPlus} />
                            </InputLeftElement>
                            <Input
                              border="1px"
                              borderColor="transparent"
                              focusBorderColor="orange.400"
                              w="100%"
                              value={field.value}
                              onChange={({ target: { value } }) => {
                                props.setFieldValue(field.name, value)
                              }}
                              name="collectionName"
                              borderRadius="1.25rem"
                              fontSize={{ lg: '1rem', base: '0.875rem' }}
                              _placeholder={{
                                fontSize: { lg: '1rem', base: '0.875rem' },
                              }}
                              placeholder={'e.g. user@agency.gov.sg'}
                              bg="white"
                            />
                          </InputGroup>
                          <HStack textColor="red" ml="0.5rem">
                            {form.errors.email && (
                              <>
                                <Icon as={MdError} />
                                <Text
                                  fontSize={{ lg: '1rem', base: '0.875rem' }}
                                >
                                  {form.errors.email}
                                </Text>
                              </>
                            )}
                          </HStack>
                        </VStack>
                      </FormControl>
                    )}
                  </Field>
                  {!isMobile && (
                    <CollabRoleDropdownField
                      isLoading={isCreateUserCollectionLoading}
                    />
                  )}
                </HStack>
              </VStack>
              <HStack
                align="left"
                justify="space-between"
                w="100%"
                pt="1rem"
                spacing="0.75rem"
              >
                {isMobile && (
                  <CollabRoleDropdownField
                    isLoading={isCreateUserCollectionLoading}
                  />
                )}
                <Button
                  w={{ base: '100%', lg: 'auto' }}
                  px="1rem"
                  fontSize={{ lg: '1rem', base: '0.875rem' }}
                  fontWeight="semibold"
                  size={{ lg: 'lg', base: '' }}
                  bg={props.values.email ? 'green.100' : 'gray.100'}
                  color={props.values.email ? 'green.800' : 'gray.800'}
                  _hover={{ bg: 'green.400', color: 'orange.50' }}
                  borderRadius="1.25rem"
                  type="submit"
                  isDisabled={!props.values.email || !!props.errors.email}
                  isLoading={isCreateUserCollectionLoading}
                >
                  Add collaborator
                </Button>
              </HStack>
            </Form>
          )}
        </Formik>
      )}
      <Divider
        //100% causes overflow to appear
        w="99%"
        border="1px"
        color="black"
        alignSelf="center"
      />
      {isUserCollectionsLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <CollabUserList />
      )}
    </VStack>
  )
}
