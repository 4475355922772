import {
  Text,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  CloseButton,
  DrawerBody,
} from '@chakra-ui/react'
import { IconType } from 'react-icons/lib'

type DrawerLayoutProps = {
  isOpen: boolean
  onClose: () => void
  HeaderIcon: IconType
  title: string
  children: React.ReactNode
}

export const DrawerLayout = ({
  isOpen,
  onClose,
  HeaderIcon,
  title,
  children,
}: DrawerLayoutProps) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      placement="bottom"
      allowPinchZoom
    >
      <DrawerOverlay />
      <DrawerContent
        px="1.5rem"
        pt="1.25rem"
        pb="0.5rem"
        background="background.default"
      >
        <DrawerHeader px="0" pt="0" pb="1rem">
          <HStack w="100%" justify="space-between">
            <HStack spacing="0.5rem" width="100%">
              <Icon width="1.5rem" height="1.5rem" as={HeaderIcon} />
              <Text fontSize="1rem" fontWeight="bold">
                {title}
              </Text>
            </HStack>
            <CloseButton onClick={onClose} />
          </HStack>
        </DrawerHeader>
        <DrawerBody p="0" w="100%">
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
