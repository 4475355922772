import Select from 'react-select'
import { PERMISSIONS, PERMISSIONS_TO_ROLE } from '~shared/constants'
import { useCollabContext } from './useCollabContext'
import { useIsMobile } from '@/hooks'

type Option = {
  label: string
  value: string
}

type CollabRoleDropdownProps = {
  permission: PERMISSIONS
  onChange: ({
    permission,
    initialPermission,
  }: {
    permission: string
    initialPermission?: string
  }) => void
  isLoading: boolean
  isEnabled: boolean
}
export const CollabRoleDropdown = ({
  permission,
  onChange,
  isLoading,
  isEnabled,
}: CollabRoleDropdownProps) => {
  const { isManage } = useCollabContext()
  const isMobile = useIsMobile()

  const initialPermission = permission as unknown as string

  const options: Array<Option> = []
  Object.keys(PERMISSIONS_TO_ROLE).forEach((key) => {
    if (!isManage && key === PERMISSIONS.MANAGE) return
    options.push({
      label: PERMISSIONS_TO_ROLE[key],
      // passing key as value as value to easily identify category
      value: key,
    })
  })

  const getBackgroundColor = (isFocused: boolean) => {
    if (isFocused) {
      return isMobile ? 'parent' : '#FEEBCB'
    }
    return 'parent'
  }

  const getColor = (isFocused: boolean) => {
    if (isFocused) {
      return isMobile ? '#ED8936' : '#DD6B20'
    }
    return 'parent'
  }

  const getFontWeight = (isFocused: boolean) => {
    if (isFocused) {
      return isMobile ? '400' : '700'
    }
    return '400'
  }

  return (
    <Select
      isDisabled={isLoading || !isEnabled}
      isSearchable={false}
      styles={{
        container: (defaultStyle) => ({
          ...defaultStyle,
          textAlign: 'center',
        }),
        control: (defaultStyle, { isDisabled }) => ({
          ...defaultStyle,
          display: 'flex',
          borderRadius: '1.25rem',
          background: isDisabled ? '#F7FAFC' : '#DD6B20',
          alignContent: 'center',
          px: '0.625rem',
          border: 'none',
          pointerEvents: 'auto',
          width: 'max-content',
          // minWidth: isMobile ? '9.125rem' : '9.125rem',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          '&:hover': {
            background: !isDisabled ? '#ED8936' : 'default',
          },
          ':active': {
            border: 'none',
          },
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        option: (defaultStyles, { isFocused }) => ({
          ...defaultStyles,
          textAlign: 'center',
          fontWeight: getFontWeight(isFocused),
          backgroundColor: getBackgroundColor(isFocused),
          color: getColor(isFocused),
          ':active': {
            ...defaultStyles[':active'],
            backgroundColor: '#FEEBCB',
            color: '#DD6B20',
          },
          cursor: 'pointer',
        }),
        dropdownIndicator: (provided, { isDisabled }) => ({
          ...provided,
          color: isDisabled ? '#A0AEC0' : '#FFFAF0',
          paddingLeft: '8px',
          '&:hover': {
            color: isDisabled ? 'default' : '#FFFAF0',
          },
        }),
        placeholder: (provided) => ({
          ...provided,
          paddingBottom: '0',
          paddingTop: '0',
        }),
        singleValue: (provided, { isDisabled }) => ({
          ...provided,
          color: isDisabled ? '#A0AEC0' : '#FFFAF0',
          fontWeight: '600',
          fontSize: isMobile ? '0.875rem' : '1rem',
        }),
        valueContainer: (provided) => ({
          ...provided,
          paddingRight: '0',
          width: '100%',
        }),
        menu: (provided) => ({
          ...provided,
          minWidth: isMobile ? '7rem' : '9.125rem',
          fontSize: '0.875rem',
          alignContent: 'center',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      menuPortalTarget={document.body}
      onChange={(option) => {
        if (option && option.value !== initialPermission) {
          onChange({
            permission: option.value,
            initialPermission,
          })
        }
      }}
      defaultValue={{
        label: PERMISSIONS_TO_ROLE[initialPermission],
        value: initialPermission as unknown as string,
      }}
      placeholder="e.g. user@agency.gov.sg"
      options={options}
    />
  )
}
