import { Text, VStack } from '@chakra-ui/react'
import { BiBookBookmark } from 'react-icons/bi'
import { AddBookmarkActions } from '../add/AddBookmarkActions'
import { useBookmarkContext } from '../useBookmarkContext'
import {
  BOOKMARK_HEADER,
  BOOKMARK_SAVE_CHANGES_CAPTION,
  BOOKMARK_SAVE_CHANGES_HEADER,
} from '~shared/constants'
import { DrawerLayout } from '@/components/drawer/DrawerLayout'

type SaveChangesDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

export const SaveChangesDrawer = ({
  isOpen,
  onClose,
}: SaveChangesDrawerProps) => {
  const { disclosureProps } = useBookmarkContext()
  return (
    <DrawerLayout
      title={BOOKMARK_HEADER}
      isOpen={isOpen}
      onClose={onClose}
      HeaderIcon={BiBookBookmark}
    >
      <VStack align="left" w="100%">
        <Text fontSize="1.125rem" fontWeight="bold">
          {BOOKMARK_SAVE_CHANGES_HEADER}
        </Text>
        <Text fontSize="0.875rem">{BOOKMARK_SAVE_CHANGES_CAPTION}</Text>
      </VStack>
      <AddBookmarkActions
        onClose={() => {
          onClose()
          disclosureProps.onClose()
        }}
        isSaveChangesAction
      />
    </DrawerLayout>
  )
}
