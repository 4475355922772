import { CollectionRoleIndicator } from '@/features/collections/CollectionRoleIndicator'
import { CollabButton } from '@/features/collections/collab/CollabButton'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'
import { useIsMobile } from '@/hooks'
import {
  Center,
  HStack,
  Spinner,
  VStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { MobileCollectionTitleDropdown } from './mobile/MobileCollectionTitleDropdown'
import { FilterTags } from '@/features/home'
import { MobileFilterButton } from './tag/MobileFilterButton'
import { MobileFilterTagsDrawer } from './tag/MobileFilterTagsDrawer'
import {
  useGetCollectionIdFromSearchParams,
  useGetHasFilters,
} from '@/hooks/params/useSearchParams'
import { searchFilterParam } from '@/constants/search-params'
import { useSearchParams } from 'react-router-dom'
import { DOCUMENT_INDEX_TO_NAME } from '@/constants/document'

export const DocumentListHeader = () => {
  const isMobile = useIsMobile()
  const { isCollectionsLoading } = useDocumentListContext()
  const { collectionId } = useGetCollectionIdFromSearchParams()

  const {
    isOpen: isFilterDrawerOpen,
    onClose: onFilterDrawerClose,
    onOpen: onFilterDrawerOpen,
  } = useDisclosure()
  const hasFilters = useGetHasFilters()

  const isLoading = collectionId && isCollectionsLoading

  return (
    <HStack justifyContent="space-between" w="100%">
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <VStack align="left" w="100%" spacing="1rem">
          <VStack align="left" w="100%" spacing="0">
            {isMobile ? <MobileHeader /> : <DesktopHeader />}
          </VStack>
          {isMobile && (
            <>
              <HStack w="100%">
                <MobileFilterButton
                  onClick={onFilterDrawerOpen}
                  isCollapsed={hasFilters}
                />
                <FilterTags />
              </HStack>
              <MobileFilterTagsDrawer
                isOpen={isFilterDrawerOpen}
                onClose={onFilterDrawerClose}
              />
            </>
          )}
        </VStack>
      )}
    </HStack>
  )
}

const MobileHeader = () => {
  const { collection, isStarredCollection } = useDocumentListContext()
  return (
    <>
      <CollectionRoleIndicator />
      <HStack justifyContent="space-between" w="100%" alignItems="center">
        <MobileCollectionTitleDropdown />
        {!isStarredCollection && collection && (
          <CollabButton name={collection.name} />
        )}
      </HStack>
    </>
  )
}

const DesktopHeader = () => {
  const { collection, isStarredCollection } = useDocumentListContext()
  const {
    query: queryParam,
    collectionIds: collectionIdParam,
    indices,
  } = searchFilterParam
  const [searchParams] = useSearchParams()
  const isSearching =
    !!searchParams.get(queryParam) ||
    searchParams.getAll(collectionIdParam).length > 1 ||
    (searchParams.getAll(collectionIdParam).length === 1 &&
      !!searchParams.get(indices))
  const publication = searchParams.get(indices)
  const hasFilters = useGetHasFilters()

  const getTitle = () => {
    if (collection) {
      return collection.name
    } else if (publication && DOCUMENT_INDEX_TO_NAME[publication]) {
      return DOCUMENT_INDEX_TO_NAME[publication]
    } else {
      return 'All articles'
    }
  }

  const searchTitle = () => {
    if (isSearching && hasFilters) {
      return 'Showing filtered search results...'
    }
    if (isSearching) {
      return 'Showing search results...'
    }
    if (hasFilters) {
      return 'Showing filtered results...'
    }
    return ''
  }

  return (
    <>
      <CollectionRoleIndicator />
      <HStack justifyContent="space-between" w="100%" alignItems="start">
        <Text
          fontSize="2.25rem"
          fontWeight={600}
          lineHeight="2.5rem"
          fontStyle="normal"
        >
          {isSearching || hasFilters ? searchTitle() : getTitle()}
        </Text>

        {!isStarredCollection && collection && (
          <CollabButton name={collection.name} />
        )}
      </HStack>
    </>
  )
}
