import { Heading, Image, VStack } from '@chakra-ui/react'
import { DocumentDateTime } from './DocumentDateTime'
import { ProcessedText } from '@/components/ProcessedText'
import { DocumentRes } from '~shared/dtos'
import { SlateReadOnly } from '@/components/slate-text-editor/SlateReadOnly'
import { DocumentOriginator } from './DocumentOriginator'

type DocumentContentProps = {
  document: DocumentRes
  isTranslateToVernacular: boolean
  isMaxRetriesExceeded: boolean
}

export const DocumentContent = ({
  isMaxRetriesExceeded,
  document,
  isTranslateToVernacular,
}: DocumentContentProps) => {
  return (
    <>
      <VStack spacing="1rem" align="left" w="100%">
        <Heading>
          {isTranslateToVernacular &&
          document.vernacular &&
          document.vernacular.title
            ? // document.vernacular defined as string in RightSideBar, handleVernacularChange
              document.vernacular.title
            : document.title}
        </Heading>

        {document.createdAt && document.originatorUserEmail && (
          <DocumentOriginator
            userEmail={document.originatorUserEmail}
            createdAt={document.createdAt}
          />
        )}

        <DocumentDateTime dateTime={document.dateTime} />
      </VStack>
      {(!isMaxRetriesExceeded || document.summary) && (
        // <VStack align="left" w="100%">
        //   <DocumentSummary
        //     sourceName={document.source}
        //     sourceLink={document.url}
        //     summary={document.summary}
        //   />
        // </VStack>
        <></>
      )}
      {document.image && <Image src={document.image} />}
      {
        // pressums will not have slate content
        document.slateContent ? (
          <>
            {isTranslateToVernacular &&
            document.vernacular &&
            document.vernacular.slateContent ? (
              <SlateReadOnly value={document.vernacular.slateContent} />
            ) : (
              <SlateReadOnly value={document.slateContent} />
            )}
          </>
        ) : (
          <ProcessedText
            style={{
              fontSize: '1rem',
            }}
            content={
              isTranslateToVernacular &&
              document.vernacular &&
              document.vernacular.content
                ? // document.vernacular defined as string in RightSideBar, handleVernacularChange
                  document.vernacular.content
                : document.content
            }
          />
        )
      }
    </>
  )
}
