import { HStack, Icon, Text } from '@chakra-ui/react'
import { IconType } from 'react-icons/lib'

type HeaderProps = {
  icon: IconType
  children: React.ReactNode
}

export const Header = ({ children, icon }: HeaderProps) => {
  return (
    <HStack spacing="0.5rem" width="100%">
      <Icon width="1.5rem" height="1.5rem" as={icon} />
      <Text fontSize="1rem" fontWeight="bold">
        {children}
      </Text>
    </HStack>
  )
}
