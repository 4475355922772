import { Text, VStack } from '@chakra-ui/react'
import { DeleteCollectionActions } from './DeleteCollectionActions'
import { FiTrash2 } from 'react-icons/fi'
import { DrawerLayout } from '@/components/drawer/DrawerLayout'

type DeleteCollectionModalProps = {
  isOpen: boolean
  onClose: () => void
  collectionName: string
  collectionId: number
}

export const DeleteCollectionDrawer = ({
  isOpen,
  onClose,
  collectionName,
  collectionId,
}: DeleteCollectionModalProps) => {
  return (
    <DrawerLayout
      isOpen={isOpen}
      onClose={onClose}
      HeaderIcon={FiTrash2}
      title={`DELETE`}
    >
      <VStack align="left" w="100%">
        <Text fontSize="1.125rem" fontWeight="bold">
          {`Delete collection: '${collectionName}'`}
        </Text>
        <Text fontSize="0.875rem">
          {`Are you sure you want to delete? Deletion will remove this collection for any shared users.`}
        </Text>
      </VStack>
      <DeleteCollectionActions collectionId={collectionId} onClose={onClose} />
    </DrawerLayout>
  )
}
