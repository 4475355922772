import { ErrorPage } from '@/components/error/ErrorPage'
import { VStack } from '@chakra-ui/react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'

export const AppLayout = () => {
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <VStack align="left" w="100%" h="100%" spacing="0px">
        <Outlet />
      </VStack>
    </ErrorBoundary>
  )
}
