import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Button,
  VStack,
  DrawerFooter,
} from '@chakra-ui/react'
import { TagDrawerHeader } from './TagDrawerHeader'
import { useState } from 'react'
import { CreateAndInsertTagReq, DocumentTagWithCategory } from '~shared/dtos'
import { BiArrowBack } from 'react-icons/bi'
import { TagCategorySelection } from './TagCategorySelection'
import { MobileTagSearchList } from './MobileTagSearchList'
import { SpinnerOverlay } from '@/components/SpinnerOverlay'

type AddTagDrawerProps = {
  isOpen: boolean
  onClose: () => void

  // for tracking the tags in the document
  documentTagsState: DocumentTagWithCategory[]
  isCreateAndInsertTagLoading: boolean
  addTagToDocument: ({
    displayName,
    category,
  }: CreateAndInsertTagReq) => Promise<void>
}

export const AddTagDrawer = ({
  isOpen,
  onClose,
  documentTagsState,
  isCreateAndInsertTagLoading,
  addTagToDocument,
}: AddTagDrawerProps) => {
  const [query, setQuery] = useState<string>('')
  const [isShowCategory, setIsShowCategory] = useState<boolean>(false)

  // cleanup the drawer state. Removes query, and closes the tag search list and category selection
  const onCloseCleanup = () => {
    setIsShowCategory(false)
    setQuery('')
    onClose()
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onCloseCleanup}
      placement="bottom"
      allowPinchZoom
      closeOnOverlayClick={!isCreateAndInsertTagLoading}
    >
      {/* use margin bottom for fixing height for category selection */}
      <DrawerContent height={isShowCategory ? 'auto' : '50%'}>
        <DrawerHeader paddingTop="1.25rem">
          <TagDrawerHeader />
          <DrawerCloseButton
            onClick={onCloseCleanup}
            disabled={isCreateAndInsertTagLoading}
          />
        </DrawerHeader>

        <DrawerBody paddingY={0}>
          {isCreateAndInsertTagLoading && <SpinnerOverlay />}
          {isShowCategory ? (
            <TagCategorySelection
              query={query}
              addTagToDocument={addTagToDocument}
              onCloseCleanup={onCloseCleanup}
            />
          ) : (
            <MobileTagSearchList
              addTagToDocument={addTagToDocument}
              query={query}
              setQuery={setQuery}
              setIsShowCategory={setIsShowCategory}
              documentTags={documentTagsState}
            />
          )}
        </DrawerBody>
        <DrawerFooter
          padding={0}
          marginTop={isShowCategory ? '6.438rem' : '0rem'}
        >
          <VStack width="100%">
            {isShowCategory && (
              <Button
                width="100%"
                _hover={{ bg: 'transparent' }}
                color="orange.500"
                background="transparent"
                fontWeight="normal"
                leftIcon={<BiArrowBack size="1.5rem" />}
                onClick={() => setIsShowCategory(false)}
                isDisabled={isCreateAndInsertTagLoading}
              >
                Return to previous page
              </Button>
            )}
            <Button
              width="100%"
              variant="ghost"
              fontWeight="normal"
              onClick={onCloseCleanup}
              isDisabled={isCreateAndInsertTagLoading}
            >
              Cancel
            </Button>
          </VStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
