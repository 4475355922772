import { createContext, useContext } from 'react'
import { UserRes } from '~shared/dtos'

type AuthContextProps = {
  user?: UserRes | null
  logout: () => void
  hasPressumUploadPermission: boolean
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  timer: number
  setTimer: React.Dispatch<React.SetStateAction<number>>
  otpError: string
  setOtpError: React.Dispatch<React.SetStateAction<string>>
}

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
)

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(
      `useAuthContext must be used within a AuthContext component`
    )
  }
  return context
}
