import { HStack, Icon, Heading } from '@chakra-ui/react'
import { AiOutlineUpload } from 'react-icons/ai'
import { IoClose } from 'react-icons/io5'

export const MobileUploadHeader = ({
  title,
  onClose,
}: {
  title: string
  onClose: () => void
}) => {
  return (
    <HStack width="100%" justifyContent="space-between">
      <HStack>
        <Icon as={AiOutlineUpload} width="1.5rem" height="1.5rem" />
        <Heading fontSize="1rem">{title}</Heading>
      </HStack>
      <HStack>
        <Icon as={IoClose} width="1.5rem" height="1.5rem" onClick={onClose} />
      </HStack>
    </HStack>
  )
}
