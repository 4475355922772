//https://gist.github.com/dphrag/4db3b453e02567a0bb52592679554a5b

import { useEffect } from 'react'
import { useFormikContext, FormikErrors } from 'formik'
import { CreateOpensourceArticleReq } from '~shared/dtos'

// Function to get nested error keys such as vernacular.slateContent
const getErrorKeys = ({
  errors,
  parentKey,
  res = [],
}: {
  errors: FormikErrors<CreateOpensourceArticleReq>
  parentKey?: string
  res?: string[]
}) => {
  for (const key in errors) {
    const fullKey = parentKey ? `${parentKey}.${key}` : key
    if (typeof errors[key] === 'object' && errors[key] !== null) {
      getErrorKeys({
        errors: errors[key],
        parentKey: fullKey,
        res,
      })
    } else {
      res.push(fullKey)
    }
  }
  return res
}

export const FocusError = () => {
  const { errors, isSubmitting, isValidating } =
    useFormikContext<CreateOpensourceArticleReq>()

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys: string[] = getErrorKeys({ errors })

      // if vernacular upload is checked, find which tab panel is selected
      const selectedTabPanel: HTMLElement | null = document.querySelector(
        '[role="tabpanel"]:not([hidden])'
      )

      // if selectedTabPanel is English Translation tab, remove vernacular keys to focus on fields in English Translation tab
      if (selectedTabPanel && selectedTabPanel.id.includes('tabpanel-1')) {
        keys = keys.filter((key) => !key.includes('vernacular'))
      }

      if (keys.length > 0) {
        const isCollectionField = keys[0].includes('collection')

        let selector: string
        if (keys[0].includes('slateContent')) {
          selector = '[role="textbox"]' // no name attribute for slateContent
        } else if (isCollectionField) {
          selector = `[id="collection"]`
        } else {
          selector = `[name="${keys[0]}"]`
        }

        let errorElement: HTMLElement | null
        if (selectedTabPanel) {
          errorElement = selectedTabPanel.querySelector(selector) // find element in selected tab panel
        } else {
          errorElement = document.querySelector(selector) // find element in document
        }
        if (errorElement) {
          if (isCollectionField) {
            // HACK: react-select hides the input with the correct name
            // hence we have to find the actual input manually
            // https://github.com/JedWatson/react-select/issues/4416
            const input = errorElement.querySelector('input')
            input?.focus()
          } else {
            errorElement.focus()
          }
        }
      }
    }
  }, [errors, isSubmitting, isValidating])

  return null
}
