import { AllDocumentSearchReq, SortOption } from '~shared/dtos'

export const queryKeys = {
  whoami: () => ['user', 'whoami'],

  document: (documentId: number) => [documentId, 'document'],
  similarDocuments: (documentId: number) => [documentId, 'document', 'similar'],
  parsedDocument: (url?: string) => {
    if (!url) return ['document', 'parse']
    return [url, 'document', 'parse']
  },

  documents: () => ['documents'],

  searchDocuments: () => ['documents', 'search'],
  searchDocumentsInMultipleCollections: () => [
    'documents',
    'search',
    'multiple',
  ],
  searchDocumentsInMultipleCollectionsQuery: (props: AllDocumentSearchReq) => [
    'documents',
    'search',
    'multiple',
    props,
  ],
  searchDocumentsInCollection: (
    collectionIds: string[] //this is a string array to conform to AllDocumentSearchReq format, but it should only be 1 collectionId
  ) => ['documents', 'search', collectionIds],
  searchDocumentsQuery: ({ collectionIds, ...rest }: AllDocumentSearchReq) => [
    'documents',
    'search',
    collectionIds,
    rest,
  ],
  latestPressums: () => ['documents', 'latest-pressums'],
  latestPublishedArticles: () => ['documents', 'latest-published'],

  searchSimilarUrl: (url: string) => ['search', url],

  tags: () => ['tags'],
  generatedTags: (content: string) => ['tags', 'generate', content],
  searchedTags: (query: string) => ['tags', query],
  tagInCategory: (category: string) => ['tags', category],
  tagNotInDocument: ({
    documentId,
    query,
  }: {
    documentId?: number
    query?: string
  }) => [documentId, 'tags', query],
  collection: (collectionId: number) => [
    collectionId,
    'collection',
    'bookmarks',
  ],

  collections: (userId: string) => ['collections', userId],
  bookmarkCollections: (userId: string, documentId: number) => [
    userId,
    documentId,
    'collections',
    'bookmark',
  ],
  searchCollections: () => ['collections', 'search'],
  searchCollectionsQuery: (params: {
    query: string
    sortOptions?: SortOption[]
  }) => ['collections', 'search', params],

  // todo(ben): to refactor and tier this properly with collections
  userCollections: (collectionId?: number) => [
    collectionId,
    'user-collections',
  ],
  userCollection: (userId: string, collectionId: number) => [
    userId,
    collectionId,
    'user-collection',
  ],

  fileUpload: () => ['file'],
  fileUploadToS3: (presignedUrl?: string) => [
    'file',
    'presigned-url',
    presignedUrl,
  ],
  presignedUrl: () => ['file', 'presigned-url'],
  startTextract: (filename?: string) => ['file', 'start', filename],
  getTextract: (filename?: string, jobId?: string) => [
    'file',
    'get',
    filename,
    jobId,
  ],
  jobStatus: (jobId?: string) => ['file', 'get', jobId],

  banner: () => ['banner'],

  translateLanguages: (text: string) => ['translate', 'languages', text],
}
