import singapore from '@/assets/home/singapore.svg'
import malaysia from '@/assets/home/malaysia.svg'
import indonesia from '@/assets/home/indonesia.svg'
import china from '@/assets/home/china.svg'
import unitedStates from '@/assets/home/united-states.svg'
import russia from '@/assets/home/russia.svg'
import ukraine from '@/assets/home/ukraine.svg'
import israel from '@/assets/home/israel.svg'
import palestine from '@/assets/home/palestine.svg'

// Keys from this map are used to filter for the documents
export const COUNTRY_SVG_MAP = {
  Singapore: {
    svg: singapore,
    background: '#3182CE',
  },
  Malaysia: {
    svg: malaysia,
    background: '#805AD5',
  },
  Indonesia: {
    svg: indonesia,
    background: '#319795',
  },
  China: { svg: china, background: '#718096' },
  'United States': { svg: unitedStates, background: '#D53F8C' },
  Russia: { svg: russia, background: '#3182CE' },
  Ukraine: { svg: ukraine, background: '#718096' },
  Israel: { svg: israel, background: '#319795' },
  Palestine: { svg: palestine, background: '#D53F8C' },
}
