import { format } from 'date-fns'
import dayjs from 'dayjs'

import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { DateRange } from 'react-day-picker'

const TIMEZONE = 'Asia/Singapore'
export const FILTER_DATE = 'Date'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(TIMEZONE)

export const toDateString = (dateTime: Date | string): string => {
  return dateTime ? dayjs(dateTime).format('DD MMM YYYY') : ''
}

export const toTimeString = (dateTime: Date | string): string => {
  return dateTime ? dayjs(dateTime).format('HH:mm') : '00:00'
}

export const dateRangeToString = (dateRange: DateRange): string => {
  const { from, to } = dateRange
  const isSameDate = from === to
  return isSameDate
    ? `${toDateString(from ?? '')}`
    : `${toDateString(from ?? '')} - ${toDateString(to ?? '')}`
}

export const formatDateForURLParams = (date: Date) => {
  return format(date, 'yyyy-MM-dd')
}
