import { DOCUMENT_INDEX_TO_NAME } from '@/constants/document'
import { searchFilterParam } from '@/constants/search-params'
import { CollectionRow } from '@/features/collections/CollectionRow'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'
import { useGetAccessiblePublicationsFromContext } from '@/hooks'
import {
  useCheckIsSearchAllArticles,
  useGetCollectionIdFromSearchParams,
  useGetIndexFromSearchParams,
} from '@/hooks/params/useSearchParams'
import { AppNavPaths } from '@/utils/paths'
import {
  Button,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  VStack,
  Text,
  Spacer,
  CloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useGetIsDocumentsPage } from '@/hooks/useGetIsDocumentsPage'
import { isEmpty } from 'lodash'
import { MenuTitleWithDivider } from './MenuTitleWithDivider'
import { DEFAULT_COLLECTION } from '~shared/constants'
import { BiStar } from 'react-icons/bi'
import { COLLECTIONS_TITLE, PUBLICATIONS_TITLE } from '@/constants/title'
import { All_ARTICLES } from '@/constants/title'

const blackCheckboxStyle = {
  '& .chakra-checkbox__control': {
    background: 'black',
    color: 'orange.50',
    borderColor: 'black',
  },
}

const orangeCheckboxStyle = {
  '& .chakra-checkbox__control': {
    background: 'orange.400',
    color: 'orange.50',
    borderColor: 'orange.400',
  },
}

export const SearchMenuComponent = () => {
  const { collections, starredCollectionId } = useDocumentListContext()
  const { publications } = useGetAccessiblePublicationsFromContext()
  const { collectionId, collectionIds } = useGetCollectionIdFromSearchParams()
  const { pathname } = useLocation()
  const { index, indices } = useGetIndexFromSearchParams()
  const { isSearchAllArticles } = useCheckIsSearchAllArticles()
  const isDocumentsPage = useGetIsDocumentsPage()
  const navigate = useNavigate()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const { indices: indicesParams, collectionIds: collectionIdsParams } =
    searchFilterParam

  const [searchParams, setSearchParams] = useSearchParams()

  const [checkedPublications, setCheckedPublications] = useState<boolean[]>([])

  const [checkedCollections, setCheckedCollections] = useState<boolean[]>([])

  const [isStarredCheck, setIsStarredCheck] = useState<boolean>()

  const collectionsWithoutStarred = useMemo(() => {
    return collections?.filter(
      (collection) => collection.collectionId !== starredCollectionId
    )
  }, [collections, starredCollectionId])

  useEffect(() => {
    const selectedCollectionIds = new Set(
      searchParams.getAll(collectionIdsParams)
    )
    const selectedPublications = new Set(searchParams.getAll(indicesParams))

    if (isSearchAllArticles) {
      setCheckedCollections(collectionsWithoutStarred?.map(() => true) ?? [])
      setCheckedPublications(publications.map(() => true))
      setIsStarredCheck(true)
    } else {
      if (selectedCollectionIds && collectionsWithoutStarred) {
        setCheckedCollections(
          collectionsWithoutStarred.map((value) => {
            return selectedCollectionIds.has(`${value.collectionId}`)
          })
        )
        setIsStarredCheck(selectedCollectionIds.has(`${starredCollectionId}`))
      }

      if (selectedPublications && publications)
        setCheckedPublications(
          publications.map((publication) => {
            return selectedPublications.has(publication)
          })
        )
    }
    // setting publications as dependency seem to cause re-renders, not sure why
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections, searchParams])

  const checkedItems = useMemo(() => {
    return [...checkedPublications, ...checkedCollections, isStarredCheck]
  }, [checkedCollections, checkedPublications, isStarredCheck])

  const allChecked = checkedItems.every(Boolean)
  const noneChecked = checkedItems.every((value) => !value)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked

  const textOnMenu = useMemo(() => {
    if (pathname === AppNavPaths.Home) {
      return 'Select Collection'
    }
    const isNoneSelected = collectionIds.length === 0 && indices.length === 0
    const count = collectionIds.length + indices.length

    if (isSearchAllArticles || isNoneSelected) {
      return All_ARTICLES
    }
    if (count === 1 && collections) {
      const collection = collections.find(
        (collection) => collection.collectionId === collectionId
      )
      const publicationName = DOCUMENT_INDEX_TO_NAME[index]
      const name = collection ? collection.name : publicationName
      return `${name}`
    }

    return `${count} Collections`
  }, [
    pathname,
    collectionIds.length,
    collections,
    indices.length,
    isSearchAllArticles,
    collectionId,
    index,
  ])

  const onCheck = useCallback(
    ({
      checkBoxes,
      setCheckBoxesState,
      event,
      index,
    }: {
      checkBoxes: boolean[]
      setCheckBoxesState: React.Dispatch<React.SetStateAction<boolean[]>>
      event: React.ChangeEvent<HTMLInputElement>
      index: number
    }) => {
      const newCheckboxes = checkBoxes.map((value, curIndex) => {
        if (index === curIndex) {
          return event.target.checked
        }
        return value
      })
      setCheckBoxesState(newCheckboxes)
    },
    []
  )

  const onHandleSubmit = () => {
    searchParams.delete(indicesParams)
    searchParams.delete(collectionIdsParams)
    if (!allChecked) {
      checkedCollections.map((isChecked, index) => {
        if (isChecked && collectionsWithoutStarred) {
          searchParams.append(
            collectionIdsParams,
            `${collectionsWithoutStarred[index].collectionId}`
          )
        }
      })
      checkedPublications.map((isChecked, index) => {
        if (isChecked) {
          searchParams.append(indicesParams, publications[index])
        }
      })
      if (isStarredCheck) {
        searchParams.append(collectionIdsParams, `${starredCollectionId}`)
      }
    }
    if (!isDocumentsPage) {
      navigate(AppNavPaths.Documents)
    }
    setSearchParams(searchParams)
    onClose()
  }

  const onCancel = () => {
    if (isSearchAllArticles) {
      setIsStarredCheck(true)
      setCheckedCollections(
        collectionsWithoutStarred
          ? collectionsWithoutStarred.map(() => true)
          : []
      )
      setCheckedPublications(publications.map(() => true))
      setIsStarredCheck(true)
    } else {
      const collectionIds = new Set(searchParams.getAll(collectionIdsParams))
      const newCheckedCollections =
        collectionsWithoutStarred &&
        collectionsWithoutStarred.map((collection) => {
          return collectionIds.has(`${collection.collectionId}`)
        })
      setCheckedCollections(newCheckedCollections ?? [])
      const indices = new Set(searchParams.getAll(indicesParams))
      const newCheckedPublications = publications.map((publication) => {
        return indices.has(publication)
      })
      setCheckedPublications(newCheckedPublications)
      if (collectionIds.has(`${starredCollectionId}`)) {
        setIsStarredCheck(true)
      }
    }
    onClose()
  }

  return (
    (!isEmpty(collections) || !isEmpty(publications)) && (
      <Menu
        closeOnSelect={false}
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        strategy="fixed"
      >
        <MenuButton
          h="auto"
          w="auto"
          py="0.25rem"
          pl="0.5rem"
          pr="0.25rem"
          bg="orange.400"
          color="orange.50"
          borderRadius="1.25rem"
          isActive={isOpen}
          rightIcon={isOpen ? <FiChevronUp /> : <FiChevronDown />}
          as={Button}
          _hover={{
            bg: 'orange.100',
            color: 'orange.500',
            fontWeight: 'normal',
          }}
          _active={{
            bg: 'orange.300',
            color: 'orange.50',
            fontWeight: 'normal',
          }}
          isTruncated
        >
          <Text fontSize="0.75rem" fontWeight="normal" isTruncated>
            {textOnMenu}
          </Text>
        </MenuButton>
        <MenuList minWidth="16rem" py="1rem" px="1.5rem">
          <HStack w="100%" justify="space-between">
            <Text fontSize="1rem" fontWeight="bold">
              Select search range
            </Text>
            <CloseButton onClick={onClose} size="sm" />
          </HStack>
          <Spacer h="0.75rem" />
          <VStack spacing="1rem" align="left" overflow="auto" maxH="22.5rem">
            <Checkbox
              size="sm"
              fontSize="0.875rem"
              fontWeight="bold"
              color="orange.400"
              borderColor="orange.400"
              aria-checked={
                isIndeterminate ? 'mixed' : allChecked ? 'true' : 'false'
              }
              _indeterminate={orangeCheckboxStyle}
              _checked={orangeCheckboxStyle}
              isChecked={allChecked}
              isIndeterminate={isIndeterminate}
              onChange={() => {
                if (allChecked) {
                  setCheckedCollections(checkedCollections.map(() => false))
                  setCheckedPublications(checkedPublications.map(() => false))
                  setIsStarredCheck(false)
                } else {
                  setCheckedCollections(checkedCollections.map(() => true))
                  setCheckedPublications(checkedPublications.map(() => true))
                  setIsStarredCheck(true)
                }
              }}
            >
              SELECT ALL
            </Checkbox>
            <Checkbox
              size="sm"
              borderColor="black"
              _checked={blackCheckboxStyle}
              isChecked={isStarredCheck}
              onChange={() => {
                if (isStarredCheck) {
                  setIsStarredCheck(false)
                } else {
                  setIsStarredCheck(true)
                }
              }}
            >
              <HStack spacing="0.5rem">
                <BiStar size="1rem" />
                <Text fontSize="1rem" fontWeight="400">
                  {DEFAULT_COLLECTION}
                </Text>
              </HStack>
            </Checkbox>
            {publications && !isEmpty(publications) && (
              <>
                <VStack spacing="1rem" align="left">
                  <MenuTitleWithDivider name={PUBLICATIONS_TITLE} />
                  {publications.map((publication, index) => (
                    <Checkbox
                      key={index + publication}
                      _checked={blackCheckboxStyle}
                      size="sm"
                      borderColor="black"
                      isChecked={checkedPublications[index]}
                      onChange={(event) => {
                        onCheck({
                          checkBoxes: checkedPublications,
                          setCheckBoxesState: setCheckedPublications,
                          event,
                          index,
                        })
                      }}
                    >
                      <HStack spacing="0.5rem">
                        <IoDocumentTextOutline size="1rem" />
                        <Text fontSize="1rem" fontWeight="400">
                          {DOCUMENT_INDEX_TO_NAME[publication]}
                        </Text>
                      </HStack>
                    </Checkbox>
                  ))}
                </VStack>
              </>
            )}
            {collectionsWithoutStarred &&
              !isEmpty(collectionsWithoutStarred) && (
                <VStack align="left">
                  <MenuTitleWithDivider name={COLLECTIONS_TITLE} />
                  {collectionsWithoutStarred.map((collection, index) => (
                    <Checkbox
                      key={`${index}_${collection.collectionId}`}
                      _checked={blackCheckboxStyle}
                      size="sm"
                      isChecked={checkedCollections[index]}
                      borderColor="black"
                      onChange={(e) => {
                        onCheck({
                          checkBoxes: checkedCollections,
                          setCheckBoxesState: setCheckedCollections,
                          event: e,
                          index,
                        })
                      }}
                    >
                      <CollectionRow
                        name={collection.name}
                        documentCount={collection.documentCount} // not required to show document count
                        userCount={collection.userCount}
                        styles={{
                          fontSize: '1rem',
                          fontWeight: '400',
                        }}
                      />
                    </Checkbox>
                  ))}
                </VStack>
              )}
          </VStack>
          <Spacer h="0.75rem" />
          <HStack w="100%" justify="end">
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              bg={'green.100'}
              color={'green.800'}
              _hover={{ bg: 'green.400', color: 'orange.50' }}
              type="submit"
              isDisabled={noneChecked}
              onClick={onHandleSubmit}
            >
              Apply
            </Button>
          </HStack>
          {noneChecked && (
            <VStack justify="end" align="end">
              <Spacer h="0.75rem" />
              <Text w="100%" maxW="10rem" color="red" fontSize="0.875rem">
                Please select at least one collection or publication to search
              </Text>
            </VStack>
          )}
        </MenuList>
      </Menu>
    )
  )
}

export const SearchMenu = memo(SearchMenuComponent)
