import { SkeletonText, Text } from '@chakra-ui/react'
import { useCollabContext } from './collab/useCollabContext'
import { useDocumentListContext } from '../home/useDocumentListContext'
import { useMemo } from 'react'
import { DEFAULT_COLLECTION, PERMISSIONS_TO_ROLE } from '~shared/constants'
import { useIsMobile } from '@/hooks'

export const CollectionRoleIndicator = () => {
  const isMobile = useIsMobile()
  const { userPermission, isUserCollectionsLoading } = useCollabContext()
  const { collection } = useDocumentListContext()

  const role = useMemo(() => {
    if (collection && collection.name !== DEFAULT_COLLECTION) {
      return userPermission && PERMISSIONS_TO_ROLE[userPermission]
    }
  }, [collection, userPermission])

  if (isUserCollectionsLoading && isMobile) {
    return <SkeletonText noOfLines={1} skeletonHeight="2" width="100%" />
  }

  return (
    role &&
    (isMobile ? (
      <Text fontSize="0.75rem">
        Current collection (<b>{role}</b>)
      </Text>
    ) : (
      <Text>
        You are {isFirstVowel(role) ? 'an ' : 'a '} <b>{role}</b> of this
        collection
      </Text>
    ))
  )
}

const isFirstVowel = (s: string) => {
  const vowels = ['a', 'e', 'i', 'o', 'u']
  const firstCharacter = s[0].toLowerCase()
  return vowels.indexOf(firstCharacter) !== -1
}
