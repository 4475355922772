import { Center, VStack, Spinner, HStack, Icon, Text } from '@chakra-ui/react'
import { BookmarkIconButton } from './BookmarkIconButton'
import {
  COLLECTION_NAME_REGEX_VALIDATION,
  DEFAULT_COLLECTION,
  MAX_COLLECTION_NAME_LENGTH,
} from '~shared/constants'
import { overflowScrollBarStyles } from '@/custom-css-prop/overflow-scrollbar-styles'
import { CollectionSearchBar } from '@/components/searchbar/CollectionSearchBar'
import { useEffect, useMemo, useState } from 'react'
import { AddBookmarkButton } from './AddBookmarkButton'
import { CollectionButton } from '~shared/dtos'
import { useBookmarkContext } from '../useBookmarkContext'
import { MdError } from 'react-icons/md'
import { Option, SelectDropdown } from '@/components/SelectDropdown'
import { BOOKMARK_MODAL_SORT_OPTIONS } from '@/constants/bookmark'
import { useGetAllCollectionsWithDocumentId } from '@/hooks/useCollection'
import { useGetUserIdFromContext } from '@/hooks'

const sortOptions = Object.keys(BOOKMARK_MODAL_SORT_OPTIONS).map((key) => {
  return {
    value: key,
    label: BOOKMARK_MODAL_SORT_OPTIONS[key],
  }
})

const sortLikedFirst = (collections: CollectionButton[]) => {
  const sortedCollections: CollectionButton[] = []
  for (const collection of collections) {
    if (collection.name.toLowerCase() === DEFAULT_COLLECTION.toLowerCase()) {
      sortedCollections.unshift(collection)
    } else {
      sortedCollections.push(collection)
    }
  }
  return sortedCollections
}

export const BookmarkIconButtonList = () => {
  const {
    selectedCollections,
    setSelectedCollections,
    documentId,
    documentCollections,
  } = useBookmarkContext()
  const { userId } = useGetUserIdFromContext()

  const { collections: fetchedCollections, isCollectionsLoading } =
    useGetAllCollectionsWithDocumentId(userId, +documentId)

  const [searchValue, setSearchValue] = useState<string>('')

  const [errMessage, setErrMessage] = useState<string>('')

  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOptions.find(
      (option) => option.label === BOOKMARK_MODAL_SORT_OPTIONS.Alphabetical
    ) ?? sortOptions[0]
  ) // default is 'Alphabetical'

  useEffect(() => {
    const regex = COLLECTION_NAME_REGEX_VALIDATION

    if (searchValue.length > MAX_COLLECTION_NAME_LENGTH && errMessage === '') {
      setErrMessage('Collection name cannot exceed 24 characters')
    } else if (
      searchValue !== '' &&
      !regex.test(searchValue) &&
      errMessage === ''
    ) {
      setErrMessage('Only alphanumeric characters are allowed')
    } else if (
      searchValue.length <= MAX_COLLECTION_NAME_LENGTH &&
      regex.test(searchValue) &&
      errMessage !== ''
    ) {
      setErrMessage('')
    } else if (searchValue === '') {
      setErrMessage('')
    }
  }, [errMessage, searchValue, setErrMessage])

  // collections are all the collections that the user have access to (i.e can bookmark to).
  const [collections, setCollections] = useState<CollectionButton[]>([])

  // this is a one time initial assignment of fetched collections to the used states
  useEffect(() => {
    if (fetchedCollections) {
      // change collectionId to string
      const documentCollectionsSet = new Set(
        documentCollections.map((collection) => `${collection.collectionId}`)
      )
      const collections = fetchedCollections
        .map(({ collectionId, name, documentCount, isEditable, userCount }) => {
          return {
            collectionId: `${collectionId}`,
            name,
            documentCount,
            userCount,
            isEditable,
          }
        })
        .filter(
          ({ name, collectionId }) =>
            !documentCollectionsSet.has(collectionId) &&
            name !== DEFAULT_COLLECTION
        )
      setCollections(collections)
    }
  }, [documentCollections, fetchedCollections, setSelectedCollections])

  // TODO (alex): Look at memoising in future
  useEffect(() => {
    // sort the collections based on the selected option
    if (selectedSortOption.label === BOOKMARK_MODAL_SORT_OPTIONS.Alphabetical) {
      setCollections((collections) => {
        const sortedCollectionsByAlphabet = collections.sort((a, b) =>
          a.name.localeCompare(b.name)
        )
        const sortedCollections: CollectionButton[] = sortLikedFirst(
          sortedCollectionsByAlphabet
        )

        return sortedCollections
      })
    } else if (
      selectedSortOption.label === BOOKMARK_MODAL_SORT_OPTIONS.MostItems
    ) {
      setCollections((collections) => {
        const sortedCollectionByDocumentCount = collections.sort(
          (a, b) => b.documentCount - a.documentCount
        )

        return sortLikedFirst(sortedCollectionByDocumentCount)
      })
    }
  }, [selectedSortOption, setCollections])

  // these are the collection search results for the bookmark modal/drawer
  const bookmarkCollections = useMemo(() => {
    if (!searchValue) {
      return collections
    }
    const collectionsDisplayed: CollectionButton[] = []
    const trimmedSearchValue = searchValue.trim()
    collections.forEach(({ collectionId, name, documentCount, isEditable }) => {
      const isSearchResults = name
        .split(' ')
        .find(
          (word: string) =>
            word.toLowerCase().startsWith(trimmedSearchValue) ||
            name === trimmedSearchValue.toLowerCase()
        )
      if (isSearchResults)
        collectionsDisplayed.push({
          collectionId,
          name,
          documentCount,
          isEditable,
        })
    })
    return collectionsDisplayed
  }, [collections, searchValue])

  if (isCollectionsLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <VStack
      maxHeight="25rem"
      w="100%"
      alignItems="left"
      justifyItems="left"
      spacing="1rem"
    >
      <CollectionSearchBar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      {errMessage !== '' && (
        <HStack textColor="red" ml="0.5rem" fontSize="0.875rem" mt="-1rem">
          <Icon as={MdError} />
          <Text>{errMessage}</Text>
        </HStack>
      )}
      {searchValue === '' && (
        <VStack alignItems="flex-start" justifyItems="left" display={'flex'}>
          <SelectDropdown
            options={sortOptions}
            selected={selectedSortOption}
            handleChange={(option) => {
              setSelectedSortOption(option)
            }}
          />
        </VStack>
      )}

      <VStack
        overflowY="auto"
        h="100%"
        w="100%"
        alignItems="left"
        justifyItems="left"
        css={overflowScrollBarStyles}
        spacing="1rem"
      >
        {searchValue && (
          <AddBookmarkButton
            isError={errMessage !== ''}
            searchValue={searchValue}
            onClick={(value) => {
              setCollections([...collections, value])
              setSelectedCollections([...selectedCollections, value])
              setSearchValue('')
            }}
          />
        )}
        {bookmarkCollections &&
          bookmarkCollections.map(
            ({ name, documentCount, collectionId, isEditable, userCount }) => {
              return (
                <BookmarkIconButton
                  key={collectionId}
                  collectionId={collectionId}
                  collectionName={name}
                  documentCount={documentCount}
                  userCount={userCount ?? 1}
                  isEditable={!!isEditable}
                />
              )
            }
          )}
      </VStack>
    </VStack>
  )
}
