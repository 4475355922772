import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  HStack,
  ToastProps,
  VStack,
} from '@chakra-ui/react'

export const Toast: React.FC<ToastProps> = (props) => {
  const {
    status,
    variant = 'solid',

    title,
    isClosable,
    onClose,
    description,
    colorScheme,
    icon,
  } = props

  return (
    <Alert
      addRole={false}
      status={status}
      variant={variant}
      alignItems="start"
      borderRadius="md"
      boxShadow="lg"
      textAlign="start"
      width="auto"
      colorScheme={colorScheme}
      justifyContent="space-between"
    >
      <HStack padding={0} spacing={0} align="top">
        <AlertIcon>{icon}</AlertIcon>
        <VStack align="start" padding={0} spacing={0} maxWidth="100%">
          {title && <AlertTitle>{title}</AlertTitle>}
          {description && (
            <AlertDescription display="block">{description}</AlertDescription>
          )}
        </VStack>
      </HStack>

      {isClosable && <CloseButton size="sm" onClick={onClose} />}
    </Alert>
  )
}
