import { searchFilterParam } from '@/constants/search-params'
import { Icon } from '@chakra-ui/icon'
import { Input } from '@chakra-ui/input'
import { Divider, HStack, IconButton } from '@chakra-ui/react'
import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { IoSearchOutline } from 'react-icons/io5'
import { useSearchParams } from 'react-router-dom'
import { RxCross2 } from 'react-icons/rx'
import {
  useAddQueryToSearchParam,
  useSetSortSearchParams,
} from '@/hooks/params/useSearchParams'
import { SearchMenu } from './SearchMenu'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'

const inputStyles = {
  border: 0,
  borderColor: 'white',
  boxShadow: 'none',
}

export const DocumentSearchbar = () => {
  const innerRef = useRef<HTMLInputElement>(null)
  const { query } = searchFilterParam
  const { addQueryToSearchParams } = useAddQueryToSearchParam()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setSortSearchparams } = useSetSortSearchParams({
    searchParams,
  })
  const { setIsFilterExpanded } = useDocumentListContext()
  const handleSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && innerRef.current) {
      addQueryToSearchParams({ queryValue: innerRef.current.value })
      setIsFilterExpanded(true)
    }
  }

  const [search, setSearch] = useState(searchParams.get(query) ?? '')
  const placeholder = 'Search articles by keywords'
  const [placeholderState, setPlaceholderState] = useState<string>(placeholder)

  // update query text if search param's query changed
  useEffect(() => {
    setSearch(searchParams.get(query) ?? '')
  }, [query, searchParams])
  return (
    // todo(ben): ask trena for new design to incorporate md screens
    <HStack
      borderRadius="20px"
      w="100%"
      background="white"
      height="40px"
      p="1rem"
      spacing="0"
    >
      <Icon as={IoSearchOutline} color="gray.500" p="0" />
      <Input
        onFocus={() => {
          setPlaceholderState('')
        }}
        onBlur={() => {
          setPlaceholderState(placeholder)
        }}
        _focus={inputStyles}
        _hover={inputStyles}
        ref={innerRef}
        borderRadius="20px"
        borderColor="white"
        value={search}
        placeholder={placeholderState}
        onKeyDown={handleSearch}
        onChange={({ target: { value } }) => {
          setSearch(value)
        }}
      />
      <HStack>
        {search && (
          <IconButton
            icon={<RxCross2 />}
            aria-label="clear-text-from-searchbar"
            color="black"
            _hover={{
              bg: 'parent',
              color: 'gray.300',
            }}
            size="s"
            bg="parent"
            onClick={() => {
              setSearch('')
              setSortSearchparams()
              searchParams.set(query, '')
              setSearchParams(searchParams, { replace: true })
            }}
          />
        )}
        <Divider
          orientation="vertical"
          height="1.25rem"
          borderColor={'gray.500'}
        />
        <SearchMenu />
      </HStack>
    </HStack>
  )
}
