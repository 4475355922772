import { DocumentRes } from '~shared/dtos/document.dto'
import { CreateAndInsertTagReq } from '~shared/dtos/tag.dto'
import { ApiService } from './ApiService'

export const createAndInsertTag = async ({
  documentId,
  payload,
}: {
  documentId: number
  payload?: CreateAndInsertTagReq
}) => {
  const { data } = await ApiService.post<DocumentRes>(
    `document/${documentId}/tag/create`,
    payload
  )
  return data
}
