export const PressifyShorthandLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
    >
      <path
        d="M28.1755 15.1773C28.1785 14.6713 28.1147 12.9135 27.203 11.0883C26.2244 9.12865 24.112 6.76088 19.6076 6.61126L19.5462 6.61005H14.0869H11.3414H10.76C10.7071 6.61005 10.6463 6.61005 10.586 6.61674V6.61005H9.22182H9.20784C8.03216 6.61005 7.07545 7.55521 7.07545 8.71689C7.07545 9.77639 7.8722 10.6559 8.90434 10.8024V10.8237H9.20601H10.586C10.6043 10.8237 11.4594 10.8237 14.076 10.8237H19.5055C20.2894 10.8535 24.1163 11.2002 24.061 15.1372L24.0573 15.1834L24.0561 15.2028L24.0573 15.2223L24.0628 15.3208C24.0628 15.3354 24.1206 17.2683 22.9534 18.5243C22.1749 19.3618 20.9402 19.7869 19.2828 19.7869H7.57357L4.16455 24H19.2932L20.2292 23.9653H20.2347L20.2402 23.9647C22.6426 23.7859 24.5712 22.918 25.9732 21.3853C28.1907 18.9616 28.1974 15.8044 28.1749 15.1779L28.1755 15.1773Z"
        fill="url(#paint0_linear_4757_7934)"
      />
      <path
        d="M19.2755 13.1976V13.1763H18.9739H17.5938C17.5756 13.1763 16.7205 13.1763 14.1039 13.1763H8.67443C7.89045 13.1465 4.06358 12.7998 4.11893 8.86285L4.12258 8.81662L4.1238 8.79716L4.12258 8.7777L4.11711 8.67917C4.11711 8.66457 4.05933 6.73168 5.22648 5.47572C6.00499 4.63822 7.23966 4.21308 8.89703 4.21308H20.4117L23.8712 0H8.88609L7.95005 0.034668H7.94458L7.9391 0.0352762C5.53667 0.21409 3.60743 1.08201 2.2055 2.6147C-0.0120367 5.03842 -0.018727 8.19564 0.00377678 8.8221C0.000735727 9.32813 0.0645978 11.0859 0.976306 12.9111C1.95492 14.8708 4.06723 17.2385 8.57164 17.3881L8.63307 17.3894H14.0924H16.8378H17.4193C17.4722 17.3894 17.533 17.3894 17.5932 17.3827V17.3894H18.9575H18.9714C20.1471 17.3894 21.1038 16.4442 21.1038 15.2825C21.1038 14.223 20.3071 13.3435 19.2749 13.197L19.2755 13.1976Z"
        fill="url(#paint1_linear_4757_7934)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4757_7934"
          x1="20.1015"
          y1="25.1799"
          x2="9.19263"
          y2="6.28526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCA90F" />
          <stop offset="0.14" stopColor="#F58E1F" />
          <stop offset="0.33" stopColor="#EF7230" />
          <stop offset="0.52" stopColor="#EA5E3C" />
          <stop offset="0.71" stopColor="#E75243" />
          <stop offset="0.9" stopColor="#E74F46" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4757_7934"
          x1="0.000127515"
          y1="8.69498"
          x2="23.8712"
          y2="8.69498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCA90F" />
          <stop offset="0.14" stopColor="#F58E1F" />
          <stop offset="0.33" stopColor="#EF7230" />
          <stop offset="0.52" stopColor="#EA5E3C" />
          <stop offset="0.71" stopColor="#E75243" />
          <stop offset="0.9" stopColor="#E74F46" />
        </linearGradient>
      </defs>
    </svg>
  )
}
