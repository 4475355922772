import { DocumentListLayout } from '@/components/DocumentListLayout'
import { DocumentList } from '@/features/document/list/DocumentList'
import { useIsMobile } from '@/hooks'
import { NavBarHeader } from '@/components/header'
import { FilterTags } from '@/features/home'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'
import { NoCollectionPlaceholder } from '@/features/collections/NoCollectionPlaceholder'
import { isEmpty } from 'lodash'
import { searchFilterParam } from '@/constants/search-params'
import { useSearchParams } from 'react-router-dom'
import { DocumentListHeader } from '@/components/DocumentListHeader'
import { useMemo } from 'react'
import { OPENSEARCH_INDEX } from '~shared/constants'

export const DocumentListPage = () => {
  const isMobile = useIsMobile()
  const { isCollectionsLoading, collections, starredCollectionId } =
    useDocumentListContext()
  const { indices } = searchFilterParam
  const [searchParams] = useSearchParams()
  const collectionsWithoutStarred = useMemo(() => {
    return collections?.filter(
      (collection) => collection.collectionId !== starredCollectionId
    )
  }, [collections, starredCollectionId])

  return (
    <DocumentListLayout
      header={<NavBarHeader hasSearchBar />}
      isShowPlaceholder={
        !isCollectionsLoading &&
        isEmpty(collectionsWithoutStarred) &&
        searchParams.get(indices) !== OPENSEARCH_INDEX.PressumArticle
      }
      placeholder={<NoCollectionPlaceholder />}
    >
      <DocumentListHeader />
      {!isMobile && <FilterTags />}
      <DocumentList />
    </DocumentListLayout>
  )
}
