import { Text, Box, Image, Button, VStack } from '@chakra-ui/react'
import { AppFooter } from '@/app/AppFooter'
import { useNavigate } from 'react-router-dom'
import { AppNavPaths } from '@/utils/paths'
import { useIsMobile } from '@/hooks'
import { PressifyLogo } from '@/components/PressifyLogo'
import ErrorScreenSvg from '@/assets/error-screen.svg'
import ErrorScreenNoLogoSvg from '@/assets/error-screen-no-logo.svg'

export const ErrorPage = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  return (
    <VStack w="100%">
      <VStack
        minH="calc(100vh - 4rem)"
        width="100%"
        align="center"
        justify="center"
        spacing="2rem"
        py={{ base: '1rem', lg: '3rem' }}
        px="1.5rem"
      >
        {isMobile ? (
          <PressifyLogo
            styles={{
              alignSelf: 'flex-start',
              paddingBottom: '1rem',
            }}
            isClickable={false}
            variant="sm-light"
          />
        ) : (
          <Image
            src={ErrorScreenSvg}
            alt="404"
            height="100%"
            maxH="26rem"
            width="70rem"
            flexShrink={0}
          />
        )}

        <Box textAlign={{ base: 'left', lg: 'center' }} width="100%">
          <Text fontSize={{ base: '1.125rem', lg: '2.25rem' }} fontWeight="500">
            The page you were looking for could not be found.
          </Text>

          <Box height="1rem" />

          <Text
            color="gray.600"
            fontSize={{ base: '1rem', lg: '1.25rem' }}
            fontWeight="400"
          >
            Please contact the Pressify team if you require further assistance.
          </Text>
        </Box>

        {isMobile && (
          <Image
            src={ErrorScreenNoLogoSvg}
            alt="404"
            width="100%"
            flexShrink={0}
          />
        )}

        <Button
          _hover={{
            bg: 'brand.primary.400',
            color: 'brand.primary.100',
          }}
          onClick={() => {
            navigate(AppNavPaths.Home)
            // reloading instead of resetting the error boundary since this is a global error boundary and this will minimise errors from frontend being not synced
            window.location.reload()
          }}
          width={{ base: '100%', lg: 'auto' }}
          borderRadius="1.25rem"
          fontWeight="600"
          bg="brand.primary.500"
          color="brand.primary.50"
        >
          Back to Home
        </Button>
      </VStack>
      <AppFooter />
    </VStack>
  )
}
