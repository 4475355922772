import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react'
import { colors } from './colors'

export const commonHoverStyles = {
  _hover: {
    // To address the specificity issue without losing the intended design of our buttons, we opted to implement the !important flag on the hover background color within our global hover style.
    backgroundColor: { lg: 'orange.100!important', base: 'parent' },
    color: { lg: 'orange.500', base: 'parent' },
    fontWeight: { lg: 'bold', base: 'parent' },
  },
}

const buttonStyles = () => ({
  baseStyle: {
    ...commonHoverStyles,
  },
})

const menuStyles = () => ({
  parts: ['item'],
  baseStyle: {
    item: {
      ...commonHoverStyles,
    },
  },
})

const sizes = {
  lg: defineStyle({
    maxW: '40rem',
  }),
}

const tooltipTheme = defineStyleConfig({ sizes })

export const theme = extendTheme({
  styles: {
    global: {
      '*': {
        WebkitTapHighlightColor: 'transparent',
      },
    },
  },
  colors,
  components: {
    Button: buttonStyles(),
    Menu: menuStyles(),
    Tooltip: tooltipTheme,
    Alert: {
      parts: ['container'],
      baseStyle: {
        container: {
          // HACK: To override Toast alert component's radius
          'border-radius': {
            base: '0rem !important',
            lg: '0.375rem !important',
          },
        },
      },
    },
  },
})
