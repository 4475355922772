import { AllDocumentSearchReq, DocumentRes } from '~shared/dtos/document.dto'
import { ApiService } from './ApiService'
import { PageParams, PaginatedRes } from '~shared/dtos/paginated'
import { DocumentTagWithCategory } from '~shared/dtos/tag.dto'
import { SearchCollectionRes, SortOption } from '~shared/dtos/search.dto'
import { PERMISSIONS } from '~shared/constants'

export const searchAllDocuments = async ({
  pageParam,
  reqParam,
}: {
  pageParam: PageParams
  reqParam: AllDocumentSearchReq
}) => {
  const { data } = await ApiService.get<PaginatedRes<DocumentRes>>(
    `/search/all`,
    {
      params: {
        ...pageParam,
        ...reqParam,
      },
    }
  )
  return data
}

export const searchTags = async ({
  pageParam,
  query,
}: {
  pageParam: PageParams
  query: string
}) => {
  const { data } = await ApiService.get<PaginatedRes<DocumentTagWithCategory>>(
    '/search/tags',
    { params: { ...pageParam, query } }
  )

  return data
}

export const searchLatestPressums = async () => {
  const { data } = await ApiService.get<DocumentRes[]>(
    '/search/latest-pressums'
  )

  return data
}

export const searchCollections = async ({
  pageParam,
  params,
}: {
  pageParam: PageParams
  params: {
    query: string
    sortOptions: SortOption[]
  }
}) => {
  const { data } = await ApiService.get<PaginatedRes<SearchCollectionRes>>(
    '/search/collections',
    {
      params: {
        ...pageParam,
        ...params,
        permissions: [PERMISSIONS.MANAGE, PERMISSIONS.UPDATE],
      },
    }
  )

  return data
}

export const searchLatestPublishedArticles = async () => {
  const { data } = await ApiService.get<DocumentRes[]>(
    '/search/latest-published'
  )
  return data
}
