// update again after we integrate similar articles within pressify-jpe
export const RelatedArticlesList = () => {
  return <></>
  // const { documentId } = useDocumentIdParam()

  // const {
  //   isSimilarDocumentsLoading,
  //   similarDocuments,
  //   isSimilarDocumentsError,
  // } = useSearchSimilarDocuments(`${documentId}`)

  // const { getRadioProps, getRootProps } = useRadioGroup()
  // const group = getRootProps()

  // return (
  //   <>
  //     {isSimilarDocumentsLoading ? (
  //       <Center>
  //         <Spinner />
  //       </Center>
  //     ) : (
  //       !isSimilarDocumentsError &&
  //       similarDocuments &&
  //       !isEmpty(similarDocuments) && (
  //         <VStack alignItems="left" w="100%" {...group} spacing="1rem">
  //           <Text fontWeight="semibold" fontSize="1rem">
  //             Related Articles/Pressums
  //           </Text>
  //           <HStack spacing="0.5rem" h="100%" w="100%" overflowX="auto">
  //             {similarDocuments.map((document) => {
  //               return (
  //                 <RelatedArticlesCard
  //                   key={document.id}
  //                   title={document.title}
  //                   dateTime={document.dateTime}
  //                   documentId={document.id}
  //                   tags={document.tags}
  //                   {...getRadioProps()}
  //                 />
  //               )
  //             })}
  //           </HStack>
  //         </VStack>
  //       )
  //     )}
  //   </>
  // )
}
