import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { FiTrash2 } from 'react-icons/fi'

type TrashProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
} & IconButtonProps

export const Trash = ({ onClick, ...rest }: TrashProps) => {
  return (
    <IconButton
      bg="transparent"
      cursor={'pointer'}
      icon={<FiTrash2 />}
      onClick={onClick}
      _groupHover={{ display: 'flex' }}
      _hover={{
        color: 'orange.500',
        bg: 'orange.100',
      }}
      borderRadius="1.25rem"
      color="gray.800"
      height="2.5rem"
      width="2.5rem"
      {...rest}
    />
  )
}
