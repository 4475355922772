import { ApiService } from '@/api/ApiService'
import { useState, useEffect } from 'react'
import { AppVersionMismatchModal } from '@/app/AppVersionCheck/AppVersionMismatchModal'
import { X_APP_VERSION } from '~shared/constants/header'

export const AppVersionCheck = () => {
  const [isVersionMismatch, setIsVersionMismatch] = useState(false)

  useEffect(() => {
    const interceptor = ApiService.interceptors.response.use((response) => {
      const backendAppVersion = response.headers[X_APP_VERSION]
      if (
        backendAppVersion &&
        backendAppVersion !== import.meta.env.VITE_APP_VERSION // compare backend and frontend versions
      ) {
        setIsVersionMismatch(true)
        ApiService.interceptors.response.eject(interceptor)
      }
      return response
    })
  }, [])

  return <>{isVersionMismatch && <AppVersionMismatchModal />}</>
}
