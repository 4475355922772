import { useIsMobile } from '@/hooks'
import {
  VStack,
  Text,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'

const HEADER = 'Remove this document'

const CONTENT =
  'Are you sure you want to remove this document? It will be permanently deleted from this collection.'

export const DeleteBookmarkContent = () => {
  const isMobile = useIsMobile()

  return isMobile ? (
    <VStack w="100%" align="left">
      <Text fontWeight="bold" fontSize="1.125rem">
        {HEADER}
      </Text>
      <Text fontWeight="400" fontSize="0.875rem">
        {CONTENT}
      </Text>
    </VStack>
  ) : (
    <>
      <ModalHeader px="0px" pt="0px" pb="1rem">
        {HEADER}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody padding="0px" w="100%">
        {CONTENT}
      </ModalBody>
    </>
  )
}
