import { Spacer, VStack } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { DocumentCardSource } from './DocumentCardSource'
import { SummaryPointsList } from '@/components/SummaryPointsList'

type DocumentSummaryContent = {
  summary: string
  sourceName?: string
  sourceLink?: string
}

export const DocumentSummaryContent = ({
  sourceName,
  sourceLink,
  summary,
}: DocumentSummaryContent) => {
  const { pathname } = useLocation()

  const isDocumentPage = pathname.includes('/document')

  return (
    <VStack align="left">
      {sourceLink && (
        <DocumentCardSource sourceName={sourceName} sourceLink={sourceLink} />
      )}
      {isDocumentPage && <Spacer />}
      <SummaryPointsList
        summary={summary}
        hoverStyles={{
          cursor: 'text',
        }}
      />
    </VStack>
  )
}
