import { Button } from '@chakra-ui/react'
import { BiFilter } from 'react-icons/bi'
import { HiX } from 'react-icons/hi'

type SidebarActionsProps = {
  isShowFilter: boolean
  onToggle: () => void
}

export const SidebarActions = ({
  onToggle,
  isShowFilter,
}: SidebarActionsProps) => {
  return (
    <Button
      leftIcon={isShowFilter ? <HiX /> : <BiFilter />}
      onClick={onToggle}
      borderRadius="1.25rem"
      background="gray.100"
    >
      {isShowFilter ? 'CLOSE FILTERS' : 'FILTER ARTICLES'}
    </Button>
  )
}
