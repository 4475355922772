import { UserProfileMenu } from '@/components/UserProfileMenu'
import { UploadMenu } from '@/features/upload/components/menu/UploadMenu'
import { APP_PATHS_TO_NAME, AppNavPaths } from '@/utils/paths'
import { HStack, Divider, Button, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type NavHeaderButtonProps = {
  path: string
}

const NavHeaderButton = ({ path }: NavHeaderButtonProps) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [currentPath, setCurrentPath] = useState(pathname)
  useEffect(() => {
    setCurrentPath(pathname)
  }, [pathname])

  const pathToWidth = {
    [AppNavPaths.Documents]: '119px',
    [AppNavPaths.Home]: '87px',
  }

  return (
    <Button
      background={currentPath === path ? 'gray.700' : 'parent'}
      textColor={currentPath === path ? 'gray.50' : 'parent'}
      onClick={() => {
        navigate(path)
      }}
      padding="0px"
      minW={pathToWidth[path]}
      w="fit-content"
      borderRadius="20px"
    >
      <Text w="100%" align="center">
        {APP_PATHS_TO_NAME[path]}
      </Text>
    </Button>
  )
}

export const HeaderNavButtons = () => {
  return (
    <HStack spacing="16px" height="64px" paddingY="12px">
      <NavHeaderButton path={AppNavPaths.Home} />
      <NavHeaderButton path={AppNavPaths.Documents} />
      <UploadMenu autoSelect={false} />
      <Divider orientation="vertical" borderColor="black" />
      <UserProfileMenu />
    </HStack>
  )
}
