import { IMAGE_FILE_EXTENSIONS } from '~shared/constants/extensions'
import { CUSTOM_LINK_PREFIX } from '~shared/constants/document'
import {
  Editor,
  Transforms,
  Node,
  Descendant,
  Element as SlateElement,
} from 'slate'
import {
  CustomElement,
  ImageElement,
  LinkElement,
  BulletedListElement,
  NumberedListElement,
} from './type'
import isUrl from 'is-url'
import { SlateTextTypes } from '~shared/types'
import { isEmpty } from 'lodash'
import { TextractContent } from '~shared/dtos'

const LIST_TYPES = [SlateTextTypes.NUMBERED_LIST, SlateTextTypes.BULLETED_LIST]

export const toggleMark = (editor: Editor, format: SlateTextTypes) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const toggleBlock = (editor: Editor, format: SlateTextTypes) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  })
  const newProperties: Partial<SlateElement> = {}

  if (isActive) {
    newProperties.type = SlateTextTypes.PARAGRAPH
  } else if (isList) {
    newProperties.type = SlateTextTypes.LIST_ITEM
  } else {
    // todo (ben): refactor again in future, handle block and mark types separately
    newProperties.type = format as
      | SlateTextTypes.IMAGE
      | SlateTextTypes.CODE
      | SlateTextTypes.LIST_ITEM
      | SlateTextTypes.NUMBERED_LIST
      | SlateTextTypes.BULLETED_LIST
      | SlateTextTypes.PARAGRAPH
      | SlateTextTypes.LINK
      | undefined
  }

  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] } // does not handle urls
    Transforms.wrapNodes(
      editor,
      block as NumberedListElement | BulletedListElement
    )
  }
}

export const isBlockActive = (
  editor: Editor,
  format: SlateTextTypes,
  blockType = 'type'
) => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

export const isMarkActive = (editor: Editor, format: SlateTextTypes) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

// function handles conversion of new image and urls that we manually add into the text editor
export const withImagesAndInlines = (editor: Editor) => {
  const { insertData, isVoid, isInline } = editor

  // treat link as inline
  editor.isInline = (element) =>
    [SlateTextTypes.LINK].includes(element.type) || isInline(element)

  editor.isVoid = (element) => {
    return element.type === SlateTextTypes.IMAGE ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            insertImage(editor, url as string)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else if (isUrl(text)) {
      wrapLink({ editor, url: text, text })
    } else {
      insertData(data)
    }
  }

  return editor
}

export const insertImage = (editor: Editor, url: string) => {
  const text = { text: '' }
  const image: ImageElement = {
    type: SlateTextTypes.IMAGE,
    url,
    children: [text],
  }
  // typings are a bit funky for images
  Transforms.insertNodes(editor, image as unknown as Node)
  // add space below image so that we can type
  Transforms.insertNodes(editor, {
    type: SlateTextTypes.PARAGRAPH,
    children: [{ text: '' }],
  })
}

const isImageUrl = (url: string) => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  // not all image urls have extensions, e.g https://www.abc.net.au/news/2024-08-20/allied-health-students-placement-poverty-graduation/104243024
  // todo(ben): handle those cases when we implement saving as base64
  return IMAGE_FILE_EXTENSIONS.includes(ext ?? '')
}

// todo(ben): split based on \n, trim \n from start
// for uploading article via url, we need to convert urls to the respective slate custom element
const createParagraph = (text: string): Descendant => ({
  type: SlateTextTypes.PARAGRAPH,
  children: [{ text }],
})

const createBoldedParagraph = (text: string): Descendant => ({
  type: SlateTextTypes.PARAGRAPH,
  children: [{ text, bold: true }],
})

const createImage = (url: string): Descendant => ({
  type: SlateTextTypes.IMAGE,
  url,
  children: [{ text: `${url}` }],
})

const createLink = (url: string, text: string): Descendant => ({
  type: SlateTextTypes.LINK,
  url,
  children: [{ text }],
})

const handleLinkText = (acc: Descendant[], url: string) => {
  // if link is the first content in editor, create a para slate element
  if (acc.length === 0) {
    acc.push(createParagraph(''))
  }
  const lastDescendant = acc[acc.length - 1]
  const lastChild =
    lastDescendant['children'][lastDescendant['children'].length - 1]
  // attach link into previous text if valid. else, create link with same display name and url link
  if (
    lastDescendant &&
    lastChild?.text &&
    lastDescendant['type'] !== SlateTextTypes.IMAGE && // if last node is image, do not append link
    lastChild.text.trim() !== '' // if last node is empty, do not append link
  ) {
    const arrayOfPrevText = lastDescendant['children'] // extract prev text and split it into an array
      .pop()
      .text.split(' ')
      .filter((t: string) => t !== '')
    const linkDisplayName = arrayOfPrevText.pop()
    const textBeforeLinkDisplayName = arrayOfPrevText.join(' ')

    // update text with required spaces
    let newText = ''
    const isFirstSlateElementInLine = lastDescendant['children'].length === 0
    if (isFirstSlateElementInLine && textBeforeLinkDisplayName) {
      newText = textBeforeLinkDisplayName + ' '
    } else if (!isFirstSlateElementInLine) {
      newText = ' ' + textBeforeLinkDisplayName + ' '
    }

    lastDescendant['children'] = lastDescendant['children'].concat([
      {
        text: newText,
      },
      createLink(url, linkDisplayName),
    ])
  } else {
    lastDescendant['children'] = lastDescendant['children'].concat([
      createLink(url, url),
    ])
  }
}

const isUrlImage = (url: string): boolean => {
  return IMAGE_FILE_EXTENSIONS.some(
    (extension) => url?.toLowerCase().includes(extension)
  )
}

const replaceContentWithLinkIdentifier = (content: string): string => {
  return content.replace(/\[\s*https:\/\/.*?\]/g, (match) => {
    if (!isUrlImage(match))
      return match.replace(/\[\s*/, '[' + CUSTOM_LINK_PREFIX)
    return match
  })
}

export const convertContentToSlateCustomElements = (
  content?: string
): Descendant[] => {
  const slateContent: Descendant[] = []
  if (!content) return [createParagraph('')]
  const contentWithLinkIdentifier = replaceContentWithLinkIdentifier(content) // Replace links [https://...] with [uniqueLinkPrefix https://...]. URL with image extensions are excluded.
  const linesArr = contentWithLinkIdentifier.split(/\n/) // Split text content by newline. Each split line will be processed into one slate component to retain structure.
  linesArr.forEach((line) => {
    let innerLineArr: string[] = []
    // further split line by [https://...], [UniqueLinkPrefix...], [data:image...]
    const regex = new RegExp(
      `\\[\\s*(https:\\/\\/.*?|${CUSTOM_LINK_PREFIX}.*?|data:image.*?)\\s*\\]`
    )
    if (!line) innerLineArr = ['']
    else innerLineArr = line.split(regex).filter((text) => text !== '')

    const slateComponentInLine = innerLineArr.reduce(
      (acc: Descendant[], text: string) => {
        // IMAGES (url and uploaded)
        if (isUrlImage(text) || text.startsWith('data:image')) {
          acc.push(createImage(text))
        }
        // LINKS
        else if (text.startsWith(CUSTOM_LINK_PREFIX)) {
          text = text.slice(CUSTOM_LINK_PREFIX.length) // remove first unique link prefix
          handleLinkText(acc, text)
        }
        // PARAGRAPH
        else {
          const lastDescendant = acc[acc.length - 1]
          const lastChild =
            lastDescendant && lastDescendant['children']
              ? lastDescendant['children'][
                  lastDescendant['children'].length - 1
                ]
              : undefined
          // append the next text as text into the previous paragraph if the last child is a link so that it stay within same line
          if (lastChild && lastChild.type === SlateTextTypes.LINK) {
            lastDescendant['children'].push({ text })
          } else {
            acc.push(createParagraph(text))
          }
        }
        return acc
      },
      []
    )
    slateContent.push(...slateComponentInLine)
  })
  return slateContent
}

export const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      (n as CustomElement).type === SlateTextTypes.LINK,
  })
}

export const wrapLink = ({
  editor,
  url,
  text,
}: {
  editor: Editor
  url: string
  text: string
}) => {
  const link: LinkElement = {
    type: SlateTextTypes.LINK,
    url,
    children: [{ text }],
  }

  Transforms.insertNodes(editor, link)
}

export const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      n.type === SlateTextTypes.LINK,
  })
  return !!link
}

export const updateLink = ({
  editor,
  newUrl,
  newText,
}: {
  editor: Editor
  newUrl: string
  newText: string
}) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => (n as CustomElement).type === SlateTextTypes.LINK,
  })
  const [, path] = link

  Transforms.setNodes(
    editor,
    {
      url: newUrl,
    },
    { at: path }
  )
  // text cannot be handled by setNodes
  // path.concat(0) points the text to the first text node, slate uses an array pair to identify the text node (e.g [paths,0] -> first text node, [paths,1]-> second text node)
  Transforms.delete(editor, { at: path.concat(0) })
  Transforms.insertText(editor, newText, { at: path.concat(0) })
}

export const getLinkData = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => (n as CustomElement).type === SlateTextTypes.LINK,
  })
  const linkObject = link[0] as LinkElement
  const url = linkObject.url ?? ''
  const text = linkObject.children.map((child) => child.text).join('') ?? ''
  return { url, text }
}

export const getSlateFirstImage = (slateContent: Descendant[]) => {
  return (
    slateContent?.find((node) => {
      const customNode = node as ImageElement
      return customNode.type === 'image'
    }) as ImageElement
  )?.url
}

export const convertExtractedContentToSlateCustomElements = (
  content: TextractContent[]
): Descendant[] => {
  if (isEmpty(content)) {
    return [createParagraph('')]
  }

  return content.map((text) => {
    if (text.LAYOUT_FIGURE && text.LAYOUT_FIGURE.length > 0) {
      return createImage(text.LAYOUT_FIGURE.join(''))
    }
    if (text.LAYOUT_HEADER && text.LAYOUT_HEADER.length > 0) {
      return createBoldedParagraph(text.LAYOUT_HEADER.join(' '))
    }
    if (text.LAYOUT_SECTION_HEADER && text.LAYOUT_SECTION_HEADER.length > 0) {
      return createBoldedParagraph(
        '\n' + text.LAYOUT_SECTION_HEADER.join(' ') + '\n'
      )
    }
    if (text.LAYOUT_TEXT && text.LAYOUT_TEXT.length > 0) {
      return createParagraph(text.LAYOUT_TEXT.join(' '))
    } else {
      return createParagraph('')
    }
  })
}
