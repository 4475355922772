import { EmblaOptionsType } from 'embla-carousel'
import { DotButton } from './DotButton'
import { useDotButton } from './useDotButton'
import { PrevButton, NextButton } from './ArrowButton'
import { usePrevNextButtons } from './usePrevNextButton'
import useEmblaCarousel from 'embla-carousel-react'
import {
  Center,
  Flex,
  Stack,
  SystemStyleObject,
  VStack,
} from '@chakra-ui/react'

type EmblaCarouselProps = {
  children: React.ReactNode
  options?: EmblaOptionsType
  slideStyle?: SystemStyleObject
}

const EmblaCarousel = ({
  children,
  options,
  slideStyle,
}: EmblaCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi)

  return (
    <VStack margin="auto" w="100%">
      <Flex overflow="hidden" ref={emblaRef} w="100%">
        <Flex
          w="100%"
          sx={{
            backfaceVisibility: 'hidden',
            touchAction: 'pan-y pinch-zoom',
          }}
          gap={slideStyle?.gap?.toString() ?? '0.5rem'}
        >
          {children}
        </Flex>
      </Flex>
      <Center>
        <Stack width="60vw">
          <Flex justifyContent="space-between">
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
            <Flex columnGap="0.5rem" alignContent="center" alignItems="center">
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  isSelected={index === selectedIndex}
                />
              ))}
            </Flex>

            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </Flex>
        </Stack>
      </Center>
    </VStack>
  )
}

export default EmblaCarousel
