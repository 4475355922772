import { RightSideBar } from '@/components/sidebar/RightSidebar'
import { useDocumentIdParam } from '@/hooks/params/useDocumentIdParam'
import { useGetDocument } from '@/hooks/useDocument'
import { Center, Divider, Flex, Spinner, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { DocumentContent } from '@/features/document/DocumentContent'
import { NavBarHeader } from '@/components/header'
import { isEmpty } from 'lodash'
import { MobileDocumentPage } from './Mobile/MobileDocumentPage'
import { useIsMobile } from '@/hooks'
import { RelatedArticlesList } from '@/components/RelatedArticlesList'

export const DocumentPage = () => {
  const isMobile = useIsMobile()
  const { documentId } = useDocumentIdParam()
  const { document, isDocumentLoading, isMaxRetriesExceeded } = useGetDocument(
    `${documentId}`
  )

  const [isTranslateToVernacular, setIsTranslateToVernacular] = useState(false)

  if (isDocumentLoading && !document) {
    return (
      <Center height="100vh">
        <Spinner />
      </Center>
    )
  }
  if (!document) {
    return <Navigate to="/" />
  }

  return isMobile ? (
    <MobileDocumentPage
      isMaxRetriesExceeded={isMaxRetriesExceeded}
      document={document}
      isTranslateToVernacular={isTranslateToVernacular}
      setIsTranslateToVernacular={setIsTranslateToVernacular}
      isVernacular={!isEmpty(document.vernacular)}
    />
  ) : (
    <Flex justifyContent="space-between" w="100%" bg="gray.300">
      <VStack w="80%" flex="1" backgroundColor="background.default">
        <NavBarHeader hasLogo />
        <VStack
          w="100%"
          pl="4.25rem"
          pr="7.5rem"
          pt="2.375rem"
          pb="1.5rem"
          align="left"
          spacing="1.75rem"
        >
          <DocumentContent
            isMaxRetriesExceeded={isMaxRetriesExceeded}
            document={document}
            isTranslateToVernacular={isTranslateToVernacular}
          />
          <Divider w="100%" borderColor="black" />
          <RelatedArticlesList />
        </VStack>
      </VStack>
      <RightSideBar
        document={document}
        setIsTranslateToVernacular={setIsTranslateToVernacular}
        isTranslateToVernacular={isTranslateToVernacular}
        isVernacular={!isEmpty(document.vernacular)}
      />
    </Flex>
  )
}
