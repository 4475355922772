import { IMAGE_FILE_EXTENSIONS } from '~shared/constants/extensions'
import { DocumentCardSource } from '@/features/document/DocumentCardSource'
import {
  Link,
  Text,
  ComponentDefaultProps,
  Image,
  VStack,
} from '@chakra-ui/react'

type ProcessedTextProps = {
  content: string
  isHideImage?: boolean
  sourceName?: string
  sourceLink?: string
  style?: ComponentDefaultProps
}

export const ProcessedText = ({
  content,
  isHideImage,
  sourceName,
  sourceLink,
  style,
}: ProcessedTextProps) => {
  const regex = /\[(https?:\/\/[^\]]+)]/
  const parts = content.split(regex)
  const processedContent = parts.map((part, index) => {
    if (index % 2 === 1) {
      const previousPart = parts[index - 1]
      const lastWord = previousPart.trim().split(/\s+/).pop()
      const previousPartWithoutLastWord = previousPart
        .trim()
        .slice(0, -(lastWord?.length ?? 0))
      return (
        <Text
          display="inline"
          key={[part, index.toString()].join()}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {previousPartWithoutLastWord}
          {!isHideImage &&
          !!IMAGE_FILE_EXTENSIONS.find(
            (extension) => part?.toLowerCase().includes(extension)
          ) ? (
            <>
              {lastWord + ' '}
              <Image color="teal.500" key={part} src={part} />
            </>
          ) : (
            <Link color="teal.500" key={part} href={part}>
              {lastWord + ' '}
            </Link>
          )}
        </Text>
      )
    } else if (index === parts.length - 1) {
      return (
        <Text
          onClick={(e) => {
            e.stopPropagation()
          }}
          display="inline"
          key={[part, index.toString()].join()}
        >
          {part}
        </Text>
      )
    }
  })

  return (
    <VStack
      align="left"
      whiteSpace="pre-wrap"
      fontSize="1rem"
      cursor="text"
      {...style}
    >
      {sourceLink && (
        <DocumentCardSource sourceName={sourceName} sourceLink={sourceLink} />
      )}
      {processedContent}
    </VStack>
  )
}
