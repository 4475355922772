import {
  Button,
  HStack,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import Select from 'react-select'
import { CategoryType } from '~shared/constants'
import * as Yup from 'yup'
import { zIndices } from '@/theme/z-index'
import { COUNTRY_REGION_TEXT_LABEL } from '@/constants/title'

type CreateTagModalProps = {
  isOpen: boolean
  onClose: () => void
  setTagName: React.Dispatch<React.SetStateAction<string>>
  addTagToDocument: ({ displayName, category }) => void
  isLoading?: boolean
  tagName?: string
}

const CreateTagSchema = Yup.object().shape({
  // todo: check if tag exist also
  displayName: Yup.string().trim().required('Name is Required'),
  category: Yup.string().trim().required('Content is Required'),
})

export const CreateTagModal = ({
  isOpen,
  onClose,
  setTagName,
  addTagToDocument,
  isLoading,
  tagName,
}: CreateTagModalProps) => {
  const options = Object.keys(CategoryType).map((key) => {
    return {
      value: CategoryType[key],
      label:
        CategoryType[key] === CategoryType.Country
          ? COUNTRY_REGION_TEXT_LABEL // manually adjust to display to "Country / Region" instead of "Country" as defined in category type
          : CategoryType[key],
    }
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setTagName('')
        onClose()
      }}
      isCentered
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent padding="24px">
        <ModalHeader px="0px" pt="0px" pb="16px">
          <Text>
            {'Create new tag '}
            <Text
              as="span"
              color="orange.500"
              fontSize="lg"
              lineHeight="7"
              fontWeight="bold"
            >
              "{tagName}"
            </Text>
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="0px" w="100%">
          <Formik
            initialValues={{ displayName: tagName ?? '', category: '' }}
            onSubmit={async ({ displayName, category }) => {
              addTagToDocument({ displayName, category })
              setTagName('')
              onClose()
            }}
            validationSchema={CreateTagSchema}
          >
            {(props) => (
              <Form>
                <HStack
                  justify="space-between"
                  spacing="16px"
                  w="100%"
                  h="100%"
                >
                  <Field name="category">
                    {({ field }) => (
                      <InputGroup
                        borderRadius="20px"
                        background="white"
                        w="100%"
                        zIndex={zIndices.modal}
                      >
                        <VStack h="100%" w="100%" align="left" spacing="16px">
                          <Text>Select a category for this new tag below.</Text>
                          <Select
                            placeholder="Click to select category"
                            onChange={(option) => {
                              if (option) {
                                props.setFieldValue(field.name, option.value)
                              }
                            }}
                            options={options}
                            styles={{
                              indicatorSeparator: () => ({ display: 'none' }),
                              control: (defaultStyle) => ({
                                ...defaultStyle,
                                borderRadius: '20px',
                              }),
                              option: (defaultStyles, { isFocused }) => ({
                                ...defaultStyles,
                                backgroundColor: isFocused
                                  ? '#FEEBCB'
                                  : 'parent',
                                color: isFocused ? '#DD6B20' : 'parent',
                                ':active': {
                                  ...defaultStyles[':active'],
                                  backgroundColor: '#FEEBCB',
                                  color: '#DD6B20',
                                },
                              }),
                            }}
                          ></Select>
                        </VStack>
                      </InputGroup>
                    )}
                  </Field>
                </HStack>
                <HStack justify="end" w="100%" pt="16px" spacing="12px">
                  <Button
                    w="10%"
                    fontSize="16px"
                    fontWeight="semibold"
                    size="lg"
                    bg="gray.100"
                    borderRadius="20px"
                    color="gray.800"
                    onClick={onClose}
                    isDisabled={isLoading}
                  >
                    Back
                  </Button>
                  <Button
                    w="20%"
                    fontSize="16px"
                    fontWeight="semibold"
                    size="lg"
                    bg={
                      props.values.displayName && props.values.category
                        ? 'green.100'
                        : 'gray.100'
                    }
                    color={
                      props.values.displayName && props.values.category
                        ? 'green.800'
                        : 'gray.800'
                    }
                    borderRadius="20px"
                    type="submit"
                    isDisabled={
                      !(props.values.displayName && props.values.category)
                    }
                    isLoading={isLoading}
                  >
                    Save New Tag
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
