import { UseDisclosureReturn } from '@chakra-ui/react'
import { createContext, useContext } from 'react'
import { CollectionInSearchResults, SelectedCollection } from '~shared/dtos'

type BookmarkContextProps = {
  documentId: number
  documentTitle: string
  disclosureProps: UseDisclosureReturn
  selectedCollections: SelectedCollection[]
  setSelectedCollections: React.Dispatch<
    React.SetStateAction<SelectedCollection[]>
  >
  selectedCollectionIds: Set<string>
  newCollectionIds: Set<string>
  setNewCollectionIds: React.Dispatch<React.SetStateAction<Set<string>>>
  documentCollections: CollectionInSearchResults[]
  collectionText?: string
}

export const BookmarkContext = createContext<BookmarkContextProps | undefined>(
  undefined
)

export const useBookmarkContext = () => {
  const context = useContext(BookmarkContext)
  if (!context) {
    throw new Error(
      `BookmarkContext must be used within a BookmarkContext component`
    )
  }
  return context
}
