import { AiOutlineFullscreen } from 'react-icons/ai'
import { DocumentCardButton } from './DocumentCardButton'

type ExpandButtonProps = {
  onClick: (e) => void
  show: boolean
}

export const ExpandButton = ({ onClick, show }: ExpandButtonProps) => {
  return (
    <DocumentCardButton
      icon={<AiOutlineFullscreen size="1.5rem" />}
      onClick={onClick}
      label={show ? 'Compress Article' : 'Expand Article'}
    />
  )
}
