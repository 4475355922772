import { AbsoluteCenter, Box, MenuDivider, Text } from '@chakra-ui/react'

type MenuTitleWithDividerProps = {
  name: string
}

export const MenuTitleWithDivider = ({ name }: MenuTitleWithDividerProps) => {
  return (
    <Box
      position="relative"
      w="100%"
      p="0"
      alignItems="center"
      justifyItems="center"
    >
      <MenuDivider />
      <AbsoluteCenter
        bg="white"
        w="fit-content"
        textAlign="center"
        px="0.25rem"
      >
        <Text
          whiteSpace="nowrap"
          fontSize="0.875rem"
          fontWeight="semibold"
          color="gray.600"
        >
          {name}
        </Text>
      </AbsoluteCenter>
    </Box>
  )
}
