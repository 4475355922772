import { Button, ButtonProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

type SidebarButtonProps = {
  children: ReactNode
  onClick: (e) => void
  style?: ButtonProps
  isHighlightedButton?: boolean
}

export const SidebarButton = ({
  isHighlightedButton,
  onClick,
  style,
  children,
}: SidebarButtonProps) => {
  return (
    <Button
      marginInlineStart="0 !important"
      {...style}
      textAlign="left"
      w="95%"
      bgColor={isHighlightedButton ? 'gray.700' : ''}
      display="block"
      justifyContent="flex-start"
      textColor="white"
      onClick={onClick}
      role="group"
      bg="parent"
      fontWeight="light"
      px="0.5rem"
      borderRadius="0.25rem"
    >
      <div>{children}</div>
    </Button>
  )
}
