export const PRESSUM_TAG_DEFAULT_NAME = 'Pressum'; // this is the default tag name for offical pressum. in the event if this changes, note to add guards to restrict additional of tag names for outgoing official pressum tag

export const TAG_NAME_CONTAINS_COLON_MEESAGE = `Tag name cannot have ":"`;

export const TAG_NAME_CONTAINS_PRESSUM_MESSAGE = `Tag name cannot start with "${PRESSUM_TAG_DEFAULT_NAME}"`;

export const TAG_NAME_EXISTS_MESSAGE = 'Tag already exist';

// order for categorytype determines the order in which the tags are displayed
// currently we display it according to what we think is important for the users
export enum CategoryType {
  Source = 'Source',
  Country = 'Country',
  Asset = 'Asset',
  Event = 'Event',
  Miscellaneous = 'Miscellaneous',
}
