import {
  Button,
  Center,
  HStack,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { AiOutlineTeam } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'
import { CollabModal } from './CollabModal'
import { useIsMobile } from '@/hooks'
import { CollabDrawer } from './CollabDrawer'
import { useCollabContext } from './useCollabContext'
import { ManageCollectionSettingsButton } from '../ManageCollectionSettingsButton'
import { useGetCollectionIdFromSearchParams } from '@/hooks/params/useSearchParams'
import { FiUser } from 'react-icons/fi'
import { useMemo } from 'react'

type CollabButtonProps = {
  name: string
}

export const CollabButton = ({ name }: CollabButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useIsMobile()
  const { collabUsers, isUserCollectionsLoading, isManage } = useCollabContext()
  const { collectionId } = useGetCollectionIdFromSearchParams()

  const numOfCollaborators = collabUsers?.length ?? 0

  const collabButtonText = useMemo(() => {
    if (isMobile) {
      return numOfCollaborators
    }
    if (numOfCollaborators === 1) {
      return 'Only you'
    } else {
      return `${numOfCollaborators} Collaborators`
    }
  }, [isMobile, numOfCollaborators])

  if (isUserCollectionsLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <HStack>
      <Button
        px="0.5rem"
        w="fit-content"
        borderWidth="0.0625rem"
        bg="parent"
        leftIcon={numOfCollaborators === 1 ? <FiUser /> : <AiOutlineTeam />}
        rightIcon={<BiEdit />}
        iconSpacing={{ base: '0.25rem', lg: '0.5rem' }}
        borderRadius="1.25rem"
        height={{ lg: '1.5rem', base: '1.75rem' }}
        fontSize="0.875rem"
        onClick={onOpen}
        _hover={{
          bg: 'brand.primary.100',
          color: 'brand.primary.500',
        }}
        borderColor="brand.primary.500"
        color="brand.primary.500"
      >
        {collabButtonText}
      </Button>
      {isMobile ? (
        <CollabDrawer name={name} isOpen={isOpen} onClose={onClose} />
      ) : (
        <CollabModal name={name} isOpen={isOpen} onClose={onClose} />
      )}
      {isManage && (
        <ManageCollectionSettingsButton
          collectionId={collectionId ?? NaN}
          collectionName={name}
        />
      )}
    </HStack>
  )
}
