import { CollectionRes } from '~shared/dtos'
import { useSearchParams } from 'react-router-dom'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { searchFilterParam } from '@/constants/search-params'
import {
  Badge,
  Flex,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Select, {
  components,
  GroupBase,
  ValueContainerProps,
} from 'react-select'
import { FiPlusCircle } from 'react-icons/fi'
import { MobileCreateCollectionDrawer } from '../../features/collections/create/CreateCollectionDrawer'
import { CollectionRow } from '@/features/collections/CollectionRow'
import { CollectionIcon } from '@/components/icons/CollectionIcon'
import { useCollabContext } from '@/features/collections/collab/useCollabContext'
import { DEFAULT_COLLECTION } from '~shared/constants'
import { useGetAccessiblePublicationsFromContext } from '@/hooks'
import { DOCUMENT_INDEX_TO_NAME } from '@/constants/document'
import { All_ARTICLES } from '@/constants/title'
import {
  useCheckIsSearchAllArticles,
  useGetCollectionIdFromSearchParams,
  useGetIndexFromSearchParams,
} from '@/hooks/params/useSearchParams'

export type Option = {
  label: string
  value: number | string
  meta: {
    documentCount?: number
    userCount?: number
  }
}

const Option = (props) => {
  return (
    <components.Option {...props}>
      <CollectionRow
        name={props.data.label}
        value={props.data.value}
        documentCount={props.data.meta.documentCount}
        userCount={props.data.meta.userCount}
        styles={{
          padding: '0',
        }}
      />
    </components.Option>
  )
}

const MenuList = ({ props, onOpen }) => {
  return (
    <components.MenuList {...props}>
      <HStack py="0" px="1rem" w="100%" spacing="0.5rem" onClick={onOpen}>
        <Icon as={FiPlusCircle} size="1.25rem" />
        <Text fontStyle="italic" fontSize="1rem">
          Add new collection
        </Text>
      </HStack>
      <VStack
        spacing="1rem"
        padding="1rem"
        align="left"
        justify="left"
        overflow="auto"
        maxH="16rem"
      >
        {props.children}
      </VStack>
    </components.MenuList>
  )
}

export type MobileCollectionTitleDropdownProps = {
  collections?: CollectionRes[]
}

const getMenuList = (onOpen) => (props) => (
  <MenuList props={props} onOpen={onOpen} />
)

export const MobileCollectionTitleDropdown = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [selected, setSelected] = useState<Option | undefined>()
  const { collection, collections } = useDocumentListContext()
  const { publications } = useGetAccessiblePublicationsFromContext()
  const { isManage } = useCollabContext()
  const { isSearchAllArticles } = useCheckIsSearchAllArticles()

  const isDefaultCollection =
    collection && collection.name === DEFAULT_COLLECTION

  const [searchParams, setSearchParams] = useSearchParams()

  const {
    collectionIds: collectionIdsSearchParam,
    indices: indicesParam,
    tags: tagsParam,
    date: { startDate: startDateParam, endDate: endDateParam },
    query: queryParam,
    sort: sortParam,
  } = searchFilterParam

  const { collectionId, collectionIds } = useGetCollectionIdFromSearchParams()
  const { index } = useGetIndexFromSearchParams()

  const indices = searchParams.getAll(indicesParam)
  const length = useMemo(
    () => collectionIds.length + indices.length,
    [collectionIds.length, indices.length]
  )

  const deleteAllUrlParams = useCallback(
    (params: URLSearchParams) => {
      params.delete(startDateParam)
      params.delete(endDateParam)
      params.delete(sortParam)
      params.delete(queryParam)
      params.delete(tagsParam)
      params.delete(indicesParam)
      params.delete(collectionIdsSearchParam)
    },
    [
      collectionIdsSearchParam,
      endDateParam,
      indicesParam,
      queryParam,
      sortParam,
      startDateParam,
      tagsParam,
    ]
  )

  const options = useMemo(() => {
    let options: Option[] = [
      {
        value: '',
        label: All_ARTICLES,
        meta: {},
      },
    ]
    options = options.concat(
      publications.map((publication) => {
        return {
          value: publication,
          label: DOCUMENT_INDEX_TO_NAME[publication],
          meta: {},
        }
      })
    )

    if (collections)
      options = options.concat(
        collections.map((collection) => {
          return {
            value: collection.collectionId,
            label: collection.name,
            meta: {
              documentCount: collection.documentCount,
              userCount: collection.userCount,
            },
          }
        })
      )
    return options
  }, [collections, publications])

  useEffect(() => {
    const isNoneSelected = collectionIds.length === 0 && indices.length === 0
    if (isSearchAllArticles || isNoneSelected) {
      setSelected(
        options.find((option) => option.label === All_ARTICLES) ?? undefined
      )
      return
    }
    if (length === 1) {
      if (collectionId) {
        setSelected(
          options.find((option) => option.value === collectionId) ?? undefined
        )
        return
      }
      if (index) {
        setSelected(
          options.find((option) => option.value === index) ?? undefined
        )
        return
      }
    }
  }, [
    collectionId,
    collectionIds.length,
    index,
    indices.length,
    isSearchAllArticles,
    length,
    options,
    searchParams,
  ])

  const handleChange = (selectedOption: Option) => {
    deleteAllUrlParams(searchParams)

    if (selectedOption.label !== All_ARTICLES) {
      if (publications.includes(`${selectedOption.value}`)) {
        searchParams.set(indicesParam, `${selectedOption.value}`)
      } else {
        searchParams.set(collectionIdsSearchParam, `${selectedOption.value}`)
      }
    }

    setSearchParams(searchParams)
  }

  // todo(ben): refactor again
  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<Option, boolean, GroupBase<Option>>) => {
    return (
      <components.ValueContainer {...props}>
        <HStack
          py="0.125rem"
          justifyContent={{ base: 'space-between', lg: 'flex-start' }}
          w="100%"
          fontSize="0.875rem"
          fontWeight="semibold"
          color="white"
          isTruncated
        >
          {length <= 1 && selected ? (
            <>
              <CollectionIcon
                name={selected.label}
                userCount={selected.meta.userCount}
                value={selected.value}
              />
              <Flex w="100%" isTruncated>
                {children}
              </Flex>
              {selected.meta.documentCount && (
                <Badge borderRadius="1.25rem" bg="black" color="white">
                  {selected.meta.documentCount}
                </Badge>
              )}
            </>
          ) : (
            <Text>{`Searching in ${length} collections`}</Text>
          )}
        </HStack>
      </components.ValueContainer>
    )
  }

  return (
    <Flex w="100%">
      <Select
        isMulti={false}
        isSearchable={false}
        components={{
          ValueContainer,
          MenuList: getMenuList(onOpen),
          Option,
        }}
        styles={{
          container: (defaultStyles) => ({
            ...defaultStyles,
            width:
              !isDefaultCollection && collection && isManage ? '100%' : '70%',
          }),
          menuList: (defaultStyles) => ({
            ...defaultStyles,
            width: '100%',
            overflow: 'hidden',
            padding: '0',
          }),
          indicatorsContainer: (defaultStyles) => ({
            ...defaultStyles,
            paddingRight: '0.75rem',
          }),
          dropdownIndicator: (defaultStyles) => ({
            ...defaultStyles,
            width: '0.875rem',
            padding: '0.05rem',
            color: '#F7FAFC',
          }),
          valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            padding: '0',
            paddingLeft: '1rem',
          }),
          menu: (provided) => ({
            ...provided,
            width: 'calc(100vw - 3rem)',
            padding: '1rem',
            borderRadius: '0.5rem',
          }),
          option: (defaultStyles, { isFocused }) => ({
            ...defaultStyles,
            alignSelf: 'left',
            justifyContent: 'left',
            display: 'flex',
            padding: '0',
            backgroundColor: 'parent',
            color: isFocused ? 'orange' : 'parent',
            fontWeight: 'normal',
          }),
          singleValue: (defaultStyles) => ({
            ...defaultStyles,
            color: '#EDF2F7',
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          control: (defaultStyles) => {
            return {
              ...defaultStyles,
              height: '2.25rem',
              background: '#2D3748',
              borderRadius: '0.375rem',
              borderColor: 'transparent',
            }
          },
        }}
        options={options}
        value={selected}
        onChange={(option) => {
          if (option) {
            handleChange(option)
          }
        }}
      />
      <MobileCreateCollectionDrawer isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
