import { createAndInsertTag } from '@/api/documentTag'
import { queryKeys } from '@/constants/query-key'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CreateAndInsertTagReq, DocumentTagWithCategory } from '~shared/dtos'
import { useSearchTags } from './useSearch'
import { PRESSUM_TAG_DEFAULT_NAME } from '~shared/constants'

// creates a new tag in the tag entity (if does not exist) and insert it into a document
export const useCreateAndInsertTag = (documentId: number) => {
  const queryClient = useQueryClient()

  const {
    mutateAsync,
    isLoading: isCreateAndInsertTagLoading,
    isError: isCreateAndInsertTagError,
  } = useMutation({
    mutationFn: async (payload: CreateAndInsertTagReq) => {
      const data = await createAndInsertTag({ documentId, payload })
      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.tags())
    },
  })

  return {
    createAndInsertTag: mutateAsync,
    isCreateAndInsertTagLoading,
    isCreateAndInsertTagError,
  }
}

export const useSearchFilteredTag = ({
  query,
  documentTags,
}: {
  query: string
  documentTags?: DocumentTagWithCategory[]
}) => {
  const { data, fetchNextPage, hasNextPage, isSearchTagsLoading } =
    useSearchTags({
      query,
    })

  const allTags = data?.pages.reduce((arr, { data }) => {
    return arr.concat(data)
  }, [] as DocumentTagWithCategory[])
  const documentTagSet = new Set(
    documentTags?.map(({ displayName }) => displayName)
  )
  const filteredTags = allTags?.filter(
    (tag) =>
      !documentTagSet.has(tag.displayName) &&
      !tag.displayName.startsWith(PRESSUM_TAG_DEFAULT_NAME)
  )

  return {
    filteredTags: filteredTags ?? [],
    allTags: allTags ?? [],
    fetchNextPage,
    hasNextPage,
    isSearchTagsLoading,
  }
}
