import { USER_NOT_AUTHENTICATED_EVENT } from '@/constants/event'
import axios, { AxiosError } from 'axios'
import { AUTHENTICATION_MESSAGE } from '~shared/constants'

export const ApiService = axios.create({
  baseURL: '/api/v1',
  maxContentLength: 5 * 1024 * 1024,
})

// can transform this to take what is thrown from the backend to output for user
ApiService.interceptors.response.use(
  (response) => response,
  (error: AxiosError<{ message: string }>) => {
    const message = error.response?.data?.message
    if (message === AUTHENTICATION_MESSAGE) {
      window.dispatchEvent(new Event(USER_NOT_AUTHENTICATED_EVENT))
    }
    throw error
  }
)
