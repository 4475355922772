import { useSendLoginOtp } from '@/hooks/useAuth'
import { Button, useInterval } from '@chakra-ui/react'
import { useAuthContext } from '../useAuthContext'
import { SHORT_HAND_EMAIL_ERROR } from '@/constants/error'
import { useToast } from '@/hooks/useToast'

export const ResendOtpButton = () => {
  const { timer, setTimer, email, setOtpError, setEmail } = useAuthContext()
  const { sendLoginOtp, isSendLoginOtpLoading } = useSendLoginOtp()
  const toast = useToast()

  useInterval(
    () => setTimer(timer - 1),
    // Stop interval if timer hits 0
    timer > 0 ? 1000 : null
  )
  return (
    <Button
      w={{ base: '100%', lg: 'auto' }}
      mt="0.5rem"
      borderRadius="1.5rem"
      fontWeight={{ base: 'normal', lg: 'semi-bold' }}
      bg={{ lg: 'gray.100', base: 'parent' }}
      color="black"
      isDisabled={timer !== 0}
      isLoading={isSendLoginOtpLoading}
      onClick={async () => {
        await sendLoginOtp(
          { email },
          {
            onSuccess: () => setTimer(60),
            onError: () => {
              setOtpError(SHORT_HAND_EMAIL_ERROR)
              toast({
                description: 'Failed to resend OTP. Please try again later',
                status: 'error',
                duration: 3000,
                isClosable: true,
              })
              setEmail('')
            },
          }
        )
      }}
    >
      {timer > 0 ? `Resend OTP in ${timer}s` : `Resend OTP`}
    </Button>
  )
}
