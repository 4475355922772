import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Flex,
  AccordionIcon,
  AccordionPanel,
  Text,
  SkeletonText,
  VStack,
} from '@chakra-ui/react'
import { SummaryPointsList } from '../SummaryPointsList'

type MobileDocumentSummaryProps = {
  isMaxRetriesExceeded: boolean
  summary?: string
}

export const MobileDocumentSummary = ({
  summary,
  isMaxRetriesExceeded,
}: MobileDocumentSummaryProps) => {
  const renderContent = () => {
    if (summary) {
      return (
        <Accordion allowToggle borderColor="transparent">
          <AccordionItem>
            <AccordionButton
              px="1rem"
              bg="gray.100"
              borderRadius="0.5rem"
              _expanded={{
                borderBottomRadius: '0',
              }}
            >
              <Flex
                as="span"
                flex={1}
                fontSize="1rem"
                fontWeight="700"
                textAlign="left"
              >
                Summary
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel bg="gray.100" borderBottomRadius="0.5rem">
              <SummaryPointsList
                summary={summary}
                textStyles={{
                  fontSize: '0.875rem',
                }}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )
    } else if (isMaxRetriesExceeded) {
      return (
        <Flex p="1rem" bg="gray.100" borderRadius="0.5rem">
          <Text fontSize="0.875rem">No summary generated</Text>
        </Flex>
      )
    } else {
      return (
        <VStack
          align="left"
          p="1rem"
          bg="gray.100"
          borderRadius="0.5rem"
          w="100%"
          spacing="0.5rem"
        >
          <Text fontSize="0.875rem">Generating summary...</Text>
          <SkeletonText w="100%" noOfLines={2} skeletonHeight="2" />
        </VStack>
      )
    }
  }

  return renderContent()
}
