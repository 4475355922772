import { useDisclosure, Text, VStack, Tag } from '@chakra-ui/react'
import { useBookmarkContext } from '../useBookmarkContext'
import { BiBookBookmark } from 'react-icons/bi'
import { BookmarkIconButtonList } from './BookmarkIconButtonList'
import { AddBookmarkActions } from './AddBookmarkActions'
import { SaveChangesDrawer } from './SaveChangesDrawer'
import { BOOKMARK_HEADER } from '~shared/constants'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { DrawerLayout } from '@/components/drawer/DrawerLayout'

export const AddBookmarkDrawer = () => {
  const {
    disclosureProps: { isOpen, onClose },
    documentTitle,
    selectedCollectionIds,
    setSelectedCollections,
    collectionText,
  } = useBookmarkContext()

  const {
    isOpen: isConfirmOpen,
    onClose: onConfirmClose,
    onOpen: onConfirmOpen,
  } = useDisclosure()

  useEffect(() => {
    if (isOpen) {
      // on open always reset selected collections
      setSelectedCollections([])
    }
  }, [isOpen, setSelectedCollections])

  return (
    <DrawerLayout
      title={BOOKMARK_HEADER}
      isOpen={isOpen}
      onClose={isEmpty(selectedCollectionIds) ? onClose : onConfirmOpen}
      HeaderIcon={BiBookBookmark}
    >
      {collectionText && (
        <Tag bg="orange.500" color="white" w="fit-content">
          {collectionText}
        </Tag>
      )}
      <VStack align="left" w="100%">
        <Text fontSize="0.875rem" noOfLines={2}>{`"${documentTitle}"`}</Text>
        <BookmarkIconButtonList />
      </VStack>
      <AddBookmarkActions onClose={onClose} />
      <SaveChangesDrawer onClose={onConfirmClose} isOpen={isConfirmOpen} />
    </DrawerLayout>
  )
}
