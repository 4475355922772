import { Center, Spinner, Text, VStack } from '@chakra-ui/react'
import { TagResultList } from './TagResultList'
import { DocumentTagWithCategory } from '~shared/dtos'
import { useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useGetTagsInCategory } from '@/hooks'
import { DatePicker } from '../datepicker'
import { useFilterTagContext } from './useFilterTagContext'
import { FILTER_DATE } from '@/utils/date'
import { COUNTRY_REGION_TEXT_LABEL } from '@/constants/title'
import { CategoryType } from '~shared/constants'

export const FilterTagCategorySearch = () => {
  const { selectedTags, selectedCategory, setSelectedDate, selectedDate } =
    useFilterTagContext()
  const { data, isGetTagsInCategoryLoading, fetchNextPage, hasNextPage } =
    useGetTagsInCategory({
      category: selectedCategory === FILTER_DATE ? '' : selectedCategory,
    })

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  const allTags = useMemo(() => {
    const allTags = data?.pages.reduce((arr, { data }) => {
      return arr.concat(data)
    }, [] as DocumentTagWithCategory[])
    return allTags ?? []
  }, [data])

  const documentTagSet = useMemo(
    () => new Set(selectedTags?.map(({ displayName }) => displayName)),
    [selectedTags]
  )

  const checkboxTags = useMemo(
    () =>
      allTags?.map((tag) => {
        return {
          ...tag,
          isChecked: documentTagSet.has(tag.displayName),
        }
      }),
    [allTags, documentTagSet]
  )

  const isDateCategory = selectedCategory === FILTER_DATE

  const renderFilterComponent = () => {
    if (isDateCategory) {
      return (
        <DatePicker selected={selectedDate} setSelected={setSelectedDate} />
      )
    } else {
      if (isGetTagsInCategoryLoading) {
        return (
          <Center height="100%">
            <Spinner color="gray.50" />
          </Center>
        )
      } else {
        return (
          <TagResultList tags={checkboxTags} ref={ref} hasNext={hasNextPage} />
        )
      }
    }
  }

  return (
    <VStack width="100%" align="left" height="100%">
      <Text
        color="white"
        fontWeight={700}
        fontSize="1.125rem"
        marginBottom="1rem"
      >
        {`${
          selectedCategory === CategoryType.Country
            ? COUNTRY_REGION_TEXT_LABEL
            : selectedCategory
        } selection`}
      </Text>
      <VStack
        width="100%"
        align="left"
        spacing="1rem"
        paddingLeft={isDateCategory ? '0' : '0.75rem'}
      >
        {renderFilterComponent()}
      </VStack>
    </VStack>
  )
}
