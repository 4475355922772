import { CollectionRow } from '@/features/collections/CollectionRow'
import { CreateCollectionSchema } from '@/features/collections/validator'
import { useSearchCollections } from '@/hooks'
import { CollectionOption } from '@/types/document'
import { VStack, Text, Flex, Divider } from '@chakra-ui/react'
import { FieldProps } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Select, {
  components,
  MenuListProps,
  MenuProps,
  OptionProps,
  SingleValueProps,
} from 'react-select'
import { useDebouncedCallback } from 'use-debounce'
import { ValidationError } from 'yup'
import { lowercaseAndRemoveWhitespace } from '~shared/utils'
import { EMPTY_COLLECTION_VALUE } from '../constants'

export const CollectionSelectDropdown = ({
  field,
  form,
}: FieldProps<CollectionOption>) => {
  // handle menu open/close manually since we want to close the menu when create new option
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const [validationErrMessage, setValidationErrMessage] = useState<string>('')

  // debounce searching
  const [searchText, setSearchText] = useState('')
  const [displayQuery, setDisplayQuery] = useState<string>('')
  const fetchSearchOptions = useDebouncedCallback(() => {
    setSearchText(displayQuery)
  }, 300)
  useEffect(() => {
    fetchSearchOptions()
  }, [displayQuery, fetchSearchOptions])

  // collection search
  const {
    isSearchCollectionsLoading,
    collections,
    fetchNextPage,
    hasNextPage,
  } = useSearchCollections({
    query: searchText,
  })

  // scroll fetch
  const { ref, inView } = useInView({ skip: !hasNextPage })
  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  // only allow create new option if display query is not already a collection name
  const isValidNewOption = useMemo(() => {
    const isExistingOption = collections?.some(
      (collection) =>
        lowercaseAndRemoveWhitespace(collection.label) ===
        lowercaseAndRemoveWhitespace(displayQuery)
    )
    return !isExistingOption
  }, [collections, displayQuery])

  const handleCreateNewOption = useCallback(
    async (collectionName: string) => {
      const collectionOption: CollectionOption = {
        value: -1,
        label: collectionName,
        isNew: true,
      }
      form.setFieldValue(field.name, collectionOption)
      setDisplayQuery('')
      setMenuIsOpen(false)
    },
    [field.name, form]
  )

  const handleNoOptionAndLoadingText = useCallback(() => {
    if (validationErrMessage) {
      return validationErrMessage
    }
    return COLLECTION_NOT_FOUND_MESSAGE
  }, [validationErrMessage])

  const Menu = useCallback(
    (props: MenuProps<CollectionOption>) => {
      return (
        <components.Menu {...props}>
          {props.children}
          {displayQuery && isValidNewOption && !validationErrMessage && (
            <CreateNewOption
              newOptionText={displayQuery}
              handleCreateNewOption={handleCreateNewOption}
            />
          )}
        </components.Menu>
      )
    },
    [
      displayQuery,
      isValidNewOption,
      handleCreateNewOption,
      validationErrMessage,
    ]
  )

  const MenuList = useCallback(
    (props: MenuListProps<CollectionOption>) => {
      return (
        <components.MenuList {...props}>
          {props.children}
          <VStack ref={ref} />
        </components.MenuList>
      )
    },
    [ref]
  )

  return (
    <Select
      id={field.name}
      name={field.name}
      // default value is empty string, set to null to indicate no value
      value={
        field.value === null || field.value.label === '' ? null : field.value
      }
      isClearable
      blurInputOnSelect
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      onFocus={() => setMenuIsOpen(true)}
      onBlur={() => setMenuIsOpen(false)}
      placeholder="Type to search or create a new collection"
      isLoading={isSearchCollectionsLoading}
      inputValue={displayQuery}
      options={collections}
      components={{ Menu, MenuList, Option, SingleValue }}
      styles={customStyles}
      // loading message and noOptions message are the same to handle flicker issue
      loadingMessage={handleNoOptionAndLoadingText}
      noOptionsMessage={handleNoOptionAndLoadingText}
      onChange={(option) => {
        if (option) {
          form.setFieldValue(field.name, option)
          setMenuIsOpen(false)
        } else {
          form.setFieldValue(field.name, EMPTY_COLLECTION_VALUE)
        }
      }}
      onInputChange={(inputText: string) => {
        // reset if inputText is empty string
        if (inputText === '') {
          setDisplayQuery('')
          setValidationErrMessage('')
          return
        }

        // only validate if inputText is not empty string
        CreateCollectionSchema.validate({
          collectionName: inputText,
        })
          .then(() => {
            setValidationErrMessage('')
          })
          .catch((err) => {
            if (err instanceof ValidationError) {
              setValidationErrMessage(err.message)
            }
          })
          .finally(() => {
            setDisplayQuery(inputText)
          })
      }}
    />
  )
}

const customStyles = {
  valueContainer: (defaultStyles) => ({
    ...defaultStyles,
    padding: 0,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: (defaultStyles, { isFocused }) => ({
    ...defaultStyles,
    borderRadius: '0.75rem',
    padding: '0.5rem 1rem',
    boxShadow: isFocused ? '0 0 0 0.0625rem #ED8936' : 'none',
    border: '0.125rem solid transparent', // to avoid flicker when border is added when hovered
    borderColor: 'transparent',
    ':hover': {
      border: '0.125rem dashed #ED8936',
    },
  }),
  multiValue: (defaultStyles) => ({
    ...defaultStyles,
    borderRadius: '0.375rem',
    padding: '0rem 0.75rem',
    background: '#EDF2F7',
    gap: '0.5rem', // gap between label and cross icon
  }),
  multiValueLabel: (defaultStyles) => ({
    ...defaultStyles,
    paddingLeft: 0,
    paddingRight: 0,
  }),
  multiValueRemove: (defaultStyles) => ({
    ...defaultStyles,
    paddingLeft: 0,
    paddingRight: 0,
    color: '#1A202C',
    opacity: '0.5',
    ':hover': {
      backgroundColor: 'none',
    },
  }),
}

const COLLECTION_NOT_FOUND_MESSAGE = 'No collections found'

const CreateNewOption = ({
  newOptionText,
  handleCreateNewOption,
}: {
  newOptionText: string
  handleCreateNewOption: (newOptionName: string) => Promise<void>
}) => {
  return (
    <>
      <Flex justifyContent="center">
        <Divider
          margin="0rem 0.5rem 0rem 0.5rem"
          height="1px"
          borderColor="gray.700"
          _hover={{
            bg: 'parent',
          }}
        />
      </Flex>
      <VStack
        borderRadius="0.25rem"
        padding="0.25rem 0.5rem"
        margin="0.25rem 0.5rem 0.5rem 0.5rem"
        alignItems="left"
        _hover={{
          bg: 'orange.100',
          fontWeight: 'bold',
          color: 'orange.500',
        }}
        cursor="pointer"
        onTouchStart={() => {
          handleCreateNewOption(newOptionText)
        }}
        onClick={() => {
          handleCreateNewOption(newOptionText)
        }}
      >
        <Text fontSize="0.875rem">
          {`Create new collection "${newOptionText}"`}
        </Text>
      </VStack>
    </>
  )
}

const SingleValue = ({ ...props }: SingleValueProps<CollectionOption>) => {
  return (
    <components.SingleValue {...props}>
      <CollectionRow
        documentCount={props.data.documentCount ?? 0}
        userCount={props.data.userCount ?? 0}
        name={props.data.label}
        isWhiteBg
        badgeText={props.data.isNew ? 'NEW' : undefined}
      />
    </components.SingleValue>
  )
}

const Option = (props: OptionProps<CollectionOption>) => {
  return (
    <components.Option {...props}>
      <CollectionRow
        value={props.data.value}
        documentCount={props.data.documentCount ?? 0}
        userCount={props.data.userCount}
        name={props.label}
        isWhiteBg
      />
    </components.Option>
  )
}
