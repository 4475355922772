import { HStack, IconButton, StyleProps } from '@chakra-ui/react'
import { IoArrowBack } from 'react-icons/io5'
import { BsTranslate } from 'react-icons/bs'
import { BookmarkButton } from '@/features/bookmark/BookmarkButton'
import { zIndices } from '@/theme/z-index'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { AppNavPaths } from '@/utils/paths'
import { isNewlyCreatedDocIndicator } from '@/constants/search-params'
import { BiTag, BiSolidTag } from 'react-icons/bi'
import { CollectionInSearchResults } from '~shared/dtos'
import { StarButton } from '@/features/document/button/StarButton'
import { useMemo } from 'react'
import { DEFAULT_COLLECTION } from '~shared/constants'
import { useGetCollections } from '@/hooks/useCollection'
import { useGetUserIdFromContext } from '@/hooks'

type HeaderIconButtonProps = {
  icon: JSX.Element
  label: string
  onClick?: () => void
  styles?: StyleProps
}

const HeaderIconButton = ({
  icon,
  label,
  onClick,
  styles,
}: HeaderIconButtonProps) => {
  return (
    <IconButton
      _hover={{ backgroundColor: 'transparent' }}
      background="base.canvas.default"
      aria-label={label}
      icon={icon}
      onClick={onClick}
      {...styles}
    />
  )
}

type MobileDocumentHeaderProps = {
  documentId: string
  documentTitle: string
  documentCollections: CollectionInSearchResults[]
  isTranslateToVernacular: boolean
  setIsTranslateToVernacular: React.Dispatch<React.SetStateAction<boolean>>
  isVernacular: boolean
  onOpen: () => void
  isOpen: boolean
}

export const MobileDocumentHeader = ({
  documentId,
  documentTitle,
  documentCollections,
  setIsTranslateToVernacular,
  isTranslateToVernacular,
  isVernacular,
  onOpen,
  isOpen,
}: MobileDocumentHeaderProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isHistoryExist = location.key !== 'default'
  const [searchParams] = useSearchParams()
  const isNewlyCreatedDoc = searchParams.get(
    Object.keys(isNewlyCreatedDocIndicator)[0]
  )

  const { userId } = useGetUserIdFromContext()
  const { collections } = useGetCollections(userId)
  //todo(ben): refactor with document card and documentpage right side bar
  const starredCollectionId = useMemo(() => {
    let id = NaN
    if (collections)
      id =
        collections.find((collection) => collection.name === DEFAULT_COLLECTION)
          ?.collectionId ?? NaN
    return id
  }, [collections])

  const isDocumentInStarredCollection = useMemo(() => {
    return !!documentCollections?.find(
      (collection) => collection.name === DEFAULT_COLLECTION
    )
  }, [documentCollections])

  return (
    <HStack
      justifyContent="space-between"
      columnGap="1.5rem"
      width="100%"
      position="sticky"
      top={0}
      background="white"
      py="0.75rem"
      zIndex={zIndices.navbar}
    >
      <HeaderIconButton
        label="Back button"
        icon={<IoArrowBack size="1.5rem" />}
        onClick={() => {
          // if its a new tab or if its a newly created doc
          // we don't want to go back to our edit article page for newly created doc
          if (isHistoryExist && !isNewlyCreatedDoc) {
            navigate(-1)
          } else {
            navigate(AppNavPaths.Documents)
          }
        }}
      />
      <HStack spacing="2rem">
        <StarButton
          starredCollectionId={starredCollectionId}
          documentId={+documentId}
          isDocumentInStarredCollection={isDocumentInStarredCollection}
        />

        <HeaderIconButton
          label="Tag Icon button"
          icon={isOpen ? <BiSolidTag size="1.5rem" /> : <BiTag size="1.5rem" />}
          onClick={onOpen}
          styles={{
            color: isOpen ? 'orange.400' : 'black',
          }}
        />
        {isVernacular && (
          <HeaderIconButton
            label="Translate button"
            icon={<BsTranslate size="1.5rem" />}
            onClick={() => {
              setIsTranslateToVernacular(!isTranslateToVernacular)
            }}
            styles={{
              color: isTranslateToVernacular ? 'orange' : 'black',
            }}
          />
        )}
        {/* todo (ben): discuss with trena if we want a diff icon for mobile */}
        <BookmarkButton
          documentId={documentId}
          documentTitle={documentTitle}
          documentCollections={documentCollections}
        />
      </HStack>
    </HStack>
  )
}
