import {
  useCreateDefaultBookmark,
  useDeleteBookmark,
} from '@/hooks/useBookmark'
import { DocumentCardButton } from './DocumentCardButton'
import { useGetUserIdFromContext } from '@/hooks'
import { useReward } from 'react-rewards'
import { StarIcon } from '@/components/icons/StarIcon'
import { useToast } from '@/hooks/useToast'

type StarButtonProps = {
  documentId: number
  starredCollectionId: number
  isDocumentInStarredCollection?: boolean
}

export const StarButton = ({
  documentId,
  isDocumentInStarredCollection,
  starredCollectionId,
}: StarButtonProps) => {
  const confettiDocumentId = `${documentId}`

  const { reward } = useReward(confettiDocumentId, 'confetti', {
    lifetime: 50,
    angle: 90, //initial direction of particles
    decay: 0.91, //velocity decrease per frame
    spread: 10,
    startVelocity: 10,
    elementCount: 20, //number of confetti
    elementSize: 8, //size of each confetti
    colors: ['#ED8936', '#1A202C'],
    position: 'absolute',
  })

  const toast = useToast()
  const { userId } = useGetUserIdFromContext()

  const { deleteBookmark, isLoading: isDeleteBookmarkLoading } =
    useDeleteBookmark({
      userId,
      documentId,
      starredCollectionId,
    })
  const { createBookmark, isLoading: isCreateBookmarkLoading } =
    useCreateDefaultBookmark({
      documentId,
      starredCollectionId,
    })
  const isLoading = isCreateBookmarkLoading || isDeleteBookmarkLoading

  return (
    <DocumentCardButton
      _disabled={{
        cursor: 'not-allowed',
        // remove blur property
        filter: 'none',
      }}
      isDisabled={isLoading}
      icon={
        <StarIcon
          confettiDocumentId={confettiDocumentId}
          isLoading={isLoading}
          isStarred={!!isDocumentInStarredCollection}
        />
      }
      onClick={() => {
        if (isDocumentInStarredCollection)
          deleteBookmark(
            {
              documentId,
              collectionId: starredCollectionId,
            },
            {
              onError: () => {
                toast({
                  description: 'Unable to remove bookmark',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                })
              },
            }
          )
        else {
          // should refactor to use collectionId
          createBookmark(
            {
              documentId,
            },
            {
              onSuccess: () => {
                reward()
              },
              onError: () => {
                toast({
                  description: 'Unable to bookmark document',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                })
              },
            }
          )
        }
      }}
      label={
        isDocumentInStarredCollection
          ? 'Remove from Starred'
          : 'Favorite article by starring'
      }
    />
  )
}
