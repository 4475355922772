import { AwsRum, AwsRumConfig } from 'aws-rum-web'
import { REGION, AWS_RUM_ENDPOINT } from '~shared/constants'

const AWS_RUM_VARIABLES = (() => {
  switch (import.meta.env.VITE_ENV) {
    case 'staging':
      return {
        applicationId: '2b1c926e-3e2c-4797-9b73-47e4c8ac2672',
        guestRoleArn:
          'arn:aws:iam::667127436100:role/pressify-rum-cognito-unauth-role',
        identityPoolId: 'ap-southeast-1:e8fab5d5-3c19-4c58-81d4-0941a0ccd387',
        endpoint: AWS_RUM_ENDPOINT,
      }
    case 'production':
      return {
        applicationId: '8ff9cf8b-335a-4f8a-9088-bace9062c57e',
        guestRoleArn:
          'arn:aws:iam::508412548035:role/pressify-prd-cognito-rum-cognito-unauth-role',
        identityPoolId: 'ap-southeast-1:35714df7-a6bf-4413-8f5d-1360c92e3cab',
        endpoint: AWS_RUM_ENDPOINT,
      }
  }
})()

export const config: AwsRumConfig = {
  sessionSampleRate: 1,
  telemetries: [
    [
      'performance',
      {
        ignore: (entry: PerformanceEntry) => {
          return entry.entryType === 'resource'
        },
      },
    ],
    'errors',
    [
      'http',
      {
        recordAllRequests: true,
        addXRayTraceIdHeader: true,
      },
    ],
  ],
  allowCookies: true,
  enableXRay: true,
  sessionLengthSeconds: 86400, // 24 hours, similar to session cookie age
  ...AWS_RUM_VARIABLES,
}

export function initMonitoring(): AwsRum | null {
  if (import.meta.env.VITE_ENV === 'development') {
    return null
  }
  const awsRum: AwsRum = new AwsRum(
    // only dev environment will not have application id and we handle that
    AWS_RUM_VARIABLES!.applicationId,
    '1.0.0', // application version (todo: application versioning)
    REGION,
    config
  )
  return awsRum
}
