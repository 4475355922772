import { HStack, Text, VStack } from '@chakra-ui/react'
import EmailPageBackgroundSvg from '@/assets/login-page-email-background.svg'
import { useIsMobile } from '@/hooks'
import { LOGIN_CAPTION } from '@/constants/auth'
import { AuthBody } from './AuthBody'
import { EmailForm } from './EmailForm'

export const EmailBody = () => {
  const isMobile = useIsMobile()
  return (
    <AuthBody caption={LOGIN_CAPTION} image={EmailPageBackgroundSvg}>
      <VStack
        alignItems="inherit"
        spacing={{ base: '0.5rem', lg: '0.3125rem' }}
        w="100%"
      >
        {!isMobile && (
          <Text fontSize="0.875rem" fontWeight="semibold">
            Email
          </Text>
        )}
        <Text
          fontSize={{ lg: '0.875rem', base: '1rem' }}
          fontWeight={{ base: 'normal', lg: 'medium' }}
        >
          Please log in with your <b>full-length</b> MINDEF or defence email
        </Text>
        <VStack
          spacing="1.5rem"
          align="flex-start"
          justify="flex-start"
          w="100%"
        >
          <HStack maxWidth="30rem" spacing="0.75rem" w="100%">
            <EmailForm />
          </HStack>
        </VStack>
      </VStack>
    </AuthBody>
  )
}
