import {
  BookmarkRes,
  CreateBookmarkReq,
  CreateBookmarksReq,
  CreateBookmarksRes,
  DeleteBookmarkReq,
} from '~shared/dtos'
import { ApiService } from './ApiService'

export const deleteBookmark = async (payload: DeleteBookmarkReq) => {
  const { data } = await ApiService.delete<BookmarkRes>(`bookmark`, {
    data: payload,
  })
  return data
}

export const createBookmark = async (payload: CreateBookmarkReq) => {
  const { data } = await ApiService.post<BookmarkRes>(
    `bookmark/default`,
    payload
  )
  return data
}

export const createBookmarks = async (payload: CreateBookmarksReq) => {
  const { data } = await ApiService.post<CreateBookmarksRes>(
    `bookmark/create`,
    payload
  )
  return data
}
