import { Flex, Text, Image, Button, VStack, Stack } from '@chakra-ui/react'
import PressifyMagnifyingGlass from '@/assets/pressify-magnifying-glass.svg'
import PressifyMobileMagnifyingGlass from '@/assets/pressify-mobile-magnifying-glass.svg'
import { useNavigate } from 'react-router-dom'
import { AppNavPaths } from '@/utils/paths'
import { useIsMobile } from '@/hooks'

export const Hero = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  return (
    <VStack align="center" justifyContent="space-between" w="100%" h="100%">
      <Stack
        w="100%"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing="2rem"
      >
        <Flex
          direction="column"
          width={{ base: '100%', lg: '30%' }}
          mt={{ base: '0', lg: '8rem' }}
          justifyContent="space-between"
        >
          <VStack w="100%" align="left" spacing="0">
            <Text
              fontSize={{ base: '1.75rem', lg: '3rem' }}
              fontWeight="normal"
              lineHeight="120%"
              color="gray.800"
              alignSelf="left"
            >
              Find what you need,{' '}
              <Text
                as="span"
                fontStyle="italic"
                fontWeight="bold"
                color="orange.500"
              >
                fast.
              </Text>
            </Text>
            <br />
            <Text
              fontSize={{ base: '1rem', lg: '0.875rem' }}
              fontWeight={{ base: 'medium', lg: 'normal' }}
              lineHeight="1.5rem"
              color="gray.800"
            >
              Pressify helps you find and compile the information you need - so
              that you can spend more time on the actual analysing and
              reporting.
            </Text>
            <Button
              _hover={{
                bg: 'brand.primary.400',
                color: 'brand.primary.100',
              }}
              borderRadius="1.25rem"
              fontWeight="semibold"
              bg="brand.primary.500"
              color="brand.primary.50"
              type="submit"
              px="1.5rem"
              onClick={() => {
                navigate(AppNavPaths.Login)
              }}
              alignSelf="bottom"
              fontSize="1rem"
              lineHeight="1.5rem"
              top="1.2rem"
            >
              Start browsing Pressify now
            </Button>
          </VStack>
        </Flex>
        <Flex
          width={{ base: '100%', lg: '60%' }}
          flex={1}
          justifyContent="center"
        >
          <Image
            w="100%"
            src={
              isMobile ? PressifyMobileMagnifyingGlass : PressifyMagnifyingGlass
            }
          />
        </Flex>
      </Stack>
    </VStack>
  )
}
