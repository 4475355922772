import { useCallback, useEffect, useMemo, useState } from 'react'
import { AuthContext } from './useAuthContext'
import { useGetUser } from '@/hooks/useUser'
import { Center, Spinner } from '@chakra-ui/react'
import { useUserLogout } from '@/hooks/useAuth'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { DocumentType } from '~shared/constants'
import { USER_NOT_AUTHENTICATED_EVENT } from '@/constants/event'
import { useToast } from '@/hooks/useToast'

type AuthProviderProps = {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { user, isUserLoading } = useGetUser()
  const toast = useToast()
  const [email, setEmail] = useState<string>('')
  const [timer, setTimer] = useState<number>(60)
  // for otp errors from backend
  const [otpError, setOtpError] = useState<string>('')
  const { userLogout } = useUserLogout()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const navigateToLogin = useCallback(() => {
    queryClient.clear()
    setEmail('')
    navigate('/login')
  }, [queryClient, navigate])

  const logout = useCallback(async () => {
    await userLogout()
    navigateToLogin()
  }, [navigateToLogin, userLogout])

  const hasPressumUploadPermission = useMemo(() => {
    return user
      ? !!user.access.find(
          ({ isWrite, type }) =>
            isWrite && type === DocumentType.PRESSUM_ARTICLE
        )
      : false
  }, [user])

  const handleUnauthorizedEvent = useCallback(async () => {
    toast({
      status: 'error',
      description: 'Please login again.',
    })
    navigateToLogin()
  }, [navigateToLogin, toast])

  const initListeners = useCallback(() => {
    window.addEventListener(
      USER_NOT_AUTHENTICATED_EVENT,
      handleUnauthorizedEvent
    )
  }, [handleUnauthorizedEvent])

  const removeListeners = useCallback(() => {
    window.removeEventListener(
      USER_NOT_AUTHENTICATED_EVENT,
      handleUnauthorizedEvent
    )
  }, [handleUnauthorizedEvent])

  // Initialise Listeners
  useEffect(() => {
    if (!user) {
      return
    }

    initListeners()
    return removeListeners
  }, [user, initListeners, removeListeners])

  const value = useMemo(
    () => ({
      email,
      setEmail,
      timer,
      setTimer,
      user,
      logout,
      hasPressumUploadPermission,
      otpError,
      setOtpError,
    }),
    [
      email,
      setEmail,
      timer,
      setTimer,
      user,
      logout,
      hasPressumUploadPermission,
      otpError,
      setOtpError,
    ]
  )

  if (isUserLoading) {
    return (
      <Center height="100vh">
        <Spinner />
      </Center>
    )
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
