import { PressifyLogo } from '@/components/PressifyLogo'
import { AGREEMENT_AGREE_PHRASE, AGREEMENT_HEADER } from '@/constants/auth'
import { CONTACT_PRESSIFY_FORM } from '@/constants/feedback'
import { PRIVACY_POLICY, TERMS_OF_USE } from '@/constants/footer-links'
import {
  Box,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react'
import { AgreementActions } from './AgreementActions'

type AgreementModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const AgreementModal = ({ isOpen, onClose }: AgreementModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="2xl"
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay background="black" />
      <ModalContent padding="24px">
        <ModalHeader px="0px" pt="0px" pb="16px">
          <PressifyLogo isClickable={false} />
        </ModalHeader>
        <ModalBody padding="0px" w="100%">
          <Heading as="h5" fontSize="1.25rem">
            {AGREEMENT_HEADER}
          </Heading>
          <Spacer h="0.5rem" />
          Before you dive in, please take a moment to review and agree to our{' '}
          <Box as="span" textDecoration="underline" color="orange.500">
            <Link
              aria-label="Click to open Pressify's Terms Of Use in a new tab"
              href={TERMS_OF_USE}
              isExternal
            >
              Terms of Use
            </Link>
          </Box>{' '}
          and{' '}
          <Box as="span" textDecoration="underline" color="orange.500">
            <Link
              aria-label="Click to open Pressify's Privacy Policy in a new tab"
              href={PRIVACY_POLICY}
              isExternal
              textDecoration="underline"
              color="orange.500"
            >
              Privacy Policy
            </Link>
          </Box>
          . We value your privacy and want to ensure you have a clear
          understanding of how your information is handled.
          <Spacer h="1rem" />
          {`${AGREEMENT_AGREE_PHRASE} If you have any questions or
          concerns, don't hesitate `}
          <Box as="span" textDecoration="underline" color="orange.500">
            <Link
              aria-label="Click to see Pressify's contact form in a new tab"
              isExternal
              href={CONTACT_PRESSIFY_FORM}
              textDecoration="underline"
              color="orange.500"
            >
              to reach out to our team
            </Link>
          </Box>
          .
          <Spacer h="1rem" />
          Thank you for joining Pressify!
        </ModalBody>
        <AgreementActions onClose={onClose} />
      </ModalContent>
    </Modal>
  )
}
