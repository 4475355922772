import {
  Divider,
  HStack,
  IconButton,
  IconButtonProps,
  Input,
} from '@chakra-ui/react'
import { UserProfileMenu } from '../UserProfileMenu'
import { useRef, useState, KeyboardEvent, useEffect } from 'react'
import { IoClose, IoSearchOutline } from 'react-icons/io5'
import { PressifyShorthandLogo } from '../icons/PressifyShorthandLogoSmall'
import { PressifyLogoSmall } from '../icons/PressifyLogoSmall'
import { useSearchParams } from 'react-router-dom'
import { searchFilterParam } from '@/constants/search-params'
import { SORT_OPTIONS } from '~shared/constants/document'
import {
  useAddQueryToSearchParam,
  useSetSortSearchParams,
} from '@/hooks/params/useSearchParams'
import { SearchMenu } from '../searchbar/document/SearchMenu'
import { zIndices } from '@/theme/z-index'

const inputStyles = {
  border: 0,
  borderColor: 'white',
  boxShadow: 'none',
}

type MobileSearchBarProps = {
  hasSearch?: boolean
}

//todo(ben): refactor with document search bar
export const MobileSearchBar = ({ hasSearch = true }: MobileSearchBarProps) => {
  const { sort, query } = searchFilterParam
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState(searchParams.get(query) ?? '')
  const innerRef = useRef<HTMLInputElement>(null)
  const { addQueryToSearchParams } = useAddQueryToSearchParam()

  const { setSortSearchparams } = useSetSortSearchParams({
    searchParams,
  })

  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  const handleSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      innerRef.current &&
      search &&
      innerRef.current.value
    ) {
      addQueryToSearchParams({ queryValue: innerRef.current.value })
      innerRef.current.blur()
    }
  }

  // update query text if search param's query changed
  useEffect(() => {
    const searchParamsQuery = searchParams.get(query)
    setSearch(searchParamsQuery ?? '')
    if (!searchParamsQuery) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }, [query, searchParams])

  return (
    <HStack
      padding="0.75rem 1.5rem 0.75rem 1.5rem"
      spacing="1rem"
      backgroundColor="background.default"
      width="100%"
      zIndex={zIndices.navbar} // required so that searchmenu doesn't get covered in mobile
    >
      <HStack justifyContent="space-between" width="100%">
        {/* TODO (ALEX): Look into how we can optimize rendering the logo, 
        not fantastic to include the svg in the intial requests as it increases bundle size. 
        Using image tag also causes extra http fetch with each rende.
        */}
        {isOpen ? (
          <HStack justifyContent="space-between" width="100%">
            <PressifyShorthandLogo />
            <HStack
              borderRadius="1.25rem"
              w="100%"
              spacing="0"
              border="1px"
              h="fit-content"
            >
              <StyledIconButton
                paddingLeft="0.5rem"
                aria-label="search-article-icon"
                icon={<IoSearchOutline />}
                onClick={() => {
                  if (innerRef.current && search && innerRef.current.value) {
                    searchParams.set(sort, SORT_OPTIONS.Relevance)
                    searchParams.set(query, innerRef.current.value)
                    setSearchParams(searchParams, { replace: true })
                  }
                }}
              />
              <Input
                value={search}
                placeholder="Search by keywords"
                _placeholder={{
                  fontSize: {
                    lg: '1rem',
                    base: '0.75rem',
                  },
                }}
                fontSize={{
                  lg: '1rem',
                  base: '0.75rem',
                }}
                _focus={inputStyles}
                borderRadius="1.25rem"
                onChange={({ target: { value } }) => {
                  setSearch(value)
                }}
                onKeyDown={handleSearch}
                ref={innerRef}
                {...inputStyles}
              />
              <HStack w="50%" justifyContent="end" px="0.5rem">
                <StyledIconButton
                  aria-label="clear-search-bar-icon"
                  onClick={() => {
                    setSearch('')
                    setSortSearchparams()
                    searchParams.set(query, '')
                    setSearchParams(searchParams, { replace: true })
                    toggleOpen()
                  }}
                  icon={<IoClose />}
                />
                <Divider
                  orientation="vertical"
                  height={'1.25rem'}
                  borderColor={'gray.500'}
                />
                <SearchMenu />
              </HStack>
            </HStack>
          </HStack>
        ) : (
          <HStack justifyContent="space-between" width="100%">
            <PressifyLogoSmall />
            {hasSearch && (
              <StyledIconButton
                aria-label="search-article-icon"
                onClick={toggleOpen}
                icon={<IoSearchOutline size="1.5rem" />}
              />
            )}
          </HStack>
        )}
      </HStack>
      {!isOpen && (
        <>
          <Divider
            orientation="vertical"
            height="1.25rem"
            borderColor={'gray.500'}
          />
          <UserProfileMenu />
        </>
      )}
    </HStack>
  )
}

const StyledIconButton = (props: IconButtonProps) => {
  const { onClick, icon, ...restProps } = props
  return (
    <IconButton
      onClick={onClick}
      icon={icon}
      variant="ghost"
      padding={0}
      width={'fit-content'}
      minW={'fit-content'}
      _hover={{ backgroundColor: 'transparent' }}
      _focus={{ backgroundColor: 'transparent' }}
      {...restProps}
    />
  )
}
