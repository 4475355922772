import { useGetUserEmailFromContext } from '@/hooks'
import {
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react'
import { DeleteUserCollectionActions } from './DeleteUserCollectionActions'
import {
  getDeleteUserCollectionContent,
  getDeleteUserCollectionHeader,
} from '@/utils/user-collection'
import { FiTrash2 } from 'react-icons/fi'

type DeleteUserCollectionDrawerProps = {
  isOpen: boolean
  onClose: () => void
  userId: string
  email: string
}

export const DeleteUserCollectionDrawer = ({
  isOpen,
  onClose,
  userId,
  email,
}: DeleteUserCollectionDrawerProps) => {
  const { email: userEmail } = useGetUserEmailFromContext()
  const isUser = email === userEmail

  const header = isUser ? `REMOVE SELF` : `REMOVE USER`
  const contentHeader = getDeleteUserCollectionHeader({ isUser, email })
  const bodyContent = getDeleteUserCollectionContent({ isUser, email })

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="bottom" allowPinchZoom>
      <DrawerOverlay />
      <DrawerContent padding="1.5rem">
        <DrawerHeader px="0" pt="0" pb="1rem">
          <HStack w="100%" justify="space-between">
            <HStack spacing="0.5rem" width="100%">
              <Icon width="1.5rem" height="1.5rem" as={FiTrash2} />
              <Text fontSize="1rem" fontWeight="bold">
                {header}
              </Text>
            </HStack>
            <CloseButton onClick={onClose} />
          </HStack>
        </DrawerHeader>
        <DrawerBody padding="0" w="100%">
          <VStack w="100%" align="left">
            <Text fontWeight="bold" fontSize="1.125rem">
              {contentHeader}
            </Text>
            <Text fontWeight="400" fontSize="0.875rem">
              {bodyContent}
            </Text>
          </VStack>
        </DrawerBody>
        <DeleteUserCollectionActions
          onClose={onClose}
          userId={userId}
          email={email}
        />
      </DrawerContent>
    </Drawer>
  )
}
