import { Flex, HStack, Link } from '@chakra-ui/react'
import { Button } from '@chakra-ui/button'
import { PressifyLogo } from '@/components/PressifyLogo'
import { useNavigate } from 'react-router-dom'
import { AppNavPaths } from '@/utils/paths'
import { useIsMobile } from '@/hooks'
import { CONTACT_PRESSIFY_FORM } from '@/constants/feedback'

export const HeroBanner = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const linkProps = {
    color: 'brand.primary.500',
    target: '_blank',
  }

  return (
    <Flex w="100%">
      <Flex
        w="100%"
        alignItems="flex-end"
        flexDir="column"
        mr={{ base: '0', lg: '2.25rem' }}
      >
        <HStack width="100%" justifyContent="space-between">
          <PressifyLogo
            variant={isMobile ? 'sm-light' : 'regular-light'}
            isClickable={false}
          />
          <HStack spacing={{ base: '1rem', lg: '1.5rem' }}>
            {/* todo: re-enable the user guide button for users when feature is needed 
            {isMobile && (
              <IconButton
                color="brand.primary.500"
                size="sm"
                aria-label="Help"
                as={IoMdHelpCircle}
                bg="parent"
                onClick={() => {
                  window.open(USER_GUIDE, '_blank', 'noopener')
                }}
              />
            )} */}
            <Link
              {...linkProps}
              href={CONTACT_PRESSIFY_FORM}
              _hover={{
                color: 'brand.primary.400',
                textDecoration: 'underline',
              }}
              fontWeight="600"
              fontSize="1rem"
              lineHeight="1.5rem"
            >
              Contact Us
            </Link>
            <Button
              _hover={{
                bg: 'brand.primary.400',
                color: 'brand.primary.100',
              }}
              borderRadius="1.25rem"
              fontWeight="600"
              bg="brand.primary.500"
              color="brand.primary.50"
              type="submit"
              onClick={() => navigate(AppNavPaths.Login)}
              padding="0rem 1.5rem"
            >
              Log in
            </Button>
          </HStack>
        </HStack>
      </Flex>
    </Flex>
  )
}
