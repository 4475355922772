import { Badge, HStack, StyleProps, Text } from '@chakra-ui/react'
import { CollectionIcon } from '@/components/icons/CollectionIcon'

type CollectionRowProps = {
  name: string
  documentCount: number
  // either collectionId or index
  value?: string | number
  userCount?: number
  styles?: StyleProps
  isWhiteBg?: boolean
  badgeText?: string
}

export const CollectionRow = ({
  name,
  documentCount,
  value,
  userCount,
  styles,
  isWhiteBg = false,
  badgeText,
}: CollectionRowProps) => {
  const badgeStyles: StyleProps = isWhiteBg
    ? { borderRadius: '0.313rem', background: '#000000A3', color: '#F7FAFC' }
    : {
        borderRadius: '1.25rem',
        bg: 'black',
        color: 'white',
      }
  return (
    <HStack
      py="0.125rem"
      justifyContent={{ base: 'space-between', lg: 'flex-start' }}
      w="100%"
      {...styles}
      isTruncated
    >
      <HStack>
        <CollectionIcon name={name} userCount={userCount} value={value} />
        <Text>{name}</Text>
      </HStack>
      <Badge {...badgeStyles}>{badgeText ?? documentCount}</Badge>
    </HStack>
  )
}
