import { DocumentTagWithCategory } from '~shared/dtos'
import {
  RECOMMENDED_TAG_TEXT_TO_COLOR,
  RECOMMENDED_TAG_TO_COLOR,
} from '@/utils/tag-to-color'
import { Wrap, WrapItem, Button } from '@chakra-ui/react'
import { RxPlus } from 'react-icons/rx'
type RecommendedTagsProps = {
  tags: DocumentTagWithCategory[]
  addTagToDocument: ({ displayName, category }) => void
}

export const RecommendedTags = ({
  tags,
  addTagToDocument,
}: RecommendedTagsProps) => {
  return (
    <>
      {tags && (
        <Wrap>
          {tags.map(({ displayName, category }) => {
            return (
              <WrapItem
                key={displayName}
                onClick={() => {
                  addTagToDocument({ displayName, category })
                }}
              >
                <Button
                  fontSize="12px"
                  height="20px"
                  borderRadius="6px"
                  padding="6px"
                  bg={RECOMMENDED_TAG_TO_COLOR[category]}
                  textColor={RECOMMENDED_TAG_TEXT_TO_COLOR[category]}
                  leftIcon={<RxPlus />}
                >
                  {displayName}
                </Button>
              </WrapItem>
            )
          })}
        </Wrap>
      )}
    </>
  )
}
