import { Badge, Button, ButtonProps, HStack, Text } from '@chakra-ui/react'
import { CollectionIcon } from '../icons/CollectionIcon'

type CollectionButtonProps = ButtonProps & {
  name: string
  documentCount: number
  userCount?: number
  isHighlighted?: boolean
}

export const CollectionButton = ({
  name,
  documentCount,
  userCount,
  isHighlighted,
  ...restProps
}: CollectionButtonProps) => {
  return (
    <HStack w="100%">
      <Button
        w="100%"
        {...restProps}
        leftIcon={<CollectionIcon name={name} userCount={userCount} />}
        rightIcon={
          <Badge borderRadius="1.25rem" background="black" color="white">
            {documentCount}
          </Badge>
        }
        variant="ghost"
        color="gray.50"
        justifyContent="flex-start" // align to left
        bgColor={isHighlighted ? 'gray.700' : ''}
      >
        <Text isTruncated>{name}</Text>
      </Button>
    </HStack>
  )
}
