import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { BiSolidChevronRight } from 'react-icons/bi'
import { IoMdAdd } from 'react-icons/io'
import { DocumentTagWithCategory } from '~shared/dtos/tag.dto'
import { DocumentTags } from '../DocumentTags'
import { TagDrawerHeader } from './TagDrawerHeader'
import { AddTagDrawer } from './AddTagDrawer'
import { useState } from 'react'
import { useAddTagToDocument } from '@/hooks/useAddTagToDocument'
import { SpinnerOverlay } from '@/components/SpinnerOverlay'

type TagDrawerProps = {
  documentId: string
  documentTags?: DocumentTagWithCategory[]
  isOpen: boolean
  onClose: () => void
}

export const DisplayTagDrawer = ({
  documentId,
  documentTags,
  isOpen,
  onClose,
}: TagDrawerProps) => {
  const {
    isOpen: isAddTagOpen,
    onOpen: onAddTagOpen,
    onClose: onAddTagClose,
  } = useDisclosure()
  const [documentTagsState, setDocumentTagsState] = useState<
    DocumentTagWithCategory[]
  >(documentTags ?? [])
  const { addTagToDocument, isCreateAndInsertTagLoading } = useAddTagToDocument(
    +documentId,
    setDocumentTagsState
  )

  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      onClose={onClose}
      blockScrollOnMount={false}
    >
      <DrawerContent maxHeight="50%">
        <DrawerHeader>
          <TagDrawerHeader />
        </DrawerHeader>

        <DrawerBody>
          {isCreateAndInsertTagLoading && <SpinnerOverlay />}
          <DocumentTags
            documentId={documentId}
            documentTags={documentTagsState}
          />
        </DrawerBody>

        <DrawerFooter>
          <Button
            backgroundColor="gray.100"
            variant="outline"
            onClick={onAddTagOpen}
            width="100%"
            height="3.5rem"
            padding="0rem 1rem"
          >
            <HStack justifyContent="space-between" width="100%">
              <HStack spacing="0.5rem">
                <Stack
                  backgroundColor="#DD6B20"
                  borderRadius="1.25rem"
                  width="2.5rem"
                  height="2.5rem"
                  justifyContent="center" // Center Horizontally
                  alignItems="center" // Center Vertically
                >
                  <Icon as={IoMdAdd} color="White" size="1rem" />
                </Stack>

                <Text>Add or create tag</Text>
              </HStack>
              <BiSolidChevronRight />
            </HStack>
          </Button>
        </DrawerFooter>

        <AddTagDrawer
          isOpen={isAddTagOpen}
          onClose={onAddTagClose}
          documentTagsState={documentTagsState}
          addTagToDocument={addTagToDocument}
          isCreateAndInsertTagLoading={isCreateAndInsertTagLoading}
        />
        <DrawerCloseButton />
      </DrawerContent>
    </Drawer>
  )
}
