import { HStack, Text } from '@chakra-ui/react'
import { BiTag } from 'react-icons/bi'

export const TagDrawerHeader = () => {
  return (
    <HStack>
      <BiTag />
      <Text color="gray.800" fontSize="1rem" fontWeight={700}>
        ARTICLE TAGS
      </Text>
    </HStack>
  )
}
