import { searchFilterParam } from '@/constants/search-params'
import { Text } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'

const textStyles = {
  w: '100%',
  alignSelf: 'left',
  fontSize: {
    lg: '1rem',
    base: '0.875rem',
  },
}

type SearchResultsTextProps = {
  totalResults: number
}

export const SearchResultsText = ({ totalResults }: SearchResultsTextProps) => {
  const resultText = totalResults <= 1 ? 'article' : 'articles'

  const [searchparams] = useSearchParams()
  const { query, tags } = searchFilterParam

  const getQueryText = (): string | undefined =>
    searchparams.get(query) ?? undefined
  const getTagText = (): string | undefined =>
    searchparams.get(tags) ?? undefined
  const hasSearchQuery = !!getQueryText()
  const hasSearchTags = !!getTagText()

  const renderFilteredResultsText = (queryText?: string) => (
    <Text {...textStyles}>
      Showing {totalResults} {resultText}
      {queryText && ` for `}
      {queryText && <b>`{queryText}`</b>}
    </Text>
  )

  const renderResultsText = () => {
    if (hasSearchQuery && hasSearchTags) {
      return renderFilteredResultsText(getQueryText())
    }
    if (hasSearchTags) {
      return renderFilteredResultsText()
    }
    if (hasSearchQuery) {
      return renderFilteredResultsText(getQueryText())
    }
    return (
      <Text {...textStyles}>
        Showing <b>{totalResults}</b> {totalResults <= 1 ? 'result' : 'results'}
      </Text>
    )
  }

  return totalResults ? renderResultsText() : null
}
