import { Box, Divider, Flex, Spinner, Text, VStack } from '@chakra-ui/react'
import { useSearchFilteredTag } from '@/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DocumentTagWithCategory } from '~shared/dtos'
import { lowercaseAndRemoveWhitespace } from '~shared/utils'
import { useInView } from 'react-intersection-observer'
import Select, { components } from 'react-select'
import { useDebouncedCallback } from 'use-debounce'
import {
  TAG_NAME_CONTAINS_COLON_MEESAGE,
  TAG_NAME_CONTAINS_PRESSUM_MESSAGE,
  TAG_NAME_EXISTS_MESSAGE,
} from '~shared/constants'
import { isTagNamePressum, isValidTagName } from '@/utils/tag'

type ModifyTagSearchBarProps = {
  setIsClicked: React.Dispatch<React.SetStateAction<boolean>>
  onOpen: () => void
  addTagToDocument: ({ displayName, category }) => void
  setNewTagName: React.Dispatch<React.SetStateAction<string>>
  // require this to find out if tags exist in document
  documentTags?: DocumentTagWithCategory[]
}

type Option = {
  label: string
  value: string
  category: string
}

export const ModifyTagSearchBar = ({
  setIsClicked,
  onOpen,
  addTagToDocument,
  setNewTagName,
  documentTags,
}: ModifyTagSearchBarProps) => {
  const [query, setQuery] = useState<string>('')
  const [displayQuery, setDisplayQuery] = useState<string>('')
  const { ref, inView } = useInView()

  const {
    filteredTags,
    allTags,
    fetchNextPage,
    hasNextPage,
    isSearchTagsLoading,
  } = useSearchFilteredTag({ query, documentTags })
  const options: Option[] = filteredTags.map(({ displayName, category }) => {
    return {
      label: displayName,
      // passing key as value as displayNamevalue to easily identify category
      value: lowercaseAndRemoveWhitespace(displayName),
      category,
    }
  })

  const fetchSearchTags = useDebouncedCallback(() => {
    setQuery(displayQuery)
  }, 250)

  useEffect(() => {
    fetchSearchTags()
  }, [displayQuery, fetchSearchTags])

  const handleSearchBarChange = (data) => {
    if (data && data.value && data.label && addTagToDocument) {
      addTagToDocument({ displayName: data.label, category: data.category })
    }
  }

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  const isValidNewOption = useMemo(() => {
    if (!isValidTagName(displayQuery) || isTagNamePressum(displayQuery)) {
      return false
    }
    const isExistInDb = !!(
      allTags &&
      !allTags.find(
        (tag) =>
          lowercaseAndRemoveWhitespace(tag.displayName) ===
          lowercaseAndRemoveWhitespace(displayQuery)
      )
    )
    return isExistInDb
  }, [allTags, displayQuery])

  const createTagLabel = `Create new tag`
  // menulist rerenders upon fetch
  // todo(ben): maintain scroll position after renredering
  const MenuList = useCallback(
    (props) => {
      return (
        <components.MenuList {...props}>
          <VStack overflow="auto" maxH="14rem" mx="0.5rem">
            {props.children}
            {hasNextPage && props.children && (
              <Box ref={ref} textAlign="center">
                <Spinner />
              </Box>
            )}
          </VStack>
          {displayQuery && isValidNewOption && (
            <>
              <Flex justifyContent="center">
                <Divider
                  w="95%"
                  borderColor="gray.700"
                  h="1px"
                  _hover={{
                    bg: 'parent',
                  }}
                />
              </Flex>
              <VStack
                borderRadius="0.25rem"
                _hover={{
                  bg: 'orange.100',
                  fontWeight: 'bold',
                  color: 'orange.500',
                }}
                align="left"
                py="0.25rem"
                px="0.5rem"
                mt="0.25rem"
                mb="0.5rem"
                mx="0.5rem"
                onClick={() => {
                  setNewTagName(displayQuery)
                  onOpen()
                }}
              >
                <Text fontSize="0.875rem">{`${createTagLabel} "${displayQuery}"`}</Text>
              </VStack>
            </>
          )}
        </components.MenuList>
      )
    },
    [
      createTagLabel,
      hasNextPage,
      isValidNewOption,
      onOpen,
      displayQuery,
      ref,
      setNewTagName,
    ]
  )

  const getNoOptionsMessage = (inputValue) => {
    if (inputValue === '') return null
    if (isSearchTagsLoading) {
      return <Spinner />
    }
    if (!isValidTagName(inputValue)) {
      return TAG_NAME_CONTAINS_COLON_MEESAGE
    }
    if (isTagNamePressum(inputValue)) {
      return TAG_NAME_CONTAINS_PRESSUM_MESSAGE
    }
    if (!isValidNewOption) {
      return TAG_NAME_EXISTS_MESSAGE
    }
    return <></>
  }

  return (
    <>
      <Select
        autoFocus
        onBlur={() => {
          setIsClicked(false)
        }}
        blurInputOnSelect
        menuIsOpen
        components={{
          MenuList,
        }}
        styles={{
          menuList: (defaultStyles) => ({
            ...defaultStyles,
          }),
          menu: (defaultStyles) => ({
            ...defaultStyles,
          }),
          noOptionsMessage: (defaultStyle) => ({
            ...defaultStyle,
            fontSize: '10px',
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
          }),
          control: (defaultStyle) => ({
            ...defaultStyle,
            borderRadius: '20px',
            height: '24px',
            minHeight: '24px',
            fontSize: '12px',
            background: '#EDF2F7',
            minWidth: '145px',
            textAlign: 'left',
            paddingBottom: '10px',
            border: 'none',
            boxShadow: 'none',
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          option: (defaultStyles, { isFocused }) => ({
            ...defaultStyles,
            borderRadius: '0.25rem',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            fontWeight: isFocused ? 'bold' : 'normal',
            backgroundColor: isFocused ? '#FEEBCB' : 'parent',
            color: isFocused ? '#DD6B20' : 'parent',
            ':active': {
              ...defaultStyles[':active'],
              backgroundColor: '#FEEBCB',
              color: '#DD6B20',
              fontWeight: 'bold',
            },
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            display: 'none',
          }),
          placeholder: (provided) => ({
            ...provided,
            paddingBottom: '0px',
            paddingTop: '0px',
          }),
          input: (provided) => ({
            ...provided,
            paddingBottom: '0px',
            paddingTop: '0px',
          }),
        }}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault()
        }}
        aria-errormessage="error"
        options={options}
        placeholder="Type to search tags"
        noOptionsMessage={({ inputValue }) => getNoOptionsMessage(inputValue)}
        onInputChange={(inputValue) => {
          setDisplayQuery(inputValue.trim())
        }}
        // adds selected tag to document, this can be for:
        //  1. adding document tag to a field
        //  2. adding tag to an existing document
        onChange={(option) => {
          handleSearchBarChange(option)
        }}
      />
    </>
  )
}
