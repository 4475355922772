import { useIsMobile } from '@/hooks'
import { Flex, HStack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { HiSortDescending } from 'react-icons/hi'
import Select, {
  GroupBase,
  ValueContainerProps,
  components,
} from 'react-select'

type SelectDropdownProps = {
  handleChange: (option: Option) => void
  options: Option[]
  selected?: Option
}

export type Option = {
  label: string
  value: string
}

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<Option, boolean, GroupBase<Option>>) => {
  const isMobile = useIsMobile()
  return (
    <components.ValueContainer {...props}>
      <HStack w="fit-content" spacing="0.5rem" p="0" fontWeight="600">
        <HiSortDescending />
        <HStack
          fontSize={{
            lg: '0.875rem',
            base: '0.75rem',
          }}
          spacing="0"
        >
          {!isMobile && <Text>Sort by:</Text>}
          {children}
        </HStack>
      </HStack>
    </components.ValueContainer>
  )
}

export const SelectDropdown = ({
  options,
  handleChange,
  selected,
}: SelectDropdownProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  // happens on sort option change
  useEffect(() => {
    setIsFocused(false)
    setIsHovered(false)
  }, [selected])

  return (
    <Flex
      w="fit-content"
      justify="end"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Select
        isMulti={false}
        isSearchable={false}
        components={{ ValueContainer }}
        styles={{
          indicatorsContainer: (defaultStyles) => ({
            ...defaultStyles,
            alignItems: 'center',
            justifyItems: 'center',
          }),
          dropdownIndicator: (defaultStyles, { isFocused }) => ({
            ...defaultStyles,
            width: '0.875rem',
            height: 'fit-content',
            padding: '0.05rem',
            color: isHovered || isFocused ? '#DD6B20' : 'black',
            ':hover': {
              color: '#DD6B20',
            },
          }),
          valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            padding: '0',
            paddingLeft: '1rem',
            width: '100%',
            color: isHovered || isFocused ? '#DD6B20' : 'black',
          }),
          menu: (provided) => ({
            ...provided,
            width: '100%',
            padding: '0.5rem',
            borderRadius: '0.5rem',
          }),
          option: (defaultStyles, { isFocused }) => ({
            ...defaultStyles,
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '0.5rem',
            fontSize: '0.875rem',
            backgroundColor: isFocused ? '#FEEBCB' : 'parent',
            color: isFocused ? '#DD6B20' : 'parent',
            fontWeight: isFocused ? 'bold' : 'normal',
            ':active': {
              ...defaultStyles[':active'],
              backgroundColor: '#FEEBCB',
              color: '#DD6B20',
              fontWeight: 'bold',
            },
          }),
          singleValue: (defaultStyles) => ({
            ...defaultStyles,
            color: isHovered || isFocused ? '#DD6B20' : 'black',
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          control: (defaultStyles, { menuIsOpen }) => {
            return {
              ...defaultStyles,
              width: 'max-content',
              borderRadius: '20px',
              padding: '0rem 0.75rem',
              height: '1.5rem',
              border: 'parent',
              borderColor: 'transparent',
              backgroundColor: menuIsOpen ? '#FEEBCB' : '#FBF9F9',
              // boxshadow prop affects the border
              boxShadow: 'none',
              ':hover': {
                cursor: 'pointer',
                borderColor: 'transparent',
                backgroundColor: '#FEEBCB',
                color: '#DD6B20',
              },
            }
          },
        }}
        options={options}
        value={selected}
        onChange={(option) => {
          if (option) {
            handleChange(option)
          }
        }}
      />
    </Flex>
  )
}
