import { Center, Spinner, VStack, Text, Stack } from '@chakra-ui/react'
import { TagSearchbar } from '../searchbar/TagSearchBar'
import { TagResultList } from './TagResultList'
import { useSearchTags } from '@/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useInView } from 'react-intersection-observer'
import { DocumentTagWithCategory } from '~shared/dtos'
import { useFilterTagContext } from './useFilterTagContext'
import { isEmpty } from 'lodash'

type FilterTagSearchProps = {
  displayQuery: string
  setDisplayQuery: (value: React.SetStateAction<string>) => void
}

export const FilterTagSearch = ({
  displayQuery,
  setDisplayQuery,
}: FilterTagSearchProps) => {
  const { selectedTags } = useFilterTagContext()
  const [query, setQuery] = useState<string>('')
  const { data, fetchNextPage, hasNextPage, isSearchTagsLoading } =
    useSearchTags({
      query,
    })
  const allTags = useMemo(
    () =>
      data?.pages.reduce((arr, { data }) => {
        return arr.concat(data)
      }, [] as DocumentTagWithCategory[]),
    [data]
  )

  const documentTagSet = useMemo(
    () => new Set(selectedTags?.map(({ displayName }) => displayName)),
    [selectedTags]
  )

  const checkboxTags = useMemo(
    () =>
      allTags?.map((tag) => {
        return {
          ...tag,
          isChecked: documentTagSet.has(tag.displayName),
        }
      }),
    [allTags, documentTagSet]
  )

  const { ref, inView } = useInView()

  const fetchSearchTags = useDebouncedCallback((searchQuery) => {
    setQuery(searchQuery)
  }, 250)

  useEffect(() => {
    fetchSearchTags(displayQuery)
  }, [displayQuery, fetchSearchTags])

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  const renderSearchResults = (): JSX.Element => {
    if (isSearchTagsLoading && displayQuery !== '') {
      return (
        <Center height="100%">
          <Spinner color="white" />
        </Center>
      )
    } else if (displayQuery !== '' && isEmpty(checkboxTags)) {
      return (
        <Center>
          <Text color="gray.50" fontSize="0.875rem">
            No tags found
          </Text>
        </Center>
      )
    } else {
      return (
        <VStack align="left" spacing="1rem" paddingLeft="0.75rem">
          {displayQuery !== '' && (
            <TagResultList
              query={displayQuery}
              ref={ref}
              hasNext={hasNextPage}
              tags={checkboxTags ?? []}
            />
          )}
        </VStack>
      )
    }
  }

  return (
    <Stack spacing="1rem">
      <TagSearchbar
        placeholder="Search tags"
        onChange={(e) => setDisplayQuery(e)}
        searchQuery={displayQuery}
        onRemoveSearchQuery={() => setDisplayQuery('')}
        hasSearchIcon
        bg="white"
        width="100%"
        mt="0"
        height="2.5rem"
        px="0.0625rem"
        backgroundColor="gray.700"
        size="md"
        mobileInputColor="gray.50"
        mobilePlaceholderColor="gray.300"
        mobileCrossIconColor="gray.50"
        searchIconColor="gray.50"
      />
      {renderSearchResults()}
    </Stack>
  )
}
