import {
  CreateOpensourceArticleReq,
  DocumentRes,
  ParseDocumentRes,
} from '~shared/dtos/document.dto'
import { ApiService } from './ApiService'
import { ManualUploadForm } from '@/types/document'

export const getDocument = async (documentId: number) => {
  const { data } = await ApiService.get<DocumentRes>(`document/${documentId}`)
  return data
}

export const getParsedDocument = async (url: string) => {
  const { data } = await ApiService.get<ParseDocumentRes>(`document/parse`, {
    params: { url },
  })
  return data
}

export const createDocument = async (payload: CreateOpensourceArticleReq) => {
  const { data } = await ApiService.post<DocumentRes>(`document`, payload)
  return data
}

export const createManualUploadDocument = async ({
  collection,
  ...values
}: ManualUploadForm) => {
  const body = {
    collection: {
      name: collection.label,
      isNew: collection.isNew,
      // new collection has no collectionId
      id: collection.value,
    },
    ...values,
  }
  const { data } = await ApiService.post<DocumentRes>(
    `document/manual-upload`,
    body
  )
  return data
}

export const generateSummary = async (documentId: number) => {
  const { data } = await ApiService.post<string>(
    `document/${documentId}/generate`
  )
  return data
}

export const searchSimilarDocuments = async (documentId: number) => {
  const { data } = await ApiService.get<DocumentRes[]>(
    `document/${documentId}/search/similar`
  )
  return data
}
