import { useGetUserCollections } from '@/hooks/useUserCollection'
import { CollabContext } from './useCollabContext'
import { useGetUserIdFromContext } from '@/hooks'
import { useMemo } from 'react'
import { PERMISSIONS } from '~shared/constants'
import { CollabUser } from '~shared/dtos'
import { checkIsAuthorised } from '~shared/utils'
import { useGetCollectionIdFromSearchParams } from '@/hooks/params/useSearchParams'

type CollabProviderProps = {
  children: React.ReactNode
}

export const CollabProvider = ({ children }: CollabProviderProps) => {
  const { userId } = useGetUserIdFromContext()
  const { collectionId } = useGetCollectionIdFromSearchParams()
  const { userCollections, isUserCollectionsLoading } =
    useGetUserCollections(collectionId)

  const userPermission = useMemo(() => {
    if (userCollections) {
      return userCollections.find(
        (userCollection) => userCollection.userId === +userId
      )?.permission as PERMISSIONS | undefined
    }
  }, [userCollections, userId])

  const isEdit = useMemo(() => {
    return (
      userPermission === PERMISSIONS.MANAGE ||
      userPermission === PERMISSIONS.UPDATE
    )
  }, [userPermission])

  const isManage = useMemo(() => {
    return userPermission === PERMISSIONS.MANAGE
  }, [userPermission])

  // assign isenabled/disabled prop to user list
  const collabUsers = useMemo(() => {
    if (userCollections && userPermission) {
      let currentUserCollection: CollabUser = {
        collectionId: 0,
        userId: 0,
        email: '',
        permission: PERMISSIONS.READ,
        isEnableDropdown: true,
        isEnableDelete: true,
      }
      const tmpCollabUsers: CollabUser[] = []
      userCollections.map((userCollection) => {
        if (userCollection.userId === +userId) {
          currentUserCollection = {
            ...userCollection,
            isEnableDropdown: true,
            isEnableDelete: true,
          }
        } else {
          const isEnabled = checkIsAuthorised({
            userPermission,
          })
          tmpCollabUsers.push({
            ...userCollection,
            isEnableDropdown: isEnabled,
            isEnableDelete: isEnabled,
          })
        }
      })

      // only allow adjusting permissions for self if there are other people that can adjust permission and if u have admin rights
      const isEnablePermissionChangeForSelf =
        !!tmpCollabUsers.find(
          (userCollection) => userCollection?.permission === PERMISSIONS.MANAGE
        ) && isManage

      currentUserCollection.isEnableDropdown = isEnablePermissionChangeForSelf
      // can delete urself freely as long as you don't have manage rights
      currentUserCollection.isEnableDelete =
        isEnablePermissionChangeForSelf || !isManage
      tmpCollabUsers.unshift(currentUserCollection)
      // insert it into front of the array
      return tmpCollabUsers
    }
  }, [isManage, userCollections, userId, userPermission])

  const value = useMemo(
    () => ({
      collabUsers,
      isManage,
      userPermission,
      isEdit,
      isUserCollectionsLoading,
    }),
    [collabUsers, isManage, userPermission, isEdit, isUserCollectionsLoading]
  )

  return (
    <CollabContext.Provider value={value}>{children}</CollabContext.Provider>
  )
}
