import * as uuid from 'uuid';

export const generateUniqueId = (
  collectionName: string,
  collectionIdSet: Set<string>,
) => {
  const newId = collectionName + '_' + uuid.v4();

  if (collectionIdSet.has(newId)) {
    return generateUniqueId(collectionName, collectionIdSet);
  } else {
    return newId;
  }
};
