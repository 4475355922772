import {
  HStack,
  VStack,
  Text,
  Spinner,
  Icon,
  Flex,
  useDisclosure,
  Tooltip,
  Stack,
  Button,
} from '@chakra-ui/react'
import { useMemo, useState, memo } from 'react'
import {
  CreateAndInsertTagReq,
  CreateOpensourceArticleReq,
  DeleteDocumentTagReq,
  DocumentTagWithCategory,
} from '~shared/dtos'
import { useFormikContext } from 'formik'
import { useIsMobile } from '@/hooks'
import { TiTick } from 'react-icons/ti'
import { AddTagButton } from '@/components/tag/AddTagButton'
import { DocumentTagsWithDeleteButton } from '@/features/document/document-tag-components'
import { RecommendedTags } from '@/components/tag/RecommendedTags'
import { lowercaseAndRemoveWhitespace } from '~shared/utils'
import { AddTagDrawer } from '@/features/document/document-tag-components/AddTagDrawer'
import { useToast } from '@/hooks/useToast'
import { useUploadDocumentContext } from '../useUploadDocumentContext'

export const DocumentTagsFieldComponent = () => {
  const {
    setFieldValue,
    initialValues: { tags },
  } = useFormikContext<CreateOpensourceArticleReq>()

  const {
    textContent,
    isGenerateTagsLoading,
    isGenerateTagsError,
    generatedTags,
  } = useUploadDocumentContext()

  const isMobile = useIsMobile()

  // for createTagModal
  const [documentTagsState, setDocumentTagsState] = useState<
    DocumentTagWithCategory[]
  >(tags ?? [])
  const toast = useToast()
  const {
    isOpen: isAddTagOpen,
    onOpen: onAddTagOpen,
    onClose: onAddTagClose,
  } = useDisclosure()

  const recommendedTags = useMemo(() => {
    if (generatedTags) {
      const names = new Set(
        documentTagsState &&
          documentTagsState.map((tag) => {
            return `${tag.displayName.toLowerCase()}:${tag.category}`
          })
      )
      return generatedTags.filter(
        (tag) => !names.has(`${tag.displayName.toLowerCase()}:${tag.category}`)
      )
    }
  }, [generatedTags, documentTagsState])

  // when user add tags to document
  const addTagFieldToDocument = (newTag: CreateAndInsertTagReq) => {
    const isDuplicate = !!documentTagsState?.find(
      (tag) =>
        lowercaseAndRemoveWhitespace(tag.displayName) ===
        lowercaseAndRemoveWhitespace(newTag.displayName)
    )
    if (!isDuplicate) {
      const newDocumentTag = [...(documentTagsState ?? []), newTag]
      setDocumentTagsState(newDocumentTag)
      setFieldValue('tags', newDocumentTag)
    } else {
      toast({
        title: 'Tag already added',
        status: 'info',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  // when user delete tags from document
  const removeTagFromDocument = ({ displayName }: DeleteDocumentTagReq) => {
    const newDocumentTag =
      documentTagsState?.filter((tag) => tag.displayName !== displayName) ?? []
    setDocumentTagsState(newDocumentTag)
    setFieldValue('tags', newDocumentTag)
  }

  const getRecommendedTagsStatus = () => {
    if (!textContent) {
      return null
    }

    if (isGenerateTagsLoading) {
      return <Spinner width="1.5rem" height="1.5rem" />
    }

    return (
      <Tooltip
        isDisabled={isMobile}
        label="All recommended tags loaded"
        placement="top"
      >
        <Flex alignSelf="center">
          <Icon width="1.5rem" height="1.5rem" color="green" as={TiTick} />
        </Flex>
      </Tooltip>
    )
  }

  return (
    <VStack align="start" w="100%" h="100%" mt="1rem">
      <HStack
        width={{ base: '100%', lg: 'fit-content' }}
        justifyContent={{
          base: 'space-between',
          lg: 'flex-start',
        }}
      >
        <Text
          fontSize={{ lg: '1rem', base: '0.875rem' }}
          color="gray.600"
          fontWeight="semibold"
          w="auto"
        >
          Tags
        </Text>
        {!isMobile ? (
          <AddTagButton
            documentTags={documentTagsState}
            addTagToDocument={addTagFieldToDocument}
          />
        ) : (
          <Button
            variant="link"
            fontSize={{ lg: '1rem', base: '0.875rem' }}
            textDecoration="underline"
            color="orange.500"
            onClick={onAddTagOpen}
          >
            Add or create tag
          </Button>
        )}
      </HStack>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing="1rem"
        w="100%"
        minH={{
          lg: '7.5rem',
        }}
        align="left"
      >
        <Flex
          bg="white"
          w={{ base: '100%', lg: '50%' }}
          h="100%"
          minH={{
            lg: '7.5rem',
          }}
          p="1rem"
          borderRadius="1rem"
        >
          <DocumentTagsWithDeleteButton
            selectedTags={documentTagsState}
            removeTagFromDocument={removeTagFromDocument}
          />
        </Flex>
        <VStack w={{ base: '100%', lg: '50%' }} align="left">
          <HStack align="center" justify="flex-start">
            <Text fontSize="0.875rem" fontStyle="italic">
              Recommended Tags
            </Text>
            {getRecommendedTagsStatus()}
          </HStack>
          <Flex>
            <>
              {isGenerateTagsError ? (
                <>
                  <Text color="red" fontStyle="italic" fontSize="1rem">
                    {`Something went wrong when generating tags. Please try again later.`}
                  </Text>
                </>
              ) : (
                <RecommendedTags
                  tags={recommendedTags ?? []}
                  addTagToDocument={addTagFieldToDocument}
                />
              )}
            </>
          </Flex>
        </VStack>
      </Stack>
      <AddTagDrawer
        isOpen={isAddTagOpen}
        onClose={onAddTagClose}
        documentTagsState={documentTagsState}
        addTagToDocument={async (
          createAndInsertTagReq: CreateAndInsertTagReq
        ) => addTagFieldToDocument(createAndInsertTagReq)}
        isCreateAndInsertTagLoading={false}
      />
    </VStack>
  )
}

export const DocumentTagsField = memo(DocumentTagsFieldComponent)
