import { LanguagesRes, TranslateReq, TranslateRes } from '~shared/dtos'
import { ApiService } from './ApiService'

export const detectAndGetLanguages = async (text: string) => {
  const { data } = await ApiService.post<LanguagesRes>(`translate/languages`, {
    text,
  })
  return data
}

export const translateTitleAndContent = async (payload: TranslateReq) => {
  const { data } = await ApiService.post<TranslateRes>(`translate`, payload)
  return data
}
