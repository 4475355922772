import { HStack, Icon, Spinner, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { DocumentTagWithCategory } from '~shared/dtos'
import { AddTagButton } from '@/components/tag/AddTagButton'
import { useAddTagToDocument } from '@/hooks/useAddTagToDocument'
import { DisplayDocumentTags } from './document-tag-components/DisplayDocumentTags'
import { BiTag } from 'react-icons/bi'
import { useIsMobile } from '@/hooks'

type DocumentTagsComponentProps = {
  addTagToDocument: ({ displayName, category }) => void
  documentId?: string
  documentTags: DocumentTagWithCategory[]
  isLoading?: boolean
  isTruncated?: boolean
}

export const DocumentTagsComponent = ({
  addTagToDocument,
  documentTags,
  isLoading,
  isTruncated,
}: DocumentTagsComponentProps) => {
  const isMobile = useIsMobile()

  return (
    <HStack
      align="start"
      wrap="wrap"
      minHeight="100%"
      height="100%"
      w="100%"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {!isMobile && (
        <HStack w="100%" spacing="0.1rem">
          <Icon as={BiTag} />
          <Text fontSize="0.75rem" fontWeight="600">
            TAGS
          </Text>
          {isLoading && <Spinner size="xs" ml="0.5rem" />}
        </HStack>
      )}
      <HStack w="100%" align="start">
        <DisplayDocumentTags isTruncated={isTruncated} tags={documentTags}>
          {!isMobile && (
            <AddTagButton
              isLoading={isLoading}
              documentTags={documentTags}
              addTagToDocument={addTagToDocument}
            />
          )}
        </DisplayDocumentTags>
      </HStack>
    </HStack>
  )
}

type DocumentTagsProps = {
  documentId: string
  documentTags?: DocumentTagWithCategory[]
  isTruncated?: boolean
}

export const DocumentTags = ({
  documentId,
  documentTags,
  isTruncated,
}: DocumentTagsProps) => {
  // to trigger re-render when documentTags change
  const [documentTagsState, setDocumentTagsState] = useState<
    DocumentTagWithCategory[]
  >(documentTags ?? [])

  // handle states for navigating between screens
  useEffect(() => {
    if (documentTags) {
      setDocumentTagsState(documentTags)
    }
  }, [documentTags])

  const { addTagToDocument, isCreateAndInsertTagLoading } = useAddTagToDocument(
    +documentId,
    setDocumentTagsState
  )

  return (
    <DocumentTagsComponent
      addTagToDocument={addTagToDocument}
      documentId={documentId}
      documentTags={documentTagsState}
      isLoading={isCreateAndInsertTagLoading}
      isTruncated={isTruncated}
    />
  )
}
