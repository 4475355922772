import { useAuthContext } from '@/features/auth'
import { EmailBody } from '../features/auth/components/EmailBody'
import { OtpBody } from '../features/auth/components/OtpBody'
import { Box, VStack } from '@chakra-ui/react'
import { AppFooter } from '@/app/AppFooter'

export const LoginPage = () => {
  const { email } = useAuthContext()

  return (
    <VStack
      alignItems="left"
      w="100%"
      spacing="0"
      background="background.default"
    >
      <VStack minHeight="100vh" spacing="0" alignItems="left" w="100%">
        <Box pb="0.75rem" pr="2rem" w="100%" display="flex" pt="2.5rem" />
        {email ? <OtpBody /> : <EmailBody />}
      </VStack>
      <AppFooter />
    </VStack>
  )
}
