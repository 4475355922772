//https://gist.github.com/phanngoc/473229c74d0119704d9c603b1251782a
//https://github.com/ianstormtaylor/slate/blob/main/site/examples/richtext.tsx

import { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import isHotkey from 'is-hotkey'
import {
  Editable,
  withReact,
  Slate,
  RenderElementProps,
  RenderLeafProps,
} from 'slate-react'
import { Descendant, Editor, Transforms, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { BlockButton, Button, Element, Leaf, MarkButton } from './components'
import { MdImage } from 'react-icons/md'
import { FieldProps } from 'formik'
import { HStack, Text, VStack } from '@chakra-ui/react'
import {
  convertExtractedContentToSlateCustomElements,
  insertImage,
  toggleBlock,
  toggleMark,
  withImagesAndInlines,
} from './utils'
import { S3_UPLOAD_MAX_FILE_SIZE } from '~shared/constants'
import { ErrorMessageWithIcon } from '../error/ErrorMessageWithIcon'
import { BulletedListElement, CustomElement, NumberedListElement } from './type'
import { SlateTextTypes } from '~shared/types'
import { AddLinkButton } from './components/Link'
import { useIsMobile } from '@/hooks'
import { useUploadDocumentContext } from '@/features/upload/useUploadDocumentContext'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
}

type SlateTextEditorProps = {
  field: FieldProps<Descendant[]>
  name: string
}

export const SlateTextEditor = ({
  field: helper,
  name,
}: SlateTextEditorProps) => {
  const { field, form } = helper
  const { documentContent, isContentLoading } = useUploadDocumentContext()

  const isMobile = useIsMobile()
  const [isFocused, setIsFocused] = useState(false)
  const { currentSlateContentSize, isFieldTooLarge } =
    useUploadDocumentContext()

  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  )
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  )
  const editor = useMemo(
    () => withImagesAndInlines(withHistory(withReact(createEditor()))),
    []
  )

  const fileUploadRef = useRef<HTMLInputElement | null>(null)

  const onChangeUpload = (e) => {
    for (const file of e.target.files) {
      const reader = new FileReader()
      const [mime] = file.type.split('/')
      if (mime === 'image') {
        reader.addEventListener('load', () => {
          const url = reader.result as string
          insertImage(editor, url)
        })
        reader.readAsDataURL(file)
      }
    }
  }

  const handleBackspace = (editor) => {
    const [isEmptyBullet] = Editor.nodes(editor, {
      match: (n) =>
        (n as CustomElement).type === SlateTextTypes.BULLETED_LIST &&
        (n as BulletedListElement).children[0].children.length === 1 &&
        (n as BulletedListElement).children[0].children[0]?.text === '',
    })
    const [isEmptyNumbered] = Editor.nodes(editor, {
      match: (n) =>
        (n as CustomElement).type === SlateTextTypes.NUMBERED_LIST &&
        (n as NumberedListElement).children[0].children.length === 1 &&
        (n as NumberedListElement).children[0].children[0]?.text === '',
    })
    if (isEmptyBullet) {
      toggleBlock(editor, SlateTextTypes.BULLETED_LIST)
    } else if (isEmptyNumbered) {
      toggleBlock(editor, SlateTextTypes.NUMBERED_LIST)
    }
  }

  const handleEnter = (event, editor) => {
    if (event.key !== 'Enter') return

    const currentParent = getCurrentParent(editor)
    const isListItem = currentParent?.type === SlateTextTypes.LIST_ITEM

    if (shouldInsertParagraph(event, isListItem, currentParent)) {
      handleListInsert(event, editor)
    } else {
      handleDefaultEnter(event, editor)
    }
  }

  const getCurrentParent = (editor) => {
    return editor.selection
      ? (editor.parent(editor.selection.focus)[0] as CustomElement)
      : null
  }

  const shouldInsertParagraph = (event, isListItem, currentParent) => {
    return (
      event.shiftKey || (isListItem && currentParent.children[0].text === '')
    )
  }

  const handleListInsert = (event, editor) => {
    const [isBullet] = Editor.nodes(editor, {
      match: (n) => (n as CustomElement).type === SlateTextTypes.BULLETED_LIST,
    })
    const [isNumbered] = Editor.nodes(editor, {
      match: (n) => (n as CustomElement).type === SlateTextTypes.NUMBERED_LIST,
    })

    if (isBullet || isNumbered) {
      if (!event.shiftKey) editor.deleteBackward('block')
      insertParagraph(editor)
      toggleBlock(
        editor,
        isBullet ? SlateTextTypes.BULLETED_LIST : SlateTextTypes.NUMBERED_LIST
      )
    } else {
      insertParagraph(editor)
    }
  }

  const insertParagraph = (editor) => {
    Transforms.insertNodes(editor, {
      type: SlateTextTypes.PARAGRAPH,
      children: [{ text: '' }],
    })
  }

  const handleDefaultEnter = (event, editor) => {
    event.preventDefault()
    const [match] = Editor.nodes(editor, {
      match: (n) => (n as CustomElement).type === SlateTextTypes.LIST_ITEM,
    })
    Transforms.insertNodes(editor, {
      type: match ? SlateTextTypes.LIST_ITEM : SlateTextTypes.PARAGRAPH,
      children: [{ text: '' }],
    })
  }

  const handleHotkeys = (event, editor) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault()
        toggleMark(editor, HOTKEYS[hotkey])
      }
    }
  }

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Backspace':
        handleBackspace(editor)
        break
      case 'Enter':
        handleEnter(event, editor)
        break
      default:
        handleHotkeys(event, editor)
        break
    }
  }

  // Deselect the editor when it loses focus so that slate doesn't error
  useEffect(() => {
    if (!isFocused) {
      editor.deselect()
    }
    // shouldn't re-run this effect when editor changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused])

  // this will update the editor's content when the document content is loaded
  // document content is paginated, so slateContent will repeatedly update for awhile
  useEffect(() => {
    if (documentContent) {
      const slateContentFromTextract =
        convertExtractedContentToSlateCustomElements(documentContent)
      form.setFieldValue('slateContent', slateContentFromTextract)
      editor.children = slateContentFromTextract
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentContent])

  // todo(ben): prevent scrolling to the top upon content load
  return (
    <Slate
      // todo(ben): this key is a hack to force the editor to re-render when the document content is loaded
      key={name + field.value?.length.toString()}
      editor={editor}
      initialValue={field.value ?? []}
      onValueChange={(descendants) => {
        form.setFieldValue(name, descendants)
      }}
    >
      <VStack spacing="0px" align="left" w="100%">
        <VStack
          align="left"
          w="100%"
          spacing="0px"
          borderRadius="16px"
          _hover={
            isFocused
              ? {}
              : { border: '2px dashed #ED8936', borderRadius: '16px' }
          }
          border="2px"
          borderColor={isFocused ? 'orange.400' : 'transparent'}
        >
          <Toolbar
            fileUploadRef={fileUploadRef}
            onChangeUpload={onChangeUpload}
          />
          <Editable
            readOnly={isContentLoading}
            style={{
              overflowY: 'auto',
              borderBottomLeftRadius: '16px',
              borderBottomRightRadius: '16px',
              outline: 'none',
              border: 'none',
              height: '523px',
              cursor: isContentLoading ? 'not-allowed' : 'text',
              background: isContentLoading ? '#EDF2F7' : 'white',
              padding: '16px',
              width: '100%',
              fontSize: isMobile ? '0.875rem' : '1rem',
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={handleKeyDown}
          />
        </VStack>
        <VStack align="left">
          <Text color={isFieldTooLarge ? 'red' : 'default'} fontSize="xs">
            {`Size of current content field remaining: ${
              S3_UPLOAD_MAX_FILE_SIZE - currentSlateContentSize - 1
            }`}
          </Text>
          <ErrorMessageWithIcon fieldName={name} />
        </VStack>
      </VStack>
    </Slate>
  )
}

const Toolbar = ({ fileUploadRef, onChangeUpload }) => (
  <HStack
    borderTopRadius="16px"
    w="100%"
    spacing="8px"
    padding="8px"
    height="44px"
    bg="gray.100"
  >
    <MarkButton format={SlateTextTypes.BOLD} />
    <MarkButton format={SlateTextTypes.ITALIC} />
    <MarkButton format={SlateTextTypes.UNDERLINE} />
    <BlockButton format={SlateTextTypes.NUMBERED_LIST} />
    <BlockButton format={SlateTextTypes.BULLETED_LIST} />
    <AddLinkButton />
    <Button
      onMouseDown={(event) => {
        event.preventDefault()
        if (fileUploadRef.current) {
          fileUploadRef.current.click()
        }
      }}
    >
      <MdImage color="black" size={20} />
    </Button>
    <input
      accept="image/*"
      type="file"
      onChange={onChangeUpload}
      id="chooseFile"
      hidden={true}
      ref={fileUploadRef}
    />
  </HStack>
)
