import { AwsRum } from 'aws-rum-web'
import { createContext, useContext } from 'react'

type AwsRumContextProps = {
  awsRum: AwsRum | null
}

export const AwsRumContext = createContext<AwsRumContextProps | undefined>(
  undefined
)

export const useAwsRumContext = () => {
  const context = useContext(AwsRumContext)
  if (!context) {
    throw new Error(
      `useAwsRumContext must be used within a AwsRumContext component`
    )
  }
  return context
}
