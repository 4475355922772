import { IconButton, Tooltip } from '@chakra-ui/react'
import { AddBookmarkModal } from './add/AddBookmarkModal'
import { BookmarkProvider } from './BookmarkContext'
import { useBookmarkContext } from './useBookmarkContext'
import { useIsMobile } from '@/hooks'
import { AddBookmarkDrawer } from './add/AddBookmarkDrawer'
import { MdOutlineCopyAll } from 'react-icons/md'
import { CollectionInSearchResults } from '~shared/dtos'

export const BookmarkButtonComponent = () => {
  const {
    disclosureProps: { onOpen },
  } = useBookmarkContext()

  const isMobile = useIsMobile()

  return (
    <>
      <Tooltip
        isDisabled={isMobile}
        hasArrow
        borderRadius="1.25rem"
        label="Copy article to collection"
      >
        <IconButton
          bg={'parent'}
          borderRadius="50%"
          aria-label="Bookmark"
          icon={<MdOutlineCopyAll size="1.5rem" />}
          onClick={async (e) => {
            e.stopPropagation()
            onOpen()
          }}
        />
      </Tooltip>
      {isMobile ? <AddBookmarkDrawer /> : <AddBookmarkModal />}
    </>
  )
}

type BookmarkButtonProp = {
  documentId: string
  documentTitle: string
  documentCollections: CollectionInSearchResults[]
}

export const BookmarkButton = ({
  documentId,
  documentTitle,
  documentCollections,
}: BookmarkButtonProp) => {
  return (
    <BookmarkProvider
      documentCollections={documentCollections}
      documentId={+documentId}
      documentTitle={documentTitle}
    >
      <BookmarkButtonComponent />
    </BookmarkProvider>
  )
}
