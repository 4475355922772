import { ErrorMessageWithIcon } from '@/components/error/ErrorMessageWithIcon'
import { useIsMobile } from '@/hooks'
import {
  Button,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { FiLink } from 'react-icons/fi'
import { createSearchParams, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { URL_MODAL_HEADER_TEXT } from '@/features/upload/constants'

// ^(http|https):\/\/[^ "]+$/;, yup regex to check if valid url
const schema = Yup.object().shape({
  url: Yup.string()
    .url('Please enter a valid URL (url should start with http/https)')
    .trim()
    .required('URL is required'),
})

export const UploadArticleUrlForm = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const handleSubmit = (values) => {
    if (values.url) {
      navigate({
        pathname: '/upload/article',
        search: createSearchParams({
          url: `${values.url}`,
        }).toString(),
      })
    }
  }

  return (
    <FormControl w="100%">
      <Formik
        initialValues={{ url: '' }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        <Form>
          <Field name="url">
            {({ field, form }) => (
              <FormControl
                w="100%"
                isInvalid={form.errors.url && form.touched.url}
              >
                {isMobile && (
                  <Text
                    color="gray.800"
                    fontSize="1.125rem"
                    fontWeight={700}
                    marginBottom={'0.75rem'}
                  >
                    {URL_MODAL_HEADER_TEXT}
                  </Text>
                )}

                <Stack w="100%" direction={{ base: 'column', lg: 'row' }}>
                  <InputGroup
                    borderRadius="20px"
                    background="white"
                    height="40px"
                    w={{
                      base: '100%',
                      lg: '85%',
                    }}
                  >
                    <InputLeftElement>
                      <Icon as={FiLink} color="gray.500" />
                    </InputLeftElement>
                    <Input
                      w="100%"
                      value={field.value}
                      onChange={field.onChange}
                      name="url"
                      borderRadius="20px"
                      border="1px"
                      borderColor="gray.500"
                      placeholder={'Add article URL'}
                    />
                  </InputGroup>
                  <Button
                    w={{
                      base: '100%',
                      lg: '15%',
                    }}
                    fontSize="16px"
                    fontWeight="semibold"
                    size="lg"
                    bg={{ base: 'green.100', lg: 'brand.primary.500' }}
                    borderRadius="20px"
                    color={{ base: 'green.800', lg: 'white' }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Stack>
                <ErrorMessageWithIcon fieldName="url" />
              </FormControl>
            )}
          </Field>
        </Form>
      </Formik>
    </FormControl>
  )
}
