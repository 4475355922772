import { CONTACT_PRESSIFY_FORM_WITHOUT_GO_GOV } from '@/constants/feedback'
import { Button, Icon } from '@chakra-ui/react'
import { MdError } from 'react-icons/md'
export const ReportIssueButton = () => {
  return (
    <Button
      role="group"
      py="0.5rem"
      px="1rem"
      borderRadius="1.5rem"
      bg="parent"
      w="fit-content"
      onClick={() => {
        window.open(CONTACT_PRESSIFY_FORM_WITHOUT_GO_GOV)
      }}
      _hover={{
        cursor: 'pointer',
        bg: 'red.100',
        color: 'red.800',
      }}
      leftIcon={
        <Icon
          _groupHover={{
            color: 'red.800',
          }}
          color="red"
          fontSize="0.75rem"
          as={MdError}
        />
      }
      fontWeight="400"
      fontSize="0.875rem"
      color="red"
    >
      Report an issue
    </Button>
  )
}
