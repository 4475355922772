import { DocumentTagWithCategory } from '~shared/dtos'
import {
  Center,
  Checkbox,
  Highlight,
  HStack,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { ForwardedRef, forwardRef } from 'react'
import { useFilterTagContext } from './useFilterTagContext'

const CheckboxControlStyle = {
  '& .chakra-checkbox__control': {
    background: 'white !important',
    color: 'gray.800 !important',
    borderColor: 'white !important',
  },
}

type CheckboxTags = DocumentTagWithCategory & {
  isChecked: boolean
}

type TagResultListProps = {
  query?: string
  tags: CheckboxTags[]
  hasNext?: boolean
}
export const TagResultList = forwardRef(
  (
    { query, tags, hasNext }: TagResultListProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { onCheckboxChange } = useFilterTagContext()
    return tags.map(({ displayName, category, isChecked }, index) => {
      const isLast = index === tags.length - 1
      return (
        <VStack key={displayName + category}>
          <HStack width="100%">
            <Checkbox
              _checked={CheckboxControlStyle}
              _active={CheckboxControlStyle}
              _hover={CheckboxControlStyle}
              _focus={CheckboxControlStyle}
              onChange={() => {
                onCheckboxChange({
                  displayName,
                  category,
                })
              }}
              isChecked={isChecked}
            />
            <Stack
              onClick={() => {
                onCheckboxChange({
                  displayName,
                  category,
                })
              }}
            >
              <Text color="white" isTruncated>
                <Highlight
                  query={query ?? ''}
                  styles={{ color: 'brand.primary.300' }}
                >
                  {displayName}
                </Highlight>
              </Text>
            </Stack>
          </HStack>
          {isLast && hasNext && (
            <Center>
              <Spinner size="sm" color="gray.50" ref={ref} />
            </Center>
          )}
        </VStack>
      )
    })
  }
)
