import { overflowScrollBarStyles } from '@/custom-css-prop/overflow-scrollbar-styles'
import { ButtonGroup, ButtonGroupProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

type SidebarButtonListProps = {
  style?: ButtonGroupProps
  children: ReactNode
}

export const SidebarButtonList = ({
  style,
  children,
}: SidebarButtonListProps) => {
  return (
    <ButtonGroup
      maxHeight="40rem"
      flexDirection="column"
      size="sm"
      variant="ghost"
      w="100%"
      overflowY="auto"
      display="block"
      css={overflowScrollBarStyles}
      {...style}
    >
      {children}
    </ButtonGroup>
  )
}
