import { BiPencil } from 'react-icons/bi'
import { RenameCollectionForm } from './RenameCollectionForm'
import { RENAME_COLLECTION_TEXT_HEADER } from '@/constants/title'
import { DrawerLayout } from '@/components/drawer/DrawerLayout'

type RenameCollectionDrawerProps = {
  isOpen: boolean
  onClose: () => void
  collectionName: string
  collectionId: number
}

export const RenameCollectionDrawer = ({
  isOpen,
  onClose,
  collectionName,
  collectionId,
}: RenameCollectionDrawerProps) => {
  return (
    <DrawerLayout
      title={RENAME_COLLECTION_TEXT_HEADER}
      isOpen={isOpen}
      onClose={onClose}
      HeaderIcon={BiPencil}
    >
      <RenameCollectionForm
        onClose={onClose}
        collectionName={collectionName}
        collectionId={collectionId}
      />
    </DrawerLayout>
  )
}
