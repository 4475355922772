import { NavBarHeader } from '@/components/header'
import { useIsMobile, useManualUpload } from '@/hooks'
import { Divider, Flex, useDisclosure, VStack } from '@chakra-ui/react'
import { Formik } from 'formik'
import {
  isDateValid,
  uploadValidationSchema,
} from '@/features/upload/validator'
import { UploadArticleForm } from '@/features/upload/components/UploadArticleForm'
import { ManualUploadForm } from '@/types/document'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { ROOT_DOCUMENT_PATH } from '@/utils/paths'
import { isNewlyCreatedDocIndicator } from '@/constants/search-params'
import { MobileUploadHeader } from './components/MobileUploadHeader'
import { WarnBeforeExitDrawer } from './components/WarnBeforeExitDrawer'
import { queryKeys } from '@/constants/query-key'
import { uploadErrorToast } from './upload-error-toast'
import {
  InfiniteQueryObserverResult,
  useQueryClient,
} from '@tanstack/react-query'
import {
  EMPTY_COLLECTION_VALUE,
  EMPTY_SLATE_CONTENT,
} from '@/features/upload/constants'
import { AwsPaginatedRes, TextractContent } from '~shared/dtos'
import { useToast } from '@/hooks/useToast'

type UploadPageLayoutProps = {
  title: string
  documentContent?: TextractContent[]
  isContentLoading?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => Promise<
    InfiniteQueryObserverResult<AwsPaginatedRes<TextractContent[]>, unknown>
  >
}

// todo(ben): refactor edit article page to use this layout
export const UploadPageLayout = ({
  title,
  documentContent,
  isContentLoading,
  hasNextPage,
  fetchNextPage,
}: UploadPageLayoutProps) => {
  const toast = useToast()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { manualUploadMutate } = useManualUpload()

  const handleSave = async (values: ManualUploadForm) => {
    const isValid = isDateValid(values.date, values.time, values.timeZone)

    if (!isValid) {
      toast({
        description: 'Future date/time not allowed.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return
    }

    const { id } = await manualUploadMutate(values, {
      onError: (err) => {
        uploadErrorToast(err, toast)
        queryClient.invalidateQueries(queryKeys.searchCollections())
      },
    })
    toast({
      description: 'Article Published!',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
    navigate({
      pathname: `/${ROOT_DOCUMENT_PATH}/${id}`,
      search: createSearchParams(isNewlyCreatedDocIndicator).toString(),
    })
  }

  const initialValues = {
    slateContent: EMPTY_SLATE_CONTENT,
    vernacular: undefined,
    title: '',
    date: '',
    time: '',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    tags: [],
    url: '',
    collection: EMPTY_COLLECTION_VALUE,
  }

  return (
    <VStack
      w="100%"
      backgroundColor="background.default"
      spacing={{
        lg: '0',
        base: '1rem',
      }}
      padding={{
        lg: '0',
        base: '0.75rem 1.5rem 0.75rem 1.5rem',
      }}
      align={{
        lg: 'auto',
        base: 'left',
      }}
    >
      {!isMobile && (
        <>
          <NavBarHeader hasLogo title={title} />
          <Divider height="2.25rem" borderColor="transparent" />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={(values: ManualUploadForm) =>
          handleSave({
            ...values,
          })
        }
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={uploadValidationSchema}
      >
        {({ dirty }) => (
          <>
            {isMobile && (
              <MobileUploadHeader
                title={title}
                onClose={() => {
                  if (dirty) {
                    onOpen()
                  } else {
                    navigate(-1)
                  }
                }}
              />
            )}
            <UploadArticleForm
              documentContent={documentContent}
              isContentLoading={isContentLoading}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              onCancel={() => {
                if (!isMobile) {
                  return
                }
                if (dirty) {
                  onOpen()
                } else {
                  navigate(-1)
                }
              }}
            />
          </>
        )}
      </Formik>
      {!isMobile && (
        <Flex
          // to overlap the submit button and footer
          mt="-1.125rem"
          width="100%"
          minHeight="4rem"
          bg="visualisation.sidebar.background"
          borderTopLeftRadius="50px"
        />
      )}
      {isMobile && (
        <WarnBeforeExitDrawer isOpen={isOpen} onClose={onClose} title={title} />
      )}
    </VStack>
  )
}
