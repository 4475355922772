import { Link, Tag } from '@chakra-ui/react'

type DocumentCardSourceProps = {
  sourceLink: string
  sourceName?: string
}

export const DocumentCardSource = ({
  sourceLink,
  sourceName,
}: DocumentCardSourceProps) => {
  return (
    <Tag
      w="fit-content"
      height="1.25rem"
      fontSize="0.875rem"
      borderRadius="0.375rem"
      py="0.5rem"
      marginBottom="0.5rem"
      textColor="orange.600"
      bg="orange.100"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      Source: <span>&nbsp;</span>
      <Link isExternal textDecoration="underline" href={sourceLink}>
        {sourceName ?? sourceLink}
      </Link>
    </Tag>
  )
}
