import { ComponentPropsWithRef } from 'react'
import { IconButton } from '@chakra-ui/react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

const ButtonStyle = {
  appearance: 'none',
  backgroundColor: 'transparent',
  touchAction: 'manipulation',
  display: 'inline-flex',
  textDecoration: 'none',
  cursor: 'pointer',
  border: '0',
  padding: '0',
  margin: '0',
  width: '3.6rem',
  height: '3.6rem',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
}

type ArrowButtonProps = ComponentPropsWithRef<'button'>

export const PrevButton = ({ disabled, ...restProps }: ArrowButtonProps) => {
  return (
    <IconButton
      variant="outline"
      aria-label="Previous"
      sx={ButtonStyle}
      _active={{ bg: 'transparent' }}
      _hover={{ bg: 'transparent' }}
      icon={<FaArrowLeft color={disabled ? '#CBD5E0' : '#2D3748'} />}
      {...restProps}
    />
  )
}

export const NextButton = ({ disabled, ...restProps }: ArrowButtonProps) => {
  return (
    <IconButton
      variant="outline"
      aria-label="Previous"
      sx={ButtonStyle}
      _active={{ bg: 'transparent' }}
      _hover={{ bg: 'transparent' }}
      icon={<FaArrowRight color={disabled ? '#CBD5E0' : '#2D3748'} />}
      {...restProps}
    />
  )
}
