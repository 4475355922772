export const colors = {
  background: {
    default: '#FBF9F9',
  },
  brand: {
    primary: {
      50: '#FFFAF0',
      100: '#FEEBCB',
      200: '#FBD38D',
      300: '#F6AD55',
      400: '#ED8936',
      500: '#DD6B20',
      600: '#C05621',
      700: '#9C4221',
      800: '#7B341E',
      900: '#652B19',
    },
    secondary: {
      50: '#FFFFF0',
      100: '#FEFCBF',
      200: '#FAF089',
      300: '#F6E05E',
      400: '#ECC94B',
      500: '#D69E2E',
      600: '#B7791F',
      700: '#975A16',
      800: '#744210',
      900: '#5F370E',
    },
  },
  // temporary placeholder until design style tokens are up
  visualisation: {
    card: {
      snippet: '#DAD8D2',
    },
    sidebar: {
      background: '#DAD8D2',
    },
    divider: '#1A202C',
  },
}
