import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { RenameCollectionForm } from './RenameCollectionForm'
import { RENAME_COLLECTION_TEXT_HEADER } from '@/constants/title'

type RenameCollectionModalProps = {
  isOpen: boolean
  onClose: () => void
  collectionId: number
  collectionName: string
}

export const RenameCollectionModal = ({
  isOpen,
  onClose,
  collectionId,
  collectionName,
}: RenameCollectionModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent padding="1.5rem" background="background.default">
        <ModalHeader px="0" pt="0" pb="1rem">
          {RENAME_COLLECTION_TEXT_HEADER}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="0" w="100%">
          <RenameCollectionForm
            onClose={onClose}
            collectionId={collectionId}
            collectionName={collectionName}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
