import { useMediaQuery, useTheme } from '@chakra-ui/react'
import { get } from 'lodash'

export const useIsMobile = () => {
  const theme = useTheme()
  // https://v2.chakra-ui.com/docs/styled-system/theme#breakpoints
  const lgBreakpoint = get(theme, 'breakpoints.lg', '62em')
  const [isWiderThanLg] = useMediaQuery(`(min-width: ${lgBreakpoint})`, {
    ssr: false,
  })
  return !isWiderThanLg
}
