import {
  COLLECTION_NAME_REGEX_VALIDATION,
  DEFAULT_COLLECTION,
  MAX_COLLECTION_NAME_LENGTH,
} from '~shared/constants'
import * as Yup from 'yup'

export const CreateCollectionSchema = Yup.object().shape({
  collectionName: Yup.string()
    .trim()
    .required('Name is Required')
    .max(
      MAX_COLLECTION_NAME_LENGTH,
      'Collection name cannot exceed 24 characters'
    )
    .matches(
      COLLECTION_NAME_REGEX_VALIDATION,
      'Only alphanumeric characters are allowed'
    )
    .test(
      'is-not-default-collection',
      ({ value }) => `"${value}" cannot be used as a collection name`,
      (value) => value.toLowerCase() !== DEFAULT_COLLECTION.toLowerCase()
    ),
})
