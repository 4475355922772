import { Stack, Text, Image } from '@chakra-ui/react'

interface FeatureGridItemProps {
  Icon: string
  title: string
  description: string
  futureFeaturesDescription?: string
}

export const FeatureGridItem = ({
  Icon,
  title,
  description,
  futureFeaturesDescription,
}: FeatureGridItemProps): JSX.Element => {
  return (
    <Stack spacing="1rem">
      <Image src={Icon} width="9rem" height="9.75rem" flexShrink="0" />
      <Text
        as="h3"
        textStyle="h3"
        color="secondary.700"
        fontSize={{ base: '1.5rem', lg: '1.875rem' }}
        style={{
          color: '#2C2E34',
          fontWeight: 'semibold',
          lineHeight: '2.25rem',
        }}
      >
        {title}
      </Text>
      <Text fontWeight={{ base: 'medium', lg: 'normal' }}>
        {description}&nbsp;
        <i>{futureFeaturesDescription}</i>
      </Text>
    </Stack>
  )
}
