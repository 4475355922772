import { InfiniteData } from '@tanstack/react-query'
import { DEFAULT_COLLECTION } from '~shared/constants'
import {
  CollectionInSearchResults,
  DocumentRes,
  PaginatedRes,
} from '~shared/dtos'

export enum UpdateStarredCollectionsActions {
  ADD = 'add',
  REMOVE = 'remove',
}

function isInfiniteData<T>(
  data: InfiniteData<T> | DocumentRes[]
): data is InfiniteData<T> {
  return data && typeof data === 'object' && 'pages' in data
}

export const updateStarredCollections = ({
  oldData,
  documentId,
  starredCollectionId,
  action,
}: {
  oldData: InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[]
  documentId: number
  starredCollectionId: number
  action: UpdateStarredCollectionsActions
}): InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[] => {
  if (isInfiniteData(oldData)) {
    return {
      ...oldData,
      pages: oldData.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((document) =>
            updateDocumentStarredCollection(
              document,
              documentId,
              action,
              starredCollectionId
            )
          ),
        }
      }),
    }
  } else {
    return oldData.map((document) =>
      updateDocumentStarredCollection(
        document,
        documentId,
        action,
        starredCollectionId
      )
    )
  }
}

const updateDocumentStarredCollection = (
  document: DocumentRes,
  documentId: number,
  action: UpdateStarredCollectionsActions,
  starredCollectionId: number
) => {
  if (document.id !== `${documentId}`) {
    return document
  }
  if (action === UpdateStarredCollectionsActions.ADD) {
    const updatedCollections = [...(document.collections ?? [])].concat({
      collectionId: starredCollectionId,
      name: DEFAULT_COLLECTION,
    })
    return {
      ...document,
      collections: updatedCollections,
    }
  }

  if (action === UpdateStarredCollectionsActions.REMOVE) {
    return {
      ...document,
      collections:
        document.collections?.filter(
          (collection) => collection.collectionId !== starredCollectionId
        ) ?? [],
    }
  }

  // default return the original document if no action is performed
  return document
}

export const addCollectionsToQuery = ({
  newCollections,
  oldData,
  documentId,
}: {
  newCollections: CollectionInSearchResults[]
  oldData: InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[]
  documentId: number
}): InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[] => {
  if (isInfiniteData(oldData)) {
    return {
      ...oldData,
      pages: oldData.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((document) =>
            addCollectionsToDocument(document, documentId, newCollections)
          ),
        }
      }),
    }
  } else {
    return oldData.map((document) =>
      addCollectionsToDocument(document, documentId, newCollections)
    )
  }
}

const addCollectionsToDocument = (
  document: DocumentRes,
  documentId: number,
  newCollections: CollectionInSearchResults[]
) => {
  if (+document.id === documentId) {
    return {
      ...document,
      collections: [...(document.collections ?? []), ...newCollections],
    }
  }
  return document
}

export const removeCollectionsFromQuery = ({
  documentId,
  collectionId,
  oldData,
}: {
  documentId: number
  collectionId: number
  oldData: InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[]
}): InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[] => {
  if (isInfiniteData(oldData)) {
    return {
      ...oldData,
      pages: oldData.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((document) =>
            removeCollectionFromDocument(document, documentId, collectionId)
          ),
        }
      }),
    }
  } else {
    return oldData.map((document) =>
      removeCollectionFromDocument(document, documentId, collectionId)
    )
  }
}

export const removeCollectionFromDocument = (
  document: DocumentRes,
  documentId: number,
  collectionId: number
) => {
  if (+document.id === documentId) {
    return {
      ...document,
      collections:
        document.collections?.filter(
          (collection) => collection.collectionId !== collectionId
        ) ?? [],
    }
  }
  return document
}

export const removeDocumentFromQuery = ({
  documentId,
  oldData,
}: {
  documentId: number
  oldData: InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[]
}): InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[] => {
  if (isInfiniteData(oldData)) {
    return {
      ...oldData,
      pages: oldData.pages.map((page) => {
        return {
          ...page,
          totalResults: page.totalResults ? page.totalResults - 1 : 0,
          data: page.data.filter((document) => +document.id !== documentId),
        }
      }),
    }
  } else {
    return oldData.filter((document) => +document.id !== documentId)
  }
}
