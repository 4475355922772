import { queryKeys } from '@/constants/query-key'
import { searchFilterParam } from '@/constants/search-params'
import { useGetUserIdFromContext, useIsMobile } from '@/hooks'
import { useDeleteCollection } from '@/hooks/useCollection'
import { useToast } from '@/hooks/useToast'
import { drawerModalButtonStyles } from '@/theme/styles'
import { Button, Stack } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

type DeleteCollectionActionsProps = {
  collectionId: number
  onClose: () => void
}

const SubmitButton = ({ isLoading, handleSubmit }) => (
  <Button
    bg="red.100"
    color="red.800"
    _hover={{ bg: 'red.500', color: 'orange.50' }}
    type="submit"
    isLoading={isLoading}
    onClick={handleSubmit}
    {...drawerModalButtonStyles}
  >
    Delete collection
  </Button>
)

const CancelButton = ({ onClose, isLoading }) => (
  <Button
    bg="gray.100"
    color="gray.800"
    onClick={onClose}
    isDisabled={isLoading}
    {...drawerModalButtonStyles}
  >
    Cancel
  </Button>
)

export const DeleteCollectionActions = ({
  collectionId,
  onClose,
}: DeleteCollectionActionsProps) => {
  const isMobile = useIsMobile()
  const { userId } = useGetUserIdFromContext()
  const queryClient = useQueryClient()
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams()

  const { deleteCollection, isDeleteCollectionLoading: isLoading } =
    useDeleteCollection()

  const {
    collectionIds: collectionIdsSearchParam,
    indices: indicesParam,
    tags: tagsParam,
    date: { startDate: startDateParam, endDate: endDateParam },
    query: queryParam,
    sort: sortParam,
  } = searchFilterParam
  const deleteAllUrlParams = useCallback(
    (params: URLSearchParams) => {
      params.delete(startDateParam)
      params.delete(endDateParam)
      params.delete(sortParam)
      params.delete(queryParam)
      params.delete(tagsParam)
      params.delete(indicesParam)
      params.delete(collectionIdsSearchParam)
    },
    [
      collectionIdsSearchParam,
      endDateParam,
      indicesParam,
      queryParam,
      sortParam,
      startDateParam,
      tagsParam,
    ]
  )

  const handleSubmit = async () => {
    await deleteCollection(collectionId, {
      onSuccess: async ({ collectionName }) => {
        toast({
          description: `${collectionName} Collection Deleted`,
          status: 'info',
          duration: 3000,
          isClosable: true,
        })
        deleteAllUrlParams(searchParams)
        setSearchParams(searchParams)
        await queryClient.invalidateQueries(queryKeys.collections(userId))
        onClose()
      },
      onError: () => {
        toast({
          description: `Unable to delete collection. Please try again later.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      justify="end"
      w="100%"
      pt="1rem"
      spacing="0.75rem"
    >
      {isMobile ? (
        <>
          <SubmitButton isLoading={isLoading} handleSubmit={handleSubmit} />
          <CancelButton onClose={onClose} isLoading={isLoading} />
        </>
      ) : (
        <>
          <CancelButton onClose={onClose} isLoading={isLoading} />
          <SubmitButton isLoading={isLoading} handleSubmit={handleSubmit} />
        </>
      )}
    </Stack>
  )
}
