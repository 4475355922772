export const IMAGE_FILE_EXTENSIONS = [
  '.jpg',
  '.jpeg',
  '.jfif',
  '.pjpeg',
  '.pjp',
  '.ico',
  '.cur',
  '.gif',
  '.apng',
  '.png',
  '.svg',
];
