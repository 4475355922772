import { DrawerLayout } from '@/components/drawer/DrawerLayout'
import { Button, Text, VStack } from '@chakra-ui/react'
import { AiOutlineUpload } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

export const WarnBeforeExitDrawer = ({
  isOpen,
  onClose,
  title,
}: {
  isOpen: boolean
  onClose: () => void
  title: string
}) => {
  const navigate = useNavigate()
  return (
    <DrawerLayout
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      HeaderIcon={AiOutlineUpload}
    >
      <Text fontSize="1.125rem" fontWeight="bold" paddingBottom="0.5rem">
        Discard Draft
      </Text>
      <Text>Are you sure you want to leave? Your draft will be lost.</Text>
      <VStack width="100%" mt="1rem">
        <Button
          padding="0rem 1.5rem"
          width="100%"
          borderRadius="1.25rem"
          background="orange.500"
          textColor="orange.50"
          onClick={onClose}
        >
          Continue Editing
        </Button>
        <Button
          width="100%"
          variant="ghost"
          textColor="red.500"
          onClick={() => {
            onClose()
            navigate(-1)
          }}
        >
          Leave and discard draft
        </Button>
      </VStack>
    </DrawerLayout>
  )
}
