import {
  Flex,
  HStack,
  Icon,
  Spinner,
  StyleProps,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { FastField, FieldProps } from 'formik'
import { SlateTextEditor } from '@/components/slate-text-editor/SlateTextEditor'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { useIsMobile } from '@/hooks'
import { useUploadDocumentContext } from '../useUploadDocumentContext'
import { memo } from 'react'

type ContentFieldProps = {
  name: string
} & StyleProps

const ContentFieldComponent = ({ name, ...rest }: ContentFieldProps) => {
  const isMobile = useIsMobile()

  const { isContentLoading } = useUploadDocumentContext()

  return (
    <VStack spacing="0" {...rest} w="100%">
      <FastField name={name}>
        {(field: FieldProps) => (
          <>
            <VStack align="left" w="100%" h="100%" spacing="0.25rem">
              <HStack w="100%">
                <Text
                  fontSize={{ lg: '1rem', base: '0.875rem' }}
                  color="gray.600"
                  fontWeight="semibold"
                  paddingLeft={{ base: '0rem', lg: '0.5rem' }}
                >
                  Publication content
                </Text>
                {isContentLoading && <Spinner size="sm" />}
              </HStack>
              <HStack>
                <Text
                  color="gray.600"
                  fontSize={{ lg: '0.875rem', base: '0.75rem' }}
                  fontStyle="italic"
                  paddingLeft={{ base: '0rem', lg: '0.5rem' }}
                >
                  Published content must be RESTRICTED and below
                </Text>
                {!isMobile && (
                  <Tooltip
                    borderRadius="0.5rem"
                    label="Pressify is hosted on GCC which supports storing of up to RESTRICTED/SENSITIVE NORMAL content"
                    placement="top"
                  >
                    {/** need to wrap icon with another component so that tooltip will appear properly */}
                    <Flex>
                      <Icon
                        w="0.875rem"
                        h="0.875rem"
                        aria-label="tool-tip for gcc mention"
                        as={AiFillQuestionCircle}
                      />
                    </Flex>
                  </Tooltip>
                )}
              </HStack>
            </VStack>
            <SlateTextEditor field={field} name={name} />
          </>
        )}
      </FastField>
    </VStack>
  )
}

const ContentField = memo(ContentFieldComponent)

export const ContentFields = () => {
  const { getDisplayProp } = useUploadDocumentContext()

  return (
    <>
      <ContentField
        name="slateContent"
        display={getDisplayProp('slateContent')}
        // using display to handle rendering so that the component is not unmounted
      />
      <ContentField
        name="vernacular.slateContent"
        display={getDisplayProp('vernacular.slateContent')}
        // using display to handle rendering so that the component is not unmounted
      />
    </>
  )
}
