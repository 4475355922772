import { HStack, Text } from '@chakra-ui/layout'
import { TagSearchbar } from '../searchbar/TagSearchBar'
import { useInView } from 'react-intersection-observer'

import {
  Accordion,
  Center,
  Highlight,
  StyleProps,
  VStack,
  Flex,
  Box,
  Spinner,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { DatePicker } from '../datepicker'
import { useLocation } from 'react-router-dom'
import { SidebarButton, SidebarButtonList } from '@/components/sidebar'
import { overflowScrollBarStyles } from '@/custom-css-prop/overflow-scrollbar-styles'
import { SidebarAccordionItem } from './SidebarAccordionItem'
import { isEmpty } from 'lodash'
import { DocumentTagWithCategory } from '~shared/dtos'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'
import { AppNavPaths } from '@/utils/paths'
import { truncate } from '@/utils/text'
import { useAddTagToSearchParams } from '@/hooks/params/useSearchParams'
import { CategoryType } from '~shared/constants'

const getTagIndex = (index: number) => {
  // 1st index is Date Accordion index,
  return index + 1
}

type TagLeftSideBarContentProps = {
  style?: StyleProps
}

export const TagLeftSideBarContent = ({
  style,
}: TagLeftSideBarContentProps) => {
  const addTagToSearchParams = useAddTagToSearchParams()
  const [highlight, setHighlight] = useState('')
  const [isSearchedTags, setIsSearchedTags] = useState<boolean>()

  const { pathname } = useLocation()
  const isDocumentsPage = pathname === AppNavPaths.Documents
  const {
    setExpandSidebarIndex,
    expandSidebarIndex,
    setSearchQuery,
    setCurrentCategory,
    getTagsInCategory,
    searchTags,
    searchQuery,
    currentCategory,
  } = useDocumentListContext()

  const spinnerColor = 'gray.300'

  const onRemoveSearchQuery = () => {
    setSearchQuery('')
    setHighlight('')
    setIsSearchedTags(false)
  }

  const { ref, inView } = useInView()
  const { ref: refInCategory, inView: inViewCategory } = useInView()

  const {
    data: tagInCategoryData,
    isGetTagsInCategoryLoading,
    fetchNextPage: fetchNextPageInCategory,
    hasNextPage: hasNextPageInCategory,
  } = getTagsInCategory

  const { data, isSearchTagsLoading, fetchNextPage, hasNextPage } = searchTags

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  useEffect(() => {
    if (inViewCategory) {
      fetchNextPageInCategory()
    }
  }, [inViewCategory, fetchNextPageInCategory])

  const selectedSideBarTags = useMemo(() => {
    const allTags = tagInCategoryData?.pages.reduce((arr, { data }) => {
      return arr.concat(data)
    }, [] as DocumentTagWithCategory[])
    return allTags
  }, [tagInCategoryData])

  // todo(ben): sort in alphabetical order
  const searchedTags = useMemo(() => {
    const allTags = data?.pages.reduce((arr, { data }) => {
      return arr.concat(data)
    }, [] as DocumentTagWithCategory[])
    return allTags
  }, [data])

  const renderSidebarButtons = (tags: DocumentTagWithCategory[]) => {
    return tags.map(
      ({ displayName, category }: DocumentTagWithCategory, index) => {
        return (
          <SidebarButton
            key={displayName}
            style={{
              isTruncated: true,
              pl: '1.5rem',
              // for some reason the first index is not aligned.
              ml: index === 0 ? '0.5rem' : 0,
            }}
            onClick={() => {
              onSidebarTagClick({
                displayName,
                category,
              })
              onRemoveSearchQuery()
            }}
          >
            <Highlight
              query={highlight}
              styles={{ color: 'brand.primary.300' }}
            >
              {truncate(displayName, 30)}
            </Highlight>
          </SidebarButton>
        )
      }
    )
  }
  const getSidebarContent = () => {
    if (isSearchTagsLoading) {
      return (
        <Center>
          <Spinner color={spinnerColor} />
        </Center>
      )
    }
    if (isEmpty(searchedTags) || !searchedTags) {
      return (
        <Center>
          <Text color="gray.50" fontSize="0.875rem">
            No tags found
          </Text>
        </Center>
      )
    }
    return renderSidebarButtons(searchedTags)
  }

  const renderSidebarTagsForSearch = () => {
    // todo(ben): issue with height of sidebar, when set to 100% it doesnt allow overflow
    return (
      <VStack
        pt="1rem"
        pl="1rem"
        style={
          isDocumentsPage ? { maxHeight: '24.5rem', overflowY: 'auto' } : {}
        }
      >
        <SidebarButtonList key={searchQuery} style={style}>
          {getSidebarContent()}
          {hasNextPage && (
            <Box ref={ref} textAlign="center">
              <Spinner color={spinnerColor} />
            </Box>
          )}
        </SidebarButtonList>
      </VStack>
    )
  }

  // todo(ben): change to every time change query the backend
  const onSearchbarChange = useDebouncedCallback((newQuery: string) => {
    setSearchQuery(newQuery)
    setHighlight(newQuery)
  }, 250)

  const onSidebarTagClick = ({
    displayName,
    category,
  }: {
    displayName: string
    category: string
  }) => {
    addTagToSearchParams({
      category,
      displayName,
    })
  }

  // render the filter buttons for the sidebar sub categories
  const renderSidebarTagsForCategory = () => {
    const style = (() => {
      if (isDocumentsPage) {
        return { maxHeight: '15rem' }
      } else if (isSearchedTags) {
        return { pt: '0.625rem' }
      } else {
        return { maxHeight: '20rem' }
      }
    })()

    if (isGetTagsInCategoryLoading) {
      return (
        <Center>
          <Spinner color={spinnerColor} />
        </Center>
      )
    }

    if (!selectedSideBarTags) return null

    return (
      <SidebarButtonList style={style}>
        {renderSidebarButtons(selectedSideBarTags)}
        {hasNextPageInCategory && (
          <Box ref={refInCategory} textAlign="center">
            <Spinner color={spinnerColor} />
          </Box>
        )}
      </SidebarButtonList>
    )
  }

  const AccordionComponent = (
    <Accordion
      allowToggle
      index={expandSidebarIndex}
      onChange={(e) => {
        setExpandSidebarIndex(e as number)
      }}
      css={overflowScrollBarStyles}
      overflowY="auto"
      py="1rem"
      {...style}
    >
      <SidebarAccordionItem
        isSelected={expandSidebarIndex === 0}
        itemName="Date"
        children={<DatePicker hasFooter />}
        onClick={() => {}}
      />
      {Object.keys(CategoryType).map((key, index) => (
        <SidebarAccordionItem
          key={key}
          onClick={() => {
            setCurrentCategory(key)
          }}
          isSelected={expandSidebarIndex === getTagIndex(index)}
          itemName={key}
          isColorTagged
        >
          {currentCategory === key && renderSidebarTagsForCategory()}
        </SidebarAccordionItem>
      ))}
    </Accordion>
  )

  return (
    <VStack height="fit-content" backgroundColor="parent" spacing="0" w="100%">
      <Flex direction={'column'} width="100%" flexGrow={1} px="1.5rem">
        <HStack py="1rem" bg="parent" spacing="0">
          <Text
            lineHeight="1.5rem"
            fontSize="1rem"
            color="white"
            fontWeight="bold"
            textColor="gray.300"
            w="100%"
            px="0"
          >
            FILTERS
          </Text>
        </HStack>

        <TagSearchbar
          placeholder="Search filters"
          searchQuery={searchQuery}
          onChange={onSearchbarChange}
          onRemoveSearchQuery={onRemoveSearchQuery}
        />
        {searchQuery ? renderSidebarTagsForSearch() : AccordionComponent}
      </Flex>
    </VStack>
  )
}
