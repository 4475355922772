import { AgreementDrawer } from '@/features/auth/components/AgreementDrawer'
import { AgreementModal } from '@/features/auth/components/AgreementModal'
import { useAuthContext } from '@/features/auth/useAuthContext'
import { useIsMobile } from '@/hooks'
import { Center, Spinner, useDisclosure } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { user } = useAuthContext()

  useEffect(() => {
    // Not logged in
    if (!user) {
      navigate('/login')
      return
    }
    if (!user.isAcceptedTou) {
      onOpen()
    }
  }, [navigate, onOpen, user])

  if (user)
    return (
      <>
        {children}
        {isMobile ? (
          <AgreementDrawer isOpen={isOpen} onClose={onClose} />
        ) : (
          <AgreementModal onClose={onClose} isOpen={isOpen} />
        )}
      </>
    )
  return (
    <Center height="100vh">
      <Spinner />
    </Center>
  )
}
