import { TagSearchbar } from '@/components/searchbar/TagSearchBar'
import { overflowScrollBarStyles } from '@/custom-css-prop/overflow-scrollbar-styles'
import { useSearchFilteredTag } from '@/hooks'
import { isTagNamePressum, isValidTagName } from '@/utils/tag'
import { Box, Button, Center, Spinner, Text, VStack } from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDebouncedCallback } from 'use-debounce'
import {
  TAG_NAME_CONTAINS_COLON_MEESAGE,
  TAG_NAME_CONTAINS_PRESSUM_MESSAGE,
  TAG_NAME_EXISTS_MESSAGE,
} from '~shared/constants/tag'
import { DocumentTagWithCategory } from '~shared/dtos'
import { lowercaseAndRemoveWhitespace } from '~shared/utils'

type MobileSearchBarProps = {
  addTagToDocument: ({ displayName, category }) => Promise<void>
  documentTags?: DocumentTagWithCategory[]
  setIsShowCategory: React.Dispatch<React.SetStateAction<boolean>>

  query: string
  setQuery: React.Dispatch<React.SetStateAction<string>>
}

export const MobileTagSearchList = ({
  setIsShowCategory,
  query,
  setQuery,
  addTagToDocument,
  documentTags,
}: MobileSearchBarProps) => {
  const {
    filteredTags,
    allTags,
    fetchNextPage,
    hasNextPage,
    isSearchTagsLoading,
  } = useSearchFilteredTag({
    query,
    documentTags,
  })
  const [displayQuery, setDisplayQuery] = useState(query)

  const fetchSearchTags = useDebouncedCallback((searchQuery) => {
    setQuery(searchQuery)
  }, 250)

  const { ref, inView } = useInView()

  useEffect(() => {
    fetchSearchTags(displayQuery)
  }, [displayQuery, fetchSearchTags])

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  const isValidNewOption = useMemo(() => {
    if (!isValidTagName(displayQuery) || isTagNamePressum(displayQuery)) {
      return false
    }
    const isExistInDb = !!(
      allTags &&
      !allTags.find(
        (tag) =>
          lowercaseAndRemoveWhitespace(tag.displayName) ===
          lowercaseAndRemoveWhitespace(displayQuery)
      )
    )
    return isExistInDb
  }, [allTags, displayQuery])

  const renderSearchResults = useCallback(() => {
    if (displayQuery === '') {
      return null
    }
    if (isSearchTagsLoading) {
      return (
        <Center height="100%">
          <Spinner />
        </Center>
      )
    }
    if (!isValidTagName(displayQuery)) {
      return <Text>{TAG_NAME_CONTAINS_COLON_MEESAGE}</Text>
    }

    if (isTagNamePressum(displayQuery)) {
      return <Text>{TAG_NAME_CONTAINS_PRESSUM_MESSAGE}</Text>
    }

    if (!isValidNewOption && allTags.length === 0) {
      return <Text>{TAG_NAME_EXISTS_MESSAGE}</Text>
    }

    return (
      <VStack>
        {filteredTags.map((tag) => (
          <Button
            padding={0}
            margin={0}
            width="100%"
            variant="ghost"
            fontSize="1.125rem"
            height="1.75rem"
            fontWeight={400}
            justifyContent="flex-start"
            textAlign="left"
            _hover={{ bg: 'transparent' }}
            key={tag.displayName + tag.category}
            onClick={() => {
              addTagToDocument(tag)
            }}
          >
            {tag.displayName}
          </Button>
        ))}
        {filteredTags && hasNextPage && (
          <Box ref={ref} textAlign="center">
            <Spinner />
          </Box>
        )}
      </VStack>
    )
  }, [
    displayQuery,
    isSearchTagsLoading,
    isValidNewOption,
    filteredTags,
    hasNextPage,
    addTagToDocument,
    ref,
    allTags,
  ])

  return (
    <VStack
      height="100%"
      width="100%"
      alignItems="left"
      justifyItems="left"
      spacing={0}
    >
      <Text
        fontSize="1.125rem"
        fontWeight={700}
        color="gray.800"
        marginBottom="0.75rem"
      >
        Add or create a tag
      </Text>
      <TagSearchbar
        placeholder="Type to search or create a tag"
        onChange={(e) => setDisplayQuery(e)}
        searchQuery={query}
        onRemoveSearchQuery={() => setDisplayQuery('')}
        hasSearchIcon={false}
        bg="white"
        width="100%"
        mt="0"
        height="2.5rem"
        px="0.0625rem"
        marginBottom="1rem"
        focusBorderColor="#F6AD55"
        mobileInputBorder="1px solid #DD6B20"
        mobileInputColor="gray.800"
        mobilePlaceholderColor="gray.500"
      />
      {displayQuery !== '' && isValidNewOption && (
        <Button
          fontSize="1.125rem"
          fontWeight={400}
          justifyContent="flex-start"
          textAlign="left"
          width="100%"
          variant="unstyled"
          borderBottom="1px solid"
          borderColor="gray.500"
          borderRadius={0}
          marginBottom="0.5rem"
          _hover={{ bg: 'transparent' }}
          onClick={() => {
            setIsShowCategory(true)
          }}
        >
          Create new tag '{displayQuery}'
        </Button>
      )}
      <VStack
        overflowY="auto"
        h="100%"
        w="100%"
        alignItems="left"
        justifyItems="left"
        spacing="1rem"
        css={overflowScrollBarStyles}
      >
        {renderSearchResults()}
      </VStack>
    </VStack>
  )
}
