import { queryKeys } from '@/constants/query-key'
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { createBookmark, createBookmarks, deleteBookmark } from '@/api/bookmark'
import { DEFAULT_COLLECTION } from '~shared/constants'
import {
  CollectionInSearchResults,
  CreateBookmarksRes,
  DocumentRes,
  PaginatedRes,
} from '~shared/dtos'
import {
  addCollectionsToQuery,
  removeCollectionsFromQuery,
  removeDocumentFromQuery,
  updateStarredCollections,
  UpdateStarredCollectionsActions,
} from '@/utils/query'

// todo(ben): updating query cache for bookmark-related changes.
// not optimal but i think this is much better than refetching the whole page
// to look out for other optimisations

export const useCreateDefaultBookmark = ({
  documentId,
  starredCollectionId,
}: {
  documentId: number
  starredCollectionId: number
}) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: createBookmark,
    onSuccess: async () => {
      // so that star will reflect on document page
      await queryClient.invalidateQueries(queryKeys.document(documentId))

      //we are not updating collections directly from here as we are unsure if it matches the search conditions
      // so we just invalidate the collection
      await queryClient.resetQueries({
        queryKey: queryKeys.searchDocumentsInCollection([
          `${starredCollectionId}`,
        ]),
      })

      // so that star button for documents will be updated
      queryClient.setQueriesData<
        InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[]
      >(queryKeys.documents(), (oldData) => {
        if (oldData) {
          return updateStarredCollections({
            oldData,
            documentId,
            starredCollectionId,
            action: UpdateStarredCollectionsActions.ADD,
          })
        }
      })
    },
  })

  return {
    createBookmark: mutateAsync,
    isLoading,
  }
}

export const useDeleteBookmark = ({
  userId,
  documentId,
  collectionId,
  starredCollectionId,
}: {
  userId: string
  documentId: number
  collectionId?: number
  starredCollectionId?: number
}) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: deleteBookmark,
    onSuccess: async (bookmark) => {
      // so that bookmark icon button list component will reflect updated collections
      await queryClient.invalidateQueries(
        queryKeys.bookmarkCollections(userId, documentId)
      )
      // so that collections will be updated on document page
      await queryClient.invalidateQueries(queryKeys.document(documentId))

      //so that sidebar will reflect numbers
      await queryClient.invalidateQueries(queryKeys.collections(userId))

      if (
        bookmark.collectionName === DEFAULT_COLLECTION &&
        starredCollectionId
      ) {
        // so that document will be removed from starred collection
        queryClient.setQueriesData<
          InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[]
        >(
          queryKeys.searchDocumentsInCollection([`${starredCollectionId}`]),
          (oldData) => {
            if (oldData) {
              return removeDocumentFromQuery({
                oldData,
                documentId,
              })
            }
          }
        )

        // so that star button will be updated
        queryClient.setQueriesData<
          InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[]
        >(queryKeys.documents(), (oldData) => {
          if (oldData) {
            return updateStarredCollections({
              oldData,
              documentId,
              starredCollectionId,
              action: UpdateStarredCollectionsActions.REMOVE,
            })
          }
        })
      } else {
        // so that collection list in the document card will be updated with new collections
        queryClient.setQueriesData<
          InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[]
        >(queryKeys.documents(), (oldData) => {
          if (oldData) {
            return removeCollectionsFromQuery({
              oldData,
              documentId,
              collectionId: collectionId ?? NaN,
            })
          }
        })

        //so that the current collection list will be updated
        queryClient.setQueriesData<
          InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[]
        >(
          queryKeys.searchDocumentsInCollection([`${collectionId}`]),
          (oldData) => {
            if (oldData) {
              return removeDocumentFromQuery({
                oldData,
                documentId,
              })
            }
          }
        )

        // so that search within multiple collections will be updated
        await queryClient.resetQueries(
          queryKeys.searchDocumentsInMultipleCollections()
        )
      }
    },
  })

  return {
    deleteBookmark: mutateAsync,
    isLoading,
  }
}

export const useCreateBookmarks = ({
  userId,
  documentId,
}: {
  userId: string
  documentId: number
}) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading: isCreateBookmarksLoading } = useMutation({
    mutationFn: createBookmarks,
    onSuccess: async (res: CreateBookmarksRes) => {
      await queryClient.invalidateQueries(
        queryKeys.bookmarkCollections(userId, documentId)
      )

      await queryClient.invalidateQueries(queryKeys.document(documentId))

      await queryClient.invalidateQueries({
        queryKey: queryKeys.collections(userId),
        type: 'all',
      })

      const newCollections: CollectionInSearchResults[] = []
      const collectionIds = res.bookmarks.map(
        (bookmark) => bookmark.collectionId
      )

      res.bookmarks.forEach((bookmark) => {
        newCollections.push({
          collectionId: bookmark.collectionId,
          name: bookmark.collectionName ?? '',
        })
      })

      // so that collectionlist will reflect
      queryClient.setQueriesData<
        InfiniteData<PaginatedRes<DocumentRes>> | DocumentRes[]
      >(queryKeys.documents(), (oldData) => {
        if (oldData) {
          return addCollectionsToQuery({
            oldData,
            newCollections,
            documentId,
          })
        }
      })

      //we are not updating collections directly from here as we are unsure if it matches the search conditions
      // so we just invalidate the collection
      await Promise.resolve(
        collectionIds.forEach(async (collectionId) => {
          await queryClient.resetQueries({
            queryKey: queryKeys.searchDocumentsInCollection([
              `${collectionId}`,
            ]),
            type: 'all',
          })
        })
      )
    },
  })
  return {
    createBookmarks: mutateAsync,
    isCreateBookmarksLoading,
  }
}
