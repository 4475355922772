import {
  HStack,
  IconButton,
  Text,
  Tooltip,
  UseRadioProps,
  VStack,
} from '@chakra-ui/react'
import { FiPlusCircle } from 'react-icons/fi'
import { HiPlusCircle } from 'react-icons/hi'
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5'
import { useBookmarkContext } from '../useBookmarkContext'
import { useState } from 'react'
import { useIsMobile } from '@/hooks'
import { MdFolderShared, MdFolderOpen } from 'react-icons/md'

type BoookmarkIconButtonProp = UseRadioProps & {
  collectionName: string
  documentCount: number
  collectionId: string
  isEditable: boolean
  userCount: number
}

const NO_PERMISSION_LABEL = 'No permissions to edit bookmark in this collection'

export const BookmarkIconButton = ({
  collectionName,
  documentCount,
  collectionId,
  isEditable,
  userCount,
}: BoookmarkIconButtonProp) => {
  const isMobile = useIsMobile()
  const { selectedCollectionIds, setSelectedCollections, selectedCollections } =
    useBookmarkContext()
  const isSelected = selectedCollectionIds.has(collectionId)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <HStack
      bg={'transparent'}
      w="100%"
      borderRadius="8px"
      padding="8px"
      justify="space-beween"
    >
      <HStack w="100%">
        <IconButton
          isRound
          aria-label="add collection"
          icon={userCount > 1 ? <MdFolderShared /> : <MdFolderOpen />}
        />
        <VStack alignItems="left" spacing="0px">
          <Text
            isTruncated
            color={'black'}
            fontSize="14px"
            fontWeight="bold"
            w="100%"
          >
            {collectionName}
          </Text>
          <Text color={'black'} fontSize="14px">
            {`${documentCount} ` + (documentCount > 1 ? `articles` : 'article')}
          </Text>
        </VStack>
      </HStack>
      {isSelected ? (
        <Tooltip
          isDisabled={isMobile}
          label={
            isEditable
              ? `Remove bookmark from collection`
              : `${NO_PERMISSION_LABEL}`
          }
        >
          <IconButton
            isDisabled={!isEditable}
            bg="parent"
            cursor="pointer"
            onClick={() => {
              setSelectedCollections(
                selectedCollections.filter(
                  (selected) => collectionId !== selected.collectionId
                )
              )
            }}
            isRound
            _hover={{ bg: 'brand.primary.100' }}
            aria-label="remove collection right icon"
            color="brand.primary.500"
            icon={
              isHovered ? (
                <IoCloseCircleSharp size="24px" />
              ) : (
                <IoCheckmarkCircleSharp size="24px" />
              )
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </Tooltip>
      ) : (
        <Tooltip
          isDisabled={isMobile}
          label={
            isEditable ? 'Add document to collection' : `${NO_PERMISSION_LABEL}`
          }
        >
          <IconButton
            isDisabled={!isEditable}
            bg="parent"
            cursor="pointer"
            onClick={() => {
              setSelectedCollections([
                ...selectedCollections,
                { collectionId, name: collectionName, isEditable },
              ])
            }}
            isRound
            _hover={{ bg: 'brand.primary.100' }}
            aria-label="add collection right icon"
            icon={
              isHovered ? (
                <HiPlusCircle size="24px" color="#DD6B20" />
              ) : (
                <FiPlusCircle size="24px" />
              )
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </Tooltip>
      )}
    </HStack>
  )
}
