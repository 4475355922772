import { Button, ButtonProps, IconButton } from '@chakra-ui/react'
import { BiFilter } from 'react-icons/bi'

type MobileFilterButtonProps = ButtonProps & {
  isCollapsed?: boolean
}

export const MobileFilterButton = ({
  isCollapsed = false,
  ...props
}: MobileFilterButtonProps) => {
  return !isCollapsed ? (
    <Button
      width="fit-content"
      backgroundColor="gray.100"
      fontSize="0.75rem"
      borderRadius="1.25rem"
      iconSpacing="0.375rem"
      leftIcon={<BiFilter size="1rem" />}
      {...props}
    >
      FILTERS
    </Button>
  ) : (
    <IconButton
      height="1.75rem"
      minWidth="1.75rem"
      width="1.75rem"
      borderRadius="1.25rem"
      backgroundColor="orange.500"
      icon={<BiFilter size="1rem" color="#FFFAF0" />}
      aria-label="mobile-filter-collapse-button"
      {...props}
    />
  )
}
