import { PressifyLogo } from '@/components/PressifyLogo'
import { useIsMobile } from '@/hooks'
import { HStack, VStack, Text, Image } from '@chakra-ui/react'
import React from 'react'

type AuthBodyProps = {
  caption: string
  children: React.ReactNode
  image: string
}

export const AuthBody = ({ caption, children, image }: AuthBodyProps) => {
  const isMobile = useIsMobile()
  return (
    <HStack
      justify="space-between"
      h="100%"
      backgroundColor="background.default"
      w="100%"
      alignItems="flex-start"
    >
      <VStack
        paddingLeft={{ lg: '10%', base: '1.5rem' }}
        paddingRight={{ base: '1.5rem', lg: '0' }}
        alignItems="inherit"
        pt="10%"
        spacing={{ base: '1.75rem', lg: '2rem' }}
        w="100%"
      >
        <PressifyLogo
          styles={{ alignSelf: 'flex-start' }}
          isClickable={false}
          variant={isMobile ? 'sm-light' : 'regular-light'}
        />
        <Text
          minH="5.4rem"
          pt={{ base: '1.75rem', lg: '0' }}
          fontSize={{ base: '1.125rem', lg: '2.25rem' }}
          fontWeight={{ base: 'medium', lg: 'normal' }}
          pb={{ base: 'auto', lg: '0' }}
        >
          {caption}
        </Text>

        {children}
      </VStack>
      {!isMobile && <Image height="100vh" src={image} />}
    </HStack>
  )
}
