import { Icon } from '@chakra-ui/icon'
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/input'
import { IconButton } from '@chakra-ui/react'
import { IoSearchOutline } from 'react-icons/io5'
import { RxCross2 } from 'react-icons/rx'

type CollectionSearchBarProps = {
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
}

export const CollectionSearchBar = ({
  searchValue,
  setSearchValue,
}: CollectionSearchBarProps) => {
  return (
    <InputGroup
      bg="white"
      borderRadius="1.25rem"
      w="100%"
      alignSelf="center"
      mt="1rem"
      height="2.5rem"
      px="0.0625rem" // the x border overlaps with the parent padding
    >
      <InputLeftElement>
        <Icon as={IoSearchOutline} color="gray.400" position="relative" />
      </InputLeftElement>
      {searchValue && (
        <InputRightElement>
          <IconButton
            icon={<RxCross2 />}
            aria-label="clear-text-from-searchbar"
            _hover={{
              bg: 'parent',
              color: 'black',
            }}
            size="s"
            bg="parent"
            onClick={() => {
              setSearchValue('')
            }}
          />
        </InputRightElement>
      )}
      <Input
        height="2.5rem"
        borderRadius="1.25rem"
        border="none"
        size="sm"
        value={searchValue}
        placeholder={'Type to search or create a new collection'}
        _placeholder={{ color: 'gray.400' }}
        onChange={({ target: { value } }) => {
          //don't allow creating empty collection
          const curValue = value === ' ' ? '' : value
          setSearchValue(curValue)
        }}
      />
    </InputGroup>
  )
}
