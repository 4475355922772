import {
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  VStack,
  Text,
  Tag,
} from '@chakra-ui/react'
import { useBookmarkContext } from '../useBookmarkContext'
import { SaveChangesModal } from './SaveChangesModal'
import { BookmarkIconButtonList } from './BookmarkIconButtonList'
import { AddBookmarkActions } from './AddBookmarkActions'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { BOOKMARK_HEADER } from '~shared/constants'

export const AddBookmarkModal = () => {
  const {
    collectionText,
    documentTitle,
    selectedCollectionIds,
    setSelectedCollections,
  } = useBookmarkContext()

  const {
    disclosureProps: { isOpen, onClose },
  } = useBookmarkContext()

  const {
    isOpen: isConfirmOpen,
    onClose: onConfirmClose,
    onOpen: onConfirmOpen,
  } = useDisclosure()

  useEffect(() => {
    if (isOpen) {
      // on open always reset selected collections
      setSelectedCollections([])
    }
  }, [isOpen, setSelectedCollections])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          if (!isEmpty(selectedCollectionIds)) {
            onConfirmOpen()
          } else {
            onClose()
          }
        }}
        isCentered
        size="md"
      >
        <ModalOverlay />
        <ModalContent padding="1.5rem" background={'background.default'}>
          <VStack align="left" spacing="1rem">
            <ModalHeader padding="0" fontWeight="bold" fontSize="1.125rem">
              {BOOKMARK_HEADER}
            </ModalHeader>
            {collectionText && (
              <Tag bg="orange.500" color="white" w="fit-content">
                {collectionText}
              </Tag>
            )}
            <VStack align="left">
              <Text
                fontSize="0.875rem"
                noOfLines={2}
              >{`"${documentTitle}"`}</Text>
            </VStack>
          </VStack>
          <ModalCloseButton />
          <BookmarkIconButtonList />
          <AddBookmarkActions onClose={onClose} />
        </ModalContent>
      </Modal>
      <SaveChangesModal onClose={onConfirmClose} isOpen={isConfirmOpen} />
    </>
  )
}
