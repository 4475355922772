export enum SlateTextTypes {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  IMAGE = 'image',
  CODE = 'code',
  LIST_ITEM = 'list-item',
  NUMBERED_LIST = 'numbered-list',
  BULLETED_LIST = 'bulleted-list',
  PARAGRAPH = 'paragraph',
  LINK = 'link',
}

// todo(aaron): to refactor and combine it with Descendant class
export declare interface SlateNode {
  text: string;
  type?: string;
  children?: SlateNode[];
}
