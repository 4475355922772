import { Box, VStack } from '@chakra-ui/react'
import { AppFooter } from '@/app/AppFooter'
import { FeaturesShowcase } from '../features/landing/components/FeaturesShowcase'
import { GetStarted } from '../features/landing/components/GetStarted'
import { Hero } from '../features/landing/components/Hero'
import { HeroBanner } from '../features/landing/components/HeroBanner'

export const LandingPage = () => {
  return (
    <VStack w="100%" align="left" spacing={0} background="background.default">
      <Box pb="0.75rem" pr="2rem" w="100%" display="flex" pt="2.5rem" />
      <VStack
        w="100%"
        align="left"
        spacing={{ base: '1.5rem', lg: '3.25rem' }}
        px={{ base: '1.5rem', lg: '0' }}
        pl={{ base: '1.5rem', lg: '10%' }}
        pb={{ base: '4rem', lg: 'auto' }}
      >
        <VStack
          spacing="2.1825rem"
          w="100%"
          align="left"
          background="background.default"
        >
          <HeroBanner />
          <Hero />
        </VStack>
        <FeaturesShowcase />
      </VStack>
      <GetStarted />
      <AppFooter />
    </VStack>
  )
}
