import { VStack, HStack, Divider, Text, Icon } from '@chakra-ui/react'
import { BiChevronRight } from 'react-icons/bi'
import { MobileFilterTag } from './MobileFilterTag'
import { useFilterTagContext } from './useFilterTagContext'
import { useMemo } from 'react'
import { dateRangeToString, FILTER_DATE } from '@/utils/date'
import { COUNTRY_REGION_TEXT_LABEL } from '@/constants/title'
import { CategoryType } from '~shared/constants'

const categories = [FILTER_DATE, ...Object.keys(CategoryType)]

export const FilterTagCategorySelection = () => {
  const {
    selectedTags,
    setSelectedCategory,
    removeTagInCategory,
    selectedDate,
  } = useFilterTagContext()
  const categoriesMap = useMemo(() => {
    const categoryTagMap = selectedTags.reduce(
      (accum, tag) => {
        const { displayName: name, category } = tag

        if (accum[category]) {
          accum[category] = [...accum[category], name]
        } else {
          accum[category] = [name]
        }
        return accum
      },
      {} as { [key: string]: string[] }
    )
    if (selectedDate) {
      categoryTagMap[FILTER_DATE] = [dateRangeToString(selectedDate)]
    }
    return categoryTagMap
  }, [selectedTags, selectedDate])

  return (
    <VStack width="100%">
      {categories.map((value, index, categories) => {
        const isLast = index === categories.length - 1
        const displayNames = categoriesMap[value]

        return (
          <VStack key={value} width="100%">
            <HStack
              width="100%"
              justifyContent="space-between"
              paddingLeft="1.5rem"
              onClick={() => {
                setSelectedCategory(value)
              }}
            >
              <Text color="white" fontWeight={700} fontSize="1.125rem">
                {value === CategoryType.Country
                  ? COUNTRY_REGION_TEXT_LABEL
                  : value}
              </Text>
              <HStack flex={1} overflow="hidden">
                {displayNames && (
                  <MobileFilterTag
                    category={value}
                    displayNames={displayNames}
                    onRemoveSelectedTags={removeTagInCategory}
                  />
                )}

                <Icon
                  marginLeft={displayNames ? undefined : 'auto'} // move chevron to right if no tags
                  as={BiChevronRight}
                  color="white"
                  w="1.25rem"
                  h="1.25rem"
                />
              </HStack>
            </HStack>
            {!isLast && <Divider marginTop="0.5rem" marginBottom="0.5rem" />}
          </VStack>
        )
      })}
    </VStack>
  )
}
