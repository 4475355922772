export const UPLOAD_ARTICLE_TITLE = 'Upload Article via URL'
export const MANUAL_UPLOAD_TITLE = 'Manual Upload'
export const MANUAL_UPLOAD_CAPTION = '(articles, documents)'

export const PUBLICATIONS_TITLE = 'MINDEF/SAF MATERIALS'
export const COLLECTIONS_TITLE = 'COLLECTIONS'

export const CREATE_NEW_COLLECTION_TEXT_HEADER = `Create new collection`
export const RENAME_COLLECTION_TEXT_HEADER = `Rename collection`

export const All_ARTICLES = 'All Articles'

export const COUNTRY_REGION_TEXT_LABEL = 'Country / Region'
export const COUNTRIES_REGIONS_TEXT_LABEL = 'Countries / Regions'
