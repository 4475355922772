import {
  Avatar,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { CollabRoleDropdown } from './CollabRoleDropdown'
import { DeleteUserCollectionModal } from './DeleteUserCollectionModal'
import { PERMISSIONS, PERMISSIONS_TO_ROLE } from '~shared/constants'
import { useUpdateUserCollection } from '@/hooks/useUserCollection'
import { useGetUserEmailFromContext, useIsMobile } from '@/hooks'
import { useState } from 'react'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'
import { Trash } from '@/components/icons/Trash'
import { DisabledTrash } from '@/components/icons/DisabledTrash'
import { isAxiosError } from 'axios'
import { LiaEditSolid } from 'react-icons/lia'
import { CollabUpdatePermissionDrawer } from './CollabUpdatePermissionDrawer'
import { DeleteUserCollectionDrawer } from './DeleteUserCollectionDrawer'
import { useGetCollectionIdFromSearchParams } from '@/hooks/params/useSearchParams'
import { useToast } from '@/hooks/useToast'

type CollabUser = {
  userId: string
  email: string
  permission: PERMISSIONS
  isEnableDropdown: boolean
  isEnableDelete: boolean
}

export const CollabUser = ({
  email,
  permission,
  userId,
  isEnableDropdown,
  isEnableDelete,
}: CollabUser) => {
  const isMobile = useIsMobile()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenUpdatePermissionDrawer,
    onClose: onCloseUpdatePermissionDrawer,
    onOpen: onOpenUpdatePermissionDrawer,
  } = useDisclosure()
  const toast = useToast()
  const { collectionId } = useGetCollectionIdFromSearchParams()
  const { email: curUserEmail } = useGetUserEmailFromContext()
  const [isUser] = useState<boolean>(email === curUserEmail)
  const { collection } = useDocumentListContext()

  const [name, emailAddress] = email.split('@')

  const { updateUserCollection, isUpdateUserCollectionLoading } =
    useUpdateUserCollection({ collectionId, userId })

  const onChange = ({
    permission,
    initialPermission,
  }: {
    permission: string
    initialPermission?: string
  }) => {
    if (!collectionId) {
      toast({
        description: `Error deleting permissions. Please try again later.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
    updateUserCollection(
      {
        permission,
        initialPermission: initialPermission as string,
      },
      {
        onSuccess: () => {
          toast({
            description: `Access Rights to ${collection?.name} updated`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        },
        onError: (error) => {
          if (isAxiosError(error)) {
            toast({
              description: `Error updating permissions. Please try again later.`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
            if (error.response?.status === 409) {
              onClose()
            }
          }
        },
      }
    )
  }
  return (
    <HStack h="100%" justify="space-between" margin="0.125rem">
      <HStack
        spacing="0.75rem"
        fontSize="0.875rem"
        h="100%"
        w="100%"
        onClick={() => {
          isMobile && isEnableDropdown && onOpenUpdatePermissionDrawer()
        }}
      >
        <Avatar
          name={name}
          width="2.25rem"
          height="2.25rem"
          bg="brand.primary.500"
        />
        {isMobile ? (
          <VStack align="left" w="100%" spacing="0.25rem">
            <VStack align="left" w="100%" spacing="0">
              <HStack spacing="0.125rem">
                <Text fontSize="0.875rem" isTruncated>
                  {name}
                </Text>
                {isUser && (
                  <Text fontSize="0.875rem" color="gray.500">
                    (you)
                  </Text>
                )}
              </HStack>
              <Text fontWeight="400" fontSize="0.75rem" isTruncated>
                {`@${emailAddress}`}
              </Text>
            </VStack>
            {isEnableDropdown ? (
              <HStack color="orange.400">
                <Text fontSize="0.875rem" fontWeight="500" isTruncated>
                  {PERMISSIONS_TO_ROLE[permission]}
                </Text>
                <Icon as={LiaEditSolid} />
              </HStack>
            ) : (
              <Text
                fontSize="0.875rem"
                fontWeight="500"
                color="gray.500"
                isTruncated
              >
                {PERMISSIONS_TO_ROLE[permission]}
              </Text>
            )}
          </VStack>
        ) : (
          <HStack spacing="0.125rem">
            <Text fontSize="1rem" isTruncated>
              {email}
            </Text>
            {isUser && <Text color="gray.500">(you)</Text>}
          </HStack>
        )}
      </HStack>
      <HStack>
        {!isMobile && (
          <>
            <CollabRoleDropdown
              isEnabled={isEnableDropdown}
              permission={permission}
              onChange={onChange}
              isLoading={isUpdateUserCollectionLoading}
            />
            {isEnableDelete ? (
              <Trash
                aria-label="delete-user-collection"
                onClick={onOpen}
                padding="0.625rem"
              />
            ) : (
              <DisabledTrash />
            )}
          </>
        )}
      </HStack>
      {isMobile ? (
        <DeleteUserCollectionDrawer
          isOpen={isOpen}
          onClose={onClose}
          userId={userId}
          email={email}
        />
      ) : (
        <DeleteUserCollectionModal
          isOpen={isOpen}
          onClose={onClose}
          userId={userId}
          email={email}
        />
      )}
      <CollabUpdatePermissionDrawer
        isOpen={isOpenUpdatePermissionDrawer}
        onClose={onCloseUpdatePermissionDrawer}
        email={email}
        permission={permission}
        onChange={onChange}
        isLoading={isUpdateUserCollectionLoading}
        userId={userId}
      />
    </HStack>
  )
}
