import { Button, HStack, Tooltip, VStack } from '@chakra-ui/react'
import { LEGAL_WARNING_PRIOR_TO_UPLOAD } from '~shared/constants'
import { useFormikContext } from 'formik'
import { CreateOpensourceArticleReq } from '~shared/dtos'
import { useIsMobile } from '@/hooks'
import { IoArrowForward } from 'react-icons/io5'
import { useMemo } from 'react'
import { checkIfSlateHasContent } from '~shared/utils'
import { useUploadDocumentContext } from '../useUploadDocumentContext'

export const MobilePublishBar = ({
  isUploadLoading,
  isPublishDisabled,
  // isTranslateDisabled,
  onCancel,
}: {
  isUploadLoading: boolean
  isPublishDisabled: boolean
  // isTranslateDisabled: boolean
  onCancel?: () => void
}) => {
  return (
    <>
      {/* <TranslationPopover isDisabled={isTranslateDisabled} /> */}
      <VStack
        marginTop="1rem"
        position="sticky"
        bottom={0}
        width="100%"
        backgroundColor="background.default"
        spacing="0.5rem"
        align="left"
      >
        <Button
          width="100%"
          bgColor="green.100"
          color="green.800"
          borderRadius="1.25rem"
          type="submit"
          isDisabled={isPublishDisabled}
          isLoading={isUploadLoading}
        >
          Publish
        </Button>
        <Button
          width="100%"
          variant="ghost"
          isDisabled={isUploadLoading}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </VStack>
    </>
  )
}

export const PublishButton = ({
  isUploadLoading,
  isPublishDisabled,
  // isTranslateDisabled,
  label,
}: {
  isUploadLoading: boolean
  isPublishDisabled: boolean
  // isTranslateDisabled: boolean
  label: string
}) => {
  return (
    <HStack w="100%" justify="flex-end" spacing="1rem">
      {/* <TranslationPopover isDisabled={isTranslateDisabled} /> */}
      <Tooltip
        borderRadius="1rem"
        p="0.5rem"
        maxW="12rem"
        fontSize="3xs"
        label={label}
      >
        <Button
          borderRadius="1.25rem"
          rightIcon={<IoArrowForward />}
          color="brand.primary.50"
          background="brand.primary.500"
          size="md"
          isDisabled={isPublishDisabled}
          isLoading={isUploadLoading}
          type="submit"
        >
          Publish
        </Button>
      </Tooltip>
    </HStack>
  )
}

export const PublishBar = ({ onCancel }: { onCancel?: () => void }) => {
  const {
    isSubmitting,
    values: { slateContent, title },
  } = useFormikContext<CreateOpensourceArticleReq>()
  const { isTranslateLoading, isContentLoading } = useUploadDocumentContext()
  const isMobile = useIsMobile()
  // const isTranslateDisabled = !checkIfSlateHasContent(slateContent) || !title
  const isPublishDisabled =
    !checkIfSlateHasContent(slateContent) ||
    !title ||
    isTranslateLoading ||
    !!isContentLoading
  const label = useMemo(() => {
    if (isTranslateLoading) {
      return 'Please wait for translation to be completed'
    }
    return LEGAL_WARNING_PRIOR_TO_UPLOAD
  }, [isTranslateLoading])
  return isMobile ? (
    <MobilePublishBar
      isPublishDisabled={isPublishDisabled}
      // isTranslateDisabled={isTranslateDisabled}
      isUploadLoading={isSubmitting}
      onCancel={onCancel}
    />
  ) : (
    <PublishButton
      label={label}
      isPublishDisabled={isPublishDisabled}
      // isTranslateDisabled={isTranslateDisabled}
      isUploadLoading={isSubmitting}
    />
  )
}
