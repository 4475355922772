import { CollectionOption } from '@/types/document'
import { Descendant } from 'slate'

export const EMPTY_SLATE_CONTENT = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
] as Descendant[]

export const EMPTY_COLLECTION_VALUE: CollectionOption = {
  value: 0,
  label: '',
  isNew: false,
}

export const URL_MODAL_HEADER_TEXT = 'Import Article From URL'
export const FILE_MODAL_HEADER_TEXT = 'Extract Article from File'
export const URL_DRAWER_HEADER_TEXT = 'UPLOAD VIA URL'
export const FILE_EXTRACTION_HEADER_TEXT = 'File Extraction'
