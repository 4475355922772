import { useGetUserIdFromContext, useIsMobile } from '@/hooks'
import { useCreateCollection } from '@/hooks/useCollection'
import {
  Button,
  FormControl,
  HStack,
  Icon,
  Input,
  InputGroup,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import { MdError } from 'react-icons/md'
import { CreateCollectionSchema } from '../validator'
import { CREATE_NEW_COLLECTION_TEXT_HEADER } from '@/constants/title'
import { useNavigate } from 'react-router-dom'
import { AppNavPaths } from '@/utils/paths'
import { useToast } from '@/hooks/useToast'

type CollectionFormProps = {
  onClose: () => void
}

export const CreateCollectionForm = ({ onClose }: CollectionFormProps) => {
  const isMobile = useIsMobile()
  const [isFocused, setIsFocused] = useState(false)

  const { userId } = useGetUserIdFromContext()
  const toast = useToast()
  const navigate = useNavigate()

  const { createCollection, isCreateCollectionLoading } =
    useCreateCollection(userId)

  const handleSubmit = async (collectionName: string) => {
    await createCollection(
      { name: collectionName.trim() },
      {
        onSuccess: ({ collectionName, collectionId }) => {
          toast({
            description: (
              <>
                <u>{`${collectionName}`}</u> Collection Created
              </>
            ),
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          onClose()
          navigate(`${AppNavPaths.Documents}?collectionIds=${collectionId}`)
        },
        onError: () => {
          toast({
            description: `Unable to create collection. Please try again later.`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        },
      }
    )
  }

  return (
    <Formik
      initialValues={{ collectionName: '' }}
      onSubmit={({ collectionName }) => {
        handleSubmit(collectionName)
      }}
      validationSchema={CreateCollectionSchema}
    >
      {(props) => (
        <Form>
          <VStack align="left" w="100%">
            <Text
              fontSize="1.125rem"
              fontWeight={{ lg: 'normal', base: 'bold' }}
            >
              {isMobile
                ? CREATE_NEW_COLLECTION_TEXT_HEADER
                : `Enter new collection name below.`}
            </Text>
            <Field name="collectionName">
              {({ field, form }) => (
                <FormControl
                  isRequired
                  isInvalid={
                    form.errors.collectionName && form.touched.collectionName
                  }
                >
                  <InputGroup width="100%">
                    <VStack w="100%" align="left" px="0.0625rem">
                      <Input
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        _hover={
                          isFocused
                            ? {}
                            : {
                                border: '1px dashed #ED8936',
                                borderRadius: '1rem',
                              }
                        }
                        border="1px"
                        borderColor="transparent"
                        focusBorderColor="orange.400"
                        w="100%"
                        value={field.value}
                        onChange={({ target: { value } }) => {
                          props.setFieldValue(field.name, value)
                        }}
                        name="collectionName"
                        borderRadius="1.25rem"
                        placeholder={'Type to create new collection'}
                        bg="white"
                      />
                      <HStack textColor="red" ml="0.5rem">
                        {form.errors.collectionName && (
                          <>
                            <Icon as={MdError} />
                            <Text>{form.errors.collectionName}</Text>
                          </>
                        )}
                      </HStack>
                    </VStack>
                  </InputGroup>
                </FormControl>
              )}
            </Field>
          </VStack>
          <HStack justify="end" w="100%" pt="1rem" spacing="0.75rem">
            {!isMobile && (
              <Button
                fontSize="1rem"
                fontWeight="semibold"
                size="md"
                bg="gray.100"
                color="gray.800"
                borderRadius="1.25rem"
                onClick={onClose}
                isDisabled={isCreateCollectionLoading}
              >
                Cancel
              </Button>
            )}
            <Button
              fontSize="1rem"
              fontWeight="semibold"
              size="md"
              w={{ lg: 'auto', base: '100%' }}
              bg={props.values.collectionName ? 'green.100' : 'gray.100'}
              color={props.values.collectionName ? 'green.800' : 'gray.800'}
              _hover={{
                bg: { lg: 'green.400', base: 'parent' },
                color: { lg: 'orange.50', base: 'parent' },
              }}
              borderRadius="1.25rem"
              type="submit"
              isDisabled={
                !props.values.collectionName || !!props.errors.collectionName
              }
              isLoading={isCreateCollectionLoading}
            >
              Save new collection
            </Button>
          </HStack>
        </Form>
      )}
    </Formik>
  )
}
