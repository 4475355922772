import { HStack, VStack } from '@chakra-ui/react'
import { DocumentRes, DocumentTagWithCategory } from '~shared/dtos'
import { LatestPressum } from './ArticleCards/LatestPressum'

type LatestPressumListProps = {
  pressums: DocumentRes[]
  pressumId: string
}

export const LatestPressumList = ({ pressums }: LatestPressumListProps) => {
  const renderPressums = (start: number, end: number) => {
    return pressums.slice(start, end).map(({ id, title, tags, dateTime }) => {
      return (
        <LatestPressum
          key={id}
          id={+id}
          title={title}
          dateTime={dateTime}
          tags={tags as DocumentTagWithCategory[]}
        />
      )
    })
  }

  return (
    <VStack align="left" w="100%" h="100%">
      <HStack spacing="1rem" align="left" w="100%" h="100%">
        {renderPressums(0, 3)}
      </HStack>
      <HStack spacing="1rem" align="left" w="100%" h="100%">
        {renderPressums(3, 6)}
      </HStack>
      <HStack spacing="1rem" align="left" w="100%" h="100%">
        {renderPressums(6, 9)}
      </HStack>
    </VStack>
  )
}
