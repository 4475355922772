export enum AppNavPaths {
  Home = '/home',
  Documents = '/documents',
  Trend = '/trend',
  Upload = '/upload',
  Login = '/login',
  Manual = '/upload/manual',
  Url = '/upload/article',
  fileUpload = '/upload/file',
}

export const APP_PATHS_TO_NAME = {
  [AppNavPaths.Home]: 'Home',
  [AppNavPaths.Documents]: 'Articles',
  [AppNavPaths.Upload]: 'Upload',
}

export const ROOT_DOCUMENT_PATH = 'document'
