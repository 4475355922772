import { VerifyOtpReq } from '~shared/dtos'
import { SendOtpReq } from '~shared/dtos'
import { ApiService } from './ApiService'

export const sendLoginOtp = async (body: SendOtpReq) => {
  const { data } = await ApiService.post(`/auth/send-otp`, body)
  return data
}

export const verifyLoginOtp = async (body: VerifyOtpReq) => {
  const { data } = await ApiService.post<void>(`/auth/verify-otp`, body)
  return data
}

export const logout = async () => {
  const { data } = await ApiService.post<void>(`/auth/logout`)
  return data
}
