import { acceptTou, getUser } from '@/api/user'
import { queryKeys } from '@/constants/query-key'
import { useAuthContext } from '@/features/auth'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { DOCUMENT_TYPE_TO_INDEX, PUBLICATIONS } from '~shared/constants'

export const useGetUser = (staleTime?: number) => {
  const { isLoading: isUserLoading, data: user } = useQuery({
    queryKey: queryKeys.whoami(),
    queryFn: getUser,
    staleTime,
    refetchOnWindowFocus: !!staleTime,
  })
  return { isUserLoading, user }
}

export const useGetUserIdFromContext = () => {
  const { user } = useAuthContext()
  return { userId: user ? `${user.id}` : '' }
}

export const useGetUserEmailFromContext = () => {
  const { user } = useAuthContext()
  return { email: user ? user.email : '' }
}

export const useGetAccessiblePublicationsFromContext = () => {
  const { user } = useAuthContext()
  const publications = useMemo(() => {
    const publications: string[] = []
    if (user) {
      user.access.forEach((access) => {
        if (
          PUBLICATIONS.includes(DOCUMENT_TYPE_TO_INDEX[access.type]) &&
          access.isRead
        ) {
          publications.push(DOCUMENT_TYPE_TO_INDEX[access.type])
        }
      })
    }
    return publications
  }, [user])
  return { publications }
}

export const useAcceptTou = () => {
  const queryClient = useQueryClient()
  const {
    mutateAsync,
    isLoading,
    data: user,
  } = useMutation({
    mutationFn: acceptTou,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.whoami())
    },
  })
  return { acceptTou: mutateAsync, isLoading, user }
}
