import {
  Box,
  HStack,
  Input,
  InputProps,
  Text,
  Textarea,
  TextareaProps,
  VStack,
} from '@chakra-ui/react'
import { useState } from 'react'

import { FastField } from 'formik'
import { ErrorMessageWithIcon } from '@/components/error/ErrorMessageWithIcon'

type FormFieldInputProps = {
  name: string
  styles?: InputProps & TextareaProps
  title?: string
  isOptional?: boolean
  placeholder?: string
}

export const FormFieldInput = ({
  title,
  name,
  styles,
  isOptional,
  placeholder,
}: FormFieldInputProps) => {
  const isTitle = name === 'title' || name === 'vernacular.title'
  const [isFocused, setIsFocused] = useState(false)
  return (
    <Box display={styles?.display}>
      <FastField name={name}>
        {({ field }) => (
          <VStack align="left" spacing="8px" width={styles?.width ?? '100%'}>
            <HStack>
              <Text
                fontSize={{ lg: '1rem', base: '0.875rem' }}
                color="gray.600"
                fontWeight="semibold"
                w="auto"
                paddingLeft={{ base: '0rem', lg: '0.5rem' }}
              >
                {title}
              </Text>
              {isOptional && (
                <Text color="gray.600" w="auto" whiteSpace="pre">
                  {' (optional)'}
                </Text>
              )}
            </HStack>
            {isTitle ? (
              <Textarea
                //if the title is too long, increase height
                minHeight={field.value?.length >= 40 ? '5rem' : '3.8rem'}
                _hover={{ border: '2px dashed #ED8936', borderRadius: '10px' }}
                focusBorderColor="orange.400"
                border="transparent"
                width="100%"
                background="white"
                name={name}
                value={field.value}
                onChange={field.onChange}
                borderRadius="12px"
                {...styles}
                display="flex"
              />
            ) : (
              <Input
                onFocus={() => {
                  setIsFocused(true)
                }}
                onBlur={() => {
                  setIsFocused(false)
                }}
                _hover={{ border: '2px dashed #ED8936', borderRadius: '10px' }}
                focusBorderColor="orange.400"
                placeholder={isFocused ? placeholder : ''}
                border="transparent"
                width="100%"
                background="white"
                padding="0.5rem 1rem 0.5rem 1rem"
                name={name}
                value={field.value}
                onChange={field.onChange}
                borderRadius="12px"
                {...styles}
                display="flex"
              />
            )}
            <ErrorMessageWithIcon fieldName={name} />
          </VStack>
        )}
      </FastField>
    </Box>
  )
}
