import { Icon } from '@chakra-ui/react'
import { DEFAULT_COLLECTION } from '~shared/constants'
import { BiStar } from 'react-icons/bi'
import { MdFolderOpen, MdFolderShared } from 'react-icons/md'
import { All_ARTICLES } from '@/constants/title'
import { HiOutlineGlobe, HiOutlineNewspaper } from 'react-icons/hi'
import { OPENSEARCH_INDEX } from '~shared/constants'

type CollectionIconProps = {
  name: string
  userCount?: number
  value?: string | number
}

export const CollectionIcon = ({
  name,
  userCount,
  value,
}: CollectionIconProps) => {
  if (name === DEFAULT_COLLECTION) {
    return <Icon as={BiStar} />
  }
  if (name === All_ARTICLES) {
    return <Icon as={HiOutlineGlobe} />
  }
  if (userCount !== undefined && userCount <= 1) {
    return <Icon as={MdFolderOpen} />
  }
  if (value && value === OPENSEARCH_INDEX.PressumArticle) {
    return <Icon as={HiOutlineNewspaper} />
  }
  return <Icon as={MdFolderShared} />
}
