import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { createContext, useContext } from 'react'
import {
  DocumentTagWithCategory,
  TextractContent,
  TranslateReq,
  TranslateRes,
} from '~shared/dtos'

type UploadDocumentContextProps = {
  textContent: string
  generatedTags?: DocumentTagWithCategory[]
  isGenerateTagsLoading: boolean
  isGenerateTagsError: boolean
  isContentLoading?: boolean
  isContentFetching?: boolean
  documentContent?: TextractContent[]
  isVernacular: boolean
  setIsVernacular: (isVernacular: boolean) => void
  isDirty: boolean
  setIsDirty: (isDirty: boolean) => void
  translate: UseMutateAsyncFunction<
    TranslateRes | undefined,
    unknown,
    TranslateReq,
    unknown
  >
  isTranslateError: boolean
  isTranslateLoading: boolean
  currentSlateContentSize: number
  isFieldTooLarge: boolean
  getDisplayProp: (fieldName: string) => 'none' | 'flex' | undefined
}

export const UploadDocumentContext = createContext<
  UploadDocumentContextProps | undefined
>(undefined)

export const useUploadDocumentContext = () => {
  const context = useContext(UploadDocumentContext)
  if (!context) {
    throw new Error(
      `useUploadDocumentContext must be used within a UploadDocumentContext component`
    )
  }
  return context
}
