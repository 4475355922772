import { searchFilterParam } from '@/constants/search-params'
import { Button, HStack, VStack, Text } from '@chakra-ui/react'
import { endOfDay, format, getYear, isAfter } from 'date-fns'
import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { useSearchParams } from 'react-router-dom'
import { SORT_OPTIONS } from '~shared/constants'
import { Row } from './components/Row'
import {
  headerStyles,
  tableBodyStyles,
  tableCellStyles,
  tableHeaderStyles,
} from './styles'
import { useIsMobile } from '@/hooks'
import { dateRangeToString } from '@/utils/date'

type FooterProps = {
  setSelected: Dispatch<SetStateAction<DateRange | undefined>>
  selected: DateRange | undefined
}

const Footer = ({ setSelected, selected }: FooterProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    date: { startDate, endDate },
  } = searchFilterParam

  const isDisabled = !(selected?.from && selected?.to)
  return (
    <HStack w="100%" paddingTop="16px" justifyContent="space-between">
      <Button
        borderRadius="20px"
        height="32px"
        px="12px"
        bg={isDisabled ? 'gray.700' : 'gray.800'}
        color={isDisabled ? 'gray.500' : 'gray.100'}
        isDisabled={isDisabled}
        fontSize="14px"
        onClick={() => {
          searchParams.delete(startDate)
          searchParams.delete(endDate)
          setSearchParams(searchParams)
          setSelected(undefined)
        }}
      >
        Clear
      </Button>
      <Button
        borderRadius="20px"
        height="32px"
        px="12px"
        bg={isDisabled ? 'gray.700' : 'orange.500'}
        fontSize="14px"
        color={isDisabled ? 'gray.500' : 'orange.50'}
        isDisabled={isDisabled}
        onClick={() => {
          // if same from and to is same date
          if (selected && selected.from && !selected.to) {
            searchParams.set(startDate, format(selected.from, 'yyyy-MM-dd'))
            searchParams.set(endDate, format(selected.from, 'yyyy-MM-dd'))
          }
          if (selected && selected.from && selected.to) {
            searchParams.set(startDate, format(selected.from, 'yyyy-MM-dd'))
            searchParams.set(endDate, format(selected.to, 'yyyy-MM-dd'))
          }
          searchParams.set('sort', SORT_OPTIONS.Relevance)
          setSearchParams(searchParams)
        }}
      >
        Apply Date
      </Button>
    </HStack>
  )
}

type DatePickerProps = {
  hasFooter?: boolean
  selected?: DateRange
  setSelected?: Dispatch<SetStateAction<DateRange | undefined>>
}

//todo (ben): date picker styling for dropdown
export const DatePicker = memo(
  ({
    hasFooter,
    selected: propsSelected,
    setSelected: propsSetSelected,
  }: DatePickerProps) => {
    const [searchParams] = useSearchParams()
    const isMobile = useIsMobile()

    const {
      date: { startDate, endDate },
    } = searchFilterParam
    const [localSelected, setLocalSelected] = useState<DateRange | undefined>()
    const selected = propsSelected ?? localSelected
    const setSelected = propsSetSelected ?? setLocalSelected

    useEffect(() => {
      if (!isMobile) {
        searchParams.get(startDate) && searchParams.get(endDate)
          ? setSelected({
              from: new Date(searchParams.get(startDate) as string),
              to: new Date(searchParams.get(endDate) as string),
            })
          : setSelected(undefined)
      }
    }, [searchParams, startDate, endDate, isMobile, setSelected])

    const year = getYear(new Date())
    return (
      <>
        <DayPicker
          ISOWeek
          mode="range"
          selected={selected}
          disabled={(date) => isAfter(date, endOfDay(new Date()))}
          onSelect={(range) => {
            if (range) {
              // if only date, set to and from to the same date
              if (!range.to) {
                range.to = range.from
              }
              setSelected(range)
            }
          }}
          captionLayout="dropdown-buttons"
          fromYear={year - 50}
          toYear={year}
          components={{
            Row,
          }}
          styles={{
            dropdown: {
              background: 'white',
              color: 'black',
            },
            root: {
              width: '100%',
              margin: '0px',
              background: '#2D3748',
              padding: '16px',
              borderRadius: '6px',
            },
            // extracting this out of tableCellStyles due to the ts-expect-error
            cell: {
              width: '28px',
              height: '28px',
              // https://developer.mozilla.org/en-US/docs/Web/CSS/text-align
              // @ts-expect-error non standard-syntax but accepted
              textAlign: '-webkit-center',
            },
            ...tableCellStyles,
            ...tableHeaderStyles,
            ...tableBodyStyles,
            ...headerStyles,
          }}
          /**
           * modifierStyles are used to style components with specific conditions
           * range_start is for the first day of the selected range
           * today is for the current day
           * the class assigned to the component will start with rdp-day_<modifier> - e.g. rdp-day_range_start
           * */
          modifiersStyles={{
            range_start: {
              background: '#DD6B20',
              borderRadius: '9999px',
            },
            range_middle: {
              background: '#4A5568',
              color: 'white',
            },
            range_end: {
              background: '#DD6B20',
              borderRadius: '9999px',
            },
            today: {
              background: '#4A5568',
              fontWeight: '500',
            },
          }}
          footer={
            hasFooter ? (
              <Footer setSelected={setSelected} selected={selected} />
            ) : null
          }
        />
        {!hasFooter && selected && (
          <VStack width="100%" align="left" marginTop="24px" spacing="8px">
            <Text color="White">Selected Date:</Text>
            <Text
              gap="4px"
              display="inline-flex"
              padding="0px 8px"
              alignItems="center"
              width="fit-content"
              fontSize="14px"
              height="20px"
              borderRadius="6px"
              backgroundColor="gray.700"
              textColor="gray.50"
              fontWeight={500}
              fontStyle={'normal'}
            >
              {dateRangeToString(selected)}
            </Text>
          </VStack>
        )}
      </>
    )
  }
)
