import { ComponentPropsWithRef } from 'react'
import { Button } from '@chakra-ui/react'

type DotButtonProps = ComponentPropsWithRef<'button'> & { isSelected: boolean }

export const DotButton = ({
  children,
  isSelected,
  ...restProps
}: DotButtonProps) => {
  return (
    <Button
      sx={{
        WebkitTapHighlightColor: `rgba(rgb(49, 49, 49), 0.5)`,
        WebkitAppearance: 'none',
        touchAction: 'manipulation',
      }}
      backgroundColor={isSelected ? '#36313D' : '#CBD5E0'}
      cursor="pointer"
      borderRadius="50%"
      width="0.5rem"
      minWidth="0.5rem"
      height="0.5rem"
      minHeight="0.5rem"
      padding={0}
      _hover={{ background: isSelected ? '#36313D' : '#CBD5E0' }}
      _active={{ background: '#36313D' }}
      _focus={{ background: '#36313D' }}
      {...restProps}
    >
      {children}
    </Button>
  )
}
