export const REGION = 'ap-southeast-1';
export const AWS_UNPROCESSED_PRESSUMS_FILENAME = 'unprocessed-pressums';
export const AWS_RUM_ENDPOINT =
  'https://dataplane.rum.ap-southeast-1.amazonaws.com';
export const AWS_COGNITO_ENDPOINT =
  'https://cognito-identity.ap-southeast-1.amazonaws.com/';
export const AWS_STS_ENDPOINT = 'https://sts.ap-southeast-1.amazonaws.com/';
export const AWS_S3_ENDPOINT = 'https://*.s3.ap-southeast-1.amazonaws.com/';

export const MAX_MB = 15;
export const S3_UPLOAD_MAX_FILE_SIZE = MAX_MB * 1024 * 1024; // bytes
export const S3_UPLOAD_CONTENT_TYPE = 'application/pdf';
export const S3_UPLOAD_MAX_PAGES = 200;
