import {
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  Text,
  HStack,
  IconButton,
  Icon,
} from '@chakra-ui/react'
import { useSlate } from 'slate-react'
import { unwrapLink, isLinkActive, getLinkData } from '../utils'
import { BiEdit, BiLink, BiUnlink } from 'react-icons/bi'
import { UpdateLinkModal } from './UpdateLinkModal'
import { Button } from '.'
import { Editor } from 'slate'
import { useState } from 'react'

const hoverStyles = {
  cursor: 'pointer',
  bg: 'orange.100',
  color: 'orange.500',
}

// todo(ben): refactor in future
export const AddLinkButton = () => {
  const editor = useSlate()
  const { onOpen, isOpen, onClose } = useDisclosure()
  const [text, setText] = useState('')
  const [url, setUrl] = useState('')

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={() => {
        const { url: selectedUrl, text: selectedText } = isLinkActive(editor)
          ? getLinkData(editor)
          : {
              url: '',
              text: editor.selection
                ? Editor.string(editor, editor.selection)
                : '',
            }
        setUrl(selectedUrl)
        setText(selectedText)
        onOpen()
      }}
    >
      <Icon h="1.25rem" w="1.25rem" as={BiLink}>
        link
      </Icon>
      <UpdateLinkModal
        isOpen={isOpen}
        onClose={onClose}
        text={text}
        url={url}
      />
    </Button>
  )
}

export const LinkComponent = ({
  attributes,
  children,
  element,
  isReadonly,
}) => {
  const editor = useSlate()
  const { onOpen, isOpen, onClose } = useDisclosure()
  const {
    isOpen: isPopOverOpen,
    onOpen: onPopOverOpen,
    onClose: onPopOverClose,
  } = useDisclosure()

  return (
    <Popover
      placement="bottom"
      closeOnBlur={true}
      isOpen={!isReadonly && isPopOverOpen}
      onClose={onPopOverClose}
    >
      <PopoverTrigger>
        <Link
          onClick={onPopOverOpen}
          {...attributes}
          href={element.url}
          isExternal
          color="blue.500"
          textDecoration="underline"
        >
          {children}
        </Link>
      </PopoverTrigger>
      <PopoverContent
        color="black"
        borderRadius="0.625rem"
        px="1.5rem"
        py="0.5rem"
        bg="gray.100"
        boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)"
        _hover={{
          cursor: 'default',
        }}
      >
        <PopoverCloseButton onClick={onPopOverClose} />
        <PopoverBody>
          <HStack>
            <Text
              isTruncated
              lineHeight="1.5rem"
              fontSize="1rem"
              color="blue.500"
              textDecoration="underline"
              _hover={{
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open(element.url, '_blank', 'noopener')
              }}
            >
              {element.url}
            </Text>
            <HStack spacing="0">
              <IconButton
                _hover={hoverStyles}
                w="1.5rem"
                h="1.5rem"
                aria-label="edit"
                as={BiEdit}
                onClick={onOpen}
              />
              <IconButton
                w="1.5rem"
                h="1.5rem"
                _hover={hoverStyles}
                aria-label="unlink"
                as={BiUnlink}
                onClick={() => {
                  unwrapLink(editor)
                }}
              />
            </HStack>
          </HStack>
        </PopoverBody>
      </PopoverContent>
      <UpdateLinkModal
        isOpen={isOpen}
        onClose={onClose}
        text={element.children[0].text}
        url={element.url}
      />
    </Popover>
  )
}
