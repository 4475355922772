import { VStack, HStack, Text } from '@chakra-ui/layout'
import { Switch } from '@chakra-ui/react'

import { ReportIssueButton } from './ReportIssueButton'
import { DocumentTags } from '@/features/document/DocumentTags'
import { DocumentRes } from '~shared/dtos'
import { StarButton } from '@/features/document/button/StarButton'
import { BookmarkButton } from '@/features/bookmark/BookmarkButton'
import { useGetCollections } from '@/hooks/useCollection'
import { useGetUserIdFromContext } from '@/hooks'
import { useMemo } from 'react'
import { DEFAULT_COLLECTION } from '~shared/constants'
import { CollectionList } from '@/features/collections/CollectionList'
import { isEmpty } from 'lodash'

type RightSideBarProps = {
  isTranslateToVernacular: boolean
  setIsTranslateToVernacular: React.Dispatch<React.SetStateAction<boolean>>
  isVernacular: boolean
  document: DocumentRes
}

export const RightSideBar = ({
  isTranslateToVernacular,
  setIsTranslateToVernacular,
  isVernacular,
  document,
}: RightSideBarProps) => {
  const { userId } = useGetUserIdFromContext()
  const { collections } = useGetCollections(userId)

  //todo(ben): refactor with document card
  const starredCollectionId = useMemo(() => {
    let id = NaN
    if (collections)
      id =
        collections.find((collection) => collection.name === DEFAULT_COLLECTION)
          ?.collectionId ?? NaN
    return id
  }, [collections])

  const isDocumentInStarredCollection = useMemo(() => {
    return !!document.collections?.find(
      (collection) => collection.name === DEFAULT_COLLECTION
    )
  }, [document.collections])

  const collectionsWithoutStarred = useMemo(() => {
    return document.collections?.filter(
      (collection) => collection.name !== DEFAULT_COLLECTION
    )
  }, [document.collections])

  return (
    <VStack
      w="20%"
      minW="17.375rem"
      maxW="17.375rem"
      height="100vh"
      bg="visualisation.sidebar.background"
      pt="1.5rem"
      paddingRight="2.25rem"
      paddingLeft="1.875rem"
      spacing="1.5rem"
      borderTopLeftRadius="50px"
      position="sticky"
      top="0"
      align="end"
      overflow="auto"
    >
      <ReportIssueButton />
      {isVernacular && (
        <VStack spacing="1rem" alignItems="left" w="100%">
          <Text fontWeight="semibold" fontSize="1rem">
            Translation
          </Text>
          <HStack spacing="0.5rem">
            <Switch
              w="2.125rem"
              colorScheme="facebook"
              onChange={() => {
                setIsTranslateToVernacular(!isTranslateToVernacular)
              }}
              isChecked={isTranslateToVernacular}
            />
            <Text fontSize="0.875rem" fontWeight="normal">
              Vernacular Translation
            </Text>
          </HStack>
        </VStack>
      )}
      <HStack spacing="1rem" w="100%" align="left">
        <StarButton
          documentId={+document.id}
          isDocumentInStarredCollection={isDocumentInStarredCollection}
          starredCollectionId={+starredCollectionId}
        />
        <BookmarkButton
          documentId={document.id}
          documentTitle={document.title}
          documentCollections={document.collections ?? []}
        />
      </HStack>
      {collectionsWithoutStarred && !isEmpty(collectionsWithoutStarred) && (
        <CollectionList collections={collectionsWithoutStarred} />
      )}
      <HStack justifyContent="space-between" align="top" w="100%">
        <DocumentTags documentId={document.id} documentTags={document.tags} />
      </HStack>
    </VStack>
  )
}
