import 'reflect-metadata'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { theme } from '@/theme'
import { AwsRumProvider } from '@/features/monitoring/AwsRumContext'
import { AppRouter } from './AppRouter'
import { useIsMobile } from '@/hooks'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
    },
    mutations: {
      networkMode: 'always',
    },
  },
})

export const App = () => {
  const isMobile = useIsMobile()
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AwsRumProvider>
        <ChakraProvider
          toastOptions={{
            defaultOptions: {
              position: isMobile ? 'top' : 'top-right',
              containerStyle: isMobile
                ? { margin: '0', width: '100vw' }
                : {
                    position: 'relative',
                    top: '6.5rem',
                  },
            },
          }}
          theme={theme}
        >
          <AppRouter />
        </ChakraProvider>
      </AwsRumProvider>
    </QueryClientProvider>
  )
}
