import { queryKeys } from '@/constants/query-key'
import { searchFilterParam } from '@/constants/search-params'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'
import {
  useGetUserEmailFromContext,
  useGetUserIdFromContext,
  useIsMobile,
} from '@/hooks'
import { useGetCollectionIdFromSearchParams } from '@/hooks/params/useSearchParams'
import { useToast } from '@/hooks/useToast'
import { useDeleteUserCollection } from '@/hooks/useUserCollection'
import { drawerModalButtonStyles } from '@/theme/styles'
import { Button, Stack } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

const CancelButton = ({
  onClose,
  isDisabled,
}: {
  onClose: () => void
  isDisabled: boolean
}) => {
  return (
    <Button
      bg={{ base: 'parent', lg: 'gray.100' }}
      color="gray.800"
      onClick={onClose}
      isDisabled={isDisabled}
      {...drawerModalButtonStyles}
    >
      Cancel
    </Button>
  )
}

const SubmitButton = ({
  onClick,
  isLoading,
  buttonContent,
}: {
  onClick: () => void
  isLoading: boolean
  buttonContent: string
}) => {
  return (
    <Button
      bg="red.100"
      color="red.800"
      _hover={{ bg: 'red.500', color: 'orange.50' }}
      type="submit"
      isLoading={isLoading}
      onClick={onClick}
      {...drawerModalButtonStyles}
    >
      {buttonContent}
    </Button>
  )
}

type DeleteUserCollectionActionsProps = {
  onClose: () => void
  userId: string
  email: string
}

export const DeleteUserCollectionActions = ({
  onClose,
  userId,
  email,
}: DeleteUserCollectionActionsProps) => {
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const { userId: curUserId } = useGetUserIdFromContext()
  const { collection } = useDocumentListContext()
  const { collectionId } = useGetCollectionIdFromSearchParams()
  const isMobile = useIsMobile()
  const toast = useToast()
  const { email: userEmail } = useGetUserEmailFromContext()
  const { deleteUserCollection, isDeleteUserCollectionLoading } =
    useDeleteUserCollection({
      collectionId,
      userId,
    })

  const isUser = email === userEmail
  const buttonContent = isUser ? 'Remove yourself' : `Remove access`

  const handleSubmit = async () => {
    if (!collectionId) {
      toast({
        description: `Error deleting permissions. Please try again later.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
    await deleteUserCollection(undefined, {
      onSuccess: async ({ deletedCollectionId }) => {
        toast({
          description: `Access to ${collection?.name} collection revoked`,
          status: 'info',
          duration: 3000,
          isClosable: true,
        })
        if (deletedCollectionId || userId === curUserId) {
          searchParams.delete(searchFilterParam.collectionIds)
          setSearchParams(searchParams)
        }
        await queryClient.invalidateQueries(
          queryKeys.userCollections(collectionId)
        )
        await queryClient.invalidateQueries(queryKeys.collections(userId))
      },
      onError: () => {
        toast({
          description: `Error deleting permissions. Please try again later.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })

    onClose()
  }
  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      justify="end"
      w="100%"
      pt="1rem"
      spacing="0.75rem"
    >
      {isMobile ? (
        <>
          <SubmitButton
            onClick={handleSubmit}
            isLoading={isDeleteUserCollectionLoading}
            buttonContent={buttonContent}
          />
          <CancelButton
            onClose={onClose}
            isDisabled={isDeleteUserCollectionLoading}
          />
        </>
      ) : (
        <>
          <CancelButton
            onClose={onClose}
            isDisabled={isDeleteUserCollectionLoading}
          />
          <SubmitButton
            onClick={handleSubmit}
            isLoading={isDeleteUserCollectionLoading}
            buttonContent={buttonContent}
          />
        </>
      )}
    </Stack>
  )
}
