import { useIsMobile } from '@/hooks'
import { Heading, VStack } from '@chakra-ui/react'
import { Form } from 'formik'
import { FocusError } from '@/components/form/FocusError'
import { DocumentTagsField } from '@/features/upload/components/DocumentTagField'
import { UploadFields } from '@/features/upload/components/UploadFields'
import { ExtractedFromGoogleCache } from '@/features/upload/components/ExtractedFromGoogleCache'
import { PublishBar } from './PublishBar'
import { UploadDocumentProvider } from '../UploadDocumentContext'
import { AwsPaginatedRes, TextractContent } from '~shared/dtos'
import { InfiniteQueryObserverResult } from '@tanstack/react-query'

type UploadArticleFormProps = {
  url?: string
  documentContent?: TextractContent[]
  isContentLoading?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => Promise<
    InfiniteQueryObserverResult<AwsPaginatedRes<TextractContent[]>, unknown>
  >
  isExtractedFromGoogleCache?: boolean
  onCancel?: () => void // opens drawer to warn user before closing page
}

export const UploadArticleForm = ({
  url,
  documentContent,
  isContentLoading,
  hasNextPage,
  fetchNextPage,
  isExtractedFromGoogleCache,
  onCancel,
}: UploadArticleFormProps) => {
  const isMobile = useIsMobile()

  return (
    <UploadDocumentProvider
      documentContent={documentContent}
      isContentLoading={isContentLoading}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
    >
      <VStack px={{ lg: '4.25rem' }}>
        <Form
          style={{ width: '100%' }}
          onKeyDown={(e) => {
            !isMobile && e.key === 'Enter' && e.preventDefault()
          }}
        >
          <VStack
            spacing={{ base: '1rem', lg: '1.5rem' }}
            w="100%"
            align="left"
          >
            <Heading fontSize={{ base: '1.125rem', lg: '4xl' }}>
              Edit Article
            </Heading>
            {isExtractedFromGoogleCache && <ExtractedFromGoogleCache />}
          </VStack>
          <UploadFields url={url} />
          <DocumentTagsField />
          <PublishBar onCancel={onCancel} />
          <FocusError />
        </Form>
      </VStack>
    </UploadDocumentProvider>
  )
}
