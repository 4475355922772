import { DrawerLayout } from '@/components/drawer/DrawerLayout'
import { FiTrash2 } from 'react-icons/fi'
import { DeleteBookmarkActions } from './DeleteBookmarkActions'
import { DeleteBookmarkContent } from './DeleteBookmarkContent'

type DeleteBookmarkDrawerProps = {
  isOpen: boolean
  onClose: () => void
  documentId: number
}

export const DeleteBookmarkDrawer = ({
  isOpen,
  onClose,
  documentId,
}: DeleteBookmarkDrawerProps) => {
  return (
    <DrawerLayout
      isOpen={isOpen}
      onClose={onClose}
      HeaderIcon={FiTrash2}
      title="DELETE DOCUMENT"
    >
      <DeleteBookmarkContent />
      <DeleteBookmarkActions documentId={documentId} onClose={onClose} />
    </DrawerLayout>
  )
}
