import { DeleteCollectionDrawer } from '@/features/collections/delete/DeleteCollectionDrawer'
import { RenameCollectionDrawer } from '@/features/collections/rename/RenameCollectionDrawer'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { MdOutlineSettings } from 'react-icons/md'
import { DeleteCollectionModal } from './delete/DeleteCollectionModal'
import { useIsMobile } from '@/hooks'
import { RenameCollectionModal } from './rename/RenameCollectionModal'

type ManageCollectionSettingsButtonProps = {
  collectionId: number
  collectionName: string
}

export const ManageCollectionSettingsButton = ({
  collectionId,
  collectionName,
}: ManageCollectionSettingsButtonProps) => {
  const isMobile = useIsMobile()
  const {
    isOpen: isOpenRename,
    onOpen: onOpenRename,
    onClose: onCloseRename,
  } = useDisclosure()
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()

  return (
    <Menu>
      <MenuButton
        color="orange.500"
        _hover={{
          color: 'orange.300',
          bg: 'transparent',
        }}
      >
        <MdOutlineSettings size="1.5rem" />
      </MenuButton>

      <MenuList fontSize="1rem">
        <MenuItem
          onClick={onOpenRename}
          px="1.5rem"
          py="0.5rem"
          icon={<BiEdit size="1rem" />}
        >
          Rename Collection
        </MenuItem>
        <MenuItem
          onClick={onOpenDelete}
          px="1.5rem"
          py="0.5rem"
          icon={<BiTrash size="1rem" />}
        >
          Delete Collection
        </MenuItem>
      </MenuList>
      {isMobile ? (
        <DeleteCollectionDrawer
          collectionId={collectionId}
          onClose={onCloseDelete}
          isOpen={isOpenDelete}
          collectionName={collectionName}
        />
      ) : (
        <DeleteCollectionModal
          collectionId={collectionId}
          onClose={onCloseDelete}
          isOpen={isOpenDelete}
          collectionName={collectionName}
        />
      )}
      {isMobile ? (
        <RenameCollectionDrawer
          isOpen={isOpenRename}
          onClose={onCloseRename}
          collectionName={collectionName}
          collectionId={collectionId}
        />
      ) : (
        <RenameCollectionModal
          isOpen={isOpenRename}
          onClose={onCloseRename}
          collectionName={collectionName}
          collectionId={collectionId}
        />
      )}
    </Menu>
  )
}
