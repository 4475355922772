import { Descendant } from 'slate';
import { SlateTextTypes, SlateNode } from '../types';
import { IMAGE_FILE_EXTENSIONS } from '../constants/extensions';
import { CUSTOM_LINK_PREFIX } from '../constants/document';
import { isEmpty } from 'lodash';

const getLinkText = (object: SlateNode[], urlLink: string): string => {
  const content: string[] = [];
  object.forEach((node) => {
    if (node['text']) {
      // check if url is a image.
      // if url image, wrap in [url] to prevent AWS translate
      // if not url image, push display text into content, then push url link with wrapper
      const isUrlImage = IMAGE_FILE_EXTENSIONS.some(
        (extension) => urlLink?.toLowerCase().includes(extension),
      );
      if (isUrlImage) content.push('[' + node['text'] + ']');
      else {
        content.push(node['text']);
        content.push(' [' + CUSTOM_LINK_PREFIX + urlLink + ']'); // add bracket and prefix to identify link from text
      }
    }
  });
  return content.join('').trim(); // sample output [display text] [CUSTOM_LINK_PREFIX urllink]
};

const extractTextFromNode = (node: SlateNode): string => {
  if (node['type'] === 'link' && node['children']) {
    return getLinkText(node['children'], node['url']);
  }
  if (node['text']) {
    return node['text'];
  }
  return '';
};

const extractTextFromList = (listItemNode: SlateNode): string => {
  if (listItemNode['children']) {
    return listItemNode['children']
      .map(extractTextFromNode)
      .filter(Boolean)
      .join('');
  }
  return '';
};

export const handleSlateTypes = (descendant: SlateNode): string => {
  if (!descendant['children']) return '';

  switch (descendant['type']) {
    case SlateTextTypes.PARAGRAPH:
      return descendant['children']
        .map(extractTextFromNode)
        .filter(Boolean)
        .join('');

    case SlateTextTypes.BULLETED_LIST:
    case SlateTextTypes.NUMBERED_LIST:
      return descendant['children']
        .map(extractTextFromList)
        .filter(Boolean)
        .join('\n'); // add new line for each list. todo (aaron): retain list type for translation

    default:
      return '';
  }
};

export const convertSlateContentToText = (
  descendants?: Descendant[],
): string => {
  if (isEmpty(descendants) || !descendants) {
    return '';
  }
  const content: string[] = [];
  descendants.forEach((descendant) => {
    // todo(ben): handle typing
    const text = handleSlateTypes(descendant as unknown as SlateNode);
    if (text) content.push(text);
  });
  return content.join('').trim();
};

export const convertSlateContentToTextWithImage = (
  descendants?: Descendant[],
): string => {
  const content: string[] = [];
  if (isEmpty(descendants) || !descendants) {
    return '';
  }
  descendants.forEach((descendant) => {
    const type = descendant['type'];
    if (type === SlateTextTypes.IMAGE) {
      descendant['children'].forEach(() => {
        content.push('[' + descendant['url'] + ']'); // if it is a image, add brackets with base64 content
      });
    } else {
      // todo(ben): handle typing
      const text = handleSlateTypes(descendant as unknown as SlateNode);
      if (text) content.push(text);
      else content.push('');
    }
  });
  return content.join('\n').trim();
};

//todo(ben): refactor with above methods
export const checkIfSlateHasContent = (descendants: Descendant[]) => {
  if (isEmpty(descendants) || !descendants) {
    return false;
  }
  return !!descendants.find((descendant) => {
    const type = descendant['type'];
    if (type === SlateTextTypes.IMAGE) {
      descendant['children'].forEach(() => {
        return !!descendant['url'];
      });
    } else {
      // todo(ben): handle typing
      const text = handleSlateTypes(descendant as unknown as SlateNode);
      return !!text;
    }
  });
};
