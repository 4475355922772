export const PressifyLogoSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="24"
      viewBox="0 0 105 24"
      fill="none"
    >
      <g clipPath="url(#clip0_4757_6140)">
        <path
          d="M13.5066 8.98152C12.9491 7.97992 12.1637 7.1807 11.1497 6.58388C10.1357 5.988 8.97986 5.68958 7.68231 5.68958C6.8346 5.68958 6.01146 5.86374 5.21289 6.2111C4.41433 6.55846 3.76602 7.10539 3.26704 7.85095V5.66699H0V24H3.44187V17.0688C3.94086 17.7644 4.56837 18.2652 5.32535 18.5721C6.08139 18.8781 6.90075 19.0315 7.78248 19.0315C9.02995 19.0315 10.1517 18.7293 11.1497 18.125C12.1477 17.5206 12.9292 16.7044 13.4944 15.6774C14.0595 14.6504 14.3421 13.5245 14.3421 12.2989C14.3421 11.0732 14.0633 9.98407 13.5066 8.98247V8.98152ZM10.3256 14.2362C10.01 14.7831 9.56863 15.2049 9.00349 15.5033C8.43835 15.8017 7.7976 15.9504 7.08315 15.9504C6.36869 15.9504 5.74874 15.7932 5.17509 15.4779C4.60145 15.1634 4.15255 14.7323 3.8284 14.1863C3.50424 13.6394 3.3417 13.035 3.3417 12.3723C3.3417 11.7096 3.50424 11.0808 3.8284 10.5338C4.15255 9.9869 4.59767 9.5614 5.16281 9.25452C5.72795 8.94858 6.36869 8.79513 7.08315 8.79513C7.7976 8.79513 8.43835 8.94387 9.00349 9.24228C9.56863 9.54069 10.009 9.96242 10.3256 10.5094C10.6413 11.0563 10.7991 11.6766 10.7991 12.3723C10.7991 13.068 10.6413 13.6893 10.3256 14.2353V14.2362Z"
          fill="#171923"
        />
        <path
          d="M24.8869 5.78842C24.5628 5.72253 24.2263 5.68864 23.8767 5.68864C23.029 5.68864 22.2096 5.86279 21.4195 6.21015C20.6295 6.55752 19.985 7.10445 19.4869 7.85V5.66605H16.2199V19.0221H19.6617V12.0984C19.6617 11.3029 19.8366 10.6411 20.1853 10.1112C20.534 9.58117 20.9914 9.19615 21.5575 8.9561C22.1227 8.71606 22.7379 8.59556 23.4032 8.59556C23.9183 8.59556 24.4673 8.66993 25.0495 8.81961L25.7479 6.03694C25.4984 5.93716 25.212 5.85526 24.8879 5.78842H24.8869Z"
          fill="#171923"
        />
        <path
          d="M29.8636 18.1739C28.7664 17.6025 27.9016 16.8118 27.2694 15.8017C26.6372 14.7916 26.3215 13.6648 26.3215 12.4231C26.3215 11.1815 26.6419 9.9803 27.2817 8.96928C27.9215 7.9592 28.7824 7.16375 29.8636 6.58387C30.9438 6.00399 32.1251 5.71405 33.4056 5.71405C34.6862 5.71405 35.841 5.98705 36.873 6.53398C37.904 7.08091 38.7149 7.8547 39.3046 8.85725C39.8943 9.8598 40.1901 11.0149 40.1901 12.3233C40.1901 12.7375 40.1816 13.036 40.1656 13.2176H28.9412V10.8576H36.7237C36.5073 10.0791 36.1037 9.47855 35.514 9.05682C34.9234 8.63415 34.2212 8.42329 33.4066 8.42329C32.7743 8.42329 32.1629 8.55978 31.5732 8.83278C30.9825 9.10577 30.5005 9.53692 30.1263 10.1243C29.752 10.7127 29.5649 11.462 29.5649 12.3723C29.5649 13.2336 29.752 13.9585 30.1263 14.5459C30.5005 15.1343 30.9872 15.5645 31.5854 15.8374C32.1837 16.1104 32.8405 16.2469 33.5559 16.2469C34.3875 16.2469 35.1152 16.0813 35.738 15.7499C36.3617 15.4185 36.7813 14.9799 36.9977 14.4329L39.7667 15.427C39.401 16.5039 38.6695 17.3737 37.5723 18.0355C36.4751 18.6973 35.136 19.0296 33.5568 19.0296C32.1931 19.0296 30.9627 18.7444 29.8655 18.172L29.8636 18.1739Z"
          fill="#171923"
        />
        <path
          d="M60.6533 18.1118C59.5721 17.4989 58.8908 16.6461 58.6082 15.5532L61.5766 14.6833C61.759 15.2792 62.1257 15.7433 62.6738 16.0747C63.2229 16.406 63.9288 16.5717 64.7935 16.5717C65.508 16.5717 66.0694 16.439 66.4767 16.1745C66.884 15.9099 67.0881 15.5701 67.0881 15.1559C67.0881 14.8744 67.0135 14.6513 66.8632 14.4847C66.7139 14.319 66.505 14.1948 66.2395 14.1119C65.973 14.0291 65.6318 13.9547 65.2169 13.8879L63.4213 13.5895C61.9083 13.3579 60.7648 12.9183 59.9917 12.2725C59.2187 11.6267 58.8322 10.7569 58.8322 9.664C58.8322 8.85255 59.0609 8.14841 59.5183 7.55253C59.9757 6.95665 60.6193 6.50103 61.4509 6.18567C62.2825 5.87126 63.2881 5.71405 64.4694 5.71405C66.0486 5.71405 67.3631 6.0087 68.4102 6.59611C69.4583 7.18446 70.1227 8.00815 70.4052 9.06812L67.3868 9.83815C67.2204 9.30817 66.8915 8.8902 66.402 8.58332C65.9115 8.27738 65.3001 8.12393 64.5686 8.12393C63.8863 8.12393 63.3542 8.24819 62.9724 8.49671C62.5897 8.74523 62.3988 9.08506 62.3988 9.51527C62.3988 9.86357 62.532 10.1281 62.7976 10.3107C63.0631 10.4933 63.496 10.6336 64.0942 10.7334L66.0401 11.0064C67.52 11.222 68.6588 11.6399 69.4574 12.2612C70.255 12.8825 70.6547 13.7316 70.6547 14.8076C70.6547 15.6859 70.4213 16.439 69.9563 17.0687C69.4904 17.6985 68.8213 18.1833 67.9481 18.5222C67.0749 18.8611 66.0316 19.0315 64.8181 19.0315C63.1217 19.0315 61.7335 18.7246 60.6523 18.1118H60.6533Z"
          fill="#171923"
        />
        <path
          d="M72.5874 3.5028C72.1886 3.10555 71.9882 2.62546 71.9882 2.06158C71.9882 1.49771 72.1876 0.993136 72.5874 0.595882C72.9862 0.198627 73.4681 0 74.0342 0C74.6003 0 75.1069 0.198627 75.5057 0.595882C75.9045 0.993136 76.1039 1.48264 76.1039 2.06158C76.1039 2.64052 75.9045 3.10555 75.5057 3.5028C75.1069 3.90006 74.6164 4.09869 74.0342 4.09869C73.4521 4.09869 72.9862 3.90006 72.5874 3.5028ZM72.3133 5.66699H75.7552V19.0315H72.3133V5.66699Z"
          fill="#171923"
        />
        <path
          d="M84.6887 3.9876C85.1877 3.56493 85.8114 3.35406 86.559 3.35406C86.9748 3.35406 87.3405 3.40395 87.6562 3.5028L87.9803 0.571399C87.5144 0.455612 87.0079 0.397247 86.4588 0.397247C84.7624 0.397247 83.3449 0.861338 82.2061 1.78858C81.1372 2.65934 80.5702 4.08362 80.504 6.06142H77.8286V8.99282H80.4974V19.0221H83.9393V8.99282H87.556V6.06142H83.9393V5.83737C83.9393 5.02592 84.1888 4.40839 84.6878 3.98666L84.6887 3.9876Z"
          fill="#171923"
        />
        <path
          d="M90.5603 23.7167C90.0443 23.5425 89.5794 23.2902 89.1635 22.9589L90.1114 20.276C90.3269 20.4417 90.6104 20.5819 90.9591 20.6987C91.3078 20.8144 91.6736 20.8728 92.0563 20.8728C92.5052 20.8728 92.9173 20.7777 93.2906 20.5876C93.6648 20.3965 94.0012 20.1112 94.3008 19.73C94.6004 19.3487 94.8745 18.8771 95.124 18.3142L95.7978 16.6743L95.673 19.1341L89.1635 5.66794H92.8048L96.9205 14.786L100.662 5.66794H104.229L98.1424 19.2085C97.6264 20.3343 97.0821 21.2456 96.5085 21.9412C95.9348 22.6369 95.3073 23.15 94.625 23.4813C93.9426 23.8127 93.1781 23.9784 92.3304 23.9784C91.6651 23.9784 91.0744 23.8908 90.5594 23.7176L90.5603 23.7167Z"
          fill="#171923"
        />
        <path
          d="M56.9893 14.0799C56.9912 13.8088 56.9562 12.8675 56.4658 11.8894C55.9394 10.8398 54.8034 9.57083 52.3813 9.49081H52.3482H49.4119H47.9357H47.6229C47.5946 9.49081 47.5615 9.49081 47.5294 9.49458V9.49081H46.796H46.7885C46.1562 9.49081 45.6412 9.99727 45.6412 10.6195C45.6412 11.1871 46.0693 11.6588 46.625 11.7369V11.7482H46.7875H47.5294C47.5398 11.7482 47.9991 11.7482 49.4062 11.7482H52.3264C52.7479 11.7642 54.8063 11.9496 54.776 14.0592L54.7741 14.0837V14.0941V14.1044L54.777 14.1571C54.777 14.1647 54.8081 15.2002 54.1806 15.8732C53.762 16.3223 53.0976 16.5501 52.2064 16.5501H45.9096L44.0762 18.8075H52.213L52.7168 18.7886H52.7196H52.7224C54.0143 18.6926 55.052 18.2276 55.8061 17.4067C56.9988 16.1086 57.0025 14.417 56.9903 14.0809L56.9893 14.0799Z"
          fill="url(#paint0_linear_4757_6140)"
        />
        <path
          d="M52.2026 13.02V13.0087H52.0401H51.2982C51.2878 13.0087 50.8285 13.0087 49.4214 13.0087H46.5012C46.0797 12.9927 44.0213 12.8072 44.0516 10.6976L44.0535 10.6732V10.6628V10.6524L44.0506 10.5997C44.0506 10.5922 44.0195 9.5567 44.647 8.88362C45.0656 8.4346 45.73 8.20679 46.6212 8.20679H52.8141L54.6749 5.9494H46.6155L46.1118 5.96823H46.109H46.1061C44.8142 6.06425 43.7766 6.52928 43.0224 7.35015C41.8298 8.64828 41.826 10.3399 41.8383 10.676C41.8364 10.9471 41.8714 11.8884 42.3618 12.8665C42.8882 13.9161 44.0242 15.1851 46.4463 15.2651H46.4794H49.4157H50.8919H51.2047C51.233 15.2651 51.2661 15.2651 51.2982 15.2613V15.2651H52.0316H52.0392C52.6714 15.2651 53.1864 14.7587 53.1864 14.1364C53.1864 13.5688 52.7583 13.0972 52.2026 13.019V13.02Z"
          fill="url(#paint1_linear_4757_6140)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4757_6140"
          x1="52.6468"
          y1="19.4391"
          x2="46.8143"
          y2="9.29691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCA90F" />
          <stop offset="0.14" stopColor="#F58E1F" />
          <stop offset="0.33" stopColor="#EF7230" />
          <stop offset="0.52" stopColor="#EA5E3C" />
          <stop offset="0.71" stopColor="#E75243" />
          <stop offset="0.9" stopColor="#E74F46" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4757_6140"
          x1="41.8354"
          y1="10.6073"
          x2="54.6739"
          y2="10.6073"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCA90F" />
          <stop offset="0.14" stopColor="#F58E1F" />
          <stop offset="0.33" stopColor="#EF7230" />
          <stop offset="0.52" stopColor="#EA5E3C" />
          <stop offset="0.71" stopColor="#E75243" />
          <stop offset="0.9" stopColor="#E74F46" />
        </linearGradient>
        <clipPath id="clip0_4757_6140">
          <rect width="104.229" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
