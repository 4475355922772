import { Text, VStack, HStack, Button, Center, Spinner } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DocumentListLayout } from '@/components/DocumentListLayout'
import { NavBarHeader } from '@/components/header'
import {
  useGetAccessiblePublicationsFromContext,
  useSearchLatestPressums,
  useSearchLatestPublishedArticles,
} from '@/hooks'
import { LatestArticlesList } from '@/features/home/LatestArticlesList'
import { useEffect, useMemo } from 'react'
import { CategoryType, OPENSEARCH_INDEX } from '~shared/constants'
import { LatestPressumList } from '@/features/home/LatestPressumList'
import { AppNavPaths } from '@/utils/paths'
import { isEmpty } from 'lodash'
import { useIsMobile } from '@/hooks/useIsMobile'
import { MobileHomePage } from './Mobile/MobileHomePage'
import { useAddTagToSearchParams } from '@/hooks/params/useSearchParams'
import { NoArticlePlaceholder } from '@/features/home/NoArticlePlaceholder'
import { COUNTRY_SVG_MAP } from '@/constants/country'
import Country from '@/features/home/CountryCards/Country'
import { useDocumentListContext } from '@/features/home/useDocumentListContext'
import { COUNTRIES_REGIONS_TEXT_LABEL } from '@/constants/title'

export const HomePage = () => {
  const navigate = useNavigate()
  const { collections, isCollectionsLoading } = useDocumentListContext()
  const collectionids = useMemo(() => {
    return collections
      ? collections.map((collection) => `${collection.collectionId}`)
      : []
  }, [collections])

  const { data: articles, isLoading: isArticlesLoading } =
    useSearchLatestPublishedArticles({ enabled: !isEmpty(collectionids) })
  const { publications } = useGetAccessiblePublicationsFromContext()

  const { data: pressums, isLatestPressumLoading: isPressumsLoading } =
    useSearchLatestPressums(
      publications.includes(OPENSEARCH_INDEX.PressumArticle)
    )

  const pressumId =
    useMemo(() => {
      if (pressums && !isEmpty(pressums)) {
        return pressums[0].pressumId
      }
    }, [pressums]) ?? ''

  const [searchParams] = useSearchParams()

  const addTagToSearchParams = useAddTagToSearchParams()

  const isMobile = useIsMobile()

  useEffect(() => {
    // isMobile needed so that we don't trigger navigation on a single filter selection
    if (!isEmpty(searchParams.toString()) && !isMobile)
      navigate(`${AppNavPaths.Documents}?${searchParams.toString()}`)
  }, [navigate, searchParams, isMobile])
  const pressumIdNumber = pressumId?.split(' ')[1]

  const hasPressum = pressums && !isEmpty(pressums)

  const hasArticles = hasPressum || !isEmpty(articles)

  const isLoading =
    isCollectionsLoading || isArticlesLoading || isPressumsLoading

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    )
  }

  return (
    <DocumentListLayout
      header={<NavBarHeader hasSearchBar />}
      pt={{ lg: '0', base: '0' }}
      pr={{ lg: '4.25rem', base: '0' }}
      pl={{ lg: '2.25rem', base: '0' }}
      isShowPlaceholder={!hasArticles}
      placeholder={
        <Center>
          <NoArticlePlaceholder />
        </Center>
      }
    >
      {isMobile ? (
        <MobileHomePage
          articles={articles}
          pressumId={pressumId}
          pressums={pressums}
        />
      ) : (
        <VStack
          backgroundColor="background.default"
          alignItems="left"
          spacing="2rem"
          w="100%"
          h="100%"
          paddingRight="68px"
          paddingLeft="36px"
        >
          <>
            {articles && !isEmpty(articles) && (
              <VStack align="left" w="100%" h="100%" spacing="1rem">
                <LatestArticlesList articles={articles} />
                <Button
                  w="fit-content"
                  fontSize="0.875rem"
                  fontWeight="semibold"
                  lineHeight="1.25rem"
                  px="1rem"
                  py="0.5rem"
                  bg="brand.primary.500"
                  borderRadius="20px"
                  h="2rem"
                  _hover={{
                    bg: 'brand.primary.400',
                    color: 'brand.primary.100',
                  }}
                  color="white"
                  type="submit"
                  alignSelf="end"
                  onClick={() => {
                    navigate(AppNavPaths.Documents)
                  }}
                >
                  {`View full list of articles`}
                </Button>
              </VStack>
            )}
            {hasPressum && (
              <VStack w="100%" spacing="1rem" align="left">
                <HStack>
                  <Text fontSize="36px" fontWeight="semibold">
                    {`Latest Pressum`}
                  </Text>
                  <Text fontSize="36px">{`(${pressumIdNumber})`}</Text>
                </HStack>

                <LatestPressumList
                  pressums={pressums}
                  pressumId={pressumId ?? ''}
                />

                <Button
                  alignSelf="end"
                  w="fit-content"
                  fontSize="0.875rem"
                  fontWeight="semibold"
                  lineHeight="1.25rem"
                  px="1rem"
                  py="0.5rem"
                  bg="brand.primary.500"
                  borderRadius="20px"
                  h="2rem"
                  color="white"
                  type="submit"
                  _hover={{
                    bg: 'brand.primary.400',
                    color: 'brand.primary.100',
                  }}
                  onClick={() =>
                    addTagToSearchParams({
                      displayName: pressumId ?? '',
                      category: CategoryType.Source,
                    })
                  }
                >
                  {`View full Pressum issue`}
                </Button>
              </VStack>
            )}
            <VStack align="left" spacing="1rem">
              <Text fontSize="2.25rem" fontWeight="semibold">
                {COUNTRIES_REGIONS_TEXT_LABEL}
              </Text>
              <HStack
                w="100%"
                spacing="2rem"
                overflowX="auto"
                sx={{
                  '&::-webkit-scrollbar': {
                    height: '0.5rem',
                    borderRadius: '0.5rem',
                    backgroundColor: `#EDF2F7`,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: `#4A5568`,
                    borderRadius: '0.5rem',
                  },
                }}
              >
                <HStack marginBottom="1.5rem" spacing="1rem">
                  {Object.keys(COUNTRY_SVG_MAP).map((country) => (
                    <Country
                      key={country}
                      country={country}
                      svg={COUNTRY_SVG_MAP[country].svg}
                      background={COUNTRY_SVG_MAP[country].background}
                    />
                  ))}
                </HStack>
              </HStack>
            </VStack>
          </>
        </VStack>
      )}
    </DocumentListLayout>
  )
}
