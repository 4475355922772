import {
  CollectionRes,
  CreateCollectionReq,
  CreateCollectionRes,
  DeleteCollectionRes,
} from '~shared/dtos/collection.dto'
import { ApiService } from './ApiService'
import {
  RenameUserCollectionReq,
  RenameUserCollectionRes,
} from '~shared/dtos/user-collection.dto'

export const getCollections = async () => {
  const { data } = await ApiService.get<CollectionRes[]>(`collection`)
  return data
}

export const deleteCollection = async (collectionId: number) => {
  const { data } = await ApiService.delete<DeleteCollectionRes>(
    `collection/${collectionId}`
  )
  return data
}

export const createCollection = async (payload?: CreateCollectionReq) => {
  const { data } = await ApiService.post<CreateCollectionRes>(
    `collection`,
    payload
  )
  return data
}

export const getAllCollectionsWithDocumentId = async (documentId: number) => {
  const { data } = await ApiService.get<CollectionRes[]>(
    `collection/document/${documentId}`
  )
  return data
}

export const updateCollectionName = async ({
  collectionId,
  payload,
}: {
  collectionId: number
  payload: RenameUserCollectionReq
}) => {
  const { data } = await ApiService.patch<RenameUserCollectionRes>(
    `collection/${collectionId}`,
    payload
  )
  return data
}
