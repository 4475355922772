import {
  getCollections,
  deleteCollection,
  createCollection,
  getAllCollectionsWithDocumentId,
  updateCollectionName,
} from '@/api/collection'
import { queryKeys } from '@/constants/query-key'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DEFAULT_COLLECTION } from '~shared/constants'
import { RenameUserCollectionReq } from '~shared/dtos'

export const useGetCollections = (userId: string) => {
  const { isLoading: isCollectionsLoading, data: collections } = useQuery({
    queryKey: queryKeys.collections(userId),
    queryFn: getCollections,
    refetchOnWindowFocus: false,
  })
  if (collections) {
    collections.sort((a, b) => {
      if (a.name === DEFAULT_COLLECTION) {
        return -1
      }
      if (b.name === DEFAULT_COLLECTION) {
        return 1
      }
      if (a.userCount === 1 && b.userCount !== 1) {
        return -1
      }
      if (a.userCount !== 1 && b.userCount === 1) {
        return 1
      }
      return a.name.localeCompare(b.name)
    })
  }

  return { isCollectionsLoading, collections }
}

export const useDeleteCollection = () => {
  const { mutateAsync, isLoading: isDeleteCollectionLoading } = useMutation({
    mutationFn: deleteCollection,
  })

  return {
    deleteCollection: mutateAsync,
    isDeleteCollectionLoading,
  }
}

export const useCreateCollection = (userId: string) => {
  const queryClient = useQueryClient()

  const {
    mutateAsync,
    isLoading: isCreateCollectionLoading,
    isError: isCreateCollectionError,
  } = useMutation({
    mutationFn: createCollection,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.collections(userId))
    },
  })

  return {
    createCollection: mutateAsync,
    isCreateCollectionLoading,
    isCreateCollectionError,
  }
}

export const useGetAllCollectionsWithDocumentId = (
  userId: string,
  documentId: number
) => {
  const { isLoading: isCollectionsLoading, data: collections } = useQuery({
    queryKey: queryKeys.bookmarkCollections(userId, documentId),
    queryFn: async () => {
      const data = await getAllCollectionsWithDocumentId(documentId)
      return data
    },
  })

  if (collections) {
    collections.sort((a, b) => {
      if (a.name === DEFAULT_COLLECTION) {
        return -1
      }
      if (b.name === DEFAULT_COLLECTION) {
        return 1
      }
      return a.name.localeCompare(b.name)
    })
  }

  return { isCollectionsLoading, collections }
}

export const useRenameCollection = ({
  collectionId,
  userId,
}: {
  userId: string
  collectionId: number
}) => {
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading: isRenameCollectionLoading } = useMutation({
    mutationFn: async (payload: RenameUserCollectionReq) => {
      const data = await updateCollectionName({ collectionId, payload })
      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryKeys.userCollections(collectionId)
      )
      await queryClient.invalidateQueries(queryKeys.collections(userId))
    },
  })

  return {
    renameCollection: mutateAsync,
    isRenameCollectionLoading,
  }
}
