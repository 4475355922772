import { useAcceptTou, useIsMobile } from '@/hooks'
import { Button, Stack } from '@chakra-ui/react'
import { useAuthContext } from '../useAuthContext'
import { useToast } from '@/hooks/useToast'

const SubmitButton = ({
  onSubmit,
  isDisabled,
}: {
  onSubmit: () => void
  isDisabled: boolean
}) => {
  return (
    <Button
      fontSize="1rem"
      fontWeight="semibold"
      size="lg"
      bg="orange.500"
      color="orange.100"
      _hover={{ bg: 'orange.600', color: 'orange.50' }}
      borderRadius="1.25rem"
      type="submit"
      isDisabled={isDisabled}
      onClick={onSubmit}
    >
      Agree
    </Button>
  )
}

const CancelButton = ({
  onClose,
  isDisabled,
}: {
  onClose: () => void
  isDisabled: boolean
}) => {
  return (
    <Button
      fontSize="1rem"
      fontWeight="semibold"
      size="lg"
      bg={{ base: 'transparent', lg: 'gray.100' }}
      color="gray.800"
      borderRadius="1.25rem"
      onClick={onClose}
      isDisabled={isDisabled}
    >
      Disagree
    </Button>
  )
}

type AgreementActionsProps = {
  onClose: () => void
}

export const AgreementActions = ({ onClose }: AgreementActionsProps) => {
  const isMobile = useIsMobile()
  const { logout } = useAuthContext()
  const { acceptTou, isLoading } = useAcceptTou()
  const toast = useToast()

  const handleSubmit = async () => {
    await acceptTou(undefined, {
      onSuccess: () => {
        toast({
          description: 'You have accepted the Terms of Use and Privacy Policy',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        onClose()
      },
      onError: () => {
        toast({
          description:
            'Failed to accept the Terms of Use and Privacy Policy. Please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      justify="end"
      w="100%"
      pt={{ lg: '1rem', base: '0' }}
      spacing="0.75rem"
    >
      {isMobile ? (
        <>
          <SubmitButton onSubmit={handleSubmit} isDisabled={isLoading} />
          <CancelButton
            onClose={() => {
              logout()
              onClose()
            }}
            isDisabled={isLoading}
          />
        </>
      ) : (
        <>
          <CancelButton
            onClose={() => {
              logout()
              onClose()
            }}
            isDisabled={isLoading}
          />
          <SubmitButton onSubmit={handleSubmit} isDisabled={isLoading} />
        </>
      )}
    </Stack>
  )
}
