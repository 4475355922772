import {
  Box,
  Container,
  Image,
  Link,
  Text,
  Flex,
  Stack,
  Wrap,
  Divider,
} from '@chakra-ui/react'
import DotcLogoSvg from '@/assets/dotc-logo.svg'
import InstagramIcon from '@/assets/social-media-insta-icon.svg'
import LinkedinIcon from '@/assets/social-media-linkedin-icon.svg'
import FacebookIcon from '@/assets/social-media-facebook-icon.svg'
import {
  PRIVACY_POLICY,
  TERMS_OF_USE,
  REPORT_VULNERABILITY,
  DIS_FACEBOOK,
  DIS_INSTAGRAM,
  DIS_LINKEDIN,
  DOTC_WEBSITE,
} from '@/constants/footer-links'
import { CONTACT_PRESSIFY_FORM } from '@/constants/feedback'
import { useIsMobile } from '@/hooks'

const SocialMediaLink = ({ href, src, alt }) => (
  <Link href={href} isExternal>
    <Image src={src} alt={alt} />
  </Link>
)

export const AppFooter = (): JSX.Element => {
  const currentYear = new Date().getFullYear()
  const isMobile = useIsMobile()

  const linkProps = {
    color: 'white',
    target: '_blank',
  }

  const textProps = {
    color: 'white',
    fontSize: '0.625rem',
    fontWeight: 'normal',
    lineHeight: '1rem',
  }

  return (
    <Flex
      as="footer"
      bg="gray.700"
      width="100%"
      py="1.125rem"
      direction="column"
      px={{ base: '1rem', lg: '5rem' }}
    >
      <Container maxW="100%" width="100%">
        <Flex
          justify="space-between"
          flexDir={{ base: 'column', lg: 'row' }}
          my="1.5rem"
        >
          <Stack
            flex={1}
            direction="column"
            paddingBottom={{ base: '1.5rem', lg: 0 }}
            paddingEnd={{ base: 0, lg: '1.5rem' }}
            align="baseline"
            spacing="0"
          >
            <Text
              color="white"
              fontSize={{ base: '1.5rem', lg: '1rem' }}
              fontWeight="500"
              lineHeight="1.5rem"
            >
              Pressify
            </Text>
            {!isMobile && (
              <Text {...textProps}>
                Less searching, more researching. Knowledge management made
                easy.
              </Text>
            )}
            <Wrap
              flex={1}
              shouldWrapChildren
              spacing={{ base: '1rem', md: '1.25rem' }}
              direction={{ base: 'column', md: 'row' }}
              justify={{ base: 'normal', md: 'flex-end' }}
              pt="1.5rem"
              fontSize={{ lg: '1rem', base: '0.875rem' }}
            >
              <Link {...linkProps} href={CONTACT_PRESSIFY_FORM}>
                Contact
              </Link>
              {/* <Link {...linkProps} href={USER_GUIDE}>
                Guide
              </Link> */}
              <Link {...linkProps} href={PRIVACY_POLICY}>
                Privacy
              </Link>
              <Link {...linkProps} href={TERMS_OF_USE}>
                Terms of Use
              </Link>
              <Link {...linkProps} href={REPORT_VULNERABILITY}>
                Report vulnerability
              </Link>
            </Wrap>
            {isMobile && <Divider mt="2.25rem" mb="2.25rem" />}
            <Box mt={{ base: '0', lg: '2rem' }}>
              <Text {...textProps}>Built by</Text>
            </Box>
            <Flex flexDir="row" gap="2.25rem" my="0.5rem">
              <Link href={DOTC_WEBSITE} isExternal>
                <Image src={DotcLogoSvg} alt="DOTC Logo" />
              </Link>
            </Flex>
          </Stack>
          <Box alignContent="flex-end">
            <Stack
              spacing="1rem"
              direction="row"
              mt="2rem"
              mb="0.5rem"
              justify={{ base: 'normal', lg: 'flex-end' }}
            >
              <SocialMediaLink
                href={DIS_INSTAGRAM}
                src={InstagramIcon}
                alt="Social Media"
              />
              <SocialMediaLink
                href={DIS_LINKEDIN}
                src={LinkedinIcon}
                alt="Social Media"
              />
              <SocialMediaLink
                href={DIS_FACEBOOK}
                src={FacebookIcon}
                alt="Social Media"
              />
            </Stack>
            <Flex
              align={{ base: 'left', lg: 'end' }}
              flexDir="column"
              gap="0.25rem"
            >
              <Text {...textProps}>
                ©{currentYear} Digital Ops-Tech Centre, a member of the Digital
                and Intelligence Service
              </Text>
              <Text {...textProps}>
                Singapore Armed Forces | Ministry of Defence Singapore
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Flex>
  )
}
