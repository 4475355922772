import { Field, useFormikContext } from 'formik'
import { useCollabContext } from './useCollabContext'
import { CollabRoleDropdown } from './CollabRoleDropdown'
import { DEFAULT_PERMISSION } from '~shared/constants'

type CollabRoleDropdownFieldProps = {
  isLoading: boolean
}

export const CollabRoleDropdownField = ({
  isLoading,
}: CollabRoleDropdownFieldProps) => {
  const { setFieldValue } = useFormikContext()

  const { isEdit } = useCollabContext()

  const onChange = ({ permission }) => {
    setFieldValue('permission', permission)
  }

  return (
    <Field name="permission">
      {() => (
        <CollabRoleDropdown
          isEnabled={isEdit}
          permission={DEFAULT_PERMISSION}
          onChange={onChange}
          isLoading={isLoading}
        />
      )}
    </Field>
  )
}
