import { RiPencilLine } from 'react-icons/ri'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuProps,
  Stack,
  Text,
} from '@chakra-ui/react'
import { commonHoverStyles } from '@/theme'
import { useNavigate } from 'react-router-dom'
import { AppNavPaths } from '@/utils/paths'
import { AiOutlineUpload } from 'react-icons/ai'
import { useIsMobile } from '@/hooks'

type UploadMenuProps = Omit<MenuProps, 'children'> & {
  selected?: boolean
}

export const UploadMenu = ({ selected, ...menuProps }: UploadMenuProps) => {
  const navigate = useNavigate()

  const isMobile = useIsMobile()
  return (
    <Menu {...menuProps} isLazy>
      {isMobile ? <MobileUploadButton selected={selected} /> : <UploadButton />}
      <MenuList>
        <MenuItem
          icon={<RiPencilLine size="1rem" />}
          onClick={() => {
            navigate(AppNavPaths.Manual)
          }}
        >
          Manual Entry
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

const UploadButton = () => {
  return (
    <MenuButton
      w="fit-content"
      borderRadius="1.25rem"
      width="5.438rem"
      height="2.5rem" // chakra's button default height
      cursor="default" // menu button cursor is pointer, we change back to default to match header buttons
      _hover={commonHoverStyles._hover}
    >
      <Text w="100%" align="center" fontWeight={600}>
        Upload
      </Text>
    </MenuButton>
  )
}

const MobileUploadButton = ({ selected }: { selected?: boolean }) => {
  return (
    <MenuButton>
      <Stack
        direction="column"
        spacing="0.25rem"
        alignItems="center"
        pt="0.5rem"
        color={selected ? 'orange.500' : 'inherit'}
      >
        <AiOutlineUpload width="1.5rem" height="1.5rem" />
        <Text fontSize="0.75rem">{'Upload'}</Text>
      </Stack>
    </MenuButton>
  )
}
