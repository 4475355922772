import { HStack } from '@chakra-ui/layout'
import { DocumentSearchbar } from '@/components/searchbar/document/DocumentSearchbar'
import { HeaderNavButtons } from './HeaderNavButtons'
import { Text } from '@chakra-ui/react'
import { zIndices } from '@/theme/z-index'
import { PressifyLogo } from '../PressifyLogo'

type NavBarHeaderProps = {
  hasSearchBar?: boolean
  hasLogo?: boolean
  hasRightBorderRadius?: boolean
  title?: string
}

export const NavBarHeader = ({
  hasSearchBar = false,
  hasLogo = false,
  hasRightBorderRadius = false,
  title,
}: NavBarHeaderProps) => {
  return (
    <HStack
      paddingRight="4rem"
      paddingLeft="2rem"
      w="100%"
      justifyContent={hasSearchBar || hasLogo ? 'space-between' : 'flex-end'}
      background="gray.300"
      borderBottomRightRadius={hasRightBorderRadius ? '3rem' : '0px'}
      position="sticky"
      top="0"
      zIndex={zIndices.navbar}
      spacing="3rem"
    >
      {hasSearchBar && <DocumentSearchbar />}
      {hasLogo && (
        <PressifyLogo
          isClickable
          variant="sm-light"
          styles={{
            ml: '2.25rem',
          }}
        />
      )}
      {title && (
        <Text fontWeight="600" fontSize="1.5rem" align="left" w="100%" p="0">
          {title}
        </Text>
      )}
      <HeaderNavButtons />
    </HStack>
  )
}
