import { CreateToastFnReturn } from '@chakra-ui/react'
import { isAxiosError } from 'axios'

export const uploadErrorToast = (err: unknown, toast: CreateToastFnReturn) => {
  if (isAxiosError(err)) {
    if (err.response?.status === 403) {
      toast({
        description:
          'Publishing rights to collection removed. Please try another collection.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } else {
      toast({
        description: `Publishing failed. Please try again later.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }

    return
  }

  toast({
    description: `Publishing failed. Please try again later.`,
    status: 'error',
    duration: 3000,
    isClosable: true,
  })
}
