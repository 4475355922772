import { FetchNextPageOptions, InfiniteData } from '@tanstack/react-query'
import { createContext, useContext } from 'react'
import {
  CollectionRes,
  DocumentTagWithCategory,
  PaginatedRes,
} from '~shared/dtos'

// not sure if i should assign the props like this. do refactor if anyone has a better way of structuring this.
type getTagsInCategoryHookProps = {
  data: InfiniteData<PaginatedRes<DocumentTagWithCategory>> | undefined
  isGetTagsInCategoryLoading: boolean
  isGetTagsInCategoryError: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<unknown>
  hasNextPage: boolean | undefined
}
type searchTagsHookProps = {
  data: InfiniteData<PaginatedRes<DocumentTagWithCategory>> | undefined
  isSearchTagsLoading: boolean
  isSearchTagsError: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<unknown>
  hasNextPage: boolean | undefined
}

type DocumentListContextProps = {
  isTagsExpanded: boolean
  expandSidebarIndex: number
  setExpandSidebarIndex: React.Dispatch<React.SetStateAction<number>>
  isFilterExpanded: boolean
  setIsFilterExpanded: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentCategory: React.Dispatch<React.SetStateAction<string>>
  currentCategory: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  searchQuery: string
  getTagsInCategory: getTagsInCategoryHookProps
  searchTags: searchTagsHookProps
  collection: CollectionRes | undefined
  collections: CollectionRes[] | undefined
  starredCollectionId: number
  isCollectionsLoading: boolean
  isStarredCollection: boolean
}

export const DocumentListContext = createContext<
  DocumentListContextProps | undefined
>(undefined)

export const useDocumentListContext = () => {
  const context = useContext(DocumentListContext)
  if (!context) {
    throw new Error(
      `DocumentListContext must be used within a DocumentListContext component`
    )
  }
  return context
}
