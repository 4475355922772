import { Button, Stack } from '@chakra-ui/react'
import { SelectedCollection } from '~shared/dtos'
import { useNavigate } from 'react-router-dom'
import { useCreateBookmarks } from '@/hooks/useBookmark'
import { useGetUserIdFromContext, useIsMobile } from '@/hooks'
import { useBookmarkContext } from '../useBookmarkContext'
import { BookmarkToast } from './BookmarkToast'
import { AppNavPaths } from '@/utils/paths'
import { useGetIsDocumentsPage } from '@/hooks/useGetIsDocumentsPage'
import { isAxiosError } from 'axios'
import { drawerModalButtonStyles } from '@/theme/styles'
import { useCallback } from 'react'
import { DEFAULT_COLLECTION } from '~shared/constants'
import { useToast } from '@/hooks/useToast'

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      bg={{ lg: 'red.100', base: 'parent' }}
      color={{ lg: 'red.800', base: 'red.500' }}
      _hover={{ bg: 'red.500', color: 'orange.50' }}
      onClick={onClick}
      {...drawerModalButtonStyles}
    >
      Close without saving
    </Button>
  )
}

const RemoveButton = ({
  onClick,
  isDisabled,
}: {
  onClick: () => void
  isDisabled: boolean
}) => {
  return (
    <Button
      bg={{ lg: 'red.100', base: 'parent' }}
      color={{ lg: 'red.800', base: 'red.500' }}
      _hover={{ bg: 'red.500', color: 'orange.50' }}
      onClick={onClick}
      isDisabled={isDisabled}
      {...drawerModalButtonStyles}
    >
      Cancel
    </Button>
  )
}

const SubmitButton = ({
  isLoading,
  isDisabled,
  onClick,
}: {
  isLoading: boolean
  isDisabled: boolean
  onClick: () => void
}) => {
  return (
    <Button
      bg={'green.100'}
      color={'green.800'}
      _hover={{ bg: 'green.400', color: 'orange.50' }}
      type="submit"
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      {...drawerModalButtonStyles}
    >
      Save Changes
    </Button>
  )
}

type AddBookmarkActionsProps = {
  onClose: () => void
  isSaveChangesAction?: boolean
}
export const AddBookmarkActions = ({
  onClose,
  isSaveChangesAction,
}: AddBookmarkActionsProps) => {
  const toast = useToast()
  const navigate = useNavigate()

  const isMobile = useIsMobile()
  const isDocumentsPage = useGetIsDocumentsPage()
  const { userId } = useGetUserIdFromContext()

  const {
    documentId,
    setSelectedCollections,
    selectedCollections,
    newCollectionIds,
  } = useBookmarkContext()

  const { createBookmarks, isCreateBookmarksLoading } = useCreateBookmarks({
    userId,
    documentId,
  })

  const isDisabled =
    selectedCollections.length === 0 || isCreateBookmarksLoading

  const removeAllPermittedCollections = useCallback(() => {
    return selectedCollections.filter((selected) => !selected.isEditable)
  }, [selectedCollections])

  const getNavigationText = (isDocumentsPage: boolean, isMobile: boolean) => {
    if (!isDocumentsPage) {
      return isMobile ? 'Manage' : 'Manage Collections'
    }
    return ''
  }
  const handleSubmit = async (selectedCollections: SelectedCollection[]) => {
    toast.closeAll()

    selectedCollections.forEach((selected) => {
      if (newCollectionIds.has(selected.collectionId)) {
        // might get removed if user deselect and select the new collection again
        selected.isNew = true
      }
    })
    await createBookmarks(
      {
        documentId,
        selectedCollections,
      },
      {
        onSuccess: () => {
          const navigationText = getNavigationText(isDocumentsPage, isMobile)
          toast({
            render: ({ onClose }) =>
              BookmarkToast({
                onClick: () => navigate(AppNavPaths.Documents),
                description: 'Collections Updated',
                navigationText,
                onClose,
              }),
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        },
        onError: (error) => {
          if (isAxiosError(error) && error.response?.status === 409) {
            toast({
              description: `"${DEFAULT_COLLECTION}" cannot be used as a collection name.`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          } else {
            toast({
              description: 'Unable to bookmark document.Please try again.',
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          }
        },
      }
    )
    onClose()
  }

  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      justify="end"
      w="100%"
      pt="1rem"
      spacing="0.75rem"
    >
      {isMobile ? (
        <>
          <SubmitButton
            isLoading={isCreateBookmarksLoading}
            isDisabled={isDisabled}
            onClick={() => {
              handleSubmit(selectedCollections)
            }}
          />
          {isSaveChangesAction ? (
            <CancelButton onClick={onClose} />
          ) : (
            <RemoveButton
              isDisabled={isDisabled}
              onClick={() =>
                setSelectedCollections(removeAllPermittedCollections)
              }
            />
          )}
        </>
      ) : (
        <>
          {isSaveChangesAction ? (
            <CancelButton onClick={onClose} />
          ) : (
            <RemoveButton
              isDisabled={isDisabled}
              onClick={() =>
                setSelectedCollections(removeAllPermittedCollections)
              }
            />
          )}
          <SubmitButton
            isDisabled={isDisabled}
            isLoading={isCreateBookmarksLoading}
            onClick={() => {
              handleSubmit(selectedCollections)
            }}
          />
        </>
      )}
    </Stack>
  )
}
