import {
  Box,
  Center,
  HStack,
  Spinner,
  VStack,
  useRadioGroup,
} from '@chakra-ui/react'
import { DocumentCard } from '../DocumentCard'
import { useEffect, useMemo } from 'react'
import { DocumentRes } from '~shared/dtos'
import { useInView } from 'react-intersection-observer'
import { ArticlesNotFoundCard } from '@/components/error'
import { SearchResultsText } from './SearchResultsText'
import { useSearchAllDocuments } from '@/hooks'
import { SortByOptions } from '@/components/SortByOptions'
import { NoBookmarkPlaceholder } from '../../bookmark/NoBookmarkPlaceholder'
import {
  useGetCollectionIdFromSearchParams,
  useGetCommonSearchParams,
  useGetHasFilters,
} from '@/hooks/params/useSearchParams'
import { sortSearchResults } from '@/utils/sort-search-results'
import { useToast } from '@/hooks/useToast'

export const DocumentList = () => {
  const { sort, tags, query, startDate, endDate, indices, collectionIds } =
    useGetCommonSearchParams()
  const toast = useToast()
  const {
    data,
    isSearchDocumentsError,
    isSearchDocumentsLoading,
    fetchNextPage,
    hasNextPage,
  } = useSearchAllDocuments({
    collectionIds,
    indices,
    sort,
    tags,
    query,
    startDate,
    endDate,
  })
  const { ref, inView } = useInView()
  const hasFilters = useGetHasFilters()

  const { collectionId } = useGetCollectionIdFromSearchParams()
  const documents = useMemo(() => {
    const allDocuments =
      data?.pages.reduce((arr, { data }) => {
        return arr.concat(data)
      }, [] as DocumentRes[]) ?? []
    const sortedData = sortSearchResults(allDocuments, sort)
    const pageLength = (data?.pages.length ?? 0) * 10
    return sortedData.slice(0, pageLength)
  }, [data, sort])

  useEffect(() => {
    if (inView) fetchNextPage()
  }, [inView, fetchNextPage])

  useEffect(() => {
    if (isSearchDocumentsError) {
      toast({
        title: 'Error',
        description: 'There was an error searching for documents.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }, [isSearchDocumentsError, toast])

  const { getRadioProps, getRootProps } = useRadioGroup()

  const group = getRootProps()

  // some time is required for collection id to be set in the search params, leading to a flicker for bookmark list page
  // the check for collectionId when on the collectionpage removes the flicker.
  if (isSearchDocumentsLoading) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    )
  }

  const isSearching = !!query || hasFilters
  const isNoBookmarks = collectionId && !documents?.length && !isSearching
  if (isNoBookmarks) {
    return <NoBookmarkPlaceholder />
  }

  if (!documents?.length) {
    return <ArticlesNotFoundCard />
  }

  return (
    <VStack h="100%" align="left" spacing="1rem" minHeight="calc(100vh - 9rem)">
      {data && (
        <HStack w="100%" justify="flex-end" align="end">
          <SearchResultsText totalResults={data.pages[0].totalResults ?? 0} />
          <SortByOptions />
        </HStack>
      )}
      <VStack
        {...group}
        spacing={{
          lg: '2.25rem',
          base: '1rem',
        }}
      >
        {documents.map((document: DocumentRes) => {
          return (
            <DocumentCard
              key={document.id}
              document={document}
              {...getRadioProps({ value: document.id })}
            />
          )
        })}
        {hasNextPage && !isSearchDocumentsError && (
          <Box ref={ref} textAlign="center">
            <Spinner />
          </Box>
        )}
      </VStack>
    </VStack>
  )
}
