import {
  Button,
  Code,
  Collapse,
  Divider,
  HStack,
  Text,
  Tooltip,
  UseRadioProps,
  VStack,
  useRadio,
} from '@chakra-ui/react'
import { DocumentRes } from '~shared/dtos/document.dto'
import { useEffect, useMemo, useState } from 'react'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { DocumentDateTime } from './DocumentDateTime'
import { ProcessedText } from '../../components/ProcessedText'
import { DocumentSummaryContent } from './DocumentSummaryContent'
import {
  DEFAULT_COLLECTION,
  SUMMARY_SHOULD_NOT_BE_GENERATED_TEXT,
} from '~shared/constants'
import { useIsMobile, useNavigateToDocumentPage } from '@/hooks'
import { useGetCollectionIdFromSearchParams } from '@/hooks/params/useSearchParams'
import { useCollabContext } from '../collections/collab/useCollabContext'
import { BookmarkButton } from '../bookmark/BookmarkButton'
import { CollectionList } from '../collections/CollectionList'
import { isEmpty } from 'lodash'
import { DocumentTags } from './DocumentTags'
import { useDocumentListContext } from '../home/useDocumentListContext'
import { StarButton } from './button/StarButton'
import { DeleteBookmarkButton } from '../bookmark/delete/DeleteBookmarkButton'
import { ExpandButton } from './button/ExpandButton'

type DocumentCardProps = UseRadioProps & {
  document: DocumentRes
}

const getSnippet = (content: string) => {
  const snippets = content.split(/[ \n]/)
  let parsedSnippets = ''
  for (let index = 0; index < snippets.length; index++) {
    parsedSnippets = [parsedSnippets, snippets[index]].join(' ')
    if (parsedSnippets.length > 500) {
      if (index !== snippets.length - 1) {
        parsedSnippets = parsedSnippets.concat('...')
        break
      }
    }
  }
  return parsedSnippets.trim()
}

export const DocumentCard = ({ document, ...props }: DocumentCardProps) => {
  const { id, title, dateTime, content, tags, summary } = document
  const { collectionId } = useGetCollectionIdFromSearchParams()

  const { isEdit } = useCollabContext()
  const { starredCollectionId } = useDocumentListContext()

  const isMobile = useIsMobile()
  const onNavigate = useNavigateToDocumentPage()

  const { getInputProps, getRadioProps } = useRadio(props)

  const [show, setShow] = useState(false)
  const [showScore, setShowScore] = useState(false)
  const [documentCollections, setDocumentCollections] = useState(
    document.collections ?? []
  )
  useEffect(() => {
    setDocumentCollections(document.collections ?? [])
  }, [document.collections])

  const parsedSnippet = getSnippet(content)
  const input = getInputProps()
  const radio = getRadioProps()

  const handleScoreToggle = () => {
    setShowScore(!showScore)
  }

  const handleToggle = (e) => {
    e.stopPropagation()
    setShow(!show)
  }

  //todo(ben): refactor with rightsidebar
  const collectionsWithoutStarred = useMemo(() => {
    return documentCollections?.filter(
      (collection) => collection.name !== DEFAULT_COLLECTION
    )
  }, [documentCollections])

  const isDocumentInStarredCollection = useMemo(() => {
    return !!documentCollections?.find(
      (collection) => collection.name === DEFAULT_COLLECTION
    )
  }, [documentCollections])

  const isInStarredCollection = collectionId === starredCollectionId

  return (
    <VStack
      padding={{
        lg: '2rem',
        base: '1rem',
      }}
      bg={
        !isEdit ? '#EDEDED' : show ? 'orange.50' : 'visualisation.card.snippet'
      }
      borderRadius={{
        lg: '0.5rem',
        base: '0.625rem',
      }}
      _hover={{
        bg: 'gray.100',
        transition: 'background-color 0.25s ease',
      }}
      borderTopRightRadius={{ lg: '3.125rem', base: '0.625rem' }}
      w="100%"
      spacing="1rem"
      cursor="pointer"
      onClick={() => onNavigate(`${id}`)}
    >
      <HStack
        {...radio}
        justifyContent="space-between"
        alignItems="flex-start"
        w="100%"
      >
        <input {...input} hidden />
        <VStack
          alignItems="flex-start"
          spacing={{
            lg: '1rem',
            base: '0.5rem',
          }}
          w="100%"
        >
          <HStack
            spacing={{ base: '0.25rem', lg: '1rem' }}
            width="100%"
            justifyContent="space-between"
          >
            <Text
              fontSize={{
                lg: '1.25rem',
                base: '0.75rem',
              }}
              fontWeight="bold"
              noOfLines={2}
            >
              {title}
            </Text>
            <HStack padding={0}>
              {!isMobile && <ExpandButton onClick={handleToggle} show={show} />}
              <StarButton
                documentId={+document.id}
                isDocumentInStarredCollection={isDocumentInStarredCollection}
                starredCollectionId={+starredCollectionId}
              />
              <BookmarkButton
                documentId={document.id}
                documentTitle={document.title}
                documentCollections={documentCollections}
              />
              {!isInStarredCollection && collectionId && isEdit && (
                <DeleteBookmarkButton documentId={+document.id} />
              )}
            </HStack>
          </HStack>
          <DocumentDateTime
            dateTime={dateTime}
            uploadedDateTime={document.createdAt}
          />

          {!isMobile &&
            !show &&
            (summary && summary !== SUMMARY_SHOULD_NOT_BE_GENERATED_TEXT ? (
              <DocumentSummaryContent
                summary={summary}
                sourceName={document.source}
                sourceLink={document.url}
              />
            ) : (
              <ProcessedText
                sourceName={document.source}
                sourceLink={document.url}
                isHideImage
                style={{ width: '100%', fontSize: '14px', cursor: 'text' }}
                content={parsedSnippet}
              />
            ))}

          <Collapse in={show}>
            <ProcessedText
              content={content}
              isHideImage
              sourceName={document.source}
              sourceLink={document.url}
            />
          </Collapse>
        </VStack>
      </HStack>
      {!isMobile && <Divider borderColor="visualisation.divider" />}
      <VStack w="100%" spacing="1rem">
        {collectionsWithoutStarred &&
          !isEmpty(collectionsWithoutStarred) &&
          !isMobile && (
            <CollectionList collections={collectionsWithoutStarred} />
          )}

        <HStack justifyContent="space-between" w="100%" alignItems="flex-start">
          <DocumentTags
            documentTags={tags}
            documentId={document.id}
            isTruncated={isMobile}
          />
          {/* only show in dev environment */}
          {document.explanation && !isMobile && (
            <VStack w="20%" align="flex-end">
              <Tooltip label="This will only show in dev env">
                <Button
                  fontSize="10px"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleScoreToggle()
                  }}
                  rightIcon={<RiArrowDropDownLine />}
                >
                  Search Score Details
                </Button>
              </Tooltip>
              <Collapse in={showScore}>
                <Code whiteSpace="pre-wrap" overflowY="auto" maxH="100px">
                  <Text fontSize="0.5rem" lineHeight="0.5rem">
                    {JSON.stringify(document.explanation, null, 2)}
                  </Text>
                </Code>
              </Collapse>
            </VStack>
          )}
        </HStack>
      </VStack>
    </VStack>
  )
}
