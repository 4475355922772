import { MobileDocumentContent } from '@/components/mobile/MobileDocumentContent'
import { MobileDocumentHeader } from '@/components/mobile/MobileDocumentHeader'
import { MobileNavBar } from '@/components/mobile/MobileNavBar'
import { RelatedArticlesList } from '@/components/RelatedArticlesList'
import { DisplayTagDrawer } from '@/features/document/document-tag-components'
import { useDisclosure, VStack } from '@chakra-ui/react'
import { DocumentRes } from '~shared/dtos'

type MobileDocumentProps = {
  document: DocumentRes
  isTranslateToVernacular: boolean
  setIsTranslateToVernacular: React.Dispatch<React.SetStateAction<boolean>>
  isVernacular: boolean
  isMaxRetriesExceeded: boolean
}

export const MobileDocumentPage = ({
  document,
  isTranslateToVernacular,
  setIsTranslateToVernacular,
  isVernacular,
  isMaxRetriesExceeded,
}: MobileDocumentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <VStack w="100%" h="100%">
      <VStack
        w="100%"
        h="100%"
        minHeight="calc(100vh - 4rem)" // 4 rem for the bottom navbar
        pl="1.25rem"
        pr="1.75rem"
        mb="2rem"
      >
        <MobileDocumentHeader
          documentId={document.id}
          documentTitle={document.title}
          documentCollections={document.collections ?? []}
          setIsTranslateToVernacular={setIsTranslateToVernacular}
          isTranslateToVernacular={isTranslateToVernacular}
          isVernacular={isVernacular}
          onOpen={onOpen}
          isOpen={isOpen}
        />
        <MobileDocumentContent
          isMaxRetriesExceeded={isMaxRetriesExceeded}
          document={document}
          isTranslateToVernacular={isTranslateToVernacular}
        />
        {/** can potentially refactor this together with the normal document apge */}
        <RelatedArticlesList />
      </VStack>

      <DisplayTagDrawer
        documentId={document.id}
        documentTags={document.tags}
        isOpen={isOpen}
        onClose={onClose}
      />

      <MobileNavBar />
    </VStack>
  )
}
