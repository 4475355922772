import { FILTER_DATE } from '@/utils/date'
import { DEFAULT_TAG_COLOR, TAG_TO_COLOR } from '@/utils/tag-to-color'
import { Button, Text } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { RxCross2 } from 'react-icons/rx'

type MobileFilterTagProps = {
  category: string
  displayNames: string[]

  onRemoveSelectedTags: (category: string) => void
}

export const MobileFilterTag = ({
  category,
  displayNames,
  onRemoveSelectedTags,
}: MobileFilterTagProps) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [isTagsTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth)
    }
  }, [])

  return (
    <Button
      marginLeft="auto"
      fontSize="0.75rem"
      height="1.25rem"
      borderRadius="0.375rem"
      padding="0.375rem"
      bg={
        category === FILTER_DATE
          ? 'gray.700'
          : TAG_TO_COLOR[category] ?? DEFAULT_TAG_COLOR
      }
      textColor={category === FILTER_DATE ? 'gray.50' : 'white'}
      rightIcon={<RxCross2 />}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation() // to stop it from triggering category selection
        onRemoveSelectedTags(category)
      }}
    >
      <Text ref={textRef} noOfLines={1} isTruncated display="inline">
        {displayNames.join(', ')}
      </Text>
      {isTagsTruncated && <Text>{`(${displayNames.length})`}</Text>}
    </Button>
  )
}
