import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useBookmarkContext } from '../useBookmarkContext'
import { AddBookmarkActions } from '../add/AddBookmarkActions'
import {
  BOOKMARK_SAVE_CHANGES_CAPTION,
  BOOKMARK_SAVE_CHANGES_HEADER,
} from '~shared/constants'

type SaveChangesModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const SaveChangesModal = ({
  isOpen,
  onClose,
}: SaveChangesModalProps) => {
  const { disclosureProps } = useBookmarkContext()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent padding="1.5rem">
        <ModalHeader px="0" pt="0" pb="1rem">
          {BOOKMARK_SAVE_CHANGES_HEADER}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="0" w="100%">
          {BOOKMARK_SAVE_CHANGES_CAPTION}
        </ModalBody>
        <AddBookmarkActions
          onClose={() => {
            onClose()
            disclosureProps.onClose()
          }}
          isSaveChangesAction
        />
      </ModalContent>
    </Modal>
  )
}
