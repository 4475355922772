import { DocumentCardButton } from '../../document/button/DocumentCardButton'
import { FiTrash2 } from 'react-icons/fi'
import { useDisclosure } from '@chakra-ui/react'
import { DeleteBookmarkModal } from './DeleteBookmarkModal'
import { useIsMobile } from '@/hooks'
import { DeleteBookmarkDrawer } from './DeleteBookmarkDrawer'

type DeleteBookmarkButtonProps = {
  documentId: number
}

export const DeleteBookmarkButton = ({
  documentId,
}: DeleteBookmarkButtonProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const isMobile = useIsMobile()
  return (
    <>
      <DocumentCardButton
        icon={<FiTrash2 size="1.5rem" />}
        onClick={onOpen}
        label={'Remove from Collection'}
      />
      {isMobile ? (
        <DeleteBookmarkDrawer
          isOpen={isOpen}
          onClose={onClose}
          documentId={documentId}
        />
      ) : (
        <DeleteBookmarkModal
          isOpen={isOpen}
          onClose={onClose}
          documentId={documentId}
        />
      )}
    </>
  )
}
