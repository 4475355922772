import { motion } from 'framer-motion'
import { useMemo } from 'react'

type StarIconProps = {
  confettiDocumentId: string
  isStarred: boolean
  isLoading: boolean
}

export const StarIcon = ({
  confettiDocumentId,
  isStarred,
  isLoading,
}: StarIconProps) => {
  const animate = useMemo(() => {
    if (isLoading) return 'loading'
    return isStarred ? 'active' : 'inactive'
  }, [isStarred, isLoading])

  const star =
    'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'

  const colors = {
    red: '#AA4A44',
    white: '#ffffff',
    gray: '#1A202C',
    orange: '#DD6B20',
  }

  const variants = {
    active: {
      stroke: colors.orange,
      fill: [colors.white, colors.red, colors.orange],
      fillOpacity: 1,
      scale: [1, 1.3, 0.8, 1],
    },
    loading: {
      fill: [colors.white, colors.gray],
      stroke: colors.gray,
      strokeWidth: '1px',
      transition: {
        duration: 0.7,
        ease: 'easeInOut',
        repeat: Infinity,
      },
    },
    inactive: {
      scale: [1.3, 0.8, 1],
      stroke: colors.gray,
      fillOpacity: 0,
      strokeWidth: '2px',
    },
  }

  return (
    <>
      <span // this is for the react-rewards confetti
        id={confettiDocumentId}
        style={{ height: 2 }}
      />
      <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24">
        <motion.path
          initial={false}
          d={star}
          animate={animate}
          variants={variants}
        />
      </svg>
    </>
  )
}
