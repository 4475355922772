import {
  createDocument,
  createManualUploadDocument,
  generateSummary,
  getDocument,
  getParsedDocument,
} from '@/api/document'
import { queryKeys } from '@/constants/query-key'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useIsMobile } from './useIsMobile'
import { useNavigate } from 'react-router-dom'
import { OpensourceUploadForm } from '@/types/document'
import { CreateOpensourceArticleReq } from '~shared/dtos'
import { HttpStatusCode, isAxiosError } from 'axios'
import { AppNavPaths } from '@/utils/paths'
import { useToast } from './useToast'

export const useGetDocument = (documentId: string) => {
  const toast = useToast()
  const navigate = useNavigate()
  const [isMaxRetriesExceeded, setIsMaxRetriesExceeded] =
    useState<boolean>(false)
  const { isLoading: isDocumentLoading, data: document } = useQuery({
    queryKey: queryKeys.document(+documentId),
    queryFn: async () => {
      const data = await getDocument(+documentId)
      return data
    },
    onError: (e) => {
      if (isAxiosError(e) && e.response?.status === HttpStatusCode.Forbidden) {
        toast({
          description: `You do not have permission to view this document.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        navigate(AppNavPaths.Documents)
        return
      }
      toast({
        description: `Error fetching document data. Please try again later.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
    retry: false,
    refetchInterval: (data, query) => {
      // max 3 retries for fetching summary
      const MAX_RETRIES = 5
      if (!data?.summary && query.state.dataUpdateCount <= MAX_RETRIES) {
        // retry in 15 seconds
        return 10000
      }

      // still no summary after MAX_RETRIES
      if (!data?.summary && query.state.dataUpdateCount > MAX_RETRIES) {
        setIsMaxRetriesExceeded(true)
        return false
      }

      return false
    },
  })
  return { isDocumentLoading, document, isMaxRetriesExceeded }
}

export const useGetParsedDocument = (url: string) => {
  const {
    isLoading: isParseDocumentLoading,
    data: parseDocument,
    isError: isParseDocumentError,
    error: ParseDocumentError,
  } = useQuery({
    queryKey: queryKeys.parsedDocument(url),
    queryFn: async () => {
      const data = await getParsedDocument(url)
      return data
    },
    staleTime: 60000,
    retry: false,
    refetchOnWindowFocus: false,
  })
  return {
    isParseDocumentLoading,
    parseDocument,
    isParseDocumentError,
    ParseDocumentError,
  }
}

export const useCreateDocument = () => {
  const queryClient = useQueryClient()
  const {
    mutateAsync,
    isLoading: isCreateDocumentLoading,
    isError: isCreateDocumentError,
  } = useMutation({
    mutationFn: async (formValues: OpensourceUploadForm) => {
      const {
        title,
        url,
        timeZone,
        source,
        date,
        time,
        slateContent,
        tags,
        vernacular,
        image,
        collection,
      } = formValues

      const data: CreateOpensourceArticleReq = {
        title,
        url,
        timeZone,
        source,
        date,
        time,
        slateContent,
        tags,
        vernacular,
        image,
        collection: {
          name: collection.label,
          isNew: collection.isNew,
          // new collection has no collectionId
          id: collection.value,
        },
      }

      return createDocument(data)
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(queryKeys.documents())
      await queryClient.invalidateQueries(queryKeys.parsedDocument(data.url))
    },
  })

  return {
    createDocumentMutate: mutateAsync,
    isCreateDocumentLoading,
    document,
    isCreateDocumentError,
  }
}

export const useManualUpload = () => {
  const queryClient = useQueryClient()
  const {
    mutateAsync,
    isLoading: isManualUploadLoading,
    isError: isManualUploadError,
  } = useMutation({
    mutationFn: createManualUploadDocument,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.documents())
    },
  })

  return {
    manualUploadMutate: mutateAsync,
    isManualUploadLoading,
    document,
    isManualUploadError,
  }
}

export const useCreateDocumentSummary = () => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading: isCreateSummaryLoading } = useMutation({
    mutationFn: generateSummary,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.documents())
    },
  })

  return {
    createSummaryMutate: mutateAsync,
    isCreateSummaryLoading,
  }
}

// this is an optimisation for navigating
export const useNavigateToDocumentPage = () => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const onNavigate = (id: string) => {
    // on mobile view, we don't open a new tab on click
    if (isMobile) return navigate(`/document/${id}`)
    return window.open(`/document/${id}`, '_blank', 'noopener')
  }

  return onNavigate
}
