import { useGetUserIdFromContext, useIsMobile } from '@/hooks'
import { useGetCollectionIdFromSearchParams } from '@/hooks/params/useSearchParams'
import { useDeleteBookmark } from '@/hooks/useBookmark'
import { useToast } from '@/hooks/useToast'
import { HStack, Button, VStack, ButtonProps } from '@chakra-ui/react'

type DeleteBookmarkActionsProp = {
  documentId: number
  onClose: () => void
}

export const DeleteBookmarkActions = ({
  documentId,
  onClose,
}: DeleteBookmarkActionsProp) => {
  const isMobile = useIsMobile()
  const { userId } = useGetUserIdFromContext()
  const toast = useToast()
  const { collectionId } = useGetCollectionIdFromSearchParams()
  const { deleteBookmark, isLoading } = useDeleteBookmark({
    userId,
    documentId,
    collectionId,
  })

  const handleOnDelete = () => {
    if (!collectionId) {
      toast({
        title: 'Failed to remove document from collection',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } else {
      deleteBookmark(
        {
          documentId,
          collectionId,
        },
        {
          onSuccess: () => {
            toast({
              title: 'Document removed from collection',
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
          },
          onError: () => {
            toast({
              title: 'Failed to remove document from collection',
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          },
        }
      )
    }
    onClose()
  }

  return isMobile ? (
    <VStack justify="end" w="100%" pt="1rem" spacing="0.75rem">
      <DeleteButton w="100%" isDisabled={isLoading} onClick={handleOnDelete} />
      <CancelButton w="100%" isLoading={isLoading} onClick={onClose} />
    </VStack>
  ) : (
    <HStack justify="end" w="100%" pt="1rem" spacing="0.75rem">
      <CancelButton isLoading={isLoading} onClick={onClose} />
      <DeleteButton isDisabled={isLoading} onClick={handleOnDelete} />
    </HStack>
  )
}

const CancelButton = (props: ButtonProps) => {
  return (
    <Button
      fontSize="1rem"
      fontWeight="semibold"
      size="lg"
      bg="gray.100"
      color="gray.800"
      borderRadius="1.25rem"
      {...props}
    >
      Cancel
    </Button>
  )
}

const DeleteButton = (props: ButtonProps) => {
  return (
    <Button
      fontSize="1rem"
      fontWeight="semibold"
      size="lg"
      bg="red.100"
      color="red.800"
      _hover={{ bg: 'red.500', color: 'orange.50' }}
      borderRadius="1.25rem"
      {...props}
    >
      Delete Document
    </Button>
  )
}
