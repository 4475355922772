import {
  Button,
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { wrapLink, isLinkActive, updateLink, unwrapLink } from '../utils'
import { useSlate } from 'slate-react'

type UpdateLinkModalProps = {
  isOpen: boolean
  onClose: () => void
  text: string
  url: string
}

export const UpdateLinkModal = ({
  isOpen,
  onClose,
  text,
  url,
}: UpdateLinkModalProps) => {
  const editor = useSlate()
  const [newText, setNewText] = useState(text)
  const [newUrl, setNewUrl] = useState(url)

  // requires a useEffect here as input constantly changes
  useEffect(() => {
    setNewText(text)
    setNewUrl(url)
  }, [text, url])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent padding="24px" background="background.default">
        <ModalHeader px="0px" pt="0px" pb="16px">
          Edit hyperlink
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="0px" w="100%">
          <VStack align="left" w="100%">
            <Text
              color="gray.600"
              fontSize="1rem"
              lineHeight="1.5rem"
              fontWeight="semibold"
            >
              Display text
            </Text>
            <InputGroup width="100%">
              <VStack w="100%" align="left">
                <Input
                  border="1px"
                  borderColor="transparent"
                  focusBorderColor="orange.400"
                  w="100%"
                  borderRadius="20px"
                  bg="white"
                  value={newText}
                  onChange={(e) => {
                    setNewText(e.target.value)
                  }}
                />
              </VStack>
            </InputGroup>
            <Text
              color="gray.600"
              fontSize="1rem"
              lineHeight="1.5rem"
              fontWeight="semibold"
            >
              Link
            </Text>
            <InputGroup width="100%">
              <VStack w="100%" align="left">
                <Input
                  border="1px"
                  borderColor="transparent"
                  focusBorderColor="orange.400"
                  w="100%"
                  borderRadius="20px"
                  bg="white"
                  value={newUrl}
                  onChange={(e) => {
                    setNewUrl(e.target.value)
                  }}
                />
              </VStack>
            </InputGroup>
          </VStack>
          <HStack justify="end" w="100%" pt="16px" spacing="12px">
            <Button
              fontSize="16px"
              fontWeight="semibold"
              size="lg"
              bg="gray.100"
              color="gray.800"
              borderRadius="20px"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              fontSize="16px"
              fontWeight="semibold"
              size="lg"
              bg={'green.100'}
              color={'green.800'}
              _hover={{ bg: 'green.400', color: 'orange.50' }}
              borderRadius="20px"
              onClick={() => {
                if (newUrl.trim() !== '')
                  isLinkActive(editor)
                    ? updateLink({
                        editor,
                        newUrl,
                        newText,
                      })
                    : wrapLink({
                        editor,
                        url: newUrl,
                        text: newText,
                      })
                else {
                  if (isLinkActive(editor)) {
                    unwrapLink(editor)
                  }
                }
                onClose()
              }}
            >
              Update hyperlink
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
