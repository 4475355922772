import * as Yup from 'yup'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import { EMPTY_COLLECTION_VALUE, EMPTY_SLATE_CONTENT } from './constants'
import { isEqual } from 'lodash'
import { checkIfSlateHasContent } from '~shared/utils'
import { S3_UPLOAD_MAX_FILE_SIZE } from '~shared/constants'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.tz.setDefault('Asia/Singapore')
dayjs.extend(isSameOrBefore)

function isDateString(dateString) {
  const regex = /^(0?\d|[12]\d|3[01])\/(0?\d|1[012])\/\d{4}$/

  return regex.test(dateString)
}

const isTimeString = (value) => {
  const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

  return regex.test(value)
}

export const isDateValid = (date, time, timeZone) => {
  const dateTimeString = `${date} ${time}`
  const inputDate = dayjs.tz(dateTimeString, 'DD/MM/YYYY HH:mm', timeZone)
  return inputDate.isSameOrBefore(dayjs())
}

export const uploadValidationSchema = Yup.object().shape({
  slateContent: Yup.array()
    .required('Content is Required')
    .test('is-content-size-valid', 'Content size is too large', (value) => {
      const encoder = new TextEncoder()
      // for some reason yup changes all empty text value to undefined.
      // we need to change them back to correctly compare the size
      value.forEach((descendant) => {
        return descendant['children'].forEach((value, index) => {
          value.text = value.text ? value.text : ''
          descendant['children'][index] = value
        })
      })
      const contentSize = encoder.encode(JSON.stringify(value)).buffer
        .byteLength
      return contentSize < S3_UPLOAD_MAX_FILE_SIZE
    })
    .test('is-content', 'Text Content is Required', (value) => {
      return checkIfSlateHasContent(value)
    })
    .test(
      'is-content-not-equals-to-vernacular',
      ' English translated content cannot be similar to Vernacular content',
      function (value) {
        const vernacular = this.parent.vernacular
        if (!vernacular) {
          return true
        }
        return !isEqual(value, vernacular.slateContent)
      }
    ),
  date: Yup.string()
    .trim()
    .required('Date is Required.')
    .test('is-date-string', 'Please enter a valid date.', isDateString),
  time: Yup.string()
    .trim()
    .test('is-time-string', 'Please enter a valid time.', isTimeString),
  title: Yup.string().trim().required('Title is Required'),
  source: Yup.string().trim(),
  url: Yup.string()
    .url('Please enter a valid URL (url should start with http/https)')
    .trim(),
  vernacular: Yup.object()
    .default(undefined)
    .shape({
      title: Yup.string().trim().required('Title is Required'),
      slateContent: Yup.array()
        .default(EMPTY_SLATE_CONTENT)
        .test('is-content', 'Text Content is Required', (value) => {
          return checkIfSlateHasContent(value)
        })
        .test(
          'is-content-not-equals-to-english',
          'Vernacular content cannot be similar to English translated content',
          function (value) {
            const englishSlateContent = this.options.context?.slateContent
            if (!englishSlateContent || !value) {
              return true
            }

            return !isEqual(englishSlateContent, value)
          }
        ),
    }),
  collection: Yup.object()
    .test('is-collection-empty', 'Collection is Required', (value) => {
      return !isEqual(value, EMPTY_COLLECTION_VALUE)
    })
    .shape({
      isNew: Yup.boolean().default(EMPTY_COLLECTION_VALUE.isNew),
      label: Yup.string().default(EMPTY_COLLECTION_VALUE.label),
      value: Yup.number()
        .default(EMPTY_COLLECTION_VALUE.value)
        .required('Collection is required')
        .typeError('Invalid value'), // HACK: yup treats NaN as invalid number
    }),
})
