import { searchFilterParam } from '@/constants/search-params'
import { toDateString } from '@/utils/date'
import { Button, WrapItem } from '@chakra-ui/react'
import { useMemo } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { useSearchParams } from 'react-router-dom'

export const DateFilterTag = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    date: { startDate: startDateParam, endDate: endDateParam },
  } = searchFilterParam
  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: searchParams.get(startDateParam),
      endDate: searchParams.get(endDateParam),
    }
  }, [endDateParam, searchParams, startDateParam])

  const isSameDate = startDate === endDate

  return (
    <>
      {startDate && endDate && (
        <WrapItem
          key={'date-time-filter'}
          onClick={() => {
            searchParams.delete(startDateParam)
            searchParams.delete(endDateParam)
            setSearchParams(searchParams)
          }}
        >
          <Button
            fontSize="12px"
            height="20px"
            borderRadius="6px"
            padding="6px"
            bg="gray.700"
            textColor="gray.50"
            rightIcon={<RxCross2 />}
          >
            {isSameDate
              ? `${toDateString(startDate)}`
              : `${toDateString(startDate)} - ${toDateString(endDate)}`}
          </Button>
        </WrapItem>
      )}
    </>
  )
}
