import { HStack, Text, Box } from '@chakra-ui/react'
import { ErrorMessage as FormikErrorMessage } from 'formik'
import { MdError } from 'react-icons/md'

type ErrorMessageWithIconProps = {
  fieldName: string
}

export const ErrorMessageWithIcon = ({
  fieldName,
}: ErrorMessageWithIconProps) => {
  return (
    <FormikErrorMessage name={fieldName}>
      {(msg) => <ErrorMessage msg={msg} />}
    </FormikErrorMessage>
  )
}

type ErrorMessageProps = {
  msg: string
}

export const ErrorMessage = ({ msg }: ErrorMessageProps) => {
  return (
    <HStack ml="0.5rem" fontSize="0.875rem" spacing="0.5rem">
      <Box
        as={MdError}
        alignSelf="start"
        color="red.600"
        width="1rem"
        height="1rem"
        mt="0.15rem"
      />
      <Text w="90%" color="red.600">
        {msg}
      </Text>
    </HStack>
  )
}
