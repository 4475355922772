import { useRenameCollection } from '@/hooks/useCollection'
import { isAxiosError } from 'axios'
import {
  COLLECTION_NAME_REGEX_VALIDATION,
  DEFAULT_COLLECTION,
  MAX_COLLECTION_NAME_LENGTH,
} from '~shared/constants'
import * as Yup from 'yup'
import { useToast } from '@/hooks/useToast'

export const RenameCollectionSchema = Yup.object().shape({
  collectionName: Yup.string()
    .trim()
    .required('Collection name should not be empty')
    .max(
      MAX_COLLECTION_NAME_LENGTH,
      'Collection name cannot exceed 24 characters'
    )
    .matches(
      COLLECTION_NAME_REGEX_VALIDATION,
      'Only alphanumeric characters are allowed'
    )
    .test(
      ' not default collection',
      `"${DEFAULT_COLLECTION}" cannot be used as a collection name.`,
      function (value) {
        if (!value) return true
        return value.toLowerCase() !== DEFAULT_COLLECTION.toLowerCase()
      }
    ),
})

// only used for desktop renaming collection in the header.
const handleHeaderValidation = async ({
  toast,
  collectionName,
  oldCollectionName,
  setCollectionName,
}: {
  toast: ReturnType<typeof useToast>
  collectionName: string
  oldCollectionName: string
  setCollectionName?: (value: string) => void
}) => {
  try {
    await RenameCollectionSchema.validate({
      collectionName,
    })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast({
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } else {
      toast({
        description: `Unable to rename collections. Please try again later.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
    if (setCollectionName) setCollectionName(oldCollectionName)
    return false
  }
}

export const handleRenameCollection = async ({
  oldCollectionName,
  collectionName,
  renameCollection,
  toast,
  setCollectionName,
}: {
  oldCollectionName: string
  collectionName: string
  renameCollection: ReturnType<typeof useRenameCollection>['renameCollection']
  toast: ReturnType<typeof useToast>
  setCollectionName?: (value: string) => void
}) => {
  if (oldCollectionName.trim() !== collectionName.trim()) {
    await renameCollection(
      { name: collectionName.trim() },
      {
        onSuccess: ({ collectionName }) => {
          toast({
            description: `Collection Renamed to ${collectionName} `,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        },
        onError: (error) => {
          if (isAxiosError(error) && error.response?.status === 409) {
            toast({
              description: '"Starred" cannot be used as a collection name.',
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          } else {
            toast({
              description: `Unable to rename collections. Please try again later.`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          }
          if (setCollectionName) setCollectionName(oldCollectionName ?? '')
        },
      }
    )
  }
  if (setCollectionName) setCollectionName(collectionName.trim())
}

export const handleSubmit = async ({
  oldCollectionName,
  collectionName,
  setCollectionName,
  toast,
  renameCollection,
}: {
  oldCollectionName: string
  collectionName: string
  setCollectionName?: (value: string) => void
  toast: ReturnType<typeof useToast>
  renameCollection: ReturnType<typeof useRenameCollection>['renameCollection']
}) => {
  const isValid = await handleHeaderValidation({
    collectionName,
    oldCollectionName,
    setCollectionName,
    toast,
  })
  if (isValid) {
    await handleRenameCollection({
      oldCollectionName,
      collectionName,
      renameCollection,
      toast,
      setCollectionName,
    })
  }
}
