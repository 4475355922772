import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  HStack,
  Text,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { BiFilter } from 'react-icons/bi'
import { FilterTagActions } from '@/components/tag/FilterTagActions'
import { useSearchParams } from 'react-router-dom'
import { useSearchAllDocuments } from '@/hooks'
import { FilterTagSearch } from './FilterTagSearch'
import { FilterTagCategorySearch } from './FilterTagCategorySearch'
import { useFilterTagContext } from './useFilterTagContext'
import { FilterTagCategorySelection } from './FilterTagCategorySelection'
import { searchFilterParam } from '@/constants/search-params'
import { FilterTagsProvider } from './FilterTagsContext'
import { parseISO } from 'date-fns'
import { useGetCommonSearchParams } from '@/hooks/params/useSearchParams'

type MobileFilterTagsDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

const MobileFilterTagsDrawerComponent = ({
  isOpen,
  onClose,
}: MobileFilterTagsDrawerProps) => {
  const {
    selectedTags,
    setSelectedTags,

    selectedCategory,
    setSelectedCategory,

    selectedDate,
    setSelectedDate,
  } = useFilterTagContext()

  const {
    tags: tagParamKey,
    date: { startDate: startDateParam, endDate: endDateParam },
  } = searchFilterParam
  const [displayQuery, setDisplayQuery] = useState('')
  const [searchParams] = useSearchParams()
  const { sort, query, indices, collectionIds, startDate, endDate } =
    useGetCommonSearchParams()

  // initialise tags from url params
  useEffect(() => {
    const initialTags = searchParams.getAll(tagParamKey).map((tag) => {
      const [displayName, category] = tag.split(',')
      return {
        displayName,
        category,
      }
    })
    setSelectedTags(initialTags)

    // parseISO parses format 'yyyy-MM-dd' to date
    const initialStartDate = searchParams.get(startDateParam)
    if (initialStartDate) {
      setSelectedDate((prev) => {
        return { from: parseISO(initialStartDate), to: prev?.to }
      })
    }

    const initialEndDate = searchParams.get(endDateParam)
    if (initialEndDate) {
      setSelectedDate((prev) => {
        return { from: prev?.from, to: parseISO(initialEndDate) }
      })
    }

    if (!initialStartDate && !initialEndDate) {
      setSelectedDate(undefined)
    }

    // need to set tags from url params to context when drawer is toggled
  }, [
    searchParams,
    isOpen,
    tagParamKey,
    setSelectedDate,
    setSelectedTags,
    startDate,
    endDate,
    startDateParam,
    endDateParam,
  ])

  const onCloseCleanup = useCallback(() => {
    setSelectedTags([])
    setSelectedCategory('')
    setSelectedDate(undefined)
    setDisplayQuery('')
    onClose()
  }, [onClose, setSelectedCategory, setSelectedDate, setSelectedTags])

  // when there is no search for tags and no category selected
  const showCategorySelection = selectedCategory === '' && displayQuery === ''

  const hasSelectedCategory = selectedCategory !== ''

  const { data, isSearchDocumentsLoading: isLoading } = useSearchAllDocuments({
    collectionIds,
    enabled:
      isOpen &&
      showCategorySelection &&
      (selectedTags.length > 0 || selectedDate !== undefined),
    tags: selectedTags,
    startDate,
    endDate,
    sort,
    indices,
    query,
  })

  const totalResults = data?.pages[0].totalResults ?? 0

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onCloseCleanup}
      placement="left"
      size="full"
      allowPinchZoom
    >
      <DrawerContent height="100%" backgroundColor="gray.800">
        {showCategorySelection && <DrawerCloseButton color="white" />}
        <DrawerHeader>
          <HStack>
            <BiFilter color="#F8F9F9" />
            <Text color="gray.50" fontSize="1rem" fontWeight={700}>
              FILTERS
            </Text>
          </HStack>
        </DrawerHeader>

        <DrawerBody>
          {!hasSelectedCategory && (
            <FilterTagSearch
              displayQuery={displayQuery}
              setDisplayQuery={setDisplayQuery}
            />
          )}
          {showCategorySelection && <FilterTagCategorySelection />}
          {hasSelectedCategory && <FilterTagCategorySearch />}
        </DrawerBody>

        <DrawerFooter>
          <FilterTagActions
            onCloseDrawer={onClose}
            onReturnToCategories={() => {
              setSelectedCategory('')
              setDisplayQuery('')
            }}
            isShowCategorySelection={showCategorySelection}
            totalResults={totalResults}
            isResultLoading={isLoading}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export const MobileFilterTagsDrawer = ({
  isOpen,
  onClose,
}: MobileFilterTagsDrawerProps) => {
  return (
    <FilterTagsProvider>
      <MobileFilterTagsDrawerComponent isOpen={isOpen} onClose={onClose} />
    </FilterTagsProvider>
  )
}
